import activationUIReducer, { ActivationUiReducerState } from '../features/Activation/redux/reducer'
import walkthrough, { Reducer as WalkthroughState } from '../features/App/components/Walkthrough/redux/reducers'
import dashboardReducer from '../features/Dashboard/redux/reducers'
import { reducer as localization, LocalizationState } from '../features/Localize/redux/slice'
import messagesReducer, { State as MessagesState } from '../features/Messages/redux/reducers'
import onBoardingReducer, { State as OnboardingReducer } from '../features/OnBoarding/redux/reducer'
import crumbOverrides, { State as CrumbsState } from '../features/Topbar/components/Breadcrumbs/redux/reducer'
import joinReducers, { JoinFormReducerState } from '../features/UnProtected/JoinPage/redux/reducers'
import resetPassword, { ResetPasswordReducerState } from '../features/UnProtected/ResetPassword/redux/reducers'
import loginFormReducers, { LoginFormReducerState } from '../features/UnProtected/SignInPage/redux/reducers'
import signUpReducer, { SignUpState } from '../features/UnProtected/SignUpPage/redux/reducer'
import signUpVerifyReducer, { SignUpVerifyState } from '../features/UnProtected/SignupVerifiedPage/redux/reducer'
import userProfileReducer, { State as UserProfileState } from '../features/UserProfile/redux/reducers'
import accountReducer, { AccountReducerState } from './account/reducers'
import activations, { Reducer as ActivationsState } from './activations/reducers'
import App, { State as AppState } from './app/reducer'
import Auth, { AuthReducerState } from './auth/reducer'
import Countries, { State as CountriesState } from './countries/reducer'
import Currencies, { State as CurrenciesState } from './currencies/reducer'
import liveTestReducer, { EnvironmentReducer } from './environment/reducer'
import FeatureToggles, { State as FeatureTogglesState } from './featureToggles/reducer'
import providersReducer, { State as ProvidersState } from './providers/reducer'
import { rolesReducer, State as RolesState } from './roles/reducer'
import userConfigurationReducer, { State as UserConfigurationState } from './userConfiguration/reducer'

export interface AppReducersState {
  Auth: AuthReducerState;
  App: AppState;
  crumbOverrides: CrumbsState;
  providersReducer: ProvidersState;
  liveTestReducer: EnvironmentReducer;
  dashboardReducer: any;
  accountReducer: AccountReducerState;
  signUp: SignUpState;
  signUpVerify: SignUpVerifyState;
  loginFormReducers: LoginFormReducerState;
  Currencies: CurrenciesState;
  rolesReducer: RolesState;
  allRolesReducer: RolesState;
  Countries: CountriesState;
  messagesReducer: MessagesState;
  resetPassword: ResetPasswordReducerState;
  joinReducers: JoinFormReducerState;
  activationUIReducer: ActivationUiReducerState;
  activations: ActivationsState;
  FeatureToggles: FeatureTogglesState,
  walkthrough: WalkthroughState;
  localization: LocalizationState;
  userProfileReducer: UserProfileState;
  userConfigurationReducer: UserConfigurationState;
  onBoarding: OnboardingReducer
}

export default {
  Auth,
  App,
  crumbOverrides,
  providersReducer,
  liveTestReducer,
  dashboardReducer,
  accountReducer,
  signUpVerify: signUpVerifyReducer,
  signUp: signUpReducer,
  loginFormReducers,
  Currencies,
  rolesReducer,
  Countries,
  messagesReducer,
  resetPassword,
  joinReducers,
  activationUIReducer,
  activations,
  FeatureToggles,
  walkthrough,
  localization,
  userProfileReducer,
  userConfigurationReducer,
  onBoarding: onBoardingReducer
}
