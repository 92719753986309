import { useLocalize } from '@zooz/react-localize'
import jwt from 'jsonwebtoken'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import sentry from '../../../helpers/sentry'
import Locations from '../../../helpers/tools/locations'
import { withLoadTranslations } from '../../Localize/HOCs'
import { ScreenNames } from '../../Localize/screens'
import { Page } from '../shared'
import language from './language'
import RequestResetForm from './RequestResetForm'
import ResetForm from './ResetForm'

const KEY = 'RESET_PASSWORD_TOKEN'

const ResetPassword: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [token, setToken] = useState(sessionStorage.getItem(KEY) || null)
  const { t } = useLocalize()

  useEffect(() => {
    try {
      if (!sessionStorage.getItem(KEY)) {
        const searchToken = queryString.parse(location.search).token as string
        if (searchToken) {
          sessionStorage.setItem(KEY, searchToken)
          setToken(searchToken)
          history.push(Locations.forgotPassword())
        }
      }
    } catch (error) {
      sentry.captureException(error as Error)
      console.error('failed to decode token', error)
    }

    return () => {
      sessionStorage.removeItem(KEY)
    }
  }, [])

  const fromEmail = !!token
  let title = language.requestResetForm.title(t)
  let subtitle = language.requestResetForm.subtitle(t)
  let email = ''
  if (fromEmail) {
    subtitle = ''
    title = language.resetForm.title(t)
    try {
      email = ((jwt.decode(token)) as { email:string }).email
    } catch (error) {
      sentry.captureException(error as Error)
      console.error('failed to decode token', error)
    }
  }
  return (
    <Page
      title={title}
      subtitle={subtitle}
      links={[
        { to: Locations.login(), id: 'back-login', text: language.link.login(t) }
      ]}
    >
      {fromEmail ? <ResetForm token={token} email={email} /> : <RequestResetForm />}
    </Page>
  )
}

export default withLoadTranslations<RouteComponentProps>({ screenName: ScreenNames.ResetPassword })(ResetPassword)
