// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__CheckboxWithDescription__labeledCheckbox___dShf2{align-items:flex-start}.style__checkbox___ElaEX{margin-top:5px}.style__CheckboxWithDescription__text___djeUu{font-size:12px}`, "",{"version":3,"sources":["webpack://./src/features/UnProtected/JoinPage/components/Checkbox/style.scss"],"names":[],"mappings":"AAEA,yDACE,sBAAA,CAGF,yBACE,cAAA,CAGF,8CACE,cAAA","sourcesContent":["@import '~@zooz/generic-ui-components/shared-styles/colors';\n\n.CheckboxWithDescription__labeledCheckbox {\n  align-items: flex-start;\n}\n\n.checkbox {\n  margin-top: 5px;\n}\n\n.CheckboxWithDescription__text {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default-turquoise": `#0d91bd`,
	"error-red": `#f9453e`,
	"danger-red": `#eb7a7c`,
	"border-grey": `#e9e9e9`,
	"white": `#fff`,
	"default-blue": `#557eff`,
	"active-blue": `#3750cd`,
	"glowing-blue": `#0d47ff`,
	"text-black": `#555`,
	"text-grey": `#778195`,
	"disabled-grey-background": `#f9fafb`,
	"disabled-grey-text": `#a8a8a8`,
	"disabled-light-grey-text": `#c2c2c2`,
	"disabled-grey-button": `#969696`,
	"disabled-light-grey-button": `#c2c2c2`,
	"disabled-grey-button-hover": `#545454`,
	"success-green": `#03b571`,
	"list-item-grey": `#f3f3f3`,
	"outline-color": `#79c2db`,
	"greyout": `#fafafa`,
	"button-hover": `#7395ff`,
	"button-active": `#3750cd`,
	"light-orange": `#eba205`,
	"input-focus-shadow": `rgba(13,145,189,.2509803922)`,
	"CheckboxWithDescription__labeledCheckbox": `style__CheckboxWithDescription__labeledCheckbox___dShf2`,
	"checkbox": `style__checkbox___ElaEX`,
	"CheckboxWithDescription__text": `style__CheckboxWithDescription__text___djeUu`
};
export default ___CSS_LOADER_EXPORT___;
