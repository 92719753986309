export const TOGGLE_TEST_LIVE = 'TOGGLE_TEST_LIVE'
export const SET_TEST_LIVE = 'SET_TEST_LIVE'

interface ToggleTestLiveAction {
  type: typeof TOGGLE_TEST_LIVE
}

interface SetTestLiveAction {
  type: typeof SET_TEST_LIVE,
  liveStatus: boolean
}

export const toggleTestLive = (): ToggleTestLiveAction => ({ type: TOGGLE_TEST_LIVE })
export const setTestLive = (liveStatus: boolean): SetTestLiveAction => ({ type: SET_TEST_LIVE, liveStatus })

export type EnvironmentActions = SetTestLiveAction | ToggleTestLiveAction
