// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Walkthrough is not typed correctly but not really in use so for now not putting any effort into it
import { createSelector } from 'reselect'

import LocalStorageAccessor from '../../../../../helpers/localStorageAccessor/localStorageAccessor'
import { Reducer } from './reducers'

interface State {
  walkthrough: Reducer
}

const getSteps = (state: State) => state.walkthrough.steps

export const getDismissed = (state: State) => {
  const stateValue = state.walkthrough.dismissed
  const dismissed = LocalStorageAccessor.walkthroughDismissed || stateValue
  return typeof dismissed === 'string' ? dismissed === 'true' : dismissed
}

const getCurrentStepIndexStored = (state: State) => state.walkthrough.current
export const getCurrentStepIndex = createSelector(getCurrentStepIndexStored, getSteps, (index, steps) => {
  if (steps[index]) {
    return index
  }
  const availableIndices = Object.keys(steps)
  const indexToReturn = availableIndices.find(availableIndex => parseInt(availableIndex) > index)
  return indexToReturn ? Number(indexToReturn) : -1
})

export const isAvailablePreviousStep = createSelector(getCurrentStepIndex, getSteps, (index, steps) => {
  if (steps[index - 1]) {
    return true
  }
  const availableIndices = Object.keys(steps)
  const indexToReturn = availableIndices.find(availableIndex => parseInt(availableIndex) < index)
  return !!indexToReturn
})

export const getNumberOfSteps = createSelector(getSteps, steps => Object.keys(steps).length)

export const isDisplayingHint = createSelector(getCurrentStepIndex, getDismissed, (index, isDismissed) => {
  if (isDismissed) { return false }
  return !isNaN(index) && index >= 0
})

export const getCurrentStep = createSelector(getSteps, getCurrentStepIndex, (steps, index) => {
  if (steps[index]) {
    return steps[index]
  }
  return null
})
