import { FeaturesToggle } from '@payu/paymentsos-types'

import { PortalState } from '../store'

export const getAccountLevelFeatureToggles = (state: PortalState): FeaturesToggle.ToggleByAccountResponse[] => (
  state.FeatureToggles.account
)
export const isAccountLevelFeatureTogglesFetched = (state: PortalState): boolean => (
  state.FeatureToggles.isFetched.account
)
