import React, { Attributes, useCallback } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import style from './MenusMeasuerer.scss'

interface MenusMeasurerProps {
  onResize: (width: number) => void
}

const MenusMeasurer: React.FC<MenusMeasurerProps> = ({ children, onResize }) => {
  const onResizeMeasure = useCallback((width) => {
    onResize(width)
  }, [])

  const { ref } = useResizeDetector({
    onResize: onResizeMeasure,
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 200
  })

  const expandedMenus = React.Children.map(children, (menu) => {
    if (React.isValidElement(menu)) {
      return React.cloneElement(menu, {
        stub: true,
        children: React.Children.map(menu.props.children, (subMenu) => {
          if (React.isValidElement(subMenu)) {
            // @ts-ignore: does not support props
            return React.cloneElement(subMenu, { stub: true })
          }
          return subMenu
        })
      } as Partial<unknown> & Attributes)
    }
    return menu
  })

  return (
    <>
      {children}
      <div ref={ref} className={style.Measurer}>
        {expandedMenus}
      </div>
    </>
  )
}

export default MenusMeasurer
