import { Analytics } from '@payu/paymentsos-types'

import apiConfig from '../../../api/AnalyticsApiHelpers/apiConfig'
import { stagesNames, PaymentStageNamesTypes } from '../components/paymentStagesConfig'
import { DEFAULT_CURRENCY } from '../config'

// view is added by the api request function
type PartialAnalyticsRequest = Omit<Analytics.AnalyticsRequest, 'view'>

const createFilters = (
  countries: string[],
  apps: string[],
  paymentStage?: PaymentStageNamesTypes
): Analytics.Filters => {
  const filters = []
  if (countries) {
    if (countries instanceof Array && countries.length !== 0) { // do nothing
      filters.push(createFilter(apiConfig.dimensions.country, countries))
    }
  }
  if (apps) {
    if (apps instanceof Array && apps.length !== 0) { // do nothing
      filters.push(createFilter(apiConfig.dimensions.application, apps))
    }
  }
  if (paymentStage) {
    filters.push(createFilter(apiConfig.dimensions.paymentStage, paymentStage))
  }
  return filters
}

const createFilter = (
  name: Analytics.Dimension,
  value: string | string[]
): Analytics.DimensionFilter => ({
  dimension: name,
  values: typeof value === 'string' ? [value] : value
})

const shouldIncludeOriginalCurrencyMetric = (metricType: Analytics.Metric['name'], dashboardCurrency: string)
  : boolean => metricType === apiConfig.metrics.totalAmount && dashboardCurrency !== DEFAULT_CURRENCY

export const getDimensionsPerPaymentStage = (paymentStage: PaymentStageNamesTypes): Analytics.Dimensions => {
  switch (paymentStage) {
    case stagesNames.INITIATED:
      return [apiConfig.dimensions.currency]
    case stagesNames.BLOCKED:
      return [
        apiConfig.dimensions.currency,
        apiConfig.dimensions.cardVendor
      ]
    case stagesNames.VOIDED:
    case stagesNames.DECLINED:
    case stagesNames.SUCCESSFUL:
    case stagesNames.REFUND:
      return [
        apiConfig.dimensions.provider,
        apiConfig.dimensions.currency,
        apiConfig.dimensions.cardVendor
      ]
    default:
      return []
  }
}

export const forFilter = (
  from: Date,
  to: Date,
  dimension: Analytics.Dimension
): PartialAnalyticsRequest => ({
  time_range: {
    from: from as unknown as string,
    to: to as unknown as string,
    granularity: apiConfig.granularity.all
  },
  metrics: [{ name: apiConfig.metrics.totalAmount }],
  dimensions: [dimension]
})

export const forPaymentStageBreakdown = (
  from: Date,
  to: Date,
  countries: string[],
  apps: string[],
  metric: Analytics.Metric['name']
): PartialAnalyticsRequest => {
  const request: PartialAnalyticsRequest = {
    time_range: {
      from: (from as unknown as string),
      to: (to as unknown as string),
      granularity: apiConfig.granularity.all
    },
    dimensions: [apiConfig.dimensions.paymentStage],
    metrics: [{ name: metric }]
  }
  if (metric === apiConfig.metrics.totalAmount) {
    request.dimensions.push(apiConfig.dimensions.currency)
    request.metrics.push({ name: apiConfig.metrics.originalCurrency })
  }
  const filters = createFilters(countries, apps)
  if (filters.length !== 0) {
    request.filters = filters
  }
  return request
}

export const forDimensionBars = (
  from: Date,
  to: Date,
  countries: string[],
  apps: string[],
  paymentStage: PaymentStageNamesTypes,
  metric: Analytics.Metric['name'],
  dashboardCurrency: string
): PartialAnalyticsRequest => {
  const dimensions = getDimensionsPerPaymentStage(paymentStage)
  const request: PartialAnalyticsRequest = {
    time_range: {
      from: from as unknown as string,
      to: to as unknown as string,
      granularity: apiConfig.granularity.all
    },
    dimensions,
    metrics: [{ name: metric }]
  }
  if (shouldIncludeOriginalCurrencyMetric(metric, dashboardCurrency)) {
    request.metrics.push({ name: apiConfig.metrics.originalCurrency })

    if (!dimensions.includes(apiConfig.dimensions.currency)) {
      request.dimensions.push(apiConfig.dimensions.currency)
    }
  }
  const filters = createFilters(countries, apps, paymentStage)
  if (filters.length !== 0) {
    request.filters = filters
  }

  return request
}

export const forTrendGraph = (
  from: Date,
  to: Date,
  granularity: Analytics.TimeRangeGranularity['granularity'],
  countries: string[],
  apps: string[],
  paymentStage: PaymentStageNamesTypes,
  metric: Analytics.Metric['name'],
  dimensions: Analytics.Dimension[],
  dashboardCurrency: string
): PartialAnalyticsRequest => {
  const request: PartialAnalyticsRequest = {
    time_range: {
      granularity,
      from: from as unknown as string,
      to: to as unknown as string
    },
    dimensions,
    metrics: [{ name: metric }],
    settings: { show_empty_buckets: true }
  }
  if (shouldIncludeOriginalCurrencyMetric(metric, dashboardCurrency)) {
    request.metrics.push({ name: apiConfig.metrics.originalCurrency })
  }
  const filters = createFilters(countries, apps, paymentStage)
  if (filters.length !== 0) {
    request.filters = filters
  }
  return request
}
