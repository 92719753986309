import produce from 'immer'
import { Managment } from '@payu/paymentsos-types'

import { types } from './actions'

export interface State {
  roles: Managment.RoleResource[]
  masqueraderRoles: Managment.RoleResource[]
  activeProvider: string
}

const initState: State = {
  roles: [],
  masqueraderRoles: [],
  activeProvider: undefined
}

export const rolesReducer = produce((state, action) => {
  switch (action.type) {
    case types.FETCH_ROLES_SUCCESS:
      state.roles = action.payload
      break
    case types.FETCH_MASQ_ROLES_SUCCESS:
      state.masqueraderRoles = action.payload
      break
    case types.SET_ACTIVE_PROVIDER:
      state.activeProvider = action.payload
      break
  }
}, initState)
