import { Analytics } from '@payu/paymentsos-types'

import ApiBase from './ApiBase'
import envConfig from '../envConfig'

type ToOptional<T, K extends keyof T> = Omit<T, K> & { [key in K]?: T[key] }

class AnalyticsApi extends ApiBase {
  get resourcePath (): string {
    return `/accounts/${this.accountId}/analytics`
  }

  getAnalytics = async (request: ToOptional<Analytics.AnalyticsRequest, 'view'>): Promise<Analytics.AnalyticsResponse> => {
    request.view = request.view || 'payments'
    // no analytics data in dev/qa - prevents throwing errors
    if (['development', 'qa'].includes(envConfig.zoozEnv)) {
      return {
        buckets: [],
        metadata: {}
      }
    }
    const response = await this.httpClient.post<Analytics.AnalyticsResponse>(
      this.resourcePath,
      request,
      {},
      this.defaultConfig
    )
    return response.data
  }
}

export default new AnalyticsApi()
