import { Currencies } from '@payu/paymentsos-types'
import { Action } from 'redux'

export const ActionTypes = {
  FETCH_CURRENCIES: 'FETCH_CURRENCIES',
  FETCH_CURRENCIES_SUCCESS: 'FETCH_CURRENCIES_SUCCESS'
}

export type FetchCurrenciesAction = Action<typeof ActionTypes.FETCH_CURRENCIES>
export const fetchCurrencies = (): FetchCurrenciesAction => ({ type: ActionTypes.FETCH_CURRENCIES })

export interface FetchCurrenciesSuccessAction extends Action<typeof ActionTypes.FETCH_CURRENCIES_SUCCESS> {
  payload: {
    currencies: Currencies.Currency[]
  }
}
export const fetchCurrenciesSuccess = (currencies: Currencies.Currency[]): FetchCurrenciesSuccessAction => ({
  type: ActionTypes.FETCH_CURRENCIES_SUCCESS,
  payload: {
    currencies
  }
})
