import produce from 'immer'

import { PathSnippet } from '../Breadcrumbs'
import { actionTypes } from './actions'
import MODES from './MODES'

export interface State {
  crumbs: string[] | PathSnippet[],
  mode?: MODES
}

const initialState: State = {
  crumbs: [],
  mode: MODES.AUTO
}

const crumbOverrides = produce((state: State, action): State | void => {
  switch (action.type) {
    case actionTypes.ADD_CRUMBS: {
      state.crumbs = action.crumbsList
      state.mode = action.mode ? action.mode : MODES.AUTO
      break
    }
    case actionTypes.CLEAR_CRUMBS: {
      return initialState
    }
  }
}, initialState)

export default crumbOverrides
