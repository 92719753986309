// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenusMeasuerer__Measurer___GxRq3{position:fixed;top:-100vh;left:-100vw;opacity:0;visibility:hidden;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/features/Sidebar/MenusMeasuerer.scss"],"names":[],"mappings":"AAAA,kCACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,iBAAA,CACA,mBAAA","sourcesContent":[".Measurer {\n  position: fixed;\n  top: -100vh;\n  left: -100vw;\n  opacity: 0;\n  visibility: hidden;\n  pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Measurer": `MenusMeasuerer__Measurer___GxRq3`
};
export default ___CSS_LOADER_EXPORT___;
