import { Managment } from '@payu/paymentsos-types'

import { UserProfileLocalizationPreferences } from '../types'
import { Passwords, UserLocalizationStore } from './reducers'

export const GET_PROFILE = 'GET_USER_PROFILE'
export const GET_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const UPDATE_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_PROFILE_FIELD = 'UPDATE_USER_PROFILE_FIELD'
export const CLEAN_TRANSIENT_PROFILE = 'CLEAN_TRANSIENT_USER_PROFILE'
export const CLEAN_TRANSIENT_PASSWORD = 'CLEAN_TRANSIENT_USER_PASSWORD'
export const UPDATE_PASSWORD = 'UPDATE_USER_PASSWORD'
export const UPDATE_PASSWORD_FIELD = 'UPDATE_USER_PASSWORD_FIELD'
export const INIT_LOCALIZATION_INSTANCE = 'USER_PROFILE/INIT_LOCALIZATION_INSTANCE'
export const SET_LOCALIZATION_PREFERENCES = 'USER_PROFILE/SET_LOCALIZATION_PREFERENCES'
export const SET_LOCALIZATION_PREFERENCES_SUCCEED = 'USER_PROFILE/SET_LOCALIZATION_PREFERENCES_SUCCEED'
export const UPDATE_LOCALIZATION_FIELD = 'USER_PROFILE/UPDATE_USER_LOCALIZATION_FIELD'
export const HANDLE_FOCUS_CHANGE = 'HANDLE_FOCUS_CHANGE'
export const ENFORCE_FIELDS_VALIDATION = 'ENFORCE_FIELDS_VALIDATION'

export type ActionType = typeof GET_PROFILE |
  typeof GET_PROFILE_SUCCESS |
  typeof UPDATE_PROFILE |
  typeof UPDATE_PROFILE_FIELD |
  typeof CLEAN_TRANSIENT_PROFILE |
  typeof CLEAN_TRANSIENT_PASSWORD |
  typeof UPDATE_PASSWORD |
  typeof UPDATE_PASSWORD_FIELD |
  typeof INIT_LOCALIZATION_INSTANCE |
  typeof SET_LOCALIZATION_PREFERENCES |
  typeof SET_LOCALIZATION_PREFERENCES_SUCCEED |
  typeof UPDATE_LOCALIZATION_FIELD |
  typeof HANDLE_FOCUS_CHANGE |
  typeof ENFORCE_FIELDS_VALIDATION

interface Action {
  type: ActionType
}

export interface GetProfileAction extends Action {
  type: typeof GET_PROFILE
}
export function getProfile (): GetProfileAction {
  return { type: GET_PROFILE }
}

export interface GetProfileSuccessAction extends Action {
  type: typeof GET_PROFILE_SUCCESS,
  profile: Managment.UserResource
}

export function getProfileSuccess (profile: Managment.UserResource): GetProfileSuccessAction {
  return { type: GET_PROFILE_SUCCESS, profile }
}

export interface UpdateProfileAction extends Action {
  type: typeof UPDATE_PROFILE,
  profile: Managment.UserResource
}

export function updateProfile (profile: Managment.UserResource): UpdateProfileAction {
  return { type: UPDATE_PROFILE, profile }
}

export interface UpdateProfileFieldAction extends Action {
  type: typeof UPDATE_PROFILE_FIELD,
  profile: Managment.UserResource
}

export function updateProfileField (profile: Managment.UserResource): UpdateProfileFieldAction {
  return { type: UPDATE_PROFILE_FIELD, profile }
}

export interface UpdatePasswordFieldAction extends Action {
  type: typeof UPDATE_PASSWORD_FIELD,
  fieldName: string,
  passwords: Passwords
}

export function updatePasswordField (passwords: Passwords, fieldName: string): UpdatePasswordFieldAction {
  return { type: UPDATE_PASSWORD_FIELD, passwords, fieldName }
}

export interface InitLocalizationInstanceAction extends Action {
  type: typeof INIT_LOCALIZATION_INSTANCE
}

export function initLocalizationInstance (): InitLocalizationInstanceAction {
  return { type: INIT_LOCALIZATION_INSTANCE }
}

export interface SetLocalizationPreferencesAction extends Action {
  type: typeof SET_LOCALIZATION_PREFERENCES,
  language: string
}

export function setLocalizationPreferences (language: string): SetLocalizationPreferencesAction {
  return { type: SET_LOCALIZATION_PREFERENCES, language }
}

export interface SetLocalizationPreferencesSucceedAction extends Action {
  type: typeof SET_LOCALIZATION_PREFERENCES_SUCCEED,
  localizationPreferences: UserProfileLocalizationPreferences
}

export function setLocalizationPreferencesSucceed (
  localizationPreferences: UserProfileLocalizationPreferences
): SetLocalizationPreferencesSucceedAction {
  return {
    type: SET_LOCALIZATION_PREFERENCES_SUCCEED,
    localizationPreferences
  }
}

export interface UpdateLocalizationFieldAction extends Action {
  type: typeof UPDATE_LOCALIZATION_FIELD,
  localizationFields: UserLocalizationStore,
}

export function updateLocalizationField (localizationFields: UserLocalizationStore): UpdateLocalizationFieldAction {
  return { type: UPDATE_LOCALIZATION_FIELD, localizationFields }
}

export interface UpdatePasswordAction extends Action {
  type: typeof UPDATE_PASSWORD,
  passwords: Passwords
}

export function updatePassword (passwords: Passwords): UpdatePasswordAction {
  return { type: UPDATE_PASSWORD, passwords }
}

export interface ClearTransientProfileAction extends Action {
  type: typeof CLEAN_TRANSIENT_PROFILE
}

export function cleanTransientProfile (): ClearTransientProfileAction {
  return { type: CLEAN_TRANSIENT_PROFILE }
}

export interface ClearTransientPasswordsAction extends Action {
  type: typeof CLEAN_TRANSIENT_PASSWORD
}

export function cleanTransientPasswords (): ClearTransientPasswordsAction {
  return { type: CLEAN_TRANSIENT_PASSWORD }
}

export interface EnforceFieldsValidationPasswordsAction extends Action {
  type: typeof ENFORCE_FIELDS_VALIDATION,
  isValid: boolean
}

export function enforceFieldsValidation (isValid: boolean): EnforceFieldsValidationPasswordsAction {
  return { type: ENFORCE_FIELDS_VALIDATION, isValid }
}

export interface HandleFocusAction extends Action {
  type: typeof HANDLE_FOCUS_CHANGE,
  focusId: string,
  focus: boolean
}

export function handleFocusChange (focusId: string, focus: boolean): HandleFocusAction {
  return { type: HANDLE_FOCUS_CHANGE, focusId, focus }
}

export type UserProfileAction = GetProfileAction |
  GetProfileSuccessAction |
  UpdateProfileAction |
  UpdateProfileFieldAction |
  UpdatePasswordAction |
  UpdatePasswordFieldAction |
  InitLocalizationInstanceAction |
  SetLocalizationPreferencesSucceedAction |
  UpdateLocalizationFieldAction |
  ClearTransientProfileAction |
  ClearTransientPasswordsAction |
  EnforceFieldsValidationPasswordsAction |
  HandleFocusAction
