import React from 'react'
import { PermissionsRouteProps } from 'cc-components'
import { Route, Redirect } from 'react-router-dom'

import ErrorBoundary from '../../features/App/components/ErrorBoundary'
import Locations from '../tools/locations'
import PermissionsWrapper from './PermissionsWrapper'

export const PermissionsRoute: React.FC<PermissionsRouteProps> = ({
  component: Component,
  permission,
  accountFeatureToggle,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <ErrorBoundary>
        <PermissionsWrapper
          permission={permission}
          accountFeatureToggle={accountFeatureToggle}
          fallback={<Redirect to={Locations.noPermission()} />}
        >
          <Component {...props} />
        </PermissionsWrapper>
      </ErrorBoundary>
    )}
  />
)

export default PermissionsRoute
