import MODES from './MODES'

export const actionTypes = {
  ADD_CRUMBS: 'ADD_CRUMBS',
  CLEAR_CRUMBS: 'CLEAR_CRUMBS'
}

interface Action {
  type: typeof actionTypes.ADD_CRUMBS | typeof actionTypes.CLEAR_CRUMBS
}

interface AddCrumbs extends Action {
  crumbsList: string[],
  mode: MODES
}

type ClearCrumbs = Action

export type Actions = AddCrumbs | ClearCrumbs

export type AddCrumbsAction = (reverseListOfCrumbs: string[], mode?: MODES) => AddCrumbs
export type ClearCrumbsAction = () => ClearCrumbs

interface ActionsObj {
  addCrumbs: AddCrumbsAction,
  clearCrumbs: ClearCrumbsAction
}

export const actions: ActionsObj = {
  addCrumbs: (reverseListOfCrumbs, mode) => ({
    type: actionTypes.ADD_CRUMBS,
    crumbsList: reverseListOfCrumbs,
    mode
  }),
  clearCrumbs: () => ({
    type: actionTypes.CLEAR_CRUMBS
  })
}
