import { UserTypeContext } from 'cc-locations'

import { PortalState } from '../store'
import { ContentRectData } from './reducer'

export const getCollapsed = (state: PortalState): boolean => state.App.collapsed
export const getOpenDrawer = (state: PortalState): boolean => state.App.openDrawer
export const getContentRectData = (state: PortalState): ContentRectData => state.App.contentRectData
export const getLoadedMicroApps = (state: PortalState): Record<string, boolean> => state.App.loadedMicroApps
export const getCurrentMicroAppContext = (state: PortalState): UserTypeContext => state.App.userTypeCurrentContext
export const getCurrentMicroApp = (state: PortalState): string => state.App.currentMicroApp
