import { Countries } from '@payu/paymentsos-types'

import ApiBase from './ApiBase'

type Country = Countries.Country;

class CountriesApi extends ApiBase {
  fetchCountries = async (): Promise<Country[]> => {
    const response = await this.httpClient.get(
      '/countries',
      undefined,
      this.defaultConfig
    )
    return response.data
  }
}

export default new CountriesApi()
