import { FeaturesToggle } from '@payu/paymentsos-types'

import ApiBase from './ApiBase'

class FeatureTogglesApi extends ApiBase {
  get resourcePath (): string {
    return `/accounts/${this.accountId}/features`
  }

  getAccountLevelToggles = async (): Promise<FeaturesToggle.ToggleByAccountResponse[]> => {
    const response = await this.httpClient.get<FeaturesToggle.ToggleByAccountResponse[]>(
      this.resourcePath,
      {},
      this.defaultConfig
    )
    return response.data
  }
}

export default new FeatureTogglesApi()
