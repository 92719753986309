import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest
} from 'redux-saga/effects'
import { Managment, Users } from '@payu/paymentsos-types'

import Errors from '../../constants/Errors'
import localize from '../../features/Localize/localize'
import { startMicroApps } from '../../initMicroApps'
import actions, { types } from './actions'
import messageDispatcher from '../../features/Messages/redux/messageDispatcher'
import UsersApi from '../../api/UsersApi'

export function * fetchPrivileges (): IterableIterator<
    CallEffect |
    PutEffect
    > {
  try {
    const userPrivileges: Users.UserPrivileges = yield call(UsersApi.getUserPrivileges, { expand: 'roles' })

    // if roles exists on account use them, if not, check if they exist on the parent
    if (userPrivileges.account && userPrivileges.account.account_roles) {
      yield put(actions.fetchRolesSuccess(userPrivileges.account.account_roles as Managment.RoleResource[]))
    } else if (userPrivileges.parent && userPrivileges.parent.account_roles) {
      yield put(actions.fetchRolesSuccess(userPrivileges.parent.account_roles as Managment.RoleResource[]))
    // if the user don't have permission on the account and on the parent check if they exist on masquerader
    } else if (userPrivileges.masquerader && userPrivileges.masquerader.account_roles) {
      yield put(actions.fetchRolesSuccess(userPrivileges.masquerader.account_roles as Managment.RoleResource[]))
    }

    // populate also the masquerader roles if exists
    if (userPrivileges.masquerader && userPrivileges.masquerader.account_roles) {
      yield put(actions.fetchMasqueraderRolesSuccess(
          userPrivileges.masquerader.account_roles as Managment.RoleResource[]
      ))
    }

    yield call(startMicroApps)
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export default function * rolesSaga () {
  yield takeLatest(types.FETCH_PRIVILEGES, fetchPrivileges)
}
