const validateEndingSlash = (url: string): string => {
  if (url.endsWith('/')) {
    return url
  }
  return `${url}/`
}

export const getVersionUrl = (microAppUrl: string): string => (
  `${validateEndingSlash(microAppUrl)}version.js?c=${Date.now()}`
)

export const getAppUrl = (microAppUrl: string, version: string): string => (
  `${validateEndingSlash(microAppUrl)}app.${version}.js`
)
