import React from 'react'

import AccountMenuItem from './AccountMenuItem'

import style from './AccountMenu.scss'

const AccountMenu: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={style.wrapper}>
    {children}
  </div>
)

export default AccountMenu
export { AccountMenuItem }
