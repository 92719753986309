import jwt, { JwtPayload } from 'jsonwebtoken'

export const safeDecode = (token: string): Partial<JwtPayload> => {
  try {
    const decoded = jwt.decode(token)
    if (typeof decoded === 'string') {
      return {}
    }
    return decoded || {}
  } catch (error) {
    return {}
  }
}
export const { decode } = jwt
