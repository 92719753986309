// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__PageNotFound___BA9I5{text-align:center;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.style__MenusAvailable___hoxeq{padding:24px 32px 27px}.style__MenusAvailable__top__title___JPd6W{margin-bottom:7px}.style__MenusAvailable___hoxeq .style__hr___UX7Gn{margin:0 -32px;margin-top:27px;margin-bottom:27px}.style__MenusAvailable___hoxeq li+li{margin-top:23px}.style__MenusAvailable___hoxeq li a{display:inline-block}`, "",{"version":3,"sources":["webpack://./src/features/App/components/PageNotFound/style.scss"],"names":[],"mappings":"AAEA,6BACE,iBAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAGF,+BAEE,sBAAA,CAGE,2CACE,iBAAA,CAIJ,kDACE,cAAA,CACA,eAAA,CACA,kBAAA,CAIA,qCACE,eAAA,CAEF,oCACE,oBAAA","sourcesContent":["@import \"~@zooz/generic-ui-components/shared-styles/colors\";\n\n.PageNotFound {\n  text-align: center;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.MenusAvailable {\n  $padding: 32px;\n  padding: 24px $padding 27px;\n\n  &__top {\n    &__title {\n      margin-bottom: 7px;\n    }\n  }\n\n  .hr {\n    margin: 0 -#{$padding};\n    margin-top: 27px;\n    margin-bottom: 27px;\n  }\n\n  li {\n    & + li {\n      margin-top: 23px;\n    }\n    a {\n      display: inline-block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default-turquoise": `#0d91bd`,
	"error-red": `#f9453e`,
	"danger-red": `#eb7a7c`,
	"border-grey": `#e9e9e9`,
	"white": `#fff`,
	"default-blue": `#557eff`,
	"active-blue": `#3750cd`,
	"glowing-blue": `#0d47ff`,
	"text-black": `#555`,
	"text-grey": `#778195`,
	"disabled-grey-background": `#f9fafb`,
	"disabled-grey-text": `#a8a8a8`,
	"disabled-light-grey-text": `#c2c2c2`,
	"disabled-grey-button": `#969696`,
	"disabled-light-grey-button": `#c2c2c2`,
	"disabled-grey-button-hover": `#545454`,
	"success-green": `#03b571`,
	"list-item-grey": `#f3f3f3`,
	"outline-color": `#79c2db`,
	"greyout": `#fafafa`,
	"button-hover": `#7395ff`,
	"button-active": `#3750cd`,
	"light-orange": `#eba205`,
	"input-focus-shadow": `rgba(13,145,189,.2509803922)`,
	"PageNotFound": `style__PageNotFound___BA9I5`,
	"MenusAvailable": `style__MenusAvailable___hoxeq`,
	"MenusAvailable__top__title": `style__MenusAvailable__top__title___JPd6W`,
	"hr": `style__hr___UX7Gn`
};
export default ___CSS_LOADER_EXPORT___;
