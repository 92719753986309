// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs__bread-crumbs-list___Qp8t2{display:flex;flex-direction:row;align-items:center;flex-wrap:wrap;white-space:normal;line-height:1.2}.breadcrumbs__crumb___UiHiG{cursor:default;overflow:hidden;text-overflow:ellipsis}.breadcrumbs__crumb___UiHiG.breadcrumbs__clickable___GjzDa{cursor:pointer}.breadcrumbs__crumb___UiHiG.breadcrumbs__final___LIgmd{cursor:default;font-weight:700}.breadcrumbs__crumb-separator___Ijd7Y{margin:0 6px}`, "",{"version":3,"sources":["webpack://./src/features/Topbar/components/Breadcrumbs/breadcrumbs.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CAGF,4BACE,cAAA,CACA,eAAA,CACA,sBAAA,CAEA,2DACE,cAAA,CAGF,uDACE,cAAA,CACA,eAAA,CAIJ,sCACE,YAAA","sourcesContent":[".bread-crumbs-list {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n  white-space: normal;\n  line-height: 1.2;\n}\n\n.crumb {\n  cursor: default;\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  &.clickable {\n    cursor: pointer;\n  }\n\n  &.final {\n    cursor: default;\n    font-weight: 700;\n  }\n}\n\n.crumb-separator {\n  margin: 0 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bread-crumbs-list": `breadcrumbs__bread-crumbs-list___Qp8t2`,
	"crumb": `breadcrumbs__crumb___UiHiG`,
	"clickable": `breadcrumbs__clickable___GjzDa`,
	"final": `breadcrumbs__final___LIgmd`,
	"crumb-separator": `breadcrumbs__crumb-separator___Ijd7Y`
};
export default ___CSS_LOADER_EXPORT___;
