/* eslint-disable camelcase */
import qs from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { safeDecode } from '../../../../helpers/jwtUtils'
import locations from '../../../../helpers/tools/locations'
import { PortalState } from '../../../../redux/store'
import { isValidToken } from '../helpers'
import { isUserExists } from '../redux/actions'

interface JoinProps extends RouteComponentProps {
  userId?: string,
  isUserExists: (token: string, accountId: string, invitationId: string) => void
}

class Join extends React.Component<JoinProps> {
  token: string

  componentDidMount () {
    const token = qs.parse(this.props.history.location.search).token as string
    const { email, account_id, invitation_id } = safeDecode(token).payload || {}

    if (isValidToken({ email, account_id, invitation_id })) {
      this.token = token
      this.props.isUserExists(token, account_id, invitation_id)
    } else {
      this.props.history.push(locations.login())
    }
  }

  componentDidUpdate () {
    const { token } = this
    const { userId } = this.props

    if (userId) {
      this.props.history.push(`${locations.joinExistingUser()}?token=${token}`)
    } else if (userId === null) {
      this.props.history.push(`${locations.joinNewUser()}?token=${token}`)
    }
  }

  render (): JSX.Element {
    return null
  }
}

const mapStateToProps = (state: PortalState) => ({
  userId: state.joinReducers.userExists.user_id
})

const mapDispatchToProps = {
  isUserExists
}

export default connect(mapStateToProps, mapDispatchToProps)(Join)
