import { Analytics } from '@payu/paymentsos-types'

type APIConfig = {
  dimensions: {
    [dimensionKey: string]: Analytics.Dimension
  },
  metrics: {
    [metricKey: string]: Analytics.Metric['name']
  },
  granularity: {
    [granularityKey: string]: Analytics.TimeRangeGranularity['granularity']
  }
}

const apiConfig: APIConfig = {
  dimensions: {
    paymentStage: 'payment_stage',
    currency: 'currency',
    country: 'country',
    provider: 'provider_name',
    application: 'application_name',
    paymentMethodType: 'payment_method_type',
    actionType: 'action_type',
    actionStatus: 'action_status',
    cardLevel: 'card_level',
    cardIssuer: 'card_issuer',
    cardType: 'card_type',
    cardVendor: 'vendor',
    cardCountryCode: 'card_country_code'
  },
  metrics: {
    totalPayments: 'total_payments',
    totalAmount: 'total_amount_usd',
    originalCurrency: 'total_amount_original_currency'
  },
  granularity: {
    all: 'all',
    halfHour: 'thirty_minute',
    hour: 'hour',
    day: 'day',
    week: 'week',
    month: 'month',
    quarter: 'quarter',
    year: 'year'
  }
}

export default apiConfig
