import React from 'react'
import { Props as PermissionsWrapperProps } from 'cc-permissions-wrapper'
import { useSelector } from 'react-redux'

import { isAccountLevelFeatureTogglesFetched } from '../../redux/featureToggles/selectors'
import { userRoles } from '../../redux/roles/selector'
import FeaturesToggleUtil from './FeaturesToggleUtil'
import PermissionsUtil from './PermissionsUtil'

const makeChildrenValid = (children: any) => React.isValidElement(children) ? children : <>{children}</>

export const PermissionsWrapper: React.FC<PermissionsWrapperProps> = ({
  children,
  permission,
  fallback = null,
  accountFeatureToggle
}) => {
  const roles = useSelector(userRoles)
  const isAccountTogglesFetched = useSelector(isAccountLevelFeatureTogglesFetched)

  const isPermitted = permission ? PermissionsUtil.isAllowed(permission, roles) : true
  const isFeatureOn = accountFeatureToggle ? FeaturesToggleUtil.isAccountOn(accountFeatureToggle) : true

  if (accountFeatureToggle && !isAccountTogglesFetched) {
    return null
  }

  return (
    isPermitted && isFeatureOn
      ? makeChildrenValid(children)
      : fallback
  )
}

export default PermissionsWrapper
