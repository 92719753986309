// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignupForm__nameContainer___MLMx3{display:grid;grid-template-rows:auto;grid-template-columns:repeat(2, 1fr);grid-gap:12px}`, "",{"version":3,"sources":["webpack://./src/features/UnProtected/SignUpPage/SignupForm.scss","webpack://./node_modules/@zooz/generic-ui-components/shared-styles/spacings.scss"],"names":[],"mappings":"AAEA,mCACE,YAAA,CACA,uBAAA,CACA,oCAAA,CACA,aCHW","sourcesContent":["@import '~@zooz/generic-ui-components/shared-styles/spacings';\n\n.nameContainer {\n  display: grid;\n  grid-template-rows: auto;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: $spacing_12;\n}\n","$spacing_0: 0;\n$spacing_4: 4px;\n$spacing_8: 8px;\n$spacing_12: 12px;\n$spacing_20: 20px;\n$spacing_32: 32px;\n$spacing_52: 52px;\n$spacing_84: 84px;\n$spacing_136: 136px;\n$spacing_220: 220px;\n$spacing_356: 356px;\n$spacing_576: 576px;\n$spacing_932: 932px;\n$spacing_1508: 1508px;\n$spacing_2440: 2440px;\n\n\n:export {\n  spacing_0: $spacing_0;\n  spacing_4: $spacing_4;\n  spacing_8: $spacing_8;\n  spacing_12: $spacing_12;\n  spacing_20: $spacing_20;\n  spacing_32: $spacing_32;\n  spacing_52: $spacing_52;\n  spacing_84: $spacing_84;\n  spacing_136: $spacing_136;\n  spacing_220: $spacing_220;\n  spacing_356: $spacing_356;\n  spacing_576: $spacing_576;\n  spacing_932: $spacing_932;\n  spacing_1508: $spacing_1508;\n  spacing_2440: $spacing_2440;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacing_0": `0`,
	"spacing_4": `4px`,
	"spacing_8": `8px`,
	"spacing_12": `12px`,
	"spacing_20": `20px`,
	"spacing_32": `32px`,
	"spacing_52": `52px`,
	"spacing_84": `84px`,
	"spacing_136": `136px`,
	"spacing_220": `220px`,
	"spacing_356": `356px`,
	"spacing_576": `576px`,
	"spacing_932": `932px`,
	"spacing_1508": `1508px`,
	"spacing_2440": `2440px`,
	"nameContainer": `SignupForm__nameContainer___MLMx3`
};
export default ___CSS_LOADER_EXPORT___;
