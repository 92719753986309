import React from 'react'
import { useLocalize } from '@zooz/react-localize'
import { useDispatch, useSelector } from 'react-redux'

import { FormItem, InputField, ButtonSubmit } from '../shared'
import language from './language'
import { requestEmailChange, requestFocusChange, resetPasswordEmail } from './redux/actions'
import {
  requestEmail, requestInvalidFields, requestRequiredFields,
  requestTraveresedFields, requestFocused, requestError, isRequesting, RequestInvalidFields
} from './redux/selectors'

const EMAIL_FOCUS_KEY = 'email'

const RequestResetForm: React.FC = () => {
  const { t } = useLocalize()
  const dispatch = useDispatch()

  // selectors
  const email = useSelector(requestEmail)
  const invalidFields: RequestInvalidFields = useSelector(requestInvalidFields)
  const requiredFields: RequestInvalidFields = useSelector(requestRequiredFields)
  const traversedFields = useSelector(requestTraveresedFields)
  const focusedFields = useSelector(requestFocused)
  const reqError = useSelector(requestError)
  const isRequestingReset = useSelector(isRequesting)

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>): void | boolean => {
    e.preventDefault()
    if (!Object.keys(invalidFields).length && !Object.keys(requiredFields).length) {
      dispatch(resetPasswordEmail())
    } else {
      return false
    }
  }

  const isTraversedEmail = traversedFields.includes(EMAIL_FOCUS_KEY) && requiredFields[EMAIL_FOCUS_KEY]
  let message = ''
  if (isTraversedEmail && requiredFields.email) {
    message = requiredFields.email(t)
  } else if (invalidFields.email) {
    message = invalidFields.email(t)
  }
  const emailFocused = focusedFields.includes(EMAIL_FOCUS_KEY)

  const isError = !!message && !emailFocused
  const divVisibility = reqError ? 'visible' : 'hidden'

  return (
    <form onSubmit={handleSubmit}>
      <InputField
        error={isError ? message : ''}
        label={language.requestResetForm.email.header(t)}
        value={email}
        onBlur={() => dispatch(requestFocusChange(EMAIL_FOCUS_KEY, false))}
        onFocus={() => dispatch(requestFocusChange(EMAIL_FOCUS_KEY, true))}
        onChange={(event) => {
          dispatch(requestEmailChange(event.target.value))
        }}
        placeholder={language.shared.email.placeholder(t)}
        id='reset-email'
      />
      <FormItem>
        <div style={{ height: '25px', visibility: divVisibility, color: 'red' }}>
          {reqError}
        </div>
        <ButtonSubmit loading={isRequestingReset}>{language.requestResetForm.button(t)}</ButtonSubmit>
      </FormItem>
    </form>
  )
}

export default RequestResetForm
