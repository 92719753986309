import produce from 'immer'
import { UserTypeContext } from 'cc-locations'

import { Actions, getView, ToggleAllAction, types } from './actions'
import { NONE } from './types'

import defaultMixinsStyles from '../../styles/defaultMixins.scss'

const { floatingSideBarResolutionBreakpoint } = defaultMixinsStyles
export type ContentRectData = Pick<DOMRect, 'x' | 'y' | 'width' | 'height' | 'top' | 'right' | 'bottom' | 'left'>

export interface State {
  collapsed: boolean;
  view: string;
  height: number;
  openDrawer: boolean;
  contentRectData: ContentRectData;
  loadedMicroApps: Record<string, boolean>;
  userTypeCurrentContext: UserTypeContext;
  currentMicroApp: string;
}

const initState: State = {
  collapsed: !(window.innerWidth > parseInt(floatingSideBarResolutionBreakpoint, 10)),
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  contentRectData: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  loadedMicroApps: {},
  userTypeCurrentContext: NONE,
  currentMicroApp: undefined
}

const appReducer = produce((state, action: Actions) => {
  switch (action.type) {
    case types.COLLAPSE_CHANGE: {
      state.collapsed = !state.collapsed
      break
    }
    case types.COLLAPSE_OPEN_DRAWER: {
      state.openDrawer = !state.openDrawer
      break
    }
    case types.TOGGLE_ALL: {
      if (state.view !== (action as ToggleAllAction).view || (action as ToggleAllAction).height !== state.height) {
        const height = (action as ToggleAllAction).height ? (action as ToggleAllAction).height : state.height
        state.collapsed = (action as ToggleAllAction).collapsed
        state.view = (action as ToggleAllAction).view
        state.height = height
      }
      break
    }
    case types.SET_CONTENT_RECT_DATA: {
      state.contentRectData = {
        x: action.payload.x,
        y: action.payload.y,
        width: action.payload.width,
        height: action.payload.height,
        top: action.payload.top,
        right: action.payload.right,
        bottom: action.payload.bottom,
        left: action.payload.left
      }
      break
    }
    case types.SET_MICROAPP_LOADING: {
      const appName = action.payload.name
      if (!state.loadedMicroApps[appName]) {
        state.loadedMicroApps[appName] = action.payload.isLoaded
      }
      break
    }
    case types.SET_CURRENT_MICROAPP: {
      state.currentMicroApp = action.payload.name
      break
    }
    case types.SET_MICROAPP_CONTEXT: {
      state.userTypeCurrentContext = action.payload.userTypeContext
      break
    }
  }
}, initState)

export default appReducer
