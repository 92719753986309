import React, { Component } from 'react'
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import classnames from 'classnames'
import { Managment } from '@payu/paymentsos-types'
import { connect } from 'react-redux'
import { UserTypeContext } from 'cc-locations'

import permissions from '../../constants/permissions'
import PermissionsUtil from '../../helpers/permissions/PermissionsUtil'
import PermissionsWrapper from '../../helpers/permissions/PermissionsWrapper'
import Locations from '../../helpers/tools/locations'
import accountActions from '../../redux/account/actions'
import { getCompanyName, getAccountId, isAccountLive, isSubAccount } from '../../redux/account/selectors'
import appActions from '../../redux/app/actions'
import { getCollapsed, getOpenDrawer } from '../../redux/app/selectors'
import authActions from '../../redux/auth/actions'
import {
  isMasqueraderUser,
  isCurrentlyMasquerading,
  getCurrentUserAccountId,
  currentUser
} from '../../redux/auth/selectors'
import { isCrossEnv } from '../../redux/environment/crossEnvSelectors'
import { isLive, getEnvironment } from '../../redux/environment/selectors'
import { CurrentPage, getCurrentPage } from '../../redux/location/selectors'
import roleActions from '../../redux/roles/actions'
import { history, PortalState } from '../../redux/store'
import { LanguageSelection, PreviewToggle } from '../Localize/components'
import Breadcrumbs, { PathSnippet } from './components/Breadcrumbs/Breadcrumbs'
import { BreadCrumbsService } from './components/Breadcrumbs/redux/selectors'
import Collapse from './components/Collapse'
import MenuItem from './components/MenuItem'
import TestLiveSwitch from './components/TestLiveSwitch'
import UserPopover from './components/UserPopover'
import language from './language'
import SwitchProvider from './components/SwitchProvider'

import style from './Topbar.scss'

const { toggleCollapsed } = appActions
const SUPPORT_LINK = 'https://helpcenter.payu.com/helpcenter/?platform=PayU%20Enterprise'
const DOCUMENTATION_LINK = 'https://developers.paymentsos.com/docs'

interface TopbarProps extends WithLocalizeProps {
  currentPage: CurrentPage,
  toggleCollapsed: () => void,
  logout: () => void,
  setAccount: (account: Managment.AccountResource) => void,
  fetchPrivileges: () => void,
  currentAccountId: string,
  userAccountId: string,
  email: string,
  company: string,
  token: string,
  isMasquerader: boolean,
  isCurrentlyMasquerading: boolean,
  isLive: boolean,
  environment: 'test' | 'live',
  isCrossEnv: boolean,
  collapsed: boolean,
  openDrawer: boolean,
  pathSnippets: PathSnippet[] | undefined,
  isAccountLive: boolean,
  isSubAccount: boolean,
  currentMicroAppContext: UserTypeContext
}

class Topbar extends Component<TopbarProps> {
  onSupportClick = (): void => {
    window.open(SUPPORT_LINK, '_blank')
  }

  openDocumentation = (): void => {
    window.open(DOCUMENTATION_LINK, '_blank')
  }

  navigateTo = (url: string): void => {
    history.push(url)
  }

  navigateToUserProfile = (): void => {
    this.navigateTo(Locations.userProfile())
  }

  render () {
    const {
      toggleCollapsed, logout, setAccount, fetchPrivileges,
      currentAccountId, userAccountId, email, company, token,
      isMasquerader, isCurrentlyMasquerading, isLive,
      environment, currentPage, isCrossEnv,
      collapsed, openDrawer, pathSnippets, isAccountLive, isSubAccount, t
    } = this.props
    const isCollapsed = collapsed && !openDrawer
    const isActivationPermitted = !isSubAccount && PermissionsUtil.isAllowed(permissions.activation.create())

    return (
      <div className={classnames(style.wrapper, {
        [style.live]: isLive && !isCrossEnv,
        [style.test]: !isLive && !isCrossEnv,
        [style.collapsed]: isCollapsed
      })}
      >
        <div className={style.left}>
          <Collapse isCrossEnv={isCrossEnv} isLive={isLive} onClick={toggleCollapsed} />
          <Breadcrumbs
            pathSnippets={pathSnippets}
          />
        </div>

        <ul className={style.right}>
          <li className={style['test_live-wrapper']}>
            {!isCrossEnv && (
              <TestLiveSwitch
                isActivationPermitted={isActivationPermitted}
                isAccountLive={isAccountLive}
                isLive={isLive}
                currentPage={currentPage}
                environment={environment}
              />
            )}
          </li>
          <MenuItem handleClick={this.onSupportClick}>{language.support(t)}</MenuItem>
          <MenuItem handleClick={this.openDocumentation}>{language.docs(t)}</MenuItem>
          <li className={classnames(style.userPrefs)}>
            {
              isMasquerader
                ? (
                  <>
                    <div className={style.userPrefs__item}>
                      <LanguageSelection />
                    </div>
                    <PermissionsWrapper permission={permissions.localization.screens.get()}>
                      <div className={style.userPrefs__item}>
                        <PreviewToggle />
                      </div>
                    </PermissionsWrapper>
                  </>
                  )
                : null
            }
            <SwitchProvider />
            <UserPopover
              isMasquerader={isMasquerader}
              isCurrentlyMasquerading={isCurrentlyMasquerading}
              company={company}
              logout={logout}
              currentAccountId={currentAccountId}
              userAccountId={userAccountId}
              setAccount={setAccount}
              fetchPrivileges={fetchPrivileges}
              navigateToUserProfile={this.navigateToUserProfile}
              token={token}
            >
              {email}
            </UserPopover>
          </li>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state: PortalState) => ({
  openDrawer: getOpenDrawer(state),
  collapsed: getCollapsed(state),
  email: currentUser(state).email,
  token: currentUser(state).sessionToken,
  environment: getEnvironment(state),
  isMasquerader: isMasqueraderUser(state),
  isCurrentlyMasquerading: isCurrentlyMasquerading(state),
  currentAccountId: getAccountId(state),
  isAccountLive: isAccountLive(state),
  userAccountId: getCurrentUserAccountId(state),
  company: getCompanyName(state),
  pathSnippets: BreadCrumbsService.crumbs(state),
  isLive: isLive(state),
  isCrossEnv: isCrossEnv(state),
  currentPage: getCurrentPage(state),
  isSubAccount: isSubAccount(state)
})

const mapDispatchToProps = {
  toggleCollapsed,
  logout: authActions.unauthorized,
  setAccount: accountActions.accountFetchSuccess,
  fetchPrivileges: roleActions.fetchPrivileges
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Topbar))
