import React, { useEffect } from 'react'
import { Dropdown, DropdownOption } from '@zooz/generic-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { Managment } from '@payu/paymentsos-types'

import { getCurrentMicroAppContext } from '../../../../redux/app/selectors'
import { actions as accountActions } from '../../../../redux/account/actions'
import {
  getActiveProvider,
  getPermittedProviders
} from '../../../../redux/roles/selector'
import { getCurrentEnvProviders } from '../../../../redux/providers/selectors'
import payuLogo from './PayU.svg'
import rolesActions from '../../../../redux/roles/actions'
import { getConnectedAccounts } from '../../../../redux/account/selectors'
import { getCurrentUserAccountId } from '../../../../redux/auth/selectors'
import { useQuery } from '../../../App/Hooks/useQuery'
import { TREASURY_OPERATOR } from '../../../../redux/app/types'

import styles from './style.scss'

const createDropdownOptions = (permittedProvidersResource: Managment.ProviderResource[]): DropdownOption[] => {
  const options = permittedProvidersResource.map(provider => ({
    key: provider.name,
    value: provider.name.replace('PayU', '')
  }))

  return options
}

const SwitchProvider: React.FC = () => {
  const dispatch = useDispatch()
  const activeProvider = useSelector(getActiveProvider)
  const permittedProviders = useSelector(getPermittedProviders)
  const currentEnvProviders = useSelector(getCurrentEnvProviders)
  const currentUserAccountId = useSelector(getCurrentUserAccountId)
  const currentMicroAppContext = useSelector(getCurrentMicroAppContext)
  const isTreasuryOperatorContext = currentMicroAppContext === TREASURY_OPERATOR // to show switch provider only for operators
  const connectedAccounts = useSelector(getConnectedAccounts)
  const permittedProvidersResource =
      currentEnvProviders?.filter(provider => permittedProviders.includes(provider.name)) || []

  // get account from account_id in query (report download scenario)
  const accountIdFromQuery = useQuery('account_id')
  const queryProviderAccount = connectedAccounts.find(account => account.id === accountIdFromQuery)

  // set the provider account
  const providerAccount = queryProviderAccount || connectedAccounts.find(account => account.name === activeProvider)

  useEffect(() => {
    const currentProvider = providerAccount?.name || activeProvider
    const providerName = permittedProviders.includes(currentProvider) ? currentProvider : permittedProviders[0]
    if (activeProvider !== providerName) {
      dispatch(rolesActions.setActiveProvider(providerName))
    }
  }, [dispatch, permittedProviders, activeProvider])

  useEffect(() => {
    if (isTreasuryOperatorContext) {
      dispatch(accountActions.accountFetchSuccess(providerAccount))
    }
  }, [providerAccount, isTreasuryOperatorContext])

  useEffect(() => {
    if (isTreasuryOperatorContext) {
      dispatch(accountActions.fetchConnectedAccounts(currentUserAccountId))
    } else {
      dispatch(accountActions.fetchAccount(currentUserAccountId))
    }
  }, [dispatch, currentUserAccountId, isTreasuryOperatorContext])

  const handleOnChange = (selectedOption: DropdownOption): void => {
    if (selectedOption.key !== activeProvider) {
      dispatch(rolesActions.setActiveProvider(selectedOption.key))
    }
  }

  return isTreasuryOperatorContext
    ? (
      <div className={styles.dropdown}>
        <Dropdown
          options={createDropdownOptions(permittedProvidersResource)}
          selectedOption={{
            key: activeProvider,
            value: (
              <span className={styles.selected}>
                <img className={styles.selected__icon} src={payuLogo} width='46' height='23' />
                <span className={styles.selected__text}>{activeProvider && activeProvider.replace('PayU', '')}</span>
              </span>
            )
          }}
          enableFilter={false}
          onChange={handleOnChange}
        />
      </div>
      )
    : null
}

export default SwitchProvider
