import * as React from 'react'
import { useLocalize } from '@zooz/react-localize'
import { Paragraph } from '@zooz/generic-ui-components'
import { useDispatch, useSelector } from 'react-redux'

import language from './language'
import languageElements from './languageElements'
import { hideSignUpSuccessMessage } from './redux/actions'
import { getSignUpInfo } from './redux/selectors'

import styles from './CheckEmailMessage.scss'

export const ReenterLink: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  return (
    <span
      className={styles.resend}
      onClick={() => { dispatch(hideSignUpSuccessMessage()) }}
    >
      {children}
    </span>
  )
}

const CheckEmailMessage: React.FC = () => {
  const { userEmail } = useSelector(getSignUpInfo)
  const { t, te } = useLocalize()

  return (
    <div className={styles.checkMailMessage}>
      <Paragraph className={styles.text}>
        {language.checkInbox.emailSent(t)}<br />
        <strong className={styles.emailAddress}>{userEmail}</strong> {language.checkInbox.verifyEmail(t)}
      </Paragraph>
      <Paragraph className={styles.text}>
        {languageElements.checkInbox.checkSpam(te)}<br />
      </Paragraph>
    </div>
  )
}

export default CheckEmailMessage
