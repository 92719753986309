import * as React from 'react'
import { Dropdown } from '@zooz/generic-ui-components'
import { useDispatch, useSelector } from 'react-redux'

import {
  getIsLocalizationLoading,
  getIsLocalizedInit,
  getLanguagesByStatus,
  getSelectedLanguage
} from '../../redux/selectors'
import { actions } from '../../redux/slice'
import languageIcon from './language-icon.svg'

import styles from './LanguageSelection.scss'

const LanguageSelection: React.FC = () => {
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const languages = useSelector(getLanguagesByStatus)
  const isLocalizeInitialized = useSelector(getIsLocalizedInit)
  const isLocalizeLoading = useSelector(getIsLocalizationLoading)

  const options = []
  let selectedLanguageDisplayName = ''
  for (const { code, displayName } of languages) {
    if (code !== selectedLanguage) {
      options.push({
        key: code,
        value: <span className={styles.languageText}>{displayName}</span>
      })
    } else {
      selectedLanguageDisplayName = displayName
    }
  }

  React.useEffect(() => {
    dispatch(actions.initLocalize())
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <Dropdown
        options={options}
        selectedOption={{
          key: selectedLanguage,
          value: (
            <span className={styles.selected}>
              <img className={styles.selected__icon} src={languageIcon} width='15' height='15' />
              <span className={styles.languageText}>{selectedLanguageDisplayName}</span>
            </span>
          )
        }}
        disabled={!isLocalizeInitialized || isLocalizeLoading || options.length === 0}
        enableFilter={false}
        onChange={({ key }) => {
          dispatch(actions.setLocalizationPreferences({ language: key }))
        }}
      />
    </div>
  )
}

export default LanguageSelection
