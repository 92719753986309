import React from 'react'
import { Toaster, ToasterTypes } from '@zooz/generic-ui-components'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { hideMessage } from './redux/actions'
import { getMessages } from './redux/selectors'

import style from './style.scss'

const TIMEOUTS_MS = {
  enter: 1000,
  exit: 500
}

const Messages: React.FC = () => {
  const dispatch = useDispatch()
  const messages = useSelector(getMessages)

  return ReactDOM.createPortal(
    <TransitionGroup className={style.messages}>
      {
        messages.map(({ text, type, id }) => {
          const messageType: ToasterTypes = Object.keys(ToasterTypes).includes(type) ? type : undefined
          return (
            <CSSTransition
              key={id}
              timeout={TIMEOUTS_MS}
              classNames={{}}
            >
              {status => (
                <div
                  data-test={`appMessage--${type}`}
                  style={{
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    ['--enter-transition' as any]: `${TIMEOUTS_MS.enter / 1000}s`,
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    ['--exit-transition' as any]: `${TIMEOUTS_MS.exit / 1000}s`
                  }}
                  className={[style.message, style[`message--${status}`]].join(' ')}
                >
                  <Toaster onClose={() => dispatch(hideMessage({ id }))} type={messageType}>{text}</Toaster>
                </div>
              )}
            </CSSTransition>
          )
        })
      }
    </TransitionGroup>
    , document.querySelector('body')
  )
}

export default Messages
