import React from 'react'
import { Button, Card, TitledEditableText } from '@zooz/generic-ui-components'

import language from '../../../../language'

import style from './SwitchAccountBox.scss'

const cardTypes = Card.CARD_TYPES

interface SwitchAccountBoxProps {
  isValid?: boolean,
  defaultAccount?: string,
  accountId?: string,
  onConfirm?: React.MouseEventHandler,
  onAccountIdChange?: (value: string) => void,
  onOriginalAccountClick?: React.MouseEventHandler
}

const SwitchAccountBox: React.FC<SwitchAccountBoxProps> = ({
  onConfirm,
  accountId,
  isValid,
  onAccountIdChange,
  onOriginalAccountClick,
  defaultAccount
}) => (
  <div className={style.wrapper}>
    <div className={style['top-box-wrapper']}>
      <TitledEditableText
        errorText={isValid ? undefined : language.switchAccount.notFound}
        editMode
        title={language.switchAccount.byId}
        text={accountId}
        onChange={onAccountIdChange}
        inputProps={{
          autoFocus: true,
          'data-test': 'switch-account-input'
        }}
      />
      <Button onClick={onConfirm}>
        {language.switchAccount.confirm}
      </Button>
    </div>
    <div className={style['bottom-box-wrapper']}>
      <Card
        type={cardTypes.CLICKER}
        hover
        className={style['user-account-card']}
        onClick={onOriginalAccountClick}
      >
        {defaultAccount}
      </Card>
    </div>
  </div>
)

export default SwitchAccountBox
