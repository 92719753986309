import { call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import FeatureTogglesApi from '../../api/FeatureTogglesApi'
import errors from '../../constants/Errors'
import localize from '../../features/Localize/localize'
import { fetchAccountFeatureTogglesSuccess, ActionTypes } from './actions'
import messageDispatcher from '../../features/Messages/redux/messageDispatcher'

const errorHandler = (err: Error) => ({
  text: errors.defaultError(localize.translate),
  err
})

export function * fetchAccountTogglesSaga (): IterableIterator<CallEffect | PutEffect> {
  try {
    const accountFeatureToggles = yield call(FeatureTogglesApi.getAccountLevelToggles)
    yield put(fetchAccountFeatureTogglesSuccess(accountFeatureToggles))
  } catch (err) {
    messageDispatcher.addError(errorHandler(err as Error))
  }
}
export default function * featureTogglesSaga (): IterableIterator<ForkEffect> {
  yield takeLatest(ActionTypes.FETCH_ACCOUNT_FEATURE_TOGGLES, fetchAccountTogglesSaga)
}
