import React from 'react'
import classnames from 'classnames'
import { FontAwesome } from '@zooz/generic-ui-components'

import style from './style.scss'

interface CollapseProps {
  onClick?: React.MouseEventHandler,
  isLive?: boolean,
  isCrossEnv?: boolean
}

const Collapse: React.FC<CollapseProps> = ({ onClick, isLive, isCrossEnv }) => {
  const classes = classnames('triggerBtn', style.collapse, {
    [style['collapse--live']]: !isCrossEnv && isLive,
    [style['collapse--test']]: !isCrossEnv && !isLive
  })
  return (
    <FontAwesome data-test='app__collapse' className={classes} icon='bars' onClick={onClick} />
  )
}

export default Collapse
