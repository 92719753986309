import { UserConfiguration } from '@payu/paymentsos-types'

import { State } from './reducer'

export interface Store {
  userConfigurationReducer: State
}

export const getIsUserConfigurationFetched = (state: Store): boolean => state.userConfigurationReducer.isFetched
export const getUserConfiguration = (state: Store): UserConfiguration.UserConfiguration => (
  state.userConfigurationReducer.configuration)
