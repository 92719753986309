/* eslint-disable class-methods-use-this */
import { Analytics } from '@payu/paymentsos-types'

import AnalyticsApi from '../../../api/AnalyticsApi'
import filtersDataConverter from './dataConverters/filtersDataConverter'
import convertToPaymentStageData, { PaymentStageTabsData } from './dataConverters/paymentStagesDataConverter'
import convertToDimensionProgressBarsData from './dataConverters/dimensionBarsDataConverter'
import graphDataConverter, { TrendGraphData, mergeProviderAndPmDataToTrendGraphData } from './dataConverters/trendGraphDataConverter'
import * as requestBuilder from './requestBuilder'
import { PaymentStageNamesTypes } from '../components/paymentStagesConfig'
import apiConfig from '../../../api/AnalyticsApiHelpers/apiConfig'

class DashboardAnalyticsApi {
  async getDashboardFiltersData ({
    from,
    to,
    dimension
  }: {
    from: Date;
    to: Date;
    dimension: Analytics.Dimension;
  }): Promise<string[]> {
    const request = requestBuilder.forFilter(from, to, dimension)
    const response = await AnalyticsApi.getAnalytics(request)
    return filtersDataConverter(response)
  }

  async getPaymentStageBreakdownData (
    { from, to, countries, apps, metrics }:
      { from: Date, to: Date, countries: string[], apps: string[], metrics: Analytics.Metric['name'] }
  ): Promise<null | PaymentStageTabsData> {
    const request = requestBuilder.forPaymentStageBreakdown(from, to, countries, apps, metrics)
    const data = await AnalyticsApi.getAnalytics(request)
    return convertToPaymentStageData(data)
  }

  async getDimensionBarsData ({ from, to, countries, apps, paymentStage, metric, dashboardCurrency }: {
    from: Date,
    to: Date,
    countries: string[],
    apps: string[],
    paymentStage: PaymentStageNamesTypes,
    metric: Analytics.Metric['name'],
    dashboardCurrency: string
  }): Promise<{ [key in Analytics.Dimension]?: null | Record<string, number> }> {
    const request =
      requestBuilder.forDimensionBars(from, to, countries, apps, paymentStage, metric, dashboardCurrency)
    const data = await AnalyticsApi.getAnalytics(request)
    return convertToDimensionProgressBarsData(data, paymentStage, metric, dashboardCurrency)
  }

  async getTrendGraphData ({ from, to, granularity, countries, apps, paymentStage, metrics, dashboardCurrency }: {
    from: Date;
    to: Date;
    granularity: Analytics.TimeRangeGranularity['granularity'],
    countries: string[],
    apps: string[],
    paymentStage: PaymentStageNamesTypes,
    metrics: Analytics.Metric['name'],
    dashboardCurrency: string
  }): Promise<TrendGraphData> {
    // #1 First request is called to get the analytics buckets based on the time range (using currency dimension)
    let requestedDimensions = [apiConfig.dimensions.currency]
    let request = requestBuilder.forTrendGraph(
      from, to, granularity, countries, apps, paymentStage, metrics, requestedDimensions, dashboardCurrency
    )
    let response = await AnalyticsApi.getAnalytics(request)
    const trendGraphData = graphDataConverter(response, metrics, dashboardCurrency)

    let trendGraphDataIncludingAllDimensions = trendGraphData

    // #2 Second request is called to get the other dimensions data (Payment Method & Provider)
    requestedDimensions = requestBuilder.getDimensionsPerPaymentStage(paymentStage)
    const dimensionsLeft = requestedDimensions.filter(dim => dim !== apiConfig.dimensions.currency)
    const isGraphHasData = Object.keys(trendGraphData.keys.currency).length > 0

    if (isGraphHasData && dimensionsLeft.length > 0) {
      request = requestBuilder.forTrendGraph(
        from, to, granularity, countries, apps, paymentStage, metrics, requestedDimensions, dashboardCurrency
      )
      response = await AnalyticsApi.getAnalytics(request)

      trendGraphDataIncludingAllDimensions = mergeProviderAndPmDataToTrendGraphData(
        trendGraphData, response, metrics, dashboardCurrency
      )
    }

    return trendGraphDataIncludingAllDimensions
  }
}

export default new DashboardAnalyticsApi()
