import React from 'react'
import classnames from 'classnames'

import style from './style.scss'

const Beta: React.FC<{ hidden: boolean }> = ({ hidden = false }) => (
  <span className={classnames(style.beta, {
    [style['beta--hidden']]: hidden
  })}
  >BETA
  </span>
)

export default Beta
