import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import { Analytics } from '@payu/paymentsos-types'

import { DEFAULT_CURRENCY } from '../../config'
import apiConfig from '../../../../api/AnalyticsApiHelpers/apiConfig'

const OTHER = 'other'

interface DimensionGraphPoint {
  other?: number;
  [key: string]: number;
}

interface TrendGraphDimensionsKeys {
  currency?: DimensionGraphPoint
  provider_name?: DimensionGraphPoint
  vendor?: DimensionGraphPoint
}

interface TrendGraphPoint extends TrendGraphDimensionsKeys {
  time: number;
}

export interface TrendGraphData {
  keys: TrendGraphDimensionsKeys;
  graphData: TrendGraphPoint[];
}

export const emptyTrendGraphData = {
  keys: {},
  graphData: [
    { time: startOfMonth(new Date()).getTime() },
    { time: endOfMonth(new Date()).getTime() }
  ]
}

const getMetricName = (metric: Analytics.Metric['name'], dashboardCurrency: string): Analytics.Metric['name'] => {
  if (metric === apiConfig.metrics.totalAmount && dashboardCurrency !== DEFAULT_CURRENCY) {
    return apiConfig.metrics.originalCurrency
  }

  return metric
}

export default function convertToTrendGraphData (
  data: Analytics.AnalyticsResponse,
  metric: Analytics.Metric['name'],
  dashboardCurrency: string
): TrendGraphData {
  return isEmptyData(data) ? emptyTrendGraphData : createBasicGraphDataToGraphPoints(data, metric, dashboardCurrency)
}

function isEmptyData (data: Analytics.AnalyticsResponse): boolean {
  return !(data?.buckets?.length > 0)
}

// BasicTrendGraph contains the graph points and the Currency dimension only
function createBasicGraphDataToGraphPoints (
  data: Analytics.AnalyticsResponse,
  metric: Analytics.Metric['name'],
  dashboardCurrency: string
): TrendGraphData {
  const graphKeys:TrendGraphDimensionsKeys = {
    currency: {}
  }
  data.buckets
    .filter(buckets => buckets.data.length > 0)
    .forEach((buckets) => {
      buckets.data.forEach(({ dimensions }) => {
        // @ts-ignore: Analytics is not well maintained for types generation
        const currencyValue = dimensions?.[apiConfig.dimensions.currency] as string
        if (currencyValue && !graphKeys.currency[currencyValue]) {
          graphKeys.currency[currencyValue] = 0
        } else if (currencyValue === null && !graphKeys.currency[OTHER]) {
          graphKeys.currency[OTHER] = 0
        }
      })
    })

  const metricName = getMetricName(metric, dashboardCurrency)
  const graphPoints = data.buckets.map((bucket): TrendGraphPoint => {
    const point: TrendGraphPoint = {
      currency: { ...graphKeys.currency },
      time: new Date(bucket.time_range.from).getTime()
    }

    if (bucket.data.length > 0) {
      bucket.data.forEach((item) => {
        // @ts-ignore: Analytics is not well maintained for types generation
        if (item.dimensions[apiConfig.dimensions.currency]) {
          // @ts-ignore: Analytics is not well maintained for types generation
          point.currency[item.dimensions[apiConfig.dimensions.currency] as string] = item.metrics[metricName] as number
        } else {
          // @ts-ignore: Analytics is not well maintained for types generation
          point.currency[OTHER] = item.metrics[metric] as number
        }
      })
    }

    return point
  })

  return { keys: graphKeys, graphData: graphPoints }
}

export function mergeProviderAndPmDataToTrendGraphData (
  currencyGraphData: TrendGraphData,
  data: Analytics.AnalyticsResponse,
  metric: Analytics.Metric['name'],
  dashboardCurrency: string
): TrendGraphData {
  const additionalGraphKeys:TrendGraphDimensionsKeys = {
    provider_name: {},
    vendor: {}
  }

  data.buckets.forEach((buckets) => {
    buckets.data.forEach(({ dimensions }) => {
      // @ts-ignore: Analytics is not well maintained for types generation
      const providerKeyValue = dimensions?.[apiConfig.dimensions.provider] as string
      if (providerKeyValue && !additionalGraphKeys.provider_name[providerKeyValue]) {
        additionalGraphKeys.provider_name[providerKeyValue] = 0
      } else if (providerKeyValue === null && !additionalGraphKeys.provider_name[OTHER]) {
        additionalGraphKeys.provider_name[OTHER] = 0
      }
      // @ts-ignore: Analytics is not well maintained for types generation
      const pmKeyValue = dimensions?.[apiConfig.dimensions.cardVendor] as string
      if (pmKeyValue && !additionalGraphKeys.vendor[pmKeyValue]) {
        additionalGraphKeys.vendor[pmKeyValue] = 0
      } else if (pmKeyValue === null && !additionalGraphKeys.vendor[OTHER]) {
        additionalGraphKeys.vendor[OTHER] = 0
      }
    })
  })

  const mergedGraphPoints = currencyGraphData.graphData.map((point: TrendGraphPoint) => {
    const coincidedBucket = data.buckets.find(bucket => (new Date(bucket.time_range.from).getTime()) === point.time)

    if (coincidedBucket) {
      const providerAndPmGraphPoints: { provider_name: DimensionGraphPoint, vendor: DimensionGraphPoint } = {
        provider_name: {},
        vendor: {}
      }

      const metricName = getMetricName(metric, dashboardCurrency)

      coincidedBucket.data.forEach((item) => {
        // @ts-ignore: Analytics is not well maintained for types generation
        const providerValue = item.dimensions[apiConfig.dimensions.provider] as string
        if (providerValue) {
          if (!providerAndPmGraphPoints.provider_name[providerValue]) {
            providerAndPmGraphPoints.provider_name[providerValue] = 0
          }
          // @ts-ignore: Analytics is not well maintained for types generation
          providerAndPmGraphPoints.provider_name[providerValue] += item.metrics[metricName] as number
        }
        // @ts-ignore: Analytics is not well maintained for types generation
        const pmValue = item.dimensions[apiConfig.dimensions.cardVendor] as string
        if (pmValue) {
          if (!providerAndPmGraphPoints.vendor[pmValue]) {
            providerAndPmGraphPoints.vendor[pmValue] = 0
          }
          // @ts-ignore: Analytics is not well maintained for types generation
          providerAndPmGraphPoints.vendor[pmValue] += item.metrics[metricName] as number
        }
      })

      return {
        ...providerAndPmGraphPoints,
        ...point
      }
    }
    return {
      ...additionalGraphKeys,
      ...point
    }
  }) as TrendGraphPoint[]

  const mergedGraphData:TrendGraphData = {
    keys: {
      ...(currencyGraphData.keys),
      ...(additionalGraphKeys)
    },
    graphData: mergedGraphPoints
  }

  return mergedGraphData
}
