import React, { useEffect } from 'react'
import { useLocalize } from '@zooz/react-localize'
import { useSelector, useDispatch } from 'react-redux'

import Locations from '../../../helpers/tools/locations'
import { withLoadTranslations } from '../../Localize/HOCs'
import { ScreenNames } from '../../Localize/screens'
import Page from '../shared/PageStructure'
import CheckEmailMessage from './CheckEmailMessage'
import language from './language'
import { signupInit } from './redux/actions'
import { getShowSuccessMessage } from './redux/selectors'
import SignupForm from './SignupForm'

const SignUp: React.FC = () => {
  const { t } = useLocalize()
  const showSuccessMessage = useSelector(getShowSuccessMessage)
  const dispatch = useDispatch()

  useEffect(() => () => { dispatch(signupInit()) }, [dispatch])

  return (
    <Page
      id='signUpForm'
      className='signUpForm'
      title={showSuccessMessage ? language.checkEmailTitle(t) : language.welcomeTitle(t)}
      links={showSuccessMessage
        ? []
        : [{
            to: Locations.login(),
            id: 'back-login',
            text: language.link.login(t)
          }]}
    >
      {
        showSuccessMessage
          ? <CheckEmailMessage />
          : <SignupForm />
      }
    </Page>
  )
}

export default withLoadTranslations({ screenName: ScreenNames.SignUp })(SignUp)
