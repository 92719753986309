import { translations as sharedComponentsTranslations } from '@zooz/generic-ui-components'

import { createLocalizeInstance } from './helpers'
import { ScreenNames } from './screens'

const screensToFetch = [
  ScreenNames.LoggedInApp,
  sharedComponentsTranslations.SHARED_COMPONENTS_SCREEN
]

const localize = createLocalizeInstance(screensToFetch)

export default localize
