import * as React from 'react'
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import { getLocation } from 'connected-react-router'
import { Location } from 'history'
import queryString from 'query-string'
import { connect } from 'react-redux'
import validator from 'validator'

import errorMessages from '../../../constants/Errors'
import { PortalState } from '../../../redux/store'
import {
  ButtonSubmit as Button,
  TermsAndConditions,
  FormError,
  InputField,
  FormButtonWrapper
} from '../shared'
import language from './language'
import { clearSignUpErrorMessage, signUpRequest, SignUpRequestInfo } from './redux/actions'
import { getFormErrorMessage, getIsSigningUp, getSignUpInfo } from './redux/selectors'

import styles from './SignupForm.scss'

export type LeadSourceType = 'website_demo'
  | 'website_test_environment'
  | 'website_get_started'
  | 'website_starter'
  | 'website_growing'
  | 'PaymentsOS'

const defaultLeadSource: LeadSourceType = 'PaymentsOS'
const leadSourceOptions: LeadSourceType[] = [
  'website_demo',
  'website_test_environment',
  'website_get_started',
  'website_starter',
  'website_growing',
  defaultLeadSource
]

export interface FormFields {
  firstName: string;
  lastName: string;
  companyName: string;
  domain: string;
  email: string;
  checkbox: boolean;
}

const formFieldsKeys: Array<keyof FormFields> = [
  'firstName',
  'lastName',
  'companyName',
  'domain',
  'email',
  'checkbox'
]

export interface RegisterFormProps extends WithLocalizeProps{
  signUpRequest: (signUpInfo: SignUpRequestInfo) => void;
  clearSignUpErrorMessage: () => void;
  location: Location;
  isSigningUp: boolean;
  formErrorMessage: string;
  signUpInfo: Omit<SignUpRequestInfo, 'leadSource'>
}

export interface RegisterFormState {
  errors: FormFields;
  values: FormFields;
  formErrorMessage: string;
}

class SignupForm extends React.Component<RegisterFormProps, RegisterFormState> {
  static getDerivedStateFromProps (props: RegisterFormProps, state: RegisterFormState): RegisterFormState | null {
    const { t } = props
    // handle setting email exists message from server to email field error
    if (props.formErrorMessage !== state.formErrorMessage) { // formErrorMessage (from server) was changed
      let nextFormErrorMessage = props.formErrorMessage
      let nextErrors = state.errors
      if (props.formErrorMessage === errorMessages.email.exists(t)) { // case message from server is email exits error
        nextFormErrorMessage = ''
        if (state.errors.email !== errorMessages.email.exists(t)) { // current displayed error is not email exist
          nextErrors = {
            ...state.errors,
            email: props.formErrorMessage
          }
        }
      } else if (state.errors.email === errorMessages.email.exists(t)) {
        nextErrors = {
          ...state.errors,
          email: ''
        }
      }
      return {
        values: state.values,
        errors: nextErrors,
        formErrorMessage: nextFormErrorMessage
      }
    }
    return null
  }

  state: RegisterFormState = {
    errors: {
      firstName: '',
      lastName: '',
      companyName: '',
      domain: '',
      email: '',
      checkbox: false
    },
    values: {
      firstName: this.props.signUpInfo.firstName,
      lastName: this.props.signUpInfo.lastName,
      companyName: this.props.signUpInfo.merchantName,
      domain: this.props.signUpInfo.merchantDomain,
      email: this.props.signUpInfo.userEmail,
      checkbox: false
    },
    formErrorMessage: ''
  }

  handleSubmit = (event: React.ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (this.isFormValid()) {
      this.props.signUpRequest({
        firstName: this.state.values.firstName,
        lastName: this.state.values.lastName,
        merchantName: this.state.values.companyName,
        merchantDomain: this.state.values.domain,
        userEmail: this.state.values.email,
        leadSource: this.getLeadSource()
      })
    }
  }

  /** onBlur and onPressEnter events**/
  handleBlur (fieldName: keyof FormFields) {
    this.setState(currentState => ({
      ...currentState,
      values: {
        ...currentState.values,
        [fieldName]: (currentState.values[fieldName] as string).trim()
      }
    }))
  }

  handleFirstNameBlur = (): void => { this.handleBlur('firstName') }
  handleLastNameBlur = (): void => { this.handleBlur('lastName') }
  handleCompanyNameBlur = (): void => { this.handleBlur('companyName') }

  handleDomainBlur = (): void => {
    const { t } = this.props
    this.setState((currentState) => {
      const isUrl = validator.isURL(currentState.values.domain)
      if (
        !isUrl &&
        currentState.values.domain.length > 0 &&
        typeof currentState.errors.domain === 'undefined'
      ) {
        return {
          ...currentState,
          errors: {
            ...currentState.errors,
            domain: errorMessages.domain.invalid(t)
          }
        }
      } if (isUrl && currentState.errors.domain) {
        return {
          ...currentState,
          errors: {
            ...currentState.errors,
            domain: ''
          }
        }
      }
      return currentState
    })
  }

  handleEmailBlur = (): void => {
    const { t } = this.props
    this.setState((currentState) => {
      const isValidEmail = validator.isEmail(currentState.values.email)
      if (
        !isValidEmail &&
        currentState.values.email.length > 0 &&
        typeof currentState.errors.email === 'undefined'
      ) {
        return {
          ...currentState,
          errors: {
            ...currentState.errors,
            email: errorMessages.email.invalid(t)
          }
        }
      } if (isValidEmail && currentState.errors.email) {
        return {
          ...currentState,
          errors: {
            ...currentState.errors,
            email: ''
          }
        }
      }
      return currentState
    }, () => {
      if (this.props.formErrorMessage === errorMessages.email.exists(t)) {
        this.props.clearSignUpErrorMessage()
      }
    })
  }

  /**  onFocus events **/
  handleOnFocus (field: keyof FormFields): void {
    this.setState((prevState: RegisterFormState): RegisterFormState => {
      if (prevState.errors[field]) {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            [field]: undefined
          }
        }
      }
      // bail out of render
      return null
    })
  }

  handleFirstNameOnFocus = (): void => { this.handleOnFocus('firstName') }

  handleLastNameOnFocus = (): void => { this.handleOnFocus('firstName') }

  handleCompanyNameOnFocus = (): void => { this.handleOnFocus('companyName') }

  handleDomainOnFocus = (): void => { this.handleOnFocus('domain') }

  handleEmailOnFocus = (): void => { this.handleOnFocus('email') }

  /**  onChange events **/
  handleOnChange (field: keyof FormFields, value = ''): void {
    this.setState((currentState: RegisterFormState): RegisterFormState => ({
      ...currentState,
      values: {
        ...currentState.values,
        [field]: value
      },
      errors: {
        ...currentState.errors,
        [field]: undefined
      }
    }))
  }

  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.handleOnChange('firstName', event.target?.value)
  }

  handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.handleOnChange('lastName', event.target?.value)
  }

  handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.handleOnChange('companyName', event.target?.value)
  }

  handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.handleOnChange('domain', event.target?.value?.trim())
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.handleOnChange('email', event.target?.value?.trim())
  }

  getLeadSource (): LeadSourceType {
    const lead = queryString.parse(this.props.location.search).s as LeadSourceType
    return leadSourceOptions.includes(lead) ? lead : defaultLeadSource
  }

  handleTermsAndConditionsChange = (checked: boolean): void => {
    this.setState(({ values, errors, ...restState }) => ({
      ...restState,
      values: {
        ...values,
        checkbox: checked
      },
      errors: {
        ...errors,
        checkbox: false
      }
    }))
  }

  isFormValid () {
    const { t } = this.props
    const newErrorObj: FormFields = { ...this.state.errors }
    let isValid = true
    let missingRequiredField = false
    for (const key of formFieldsKeys) {
      isValid = isValid && !this.state.errors[key]
      if (!this.state.values[key]) {
        // @ts-ignore: ts fails to accept type of newErrorObj
        newErrorObj[key] = key === 'checkbox' ? true : errorMessages[key].required(t)
        missingRequiredField = true
      }
    }
    if (missingRequiredField) {
      this.setState({ errors: newErrorObj })
    }
    return isValid && !missingRequiredField
  }

  /** REACT Lifecycle **/
  render (): JSX.Element {
    const { t } = this.props
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.nameContainer}>
          <InputField
            autoFocus
            id='register-first-name'
            error={this.state.errors.firstName}
            onBlur={this.handleFirstNameBlur}
            onFocus={this.handleFirstNameOnFocus}
            onChange={this.handleFirstNameChange}
            value={this.state.values.firstName}
            label={language.signUpForm.firstName(t)}
            autoComplete='given-name'
          />
          <InputField
            id='register-last-name'
            error={this.state.errors.lastName}
            onBlur={this.handleLastNameBlur}
            onFocus={this.handleLastNameOnFocus}
            onChange={this.handleLastNameChange}
            value={this.state.values.lastName}
            label={language.signUpForm.lastName(t)}
            autoComplete='family-name'
          />
        </div>
        <InputField
          id='register-company-name'
          error={this.state.errors.companyName}
          onBlur={this.handleCompanyNameBlur}
          onFocus={this.handleCompanyNameOnFocus}
          onChange={this.handleCompanyNameChange}
          value={this.state.values.companyName}
          label={language.signUpForm.companyName(t)}
        />
        <InputField
          label={language.signUpForm.domain(t)}
          error={this.state.errors.domain}
          id='register-domain'
          onBlur={this.handleDomainBlur}
          onFocus={this.handleDomainOnFocus}
          value={this.state.values.domain}
          onChange={this.handleDomainChange}
        />
        <InputField
          label={language.signUpForm.email(t)}
          error={this.state.errors.email}
          id='register-email'
          onBlur={this.handleEmailBlur}
          onFocus={this.handleEmailOnFocus}
          value={this.state.values.email}
          onChange={this.handleEmailChange}
          autoComplete='email'
          type='email'
        />
        <TermsAndConditions
          onChange={this.handleTermsAndConditionsChange}
          checked={this.state.values.checkbox}
          error={this.state.errors.checkbox}
        />
        <FormButtonWrapper>
          <FormError isVisible={!!this.props.formErrorMessage}>
            {this.state.formErrorMessage}
          </FormError>
          <Button loading={this.props.isSigningUp}>{language.signUpForm.button(t)}</Button>
        </FormButtonWrapper>
      </form>
    )
  }
}

const mapDispatchToProps = {
  signUpRequest,
  clearSignUpErrorMessage
}

const mapStateToProps = (state: PortalState) => ({
  location: getLocation(state),
  isSigningUp: getIsSigningUp(state),
  formErrorMessage: getFormErrorMessage(state),
  signUpInfo: getSignUpInfo(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(SignupForm))
