import localStorageAccessor from '../../../../../helpers/localStorageAccessor/localStorageAccessor'
import { ADD_WALK_STEP, CLEAR_STEPS, NEXT_STEP, PREVIOUS_STEP } from './actionTypes'

// Action Creators
export function addWalkStep ({ x, y, height, width, description, title, position, tooltipOffsets }) {
  return { type: ADD_WALK_STEP, x, y, height, width, title, description, position, tooltipOffsets }
}

export function nextStep () {
  return { type: NEXT_STEP }
}

export function previousStep () {
  return { type: PREVIOUS_STEP }
}

export function clearSteps () {
  return (dispatch) => {
    localStorageAccessor.walkthroughDismissed = true
    dispatch({ type: CLEAR_STEPS })
  }
}
