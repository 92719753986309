import { all, call, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import ProvidersApi from '../../api/ProvidersApi'
import * as ENVIRONMENTS from '../../constants/environments'
import Errors from '../../constants/Errors'
import localize from '../../features/Localize/localize'
import { actions, types } from './actions'
import messageDispatcher from '../../features/Messages/redux/messageDispatcher'

export function * fetchProviders (): any {
  try {
    const [providersLive, providersTest, riskTest, riskLive] = yield all([
      call(ProvidersApi.fetchProviders, ENVIRONMENTS.LIVE),
      call(ProvidersApi.fetchProviders, ENVIRONMENTS.TEST),
      call(ProvidersApi.fetchProviders, ENVIRONMENTS.TEST, 'risk_provider'),
      call(ProvidersApi.fetchProviders, ENVIRONMENTS.LIVE, 'risk_provider')
    ])
    yield put(actions.fetchProvidersSuccess(
      [...providersLive, ...riskLive],
      [...providersTest, ...riskTest]
    ))
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * providersSaga (): IterableIterator<ForkEffect> {
  yield takeLatest(types.FETCH_PROVIDERS, fetchProviders)
}
