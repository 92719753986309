// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormButtonWrapper__buttonWrapper___Zmgub{margin-top:50px}`, "",{"version":3,"sources":["webpack://./src/features/UnProtected/shared/FormButtonWrapper/FormButtonWrapper.scss"],"names":[],"mappings":"AAAA,0CACE,eAAA","sourcesContent":[".buttonWrapper {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": `FormButtonWrapper__buttonWrapper___Zmgub`
};
export default ___CSS_LOADER_EXPORT___;
