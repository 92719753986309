import React from 'react'
import classNames from 'classnames'

import style from './FormError.scss'

const FormError: React.FC<{ isVisible?: boolean }> = ({ isVisible = false, children }) => (
  <div
    data-test='unProtected__formError'
    data-shown={isVisible}
    className={classNames(style.error, { [style['error--show']]: isVisible })}
  >
    {children}
  </div>
)

export default FormError
