import React from 'react'
import classnames from 'classnames'
import { FontAwesome } from '@zooz/generic-ui-components'

import style from './style.scss'

interface MenuEntryProps extends React.HTMLAttributes<HTMLSpanElement>{
  icon?: string,
  isCollapsed: boolean
}

const MenuEntry: React.FC<MenuEntryProps> = ({ icon, children, isCollapsed, ...rest }) => (
  <span
    {...rest}
    className={classnames(style['paymentsos-menu-text'], {
      [style['paymentsos-menu-text--collapsed']]: isCollapsed
    })}
  >
    {icon && <FontAwesome data-test='menuEntry__icon' icon={icon} />}
    <span data-test='menuEntry__text' className={style['nav-text']}>{children}</span>
  </span>

)

export default MenuEntry
