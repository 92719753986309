import { Managment } from '@payu/paymentsos-types'

import ApiBase from './ApiBase'

type ProviderType = 'cc_processor' | 'risk_provider'

class ProvidersApi extends ApiBase {
  // eslint-disable-next-line class-methods-use-this
  get resourcePath (): string {
    return '/providers'
  }

  fetchProviders = async (env: 'test' | 'live', type: ProviderType = 'cc_processor'): Promise<Managment.ProviderResource[]> => {
    const configHeaders = this.defaultConfig
    if (env) {
      configHeaders.headers['x-payments-os-env'] = env
    }
    const response = await this.httpClient.get(this.resourcePath, {
      type
    }, configHeaders)
    return response.data
  }
}

export default new ProvidersApi()
