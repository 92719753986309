import React, { PureComponent } from 'react'
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Transition } from 'react-transition-group'

import language from './language'
import { dismissMessage } from './redux/actions'
import { isMessageDismissed } from './redux/selectors'

import css from './ActivatedAccountMessage.scss'

export interface ActivatedAccountMessageOwnProps {
  onClick?: () => void,
  visible?: boolean
}

export interface ActivatedAccountMessageState {
  shake: boolean
}

type ActivatedAccountMessageProps = ActivatedAccountMessageOwnProps & WithLocalizeProps

class ActivatedAccountMessage extends PureComponent<ActivatedAccountMessageProps, ActivatedAccountMessageState> {
  state = {
    shake: false
  }

  timeout: ReturnType<typeof setTimeout>

  componentDidMount (): void {
    window.addEventListener('click', this.shake)
  }

  componentWillUnmount (): void {
    window.removeEventListener('click', this.shake)
    clearTimeout(this.timeout)
  }

  shake = (): void => {
    clearTimeout(this.timeout)
    const { visible } = this.props
    if (visible) {
      this.setState({ shake: true })
      this.timeout = setTimeout(() => {
        this.setState({ shake: false })
        clearTimeout(this.timeout)
      }, 800)
    }
  }

  render (): JSX.Element {
    const { t, visible, onClick } = this.props

    return (
      <Transition appear unmountOnExit in={visible} timeout={500}>
        {status => (
          <div className={classnames(css.message, css[`message--${status}`], {
            [css['message--shake']]: this.state.shake
          })}
          >
            <div className={css.account_activated}>{language.accountActivated(t)}</div>
            <div className={css.default_env}>
              <label className={css.default_env__prefix}>
                {language.defaultEnv.prefix(t)}
              </label>
              <label className={css.default_env__env}>
                {language.defaultEnv.env(t)}
              </label>
            </div>
            <button type='button' onClick={onClick} className={css.button}>{language.button(t)}</button>
          </div>
        )}
      </Transition>
    )
  }
}

const mapStateToProps = (state: Record<string, unknown>) => ({
  // @ts-ignore: state not typed yet
  visible: !isMessageDismissed(state)
})

const mapDispatchToProps = {
  onClick: dismissMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(ActivatedAccountMessage))
