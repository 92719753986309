import { TranslationFunction } from '@zooz/react-localize'
import { global as globalTranslations, reset_password as resetPasswordTranslations } from '@zooz/translation-values'

export default {
  shared: {
    email: {
      placeholder: (t: TranslationFunction) => t(globalTranslations.sharedText_customerProperties_email.key, {
        fallback: globalTranslations.sharedText_customerProperties_email.fallback
      })
    }
  },
  requestResetForm: {
    email: {
      header: (t: TranslationFunction) => t(resetPasswordTranslations.requestReset_lbl_dropUsYourEmail.key, {
        fallback: resetPasswordTranslations.requestReset_lbl_dropUsYourEmail.fallback
      })
    },
    button: (t: TranslationFunction) => t(resetPasswordTranslations.requestReset_btn_sendEmail.key, {
      fallback: resetPasswordTranslations.requestReset_btn_sendEmail.fallback
    }),
    title: (t: TranslationFunction) => t(resetPasswordTranslations.requestReset_title_forgotPassword.key, {
      fallback: resetPasswordTranslations.requestReset_title_forgotPassword.fallback
    }),
    subtitle: (t: TranslationFunction) => t(resetPasswordTranslations.requestReset_title_helpRegainAccess.key, {
      fallback: resetPasswordTranslations.requestReset_title_helpRegainAccess.fallback
    }),
    checkEmail: (t: TranslationFunction) => t(resetPasswordTranslations.requestReset_msgSuccess_checkInbox.key, {
      fallback: resetPasswordTranslations.requestReset_msgSuccess_checkInbox.fallback
    })
  },
  resetForm: {
    password: {
      placeholder: (t: TranslationFunction) => t(globalTranslations.sharedText_lbl_password.key, {
        fallback: globalTranslations.sharedText_lbl_password.fallback
      })
    },
    typePasswordAgain: {
      placeholder: (t: TranslationFunction) => t(globalTranslations.sharedText_lbl_typePasswordAgain.key, {
        fallback: globalTranslations.sharedText_lbl_typePasswordAgain.fallback
      })
    },
    button: (t: TranslationFunction) => t(resetPasswordTranslations.resetForm_btn_reset.key, {
      fallback: resetPasswordTranslations.resetForm_btn_reset.fallback
    }),
    title: (t: TranslationFunction) => t(resetPasswordTranslations.resetForm_title_resetPassword.key, {
      fallback: resetPasswordTranslations.resetForm_title_resetPassword.fallback
    }),
    success: (t: TranslationFunction) => t(resetPasswordTranslations.resetForm_msgSuccess_passwordChanged.key, {
      fallback: resetPasswordTranslations.resetForm_msgSuccess_passwordChanged.fallback
    })
  },
  link: {
    login: (t: TranslationFunction) => t(resetPasswordTranslations.main_lbl_backToLogin.key, {
      fallback: resetPasswordTranslations.main_lbl_backToLogin.fallback
    })
  }
}
