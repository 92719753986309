import { UserConfiguration } from '@payu/paymentsos-types'

import { UpdateUserConfigurationsOptions } from '../../api/UsersConfigurationApi'

export enum ActionTypes {
  FETCH_USER_CONFIGURATION = 'User_Configuration/Fetch Configuration',
  FETCH_USER_CONFIGURATION_SUCCESSFULLY = 'User_Configuration/Fetch Configuration Successfully',
  UPDATE_USER_CONFIGURATION = 'User_Configuration/Update Configuration',
  UPDATE_USER_CONFIGURATION_SUCCESSFULLY = 'User_Configuration/Update Configuration Successfully'
}

type FetchUserConfigurationType = {
  type: ActionTypes.FETCH_USER_CONFIGURATION
}
export const fetchUserConfiguration = (): FetchUserConfigurationType => ({ type: ActionTypes.FETCH_USER_CONFIGURATION })

export type FetchUserConfigurationSuccessfullyType = {
  type: ActionTypes.FETCH_USER_CONFIGURATION_SUCCESSFULLY,
  userConfiguration: UserConfiguration.UserConfiguration
}
export const fetchUserConfigurationSuccessfully = (
  userConfiguration: UserConfiguration.UserConfiguration
):FetchUserConfigurationSuccessfullyType => ({
  type: ActionTypes.FETCH_USER_CONFIGURATION_SUCCESSFULLY,
  userConfiguration
})

export type UpdateUserConfigurationType = {
  type: ActionTypes.UPDATE_USER_CONFIGURATION,
  userConfiguration: UserConfiguration.UserConfiguration,
  options?: UpdateUserConfigurationsOptions
}

export const updateUserConfiguration = (
  userConfiguration: UserConfiguration.UserConfiguration,
  options?: UpdateUserConfigurationsOptions
): UpdateUserConfigurationType => ({
  type: ActionTypes.UPDATE_USER_CONFIGURATION,
  userConfiguration,
  options
})

type UpdateUserConfigurationTypeSuccessfully = {
  type: ActionTypes.UPDATE_USER_CONFIGURATION_SUCCESSFULLY,
  userConfiguration: UserConfiguration.UserConfiguration
}
export const updateUserConfigurationSuccessfully = (
  userConfiguration: UserConfiguration.UserConfiguration
): UpdateUserConfigurationTypeSuccessfully => ({
  type: ActionTypes.UPDATE_USER_CONFIGURATION_SUCCESSFULLY,
  userConfiguration
})

export type Actions = FetchUserConfigurationType |
  FetchUserConfigurationSuccessfullyType |
  UpdateUserConfigurationType |
  UpdateUserConfigurationTypeSuccessfully
