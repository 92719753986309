import { TranslationFunction } from '@zooz/react-localize'
import { app_logged_in as appLoggedInTranslations, global as globalTranslation } from '@zooz/translation-values'

export default {
  accountActivated: (t: TranslationFunction): string => t(
    appLoggedInTranslations.activatedPopup_title_accountActivated.key, {
      fallback: appLoggedInTranslations.activatedPopup_title_accountActivated.fallback
    }
  ),
  defaultEnv: {
    prefix: (t: TranslationFunction): string => t(
      appLoggedInTranslations.activatedPopup_defaultEnv_isNow.key, {
        fallback: appLoggedInTranslations.activatedPopup_defaultEnv_isNow.fallback
      }
    ),
    env: (t: TranslationFunction): string => t(
      globalTranslation.sharedText_environment_live.key, {
        fallback: globalTranslation.sharedText_environment_live.fallback
      }
    ).toUpperCase()
  },
  button: (t: TranslationFunction): string => t(
    appLoggedInTranslations.activatedPopup_btn_understand.key, {
      fallback: appLoggedInTranslations.activatedPopup_btn_understand.fallback
    }
  )
}
