import { get } from 'lodash'

import SessionsApi from '../api/SessionsApi'
import UsersApi from '../api/UsersApi'
import errors from '../constants/Errors'
import localize from '../features/Localize/localize'
import localStorageAccessor from '../helpers/localStorageAccessor/localStorageAccessor'
import sentry from '../helpers/sentry'
import Locations from '../helpers/tools/locations'
import auth from './auth/actions'
import * as authTypes from './auth/types'
import { history } from './store'

const USER_LOCKED_MSG = 'You have been temporarily locked out due to too many failed login attempts'
const EMAIL_ALREADY_EXISTS = 'User email already exists'

export const authMiddleWare = store => next => async (action) => {
  const clearServerSession = async () => {
    try {
      await SessionsApi.kill()
    } catch (err) {
      if (get(err, 'response.status') === 500) {
        sentry.captureException(err)
      }
    }
  }

  const clearLocalSession = () => {
    localStorageAccessor.clearSession()
    store.dispatch(auth.clearSession())
  }

  const clearUnattachedUser = () => {
    store.dispatch(auth.isUnattachedUser(false))
  }

  const storeLocalSession = (merchantIds, userId, sessionToken, email, primaryAccountId) => {
    localStorageAccessor.initializeUser()
    localStorageAccessor.saveSession({ merchantIds, userEmail: email, sessionToken, userId, primaryAccountId })
    store.dispatch(auth.loginSuccess({ email, accountId: primaryAccountId, userId, sessionToken, merchantIds }))
  }

  const redirectOnAuthSuccess = (fromUrl = Locations.root()) => {
    try {
      history.push(fromUrl, { noPrompt: true })
    } catch (err) {
      history.push(Locations.root(), { noPrompt: true })
    }
  }

  const getAccountId = (user, merchantIds) => {
    let { accountId } = user

    if (accountId === '*') {
      accountId = merchantIds.find(id => id !== '*') || '*'
    }

    return accountId
  }

  if (action.type === authTypes.LOGIN_USER) {
    store.dispatch(auth.isLoggingIn(true))
    try {
      const { email, password, from } = action.payload
      const user = await SessionsApi.create(email, password)
      const merchantIds = await UsersApi.permittedAccounts(user.userId, user.sessionToken, user.accountId)

      const accountId = getAccountId(user, merchantIds)
      storeLocalSession(merchantIds, user.userId, user.sessionToken, email, accountId)

      if (accountId === '*') {
        store.dispatch(auth.isUnattachedUser(true))
      } else {
        redirectOnAuthSuccess(from)
      }
    } catch (error) {
      const { status, data } = error.response || {}
      if (!error.response || status !== 401) {
        store.dispatch(auth.loginPageError(errors.defaultError(localize.translate)))
      } else if (data.more_info.startsWith(USER_LOCKED_MSG)) {
        store.dispatch(auth.loginPageError(data.more_info))
      } else {
        store.dispatch(auth.loginPageError(errors.invalidCredentials(localize.translate)))
      }
    }
  }

  if (action.type === authTypes.SSO_USER_LOGGED_IN) {
    store.dispatch(auth.isLoggingIn(true))
    try {
      const { email, userId, sessionToken, accountId, from } = action.payload
      const merchantIds = await UsersApi.permittedAccounts(userId, sessionToken, accountId)
      storeLocalSession(merchantIds, userId, sessionToken, email, accountId)
      redirectOnAuthSuccess(from)
    } catch (error) {
      store.dispatch(auth.loginPageError(errors.defaultError(localize.translate)))
    }
  }

  const PASSWORD_VALIDATION_ERROR = 'password field is mandatory and should be a strong password.'
  const INVALID_TOKEN = 'Invalid token'
  const getRegisterErrorMessage = (error) => {
    const moreInfo = get(error, 'response.data.more_info', '')
    let errorMessage = moreInfo || errors.defaultError(localize.translate)
    switch (get(error, 'response.status')) {
      case 400:
        sentry.captureException(error)
        if (moreInfo.startsWith(PASSWORD_VALIDATION_ERROR)) {
          errorMessage = moreInfo.split(PASSWORD_VALIDATION_ERROR)[1] || errors.defaultError(localize.translate)
        }
        break
      case 401:
        if (moreInfo.startsWith(INVALID_TOKEN)) {
          errorMessage = errors.invalidToken(localize.translate)
        } else {
          errorMessage = errors.invalidCredentials(localize.translate)
        }
        break
      case 409:
        // This is part of the field errors and not page errors
        if (moreInfo.startsWith(EMAIL_ALREADY_EXISTS)) {
          errorMessage = errors.email.exists(localize.translate)
        } else {
          errorMessage = errors.alreadyMember(localize.translate)
        }
        break
    }
    return errorMessage
  }

  if ([authTypes.JOIN_USER, authTypes.JOIN_EXISTING_USER].includes(action.type)) {
    store.dispatch(auth.isJoiningUser(true))
    try {
      const { accountId, password, token, from, email } = action.payload

      if (action.type === authTypes.JOIN_USER) {
        await UsersApi.join(accountId, password, token)
      } else {
        await UsersApi.joinExistingUser(accountId, password, token)
      }

      // login
      const isSso = await SessionsApi.verifySso(email)
      const ssoConstaints = isSso ? await SessionsApi.getSsoConstraints(email) : {}
      if (ssoConstaints.force_idp_login) {
        SessionsApi.initSso(email)
      } else {
        const user = await SessionsApi.create(email, password)
        const merchantIds = await UsersApi.permittedAccounts(user.userId, user.sessionToken, user.accountId)
        storeLocalSession(merchantIds, user.userId, user.sessionToken, email, user.accountId)
        redirectOnAuthSuccess(from)
      }
    } catch (error) {
      const errorMessage = getRegisterErrorMessage(error)
      store.dispatch(auth.isJoiningUser(false))
      store.dispatch(auth.registerPageError(errorMessage))
    }
  }

  if (action.type === authTypes.UNAUTHORIZED) {
    const { location } = history
    clearServerSession()
    clearLocalSession()
    clearUnattachedUser()
    const pathName = location.pathname
    if (pathName !== Locations.login()) {
      const state = {
        from: location,
        noPrompt: true
      }
      history.push(Locations.login(), state)
    }
  }

  return next(action)
}
