/* eslint-disable camelcase */
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import { Button, FontAwesome, Paragraph } from '@zooz/generic-ui-components'
import qs from 'query-string'
import React, { Component, FormEvent } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import errorMessages from '../../../../constants/Errors'
import { safeDecode } from '../../../../helpers/jwtUtils'
import validatePassword from '../../../../helpers/passwordValidation'
import locations from '../../../../helpers/tools/locations'
import auth from '../../../../redux/auth/actions'
import { getJoinError, isJoining } from '../../../../redux/auth/selectors'
import { PortalState } from '../../../../redux/store'
import { withLoadTranslations } from '../../../Localize/HOCs'
import { ScreenNames } from '../../../Localize/screens'
import { FormError, InputField, Page } from '../../shared'
import { isValidToken } from '../helpers'
import language from '../language'
import { handleHideSubmitError } from '../redux/actions'
import Checkbox from './Checkbox'

import css from './JoinExistingUser.scss'

interface FormFields {
  password: string,
  checkbox: boolean
}

const formFields: FormFields = {
  password: '',
  checkbox: false
}

const reduceFunc = <T, K>(val: T, nextVal: K): T | K => val && nextVal

type JoinExistingUserOwnProps = RouteComponentProps

interface JoinExistingUserReduxProps {
  handleHideSubmitError: () => void,
  joinExistingUser: (props: {
    email: string,
    token: string,
    accountId: string,
    password: string,
    from: string
  }) => void,
  isJoining: boolean,
  joinError: string,
  from: string
}

type JoinExistingUserProps = JoinExistingUserOwnProps & JoinExistingUserReduxProps & WithLocalizeProps

interface JoinUserState {
  errors: FormFields,
  values: FormFields
}

const buttonWidth = '100%'

class JoinExistingUser extends Component<JoinExistingUserProps, JoinUserState> {
  email: string
  account_id: string
  token: string

  constructor (props: JoinExistingUserProps) {
    super(props)
    this.state = {
      errors: { ...formFields },
      values: { ...formFields }
    }
  }

  componentDidMount () {
    this.props.handleHideSubmitError()
    const { token } = qs.parse(this.props.history.location.search)
    const { email, account_id, invitation_id } = safeDecode(token as string).payload || {}

    if (isValidToken({ email, account_id, invitation_id })) {
      Object.assign(this, { email, account_id, invitation_id, token })
    } else {
      this.props.history.push(locations.login())
    }
  }

  isFormValid = () => {
    const { t } = this.props
    const newErrorObj = { ...this.state.errors }
    const errorKeys = Object.keys(formFields) as Array<keyof FormFields>
    let isValid = true
    for (const key of errorKeys) {
      isValid = isValid && !this.state.errors[key]
      if (!this.state.values[key]) {
        // @ts-ignore: TS fails to handle key in newErrorObj
        newErrorObj[key] = errorMessages[key]?.required?.(t)
      }
    }
    this.setState({ errors: newErrorObj })
    const requiredVals = (Object.keys(formFields) as Array<keyof FormFields>).map(key => this.state.values[key])
    return isValid && requiredVals.reduce(reduceFunc, true)
  }

  handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    const { email, account_id, token, state } = this
    if (this.isFormValid()) {
      const payload = {
        email,
        token,
        accountId: account_id,
        password: state.values.password,
        from: this.props.from
      }
      this.props.joinExistingUser(payload)
    }
  }

  validatePasswordLogic = (password: string) => {
    const { t } = this.props

    const { values, errors } = this.state

    const nextState = {
      values: { ...values, password },
      errors: {
        ...errors,
        password: validatePassword(password).message && validatePassword(password).message(t)
      }
    }

    this.setState(nextState)
  }

  validateCheckbox = (value: boolean) => {
    this.setState({
      values: { ...this.state.values, checkbox: value },
      errors: { ...this.state.errors, checkbox: undefined }
    })
  }

  handleOnFocus = (field: keyof FormFields) => {
    if (this.state.errors[field]) {
      this.setState({ errors: { ...this.state.errors, [field]: undefined } })
    }
  }

  render () {
    const { t } = this.props
    return (
      <Page
        title={language.existingUserTitle(t)}
        subtitle={language.existingUserSubtitle(t)}
        links={[
          {
            to: locations.forgotPassword(),
            id: 'forgot',
            text: language.forgot(t)
          }
        ]}
      >
        <form onSubmit={this.handleSubmit}>
          <InputField
            disabled
            value={this.email}
            id='join-email'
          />
          <InputField
            type='password'
            placeholder={language.password(t)}
            error={this.state.errors.password}
            id='join-password'
            onBlur={(event) => {
              this.validatePasswordLogic(event.target.value)
            }}
            onFocus={() => { this.handleOnFocus('password') }}
            onPressEnter={(event) => {
              const target = event.target as HTMLInputElement
              this.validatePasswordLogic(target.value)
            }}
          />
          <div className={css.MessageBox}>
            <FontAwesome className={css.MessageBox__icon} icon='info' />
            <Paragraph className={css.MessageBox__text}>{language.checkboxDescription(t)}</Paragraph>
          </div>
          <Checkbox
            checkboxText={language.checkboxApproval(t)}
            errorMessage={errorMessages.checkbox.required(t)}
            onChange={(checked) => { this.validateCheckbox(checked) }}
            checked={this.state.values.checkbox}
            error={this.state.errors.checkbox}
          />
          <FormError isVisible={!!this.props.joinError}>
            {this.props.joinError}
          </FormError>
          <div className={css.buttonsBox}>
            <Button type='submit' width={buttonWidth} spinner={this.props.isJoining}>
              {language.buttonJoinTeam(t)}
            </Button>
            <div className={css.text}>{language.orBetweenButtons(t)}</div>
            <Button type='button' width={buttonWidth} inverted onClick={() => this.props.history.push(`${locations.login()}`)}>
              {language.buttonLoginToPreviousAccount(t)}
            </Button>
          </div>
        </form>
      </Page>
    )
  }
}

const mapDispatchToProps = {
  joinExistingUser: auth.joinExistingUser,
  handleHideSubmitError
}

const mapStateToProps = (state: PortalState) => ({
  joinForm: state.joinReducers.handleFormRequestsErrors.joinForm,
  isJoining: isJoining(state),
  joinError: getJoinError(state)
})

export default withLoadTranslations<JoinExistingUserProps>({
  screenName: ScreenNames.Join
})(connect(mapStateToProps, mapDispatchToProps)(withLocalize(JoinExistingUser)))
