import React, { useEffect } from 'react'
import { useLocalize } from '@zooz/react-localize'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import Locations from '../../../helpers/tools/locations'
import auth from '../../../redux/auth/actions'
import { withLoadTranslations } from '../../Localize/HOCs'
import { ScreenNames } from '../../Localize/screens'
import { Page } from '../shared'
import language from './language'
import LoginForm from './LoginForm'

const SignIn: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useLocalize()
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      if (window.location.search) {
        const qs = queryString.parse(window.location.search)
        const errorBase64 = (qs.error as string)
        if (errorBase64) {
          const error = JSON.parse(atob(errorBase64)) as { description: string }
          dispatch(auth.loginPageError(error.description))
        }
      }
    } catch (err) {
      history.replace(Locations.login())
      console.error('failed to parse base64', err)
    }
  }, [dispatch, history])

  return (
    <Page
      title={language.title(t)}
      subtitle={language.subtitle(t)}
      links={[
        {
          to: Locations.forgotPassword(),
          id: 'forgot',
          text: language.link.forgot(t)
        },
        {
          to: Locations.signUp(),
          id: 'signin-register',
          text: language.link.register(t)
        }
      ]}
    >
      <LoginForm history={history} />
    </Page>
  )
}

export default withLoadTranslations<RouteComponentProps>({ screenName: ScreenNames.SignIn })(SignIn)
