import { TranslationFunction } from '@zooz/react-localize'
import { global as globalTranslations } from '@zooz/translation-values'

export default {
  termsAndConditions: {
    first: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_TermsAndConditions_acceptThe.key, {
        fallback: globalTranslations.sharedText_TermsAndConditions_acceptThe.fallback
      }
    ),
    termsOfUse: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_TermsAndConditions_termsOfService.key, {
        fallback: globalTranslations.sharedText_TermsAndConditions_termsOfService.fallback
      }
    ),
    connector: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_TermsAndConditions_agreeRead.key, {
        fallback: globalTranslations.sharedText_TermsAndConditions_agreeRead.fallback
      }
    ),
    privacy: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_TermsAndConditions_privacyPolicy.key, {
        fallback: globalTranslations.sharedText_TermsAndConditions_privacyPolicy.fallback
      }
    ),
    error: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_errors_agreeTermsPolicy.key, {
        fallback: globalTranslations.sharedText_errors_agreeTermsPolicy.fallback
      }
    )
  }
}
