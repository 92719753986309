import { Providers } from '@payu/paymentsos-types'

const types = {
  FETCH_PROVIDERS: 'FETCH_PROVIDERS',
  FETCH_PROVIDERS_SUCCESS: 'FETCH_PROVIDERS_SUCCESS'
}

const actions = {
  fetchProviders: () => ({ type: types.FETCH_PROVIDERS }),
  fetchProvidersSuccess:
    (providersLive: Providers.ProviderResource[], providersTest: Providers.ProviderResource[]) => ({
      type: types.FETCH_PROVIDERS_SUCCESS,
      providersLive,
      providersTest
    })
}
export default actions
export { types, actions }
