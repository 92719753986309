import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { combinePathsWithEnv, isFeatureAllowed } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'

const { permissions } = PermissionsUtil

const microAppUrl = envConfig.apps.businessUnits

const App: MicroApp = {
  name: 'business_units',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: () => {
    const requiredPermissions = [
      permissions.businessUnits.get
    ]
    return isFeatureAllowed(PermissionsUtil.all, requiredPermissions)
  },
  paths: combinePathsWithEnv([Locations.businessUnits])
}

export default App
