/* eslint-disable class-methods-use-this */
import { HttpClient, HttpError } from '../helpers/httpClient'
import { getAccountId } from '../redux/account/selectors'
import { CurrentUser, currentUser } from '../redux/auth/selectors'
import { getEnvironment } from '../redux/environment/selectors'
import { store } from '../redux/store'
import { AddError } from '../features/Messages/redux/actions'
import { LIVE, TEST } from '../constants/environments'
import messageDispatcher from '../features/Messages/redux/messageDispatcher'

class ApiBase {
  httpClient = new HttpClient()

  get currentUser (): CurrentUser {
    return currentUser(store.getState())
  }

  get accountId (): string {
    return getAccountId(store.getState()) || this.currentUser.accountId
  }

  get currentEnvironment (): typeof LIVE | typeof TEST {
    return getEnvironment(store.getState())
  }

  showError ({ text, code, err, shouldLogError }: AddError): void {
    messageDispatcher.addError({ text, code, err, shouldLogError })
  }

  get defaultConfig (): APIDefaultConfig {
    const sessionToken = this.currentUser?.sessionToken
    const accountId = this.currentUser?.accountId

    const config: APIDefaultConfig = {
      headers: {
        'api-version': '1.1.0'
      }
    }

    if (this.currentEnvironment) {
      config.headers['x-payments-os-env'] = this.currentEnvironment
    }

    if (sessionToken) {
      config.headers.Authorization = `Bearer ${sessionToken}`
    }

    if (accountId) {
      config.headers['x-zooz-account-id'] = accountId
    }

    return config
  }
}

// eslint-disable-next-line camelcase
export type APIError = HttpError<{ more_info?: string }>
export default ApiBase

window.zooz_api = ApiBase
