// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormItem__item___i_EgH{margin-bottom:29px}`, "",{"version":3,"sources":["webpack://./src/features/UnProtected/shared/FormItem/FormItem.scss"],"names":[],"mappings":"AAAA,wBACE,kBAAA","sourcesContent":[".item {\n  margin-bottom: 29px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `FormItem__item___i_EgH`
};
export default ___CSS_LOADER_EXPORT___;
