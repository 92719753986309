import React from 'react'
import { Card, Button, FontAwesome, Hr, ButtonComponentProps } from '@zooz/generic-ui-components'

import language from '../language'
import lightbulbIcon from './lightbulb.svg'

import css from './Hint.scss'

const ButtonHint: React.FC<ButtonComponentProps> = props => (
  <Button {...props} height='19px' className={css.hint__buttons__button} />
)

interface HintProps {
  title: string,
  description: string,
  canNext?: boolean,
  canPrevious?: boolean,
  closeHint?: () => void,
  onNext?: () => void,
  onPrevious?: () => void
}

const Hint: React.FC<HintProps> = ({
  title,
  description,
  canNext = true,
  canPrevious = true,
  onNext,
  onPrevious,
  closeHint
}) => (
  <div className={css.hint}>
    <FontAwesome icon='times' data-test='walkthrough__hint__close' onClick={closeHint} className={css.hint__close} />
    <div className={css.hint__uppersection}>
      <Card className={css.hint__uppersection__image} type={Card.CARD_TYPES.PLACEHOLDER}>
        <img src={lightbulbIcon} />
      </Card>
      <div>
        <h3 className={css.hint__uppersection__title}>{title}</h3>
        <p className={css.hint__uppersection__description}>{description}</p>
      </div>
    </div>
    <Hr className={css.hint__separator} />
    <div className={css.hint__buttons}>
      <ButtonHint onClick={onPrevious} disabled={!canPrevious}>
        {language.hint.previous}
      </ButtonHint>
      {canNext && (
      <ButtonHint onClick={onNext}>
        {language.hint.next}
      </ButtonHint>
      )}
      {!canNext && (
      <ButtonHint onClick={closeHint}>
        {language.hint.done}
      </ButtonHint>
      )}
    </div>
  </div>
)

export default Hint
