import { Accounts, Managment } from '@payu/paymentsos-types'
import { combineReducers } from 'redux'

import localStorageAccessor from '../../helpers/localStorageAccessor/localStorageAccessor'
import { PORTAL_ACTIVATE_MANUALLY_SUCCESS } from '../activations/types'
import { types } from './actions'

export type AccountState = Managment.AccountResource
export type RootAccountState = {
  connectedAccounts: Accounts.BaseAccount[]
}

const initRootAccount: RootAccountState = {
  connectedAccounts: []
}

const initAccount: AccountState = {
  id: null,
  name: null,
  // eslint-disable-next-line camelcase
  reverse_domain: null,
  created: null,
  modified: null,
  // eslint-disable-next-line camelcase
  connected_accounts: null,
  // eslint-disable-next-line camelcase
  is_live: true,
  // eslint-disable-next-line camelcase
  root_account: null
}

function account (state: AccountState = { ...initAccount, ...(localStorageAccessor.accountInformation) }, action: any) {
  switch (action.type) {
    case types.ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        ...action.account
      }

    case PORTAL_ACTIVATE_MANUALLY_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line camelcase
        is_live: true
      }
    default:
      return state
  }
}

export interface RootAccountAction {
  type: typeof types.CONNECTED_ACCOUNTS_FETCH_SUCCESS
  connectedAccounts: Accounts.ConnectedAccountsResponse
}

function rootAccount (state: RootAccountState = { ...initRootAccount }, action: RootAccountAction) {
  switch (action.type) {
    case types.CONNECTED_ACCOUNTS_FETCH_SUCCESS: {
      return {
        ...state,
        connectedAccounts: action.connectedAccounts.connected_accounts
      }
    }
    default:
      return state
  }
}

export interface AccountReducerState {
  account: AccountState,
  rootAccount: RootAccountState
}
const accountReducer = combineReducers({ account, rootAccount })
export default accountReducer
