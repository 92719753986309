import { ToasterTypes } from '@zooz/generic-ui-components'

// Actions
export const ADD_ERROR = 'ADD_ERROR_APP'
export const ADD_MESSAGE = 'ADD_MESSAGE_APP'
export const SHOW_MESSAGE = 'SHOW_MESSAGE_APP'
export const HIDE_MESSAGE = 'HIDE_ERROR_APP'

export type MessageType = ToasterTypes
export const MessageTypes = ToasterTypes

// Action Creators
export interface AddError {
  code?: string,
  text: string,
  err?: unknown,
  shouldLogError?: boolean
}

export interface AddErrorAction extends AddError {
  type: typeof ADD_ERROR
}

export function addError ({ code, text, err, shouldLogError }: AddError): AddErrorAction {
  return { type: ADD_ERROR, code, text, err, shouldLogError }
}

export interface AddMessage {
  code?: string,
  text: string,
  type: MessageType
}

export interface AddMessageAction {
  code?: string,
  text: string,
  messageType: MessageType,
  type: typeof ADD_MESSAGE
}

export function addMessage ({ code, text, type }: AddMessage): AddMessageAction {
  if (type === ToasterTypes.ERROR) {
    console.warn('for shown error messages please use addError action')
  }
  return { type: ADD_MESSAGE, code, text, messageType: type }
}

export function addSuccess (text: string, options = {}): AddMessageAction {
  return addMessage({ ...options, text, type: ToasterTypes.SUCCESS })
}

interface ShowMessage {
  code?: string,
  text: string,
  messageType: MessageType,
  id: string
}
export interface ShowMessageAction extends ShowMessage {
  type: typeof SHOW_MESSAGE
}

export function showMessage ({ code, text, messageType, id }: ShowMessage): ShowMessageAction {
  return { type: SHOW_MESSAGE, code, text, messageType, id }
}

interface HideMessage {
  id: string
}

export interface HideMessageAction extends HideMessage {
  type: typeof HIDE_MESSAGE
}

export function hideMessage ({ id }: HideMessage): HideMessageAction {
  return { type: HIDE_MESSAGE, id }
}
