import { Analytics } from '@payu/paymentsos-types'
import get from 'lodash/get'

import apiConfig from '../../../../api/AnalyticsApiHelpers/apiConfig'
import { PaymentStageNamesTypes } from '../../components/paymentStagesConfig'
import { DEFAULT_CURRENCY } from '../../config'

export type PaymentStageTabsData = {
  paymentStages: {
    [key in PaymentStageNamesTypes]?: Analytics.MetricsResponse;
  },
  dashboardCurrency: string;
}

export default function convertToPaymentStageData (
  data: Analytics.AnalyticsResponse
): null | PaymentStageTabsData {
  return isEmptyData(data) ? null : mapDataToPaymentStagesData(data)
}

function isEmptyData (data: Analytics.AnalyticsResponse): boolean {
  return !(data?.buckets?.length > 0)
}

function mapDataToPaymentStagesData (data: Analytics.AnalyticsResponse): PaymentStageTabsData {
  let paymentStagesData: PaymentStageTabsData = {
    paymentStages: {},
    dashboardCurrency: DEFAULT_CURRENCY
  }
  const currenciesInUse: string[] = []
  const actualData = data.buckets[0].data

  paymentStagesData = actualData.reduce((obj: PaymentStageTabsData, curItem) => {
    // @ts-ignore: Analytics is not well maintained for types generation
    if (!obj.paymentStages[curItem.dimensions[apiConfig.dimensions.paymentStage] as PaymentStageNamesTypes]) {
      // @ts-ignore: Analytics is not well maintained for types generation
      obj.paymentStages[curItem.dimensions[apiConfig.dimensions.paymentStage] as PaymentStageNamesTypes] =
        curItem.metrics
    } else {
      Object.entries(curItem.metrics).forEach(([key, value]) => {
        // @ts-ignore: Analytics is not well maintained for types generation
        obj.paymentStages[curItem.dimensions[apiConfig.dimensions.paymentStage]][key] += value
      })
    }

    const currency = get(curItem, 'dimensions.currency')
    if (curItem.metrics.total_amount_usd !== undefined && !currenciesInUse.includes(currency)) {
      currenciesInUse.push(currency)
    }

    return obj
  }, paymentStagesData)

  if (currenciesInUse.length === 1 && currenciesInUse[0] !== DEFAULT_CURRENCY) {
    paymentStagesData.dashboardCurrency = currenciesInUse[0]
  }

  return paymentStagesData
}
