import { TranslationFunction } from '@zooz/react-localize'
import { app_logged_in as translations } from '@zooz/translation-values'

export default {
  support: (t: TranslationFunction): string => t(translations.topBar_lbl_support.key, {
    fallback: translations.topBar_lbl_support.fallback
  }),
  docs: (t: TranslationFunction): string => t(translations.topBar_lbl_docs.key, {
    fallback: translations.topBar_lbl_docs.fallback
  }),
  logout: (t: TranslationFunction): string => t(translations.topBar_popup_logout.key, {
    fallback: translations.topBar_popup_logout.fallback
  }),
  switchAccount: {
    title: (t: TranslationFunction): string => t(translations.topBar_popup_switchAccount.key, {
      fallback: translations.topBar_popup_switchAccount.fallback
    }),
    byId: 'Switch Account By ID',
    notFound: 'Account not found',
    confirm: 'GO',
    defaultAccount: 'Zooz'
  },
  copySession: {
    text: (t: TranslationFunction): string => t(translations.topBar_popup_copySession.key, {
      fallback: translations.topBar_popup_copySession.fallback
    }),
    success: 'Session Token Copied',
    error: 'Failed To Copy Session Token'
  },
  accountId: 'Account ID',
  userProfile: (t: TranslationFunction): string => t(translations.topBar_popup_userProfile.key, {
    fallback: translations.topBar_popup_userProfile.fallback
  }),
  statusPage: (t: TranslationFunction): string => t(translations.topBar_popup_statusPage.key, {
    fallback: translations.topBar_popup_statusPage.fallback
  }),
  supportTools: (t: TranslationFunction): string => t(translations.topBar_popup_supportTools.key, {
    fallback: translations.topBar_popup_supportTools.fallback
  })
}
