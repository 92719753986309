import { flatten } from 'lodash'
import { createSelector } from 'reselect'

import {
  validateBusinessAddress,
  validateBusinessInformation,
  validateContactInformation,
  validateUserConfirmation
} from '../helpers/validators'
import { State } from './reducers/activationRegistrationUIReducer'

export interface NestedState {
  activationUIReducer: {
    activationRegistrationUIReducer: State
  }
}

export const getBusinessInformation = (state: NestedState) => (
  state.activationUIReducer.activationRegistrationUIReducer.businessInformation)
export const getBusinessAddress = (state: NestedState) => (
  state.activationUIReducer.activationRegistrationUIReducer.businessAddress)
export const getContactInformation = (state: NestedState) => (
  state.activationUIReducer.activationRegistrationUIReducer.contactInformation)
export const getIsShowSuccessPage = (state: NestedState) => (
  state.activationUIReducer.activationRegistrationUIReducer.showSuccessPage)
export const getIsLoading = (state: NestedState) => (
  state.activationUIReducer.activationRegistrationUIReducer.isLoading)
export const accountDetailsSelector = (state: NestedState) => (
  state.activationUIReducer.activationRegistrationUIReducer)
export const isUpdateSucceeded = (state: NestedState) => (
  state.activationUIReducer.activationRegistrationUIReducer.isUpdateSucceeded)
export const userHasNecessaryRightConfirmationSelector = (state: NestedState) => (
  state.activationUIReducer.activationRegistrationUIReducer.userHasNecessaryRightConfirmation)

export const invalidFields = createSelector(
  [
    getBusinessInformation,
    getBusinessAddress,
    getContactInformation,
    userHasNecessaryRightConfirmationSelector
  ],
  (
    businessInformation,
    businessAddress,
    contactInformation,
    userConfirmation
  ) => {
    const validationResults: any[] = []

    const businessInformationValidationResult = validateBusinessInformation(businessInformation)
    pushIfNotEmpty(validationResults, businessInformationValidationResult)

    const validateBusinessAddressResult = validateBusinessAddress(businessAddress)
    pushIfNotEmpty(validationResults, validateBusinessAddressResult)

    const validateContactInformationResult = validateContactInformation(contactInformation)
    pushIfNotEmpty(validationResults, validateContactInformationResult)

    const validateUserConfirmationResult = validateUserConfirmation(userConfirmation)
    pushIfNotEmpty(validationResults, validateUserConfirmationResult)

    return flatten(validationResults)
  }
)

const pushIfNotEmpty = (array: any[], arrToBePushed: any): any[] => {
  if (arrToBePushed.length) {
    array.push(arrToBePushed)
  }
  return array
}
