import { TranslationFunction } from '@zooz/react-localize'
import zxcvbn from 'zxcvbn'

import errors from '../constants/Errors'

export const REQUIRED_PASSWORD_STRENGTH_SCORE = 2
interface PasswordValidationResult {
  valid: boolean;
  strengthScore: number;
  message?: (t: TranslationFunction) => string;
}

export default (password: string): PasswordValidationResult => {
  if (password.length === 0) {
    return { valid: false, message: errors.password.required, strengthScore: 0 }
  } if (password.length < 8) {
    return { valid: false, message: errors.password.tooShort, strengthScore: 0 }
  } if (password.length > 128) {
    return { valid: false, message: errors.password.tooLong, strengthScore: 0 }
  }

  const { score, feedback } = zxcvbn(password)
  if (score < REQUIRED_PASSWORD_STRENGTH_SCORE || feedback) {
    const error = feedback.warning || feedback.suggestions[0]
    if (error) {
      return { valid: false, message: _t => error, strengthScore: score }
    }
  }

  return { valid: true, strengthScore: score }
}
