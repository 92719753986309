import React from 'react'
import { LabeledCheckbox, Action } from '@zooz/generic-ui-components'

import style from './PasswordPolicyPopoverContent.scss'

const MESSAGE_TITLE = 'Upcoming Password Reset Requirement'
const MESSAGE_SUBTITLE = 'Effective January 15, 2025'
const MESSAGE_CONTENT = `Starting January 15, 2025, all users accessing the Control Center will be required to reset their password every 90 days.
This update reinforces our commitment to safeguarding your sensitive information and ensuring the highest level of protection `
const DONT_SHOW_AGAIN = 'Got it! Don’t show this message again'

export interface PasswordPolicyMessageProps {
  dismiss: () => void;
}

const PasswordPolicyPopoverContent: React.FC<PasswordPolicyMessageProps> = ({ dismiss }) => (
  <div data-test='password-policy-message' className={style.PasswordPolicyPopoverContent}>
    <div className={style.PasswordPolicyPopoverContent__title}>{MESSAGE_TITLE}</div>
    <div className={style.PasswordPolicyPopoverContent__subTitle}>{MESSAGE_SUBTITLE}</div>
    <div>{MESSAGE_CONTENT}</div>
    <div className={style.PasswordPolicyPopoverContent__footer}>
      <div>
        <LabeledCheckbox onChange={dismiss}>
          <Action type={Action.TYPES.GHOST}>{DONT_SHOW_AGAIN}</Action>
        </LabeledCheckbox>
      </div>
    </div>
  </div>
)

export default PasswordPolicyPopoverContent
