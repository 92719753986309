import React, { useEffect } from 'react'
import { useLocalize } from '@zooz/react-localize'
import { useDispatch, useSelector } from 'react-redux'

import { FormError, FormItem, InputField, ButtonSubmit as Button } from '../shared'
import language from './language'
import { resetInputChange, resetFocusChange, changePassword } from './redux/actions'
import {
  resetFields,
  resetInvalidFields,
  resetRequiredFields,
  resetTraveresedFields,
  resetFocused,
  resetError as resetErrorSelector,
  resetIsReseting,
  ResetInvalidFields
} from './redux/selectors'

interface ResetFormProps {
  email: string,
  token: string
}
const ResetForm: React.FC<ResetFormProps> = ({ email, token }) => {
  const { t } = useLocalize()
  const dispatch = useDispatch()

  // selectors
  const fieldsData = useSelector(resetFields)
  const invalidFields: ResetInvalidFields = useSelector(resetInvalidFields)
  const requiredFields: ResetInvalidFields = useSelector(resetRequiredFields)
  const traversedFields = useSelector(resetTraveresedFields)
  const focusedFields = useSelector(resetFocused)
  const resetError = useSelector(resetErrorSelector)
  const isResetting = useSelector(resetIsReseting)

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>): void | boolean => {
    event.preventDefault()
    if (!Object.keys(invalidFields).length && !Object.keys(requiredFields).length) {
      dispatch(changePassword(token))
    } else {
      return false
    }
  }

  useEffect(() => {
    if (email) {
      dispatch(resetInputChange('email', email))
    }
  }, [])

  const getMessage = (key: keyof ResetInvalidFields): string => {
    const isTraversed = traversedFields.includes(key)
    if (isTraversed && requiredFields[key]) {
      return requiredFields[key](t)
    } if (invalidFields[key]) {
      return invalidFields[key](t)
    }
    return ''
  }

  const messages = {
    email: getMessage('email'),
    password: getMessage('password'),
    passwordAgain: getMessage('passwordAgain')
  }
  const focused = {
    email: focusedFields.includes('email'),
    password: focusedFields.includes('password'),
    passwordAgain: focusedFields.includes('passwordAgain')
  }

  const isError = (key: string) => messages[key as keyof typeof messages] && !focused[key as keyof typeof focused]

  const help = (key: string) => isError(key) ? messages[key as keyof typeof messages] : ''

  return (
    <form onSubmit={handleSubmit}>
      <InputField
        value={fieldsData.email}
        disabled={!!email}
        onChange={event => dispatch(resetInputChange('email', event.target.value))}
        onBlur={() => dispatch(resetFocusChange('email', false))}
        onFocus={() => dispatch(resetFocusChange('email', true))}
        placeholder={language.shared.email.placeholder(t)}
        id='reset-email'
        error={help('email')}
      />
      <InputField
        value={fieldsData.password}
        onChange={event => dispatch(resetInputChange('password', event.target.value))}
        onBlur={() => dispatch(resetFocusChange('password', false))}
        onFocus={() => dispatch(resetFocusChange('password', true))}
        placeholder={language.resetForm.password.placeholder(t)}
        id='reset-password'
        type='password'
        error={help('password')}
      />
      <InputField
        value={fieldsData.passwordAgain}
        onChange={event => dispatch(resetInputChange('passwordAgain', event.target.value))}
        onBlur={() => dispatch(resetFocusChange('passwordAgain', false))}
        onFocus={() => dispatch(resetFocusChange('passwordAgain', true))}
        placeholder={language.resetForm.typePasswordAgain.placeholder(t)}
        id='reset-password-again'
        type='password'
        error={help('passwordAgain')}
      />
      <FormItem>
        <FormError isVisible={!!resetError}>
          {resetError}
        </FormError>
        <Button loading={isResetting}>{language.resetForm.button(t)}</Button>
      </FormItem>
    </form>
  )
}

export default ResetForm
