import _ from 'lodash'
import { takeEvery, put, select } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'

import { getLocation } from '../../../../../redux/location/selectors'
import { Actions, actions } from './actions'

function * clearBreadcrumbsIfNeeded (action: Actions) {
  const location = getLocation(yield select())
  if (_.get(location, 'pathname') !== _.get(action, 'payload.location.pathname')) {
    yield put(actions.clearCrumbs())
  }
}

export default function * environmentSaga () {
  yield takeEvery(LOCATION_CHANGE, clearBreadcrumbsIfNeeded)
}
