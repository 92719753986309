import { CcActions } from 'cc-actions'

import { actions as accountActions } from '../redux/account/actions'
import { portalActivateManuallySuccess } from '../redux/activations/actions'
import rolesActions from '../redux/roles/actions'

const ccActions: CcActions = {
  portalActivateManuallySuccess,
  fetchAccount: accountActions.fetchAccount,
  fetchRoles: rolesActions.fetchPrivileges
}

export default ccActions
