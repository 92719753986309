import { global as globalTranslations } from '@zooz/translation-values'
import {
  Localize,
  GroupDelimiter,
  DecimalFormats,
  Language,
  LogLevels,
  ApiEnvironmentTypes,
  ShortDateFormats,
  LocalizeCreateOptions
} from '@zooz/localize'
import { UserConfiguration } from '@payu/paymentsos-types'

import envConfig from '../../envConfig'
import localStorageAccessor from '../../helpers/localStorageAccessor/localStorageAccessor'
import {
  DEFAULT_DECIMAL_NUMBER_FORMAT,
  DEFAULT_GROUP_NUMBER_DELIMITER,
  DEFAULT_SHORT_DATE_FORMAT,
  DEFAULT_CACHING_TRANSLATIONS_TTL
} from './constants'
import { ScreenNames } from './screens'
import { LocalizationPreferences } from './types'

function getCurrentDateFormat (localize: Localize): string {
  return getCurrentLanguage(localize)?.dateFormat || DEFAULT_SHORT_DATE_FORMAT
}
function getCurrentGroupNumberDelimiter (localize: Localize): GroupDelimiter {
  return getCurrentLanguage(localize)?.groupNumberDelimiter || DEFAULT_GROUP_NUMBER_DELIMITER
}

function getCurrentDecimalNumberFormat (localize: Localize): DecimalFormats {
  return getCurrentLanguage(localize)?.decimalNumberFormat || DEFAULT_DECIMAL_NUMBER_FORMAT
}

function getCurrentLanguage (localize: Localize): Language {
  return localize.getLanguages().find(language => language.code === localize.getTranslationLanguage())
}

function createLocalizeInstance (screens?: string[]): Localize {
  let logLevel: LogLevels
  let apiEnv: ApiEnvironmentTypes
  let cachingTranslations: boolean
  let cachingTranslationsTtl: number

  switch (envConfig.zoozEnv) {
    case ('development'):
      logLevel = LogLevels.Warn
      apiEnv = ApiEnvironmentTypes.Mars
      cachingTranslations = false
      break
    case ('qa'):
      logLevel = LogLevels.Warn
      apiEnv = ApiEnvironmentTypes.Qa
      cachingTranslations = false
      break
    case ('mars'):
      logLevel = LogLevels.Warn
      apiEnv = ApiEnvironmentTypes.Mars
      cachingTranslations = true
      cachingTranslationsTtl = DEFAULT_CACHING_TRANSLATIONS_TTL
      break
    case ('production'):
      logLevel = LogLevels.Error
      apiEnv = ApiEnvironmentTypes.Prod
      cachingTranslations = true
      cachingTranslationsTtl = DEFAULT_CACHING_TRANSLATIONS_TTL
      break
    default:
      logLevel = LogLevels.Silent
      apiEnv = ApiEnvironmentTypes.Prod
      cachingTranslations = true
      cachingTranslationsTtl = DEFAULT_CACHING_TRANSLATIONS_TTL
  }

  const translationLanguage = localStorageAccessor.selectedLanguage

  const options: LocalizeCreateOptions = {
    logLevel,
    screens: [ScreenNames.Global as string].concat(screens || []),
    longDateNotationKeys: {
      shortenedFormat: globalTranslations.dates_longFormat_shortened,
      extendedFormat: globalTranslations.dates_longFormat_extended,
      monthNames: [
        globalTranslations.dates_months_january,
        globalTranslations.dates_months_february,
        globalTranslations.dates_months_march,
        globalTranslations.dates_months_april,
        globalTranslations.dates_months_may,
        globalTranslations.dates_months_june,
        globalTranslations.dates_months_july,
        globalTranslations.dates_months_august,
        globalTranslations.dates_months_september,
        globalTranslations.dates_months_october,
        globalTranslations.dates_months_november,
        globalTranslations.dates_months_december
      ],
      shortMonthNames: [
        globalTranslations.dates_shortMonths_january,
        globalTranslations.dates_shortMonths_february,
        globalTranslations.dates_shortMonths_march,
        globalTranslations.dates_shortMonths_april,
        globalTranslations.dates_shortMonths_may,
        globalTranslations.dates_shortMonths_june,
        globalTranslations.dates_shortMonths_july,
        globalTranslations.dates_shortMonths_august,
        globalTranslations.dates_shortMonths_september,
        globalTranslations.dates_shortMonths_october,
        globalTranslations.dates_shortMonths_november,
        globalTranslations.dates_shortMonths_december
      ]
    },
    translationLanguage,
    interpolation: {
      dangerouslyDisableEscape: true
    }
  }

  const localize = Localize.create(options).useApi({ apiEnv, cachingTranslations, cachingTranslationsTtl })

  if (!options.translationLanguage) {
    localize.useDetectLanguage()
  }

  return localize
}

function getLocalizationPreferences (
  localizeInstance: Localize,
  userConfiguration: UserConfiguration.UserConfiguration = {}
): LocalizationPreferences {
  let preferredLanguage = localizeInstance.getTranslationLanguage()
  let preferredShortDateFormat = getCurrentDateFormat(localizeInstance) as ShortDateFormats
  let preferredNumberFormat = {
    decimal: getCurrentDecimalNumberFormat(localizeInstance),
    group: getCurrentGroupNumberDelimiter(localizeInstance)
  }

  if (userConfiguration?.localization?.language_code) {
    preferredLanguage = userConfiguration.localization.language_code
  }
  if (userConfiguration?.localization?.date_format) {
    preferredShortDateFormat = userConfiguration.localization.date_format as ShortDateFormats
  }
  if (userConfiguration?.localization?.number_decimal_format &&
    userConfiguration?.localization?.number_group_delimiter) {
    preferredNumberFormat = {
      decimal: userConfiguration.localization.number_decimal_format as DecimalFormats,
      group: userConfiguration.localization.number_group_delimiter as GroupDelimiter
    }
  }

  return {
    language: preferredLanguage,
    dateFormat: preferredShortDateFormat,
    numberFormat: preferredNumberFormat
  }
}

export {
  createLocalizeInstance,
  getCurrentDateFormat,
  getCurrentGroupNumberDelimiter,
  getCurrentDecimalNumberFormat,
  getLocalizationPreferences
}
