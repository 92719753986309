// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormError__error___qvMFC{visibility:hidden;color:red;line-height:normal;display:flex;align-items:flex-end}.FormError__error--show___oV81b{visibility:visible}`, "",{"version":3,"sources":["webpack://./src/features/UnProtected/shared/FormError/FormError.scss"],"names":[],"mappings":"AAAA,0BACE,iBAAA,CACA,SAAA,CACA,kBAAA,CACA,YAAA,CACA,oBAAA,CAEA,gCACE,kBAAA","sourcesContent":[".error {\n  visibility: hidden;\n  color: red;\n  line-height: normal;\n  display: flex;\n  align-items: flex-end;\n\n  &--show {\n    visibility: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `FormError__error___qvMFC`,
	"error--show": `FormError__error--show___oV81b`
};
export default ___CSS_LOADER_EXPORT___;
