import pick from 'lodash/pick'
import get from 'lodash/get'
import { createSelector } from 'reselect'

import { getAppName } from '../../../helpers/businessUnitsHelper'
import { getReverseDomain } from '../../../redux/account/selectors'
import apiConfig from '../../../api/AnalyticsApiHelpers/apiConfig'
import { DEFAULT_CURRENCY } from '../config'

// Sub-Selectors
const apps = state => state.dashboardReducer.dashboard.appsData
export const getCountries = state => state.dashboardReducer.dashboard.countriesData
export const getRawGraphsData = state => state.dashboardReducer.dashboard.trendGraphData.graphData
export const getRawGraphKeys = state => state.dashboardReducer.dashboard.trendGraphData.keys
export const getGraphFormat = state => state.dashboardReducer.dashboard.trendGraphData.format
export const getColors = state => state.dashboardReducer.dashboard.colors
export const getBars = state => state.dashboardReducer.dashboard.dimensionBarsData.data
export const getBarsFormat = state => state.dashboardReducer.dashboard.dimensionBarsData.format

export const getPaymentStagesData = state => state.dashboardReducer.dashboard.paymentStagesData.data
export const getPaymentStagesFormat = state => state.dashboardReducer.dashboard.paymentStagesData.format
export const getDashboardCurrency = state => state.dashboardReducer.dashboard.dashboardCurrency
export const getPaymentStage = state => state.dashboardReducer.dashboard.paymentStage
export const getMetrics = state => state.dashboardReducer.dashboard.metrics
export const getSelectedBar = state => state.dashboardReducer.dashboard.bar

export const selectedApps = state => state.dashboardReducer.dashboard.apps || []
export const getSelectedCountries = state => state.dashboardReducer.dashboard.countries
export const getDatesData = state => pick(state.dashboardReducer.dashboard, ['from', 'to', 'granularity'])

// selectors
export const getApps = createSelector(
  apps,
  getReverseDomain,
  (apps, reverseDomain) => (apps
    ? apps.map(({ id, status }) => ({
      status,
      key: id,
      value: getAppName(reverseDomain, id)
    }))
    : [])
)

export const getGraphKeys = createSelector(
  getSelectedBar,
  getRawGraphKeys,
  (selectedBar, rawGraphKeys) => get(rawGraphKeys, selectedBar, {})
)

export const getGraphsData = createSelector(
  getSelectedBar,
  getRawGraphsData,
  (selectedBar, rawGraphData) => rawGraphData.map(graphPoint => ({ time: graphPoint.time, ...graphPoint[selectedBar] }))
)

export const getPaymentStagesMetricName = createSelector(
  getPaymentStagesFormat, getDashboardCurrency, (paymentStageSelectedFormat, dashboardCurrency) => {
    if (paymentStageSelectedFormat === apiConfig.metrics.totalAmount && dashboardCurrency !== DEFAULT_CURRENCY) {
      return apiConfig.metrics.originalCurrency
    }

    return paymentStageSelectedFormat
  }
)
