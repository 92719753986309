import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import { combinePathsWithEnv } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'

const microAppUrl = envConfig.apps.services

const App: MicroApp = {
  name: 'services',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: () => true,
  paths: combinePathsWithEnv([
    Locations.providerConfigurationsList,
    Locations.createProviderConfiguration,
    Locations.providerConfigurationsById
  ])
}

export default App
