/* eslint-disable camelcase */
import React, { Component, FormEvent } from 'react'
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import qs from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import errorMessages from '../../../../constants/Errors'
import { safeDecode } from '../../../../helpers/jwtUtils'
import validatePassword from '../../../../helpers/passwordValidation'
import locations from '../../../../helpers/tools/locations'
import auth from '../../../../redux/auth/actions'
import { getJoinError, isJoining } from '../../../../redux/auth/selectors'
import { PortalState } from '../../../../redux/store'
import { withLoadTranslations } from '../../../Localize/HOCs'
import { ScreenNames } from '../../../Localize/screens'
import {
  FormItem,
  Page,
  FormError,
  InputField,
  TermsAndConditions,
  ButtonSubmit as Button
} from '../../shared'
import { isValidToken } from '../helpers'
import language from '../language'
import { handleHideSubmitError } from '../redux/actions'

interface FormFields {
  password: string,
  confirmPassword: string,
  checkbox: boolean
}

const formFields: FormFields = {
  password: undefined,
  confirmPassword: undefined,
  checkbox: undefined
}

const reduceFunc = <T, K>(val: T, nextVal: K): T | K => val && nextVal

type JoinNewUserOwnProps = RouteComponentProps

interface JoinNewUserReduxProps {
  handleHideSubmitError: () => void,
  joinUser: (props: {
    email: string,
    token: string,
    accountId: string,
    password: string,
    from: string
  }) => void,
  isJoining: boolean,
  joinError: string,
  from: string
}

type JoinNewUserProps = JoinNewUserOwnProps & JoinNewUserReduxProps & WithLocalizeProps

interface JoinNewUserState {
  errors: FormFields,
  values: FormFields
}

class JoinNewUser extends Component<JoinNewUserProps, JoinNewUserState> {
  email: string
  account_id: string
  token: string

  constructor (props: JoinNewUserProps) {
    super(props)
    this.state = {
      errors: { ...formFields },
      values: { ...formFields }
    }
  }

  componentDidMount () {
    this.props.handleHideSubmitError()
    const { token } = qs.parse(this.props.history.location.search)
    const { email, account_id, invitation_id } = safeDecode(token as string).payload || {}

    if (isValidToken({ email, account_id, invitation_id })) {
      Object.assign(this, { email, account_id, invitation_id, token })
    } else {
      this.props.history.push(locations.login())
    }
  }

  isFormValid = () => {
    const { t } = this.props
    const newErrorObj = { ...this.state.errors }
    const errorKeys = Object.keys(formFields) as Array<keyof FormFields>
    let isValid = true
    for (const key of errorKeys) {
      isValid = isValid && !this.state.errors[key]
      if (!this.state.values[key]) {
        // @ts-ignore: ts fails to get right type for newErrorObj
        newErrorObj[key] = errorMessages[key]?.required?.(t)
      }
    }
    this.setState({ errors: newErrorObj })
    const requiredVals = (Object.keys(formFields) as Array<keyof FormFields>).map(key => this.state.values[key])
    return isValid && requiredVals.reduce(reduceFunc, true)
  }

  handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    const { email, account_id, token, state } = this
    if (this.isFormValid()) {
      const payload = {
        email,
        token,
        accountId: account_id,
        password: state.values.password,
        from: this.props.from
      }
      this.props.joinUser(payload)
    }
  }

  validatePasswordLogic = (password: string) => {
    const { t } = this.props
    const invalidConfirmPassword = this.state.errors.confirmPassword && password !== this.state.values.confirmPassword

    const { values, errors } = this.state

    const nextState = {
      values: { ...values, password },
      errors: {
        ...errors,
        password: validatePassword(password).message && validatePassword(password).message(t),
        confirmPassword: invalidConfirmPassword ? errorMessages.confirmPassword.invalid(t) : undefined
      }
    }

    this.setState(nextState)
  }

  validate = (value: string, field: keyof FormFields, invalidCondition: boolean) => {
    this.setState({
      values: { ...this.state.values, [field]: value }
    })
    if (value) {
      if (invalidCondition) {
        this.setState({
          // @ts-ignore: TS fails to allow invalid
          errors: { ...this.state.errors, [field]: errorMessages[field].invalid }
        })
        return
      }
      if (field === 'password') {
        this.validatePasswordLogic(value)
      }
    } else {
      if (field === 'password' && this.state.errors.confirmPassword) {
        this.setState({
          errors: { ...this.state.errors, confirmPassword: undefined }
        })
      }
      if (this.state.errors[field]) {
        this.setState({
          errors: { ...this.state.errors, [field]: undefined }
        })
      }
    }
  }

  validateCheckbox = (value: boolean) => {
    this.setState({
      values: { ...this.state.values, checkbox: value },
      errors: { ...this.state.errors, checkbox: undefined }
    })
  }

  handleOnFocus = (field: keyof FormFields) => {
    if (this.state.errors[field]) {
      this.setState({ errors: { ...this.state.errors, [field]: undefined } })
    }
  }

  handleOnPressEnter = (value: string, field: 'password' | 'confirmPassword', isInvalid: boolean) => {
    const { t } = this.props
    if (isInvalid) {
      this.setState({
        values: { ...this.state.values, [field]: value },
        // @ts-ignore: TS fails to allow invalid
        errors: { ...this.state.errors, [field]: errorMessages[field].invalid?.(t) }
      })
    } else if (
      this.state.errors[field] === errorMessages[field]?.required?.(t) ||
      // @ts-ignore: TS fails to allow invalid
      this.state.errors[field] === errorMessages[field]?.invalid?.(t)
    ) {
      this.setState({
        values: { ...this.state.values, [field]: value },
        errors: { ...this.state.errors, [field]: undefined }
      })
    } else {
      this.setState({ values: { ...this.state.values, [field]: value } })
    }
  }

  render () {
    const { t } = this.props
    return (
      <Page title={language.title(t)} subtitle={language.createUser(t)}>
        <form onSubmit={this.handleSubmit}>
          <InputField
            disabled
            value={this.email}
            id='join-email'
          />
          <InputField
            type='password'
            placeholder={language.password(t)}
            error={this.state.errors.password}
            id='join-password'
            onBlur={(event) => { this.validate(event.target.value, 'password', event.target.value.length > 128) }}
            onFocus={() => { this.handleOnFocus('password') }}
            onPressEnter={(event) => {
              const target = event.target as HTMLInputElement
              this.handleOnPressEnter(target.value, 'password', target.value.length > 128)
            }}
          />
          <InputField
            type='password'
            placeholder={language.passwordAgain(t)}
            id='join-confirm-password'
            error={this.state.errors.confirmPassword}
            onBlur={(event) => { this.validate(event.target.value, 'confirmPassword', event.target.value !== this.state.values.password) }}
            onFocus={() => { this.handleOnFocus('confirmPassword') }}
            onPressEnter={(event) => {
              const target = event.target as HTMLInputElement
              this.handleOnPressEnter(target.value, 'confirmPassword', target.value !== this.state.values.password)
            }}
          />
          <TermsAndConditions
            onChange={(checked) => { this.validateCheckbox(checked) }}
            checked={this.state.values.checkbox}
            error={this.state.errors.checkbox}
          />
          <FormItem>
            <FormError isVisible={!!this.props.joinError}>
              {this.props.joinError}
            </FormError>
            <Button loading={this.props.isJoining}>
              Join Team
            </Button>
          </FormItem>
        </form>
      </Page>
    )
  }
}

const mapDispatchToProps = {
  joinUser: auth.joinUser,
  handleHideSubmitError
}

const mapStateToProps = (state: PortalState) => ({
  joinForm: state.joinReducers.handleFormRequestsErrors.joinForm,
  isJoining: isJoining(state),
  joinError: getJoinError(state)
})

export default withLoadTranslations<JoinNewUserProps>({
  screenName: ScreenNames.Join
})(connect(mapStateToProps, mapDispatchToProps)(withLocalize(JoinNewUser)))
