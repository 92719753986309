import { cloneDeep } from 'lodash'
import { combineReducers } from 'redux'

import * as auth from '../../../../redux/auth/types'
import { LOGIN_PAGE_HIDE_ERROR } from './actions'

export interface LoginFormErrors {
  formErrorValue: string,
  formErrorVisibility: string
}

export interface LoginReducer {
  loginForm: LoginFormErrors
}

const initialState: LoginReducer = {
  loginForm: {
    formErrorValue: '',
    formErrorVisibility: 'hidden'
  }
}

const handleFormRequestsErrors = (state = initialState, action: { payload: any, type: string }) => {
  if (action.type === auth.LOGIN_PAGE_ERROR) {
    const newState = cloneDeep(state)
    newState.loginForm.formErrorVisibility = 'visible'
    newState.loginForm.formErrorValue = action.payload
    return newState
  }
  if (action.type === LOGIN_PAGE_HIDE_ERROR) {
    const newState = cloneDeep(state)
    newState.loginForm.formErrorVisibility = 'hidden'
    newState.loginForm.formErrorValue = action.payload
    return newState
  }
  return state
}

export interface LoginFormReducerState {
  handleFormRequestsErrors: LoginReducer
}
const loginFormReducers = combineReducers({ handleFormRequestsErrors })
export default loginFormReducers
