import {
  fork,
  take,
  put,
  call,
  cancel,
  cancelled,
  takeLatest,
  CallEffect,
  PutEffect,
  ForkEffect,
  CancelEffect,
  CancelledEffect,
  TakeEffect, takeEvery
} from 'redux-saga/effects'
import axios from 'axios'
import jsonwebtoken from 'jsonwebtoken'

import * as types from './types'
import authActions, { CheckSSOAction, LoginUserSSOAction, SSORedirectAction } from './actions'
import SessionsApi from '../../api/SessionsApi'
import errors from '../../constants/Errors'
import localize from '../../features/Localize/localize'
import sentry from '../../helpers/sentry'

export default function * ssoAuthSaga (): IterableIterator<ForkEffect> {
  yield takeLatest(types.CHECK_SSO, checkSSOFlow)
  yield takeEvery(types.SSO_REDIRECT, ssoRedirectSaga)
  yield takeEvery(types.SSO_LOGIN_USER, loginUserSSO)
}

function * checkSSOFlow ({ payload: { email } }: CheckSSOAction): IterableIterator<
  ForkEffect |
  TakeEffect |
  CancelledEffect |
  CancelEffect
  > {
  let checkSSOTask
  try {
    checkSSOTask = yield fork(checkSSOUserSaga, email)
    const action: {
      type: typeof types.CHECK_SSO_END | typeof types.CANCEL_CHECK_SSO
    } = yield take([types.CHECK_SSO_END, types.CANCEL_CHECK_SSO])
    if (action.type === types.CANCEL_CHECK_SSO) {
      yield cancel(checkSSOTask)
    }
  } finally {
    if (yield cancelled()) {
      yield cancel(checkSSOTask)
    }
  }
}

function * checkSSOUserSaga (email: string): IterableIterator<
  CallEffect |
  PutEffect |
  CancelledEffect |
  CancelEffect
  > {
  let checkSSOCancelToken
  try {
    checkSSOCancelToken = axios.CancelToken.source()
    const isSsoUser = yield call(SessionsApi.verifySso, email, { cancelToken: checkSSOCancelToken.token })
    const isSSO = !!isSsoUser
    let isPasswordSupport = true
    if (isSSO) {
      // eslint-disable-next-line camelcase
      const constraints: { force_idp_login?: boolean } = yield call(SessionsApi.getSsoConstraints, email)
      isPasswordSupport = !constraints.force_idp_login
    }
    yield put(authActions.checkSSOEnd(isSSO, isPasswordSupport))
  } catch (error) {
    put(authActions.checkSSOEnd(false, true))
  } finally {
    if (yield cancelled()) {
      if (checkSSOCancelToken) {
        checkSSOCancelToken.cancel()
      }
    }
  }
}

function * ssoRedirectSaga ({
  payload: {
    email,
    redirectPath
  }
}: SSORedirectAction): IterableIterator<PutEffect | CallEffect> {
  yield put(authActions.isLoggingIn(true))
  yield call(SessionsApi.initSso, email, redirectPath)
}

function * loginUserSSO ({ payload: { sessionToken, from } }: LoginUserSSOAction): IterableIterator<
  PutEffect |
  CallEffect
  > {
  try {
    yield put(authActions.isLoggingIn(true))
    const {
      user_id: userId,
      primary_account_id: accountId,
      session_token: realToken
    }: {
      // eslint-disable-next-line camelcase
      user_id: string,
      // eslint-disable-next-line camelcase
      primary_account_id: string,
      // eslint-disable-next-line camelcase
      session_token: string,
    } = yield call(SessionsApi.getSessionSso, sessionToken)

    const { email } = jsonwebtoken.decode(realToken) as { email: string }
    yield put(authActions.ssoUserLoggedIn(email, userId, realToken, accountId, from))
  } catch (error) {
    yield put(authActions.isLoggingIn(false))
    yield put(authActions.loginPageError(errors.defaultError(localize.translate)))
    sentry.captureException(error as Error)
    console.error('failed sso', error)
  }
}
