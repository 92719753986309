import { Analytics } from '@payu/paymentsos-types'

const mapDataToArray = (data: Analytics.AnalyticsResponse): string[] => {
  const dimensionName = Object.keys(data.buckets[0].data[0].dimensions)[0]
  const bucketDataReducer = (entity: string[], current: Analytics.ResponseItem) => {
    if (current.dimensions) {
      // @ts-ignore: Analytics is not well maintained for types generation
      entity.push(current.dimensions[dimensionName])
    }
    return entity
  }
  return data.buckets.map(bucket => bucket.data.reduce(bucketDataReducer, []))[0]
}

export default function convertRawDataToFilterData (data: Analytics.AnalyticsResponse): string[] {
  return dataIsEmpty(data) ? [] : mapDataToArray(data)
}

function dataIsEmpty (data: Analytics.AnalyticsResponse): boolean {
  return !(data?.buckets?.[0]?.data?.length > 0)
}
