import React, { Component } from 'react'
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import classnames from 'classnames'
import { InfoTooltip } from '@zooz/generic-ui-components'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import validator from 'validator'

import errors from '../../../constants/Errors'
import auth from '../../../redux/auth/actions'
import { isLoggingIn, getIsSSO, getIsCheckingSSO, getIsPasswordSupport } from '../../../redux/auth/selectors'
import { PortalState } from '../../../redux/store'
import { FormError, InputField, ButtonSubmit as Button, FormButtonWrapper } from '../shared'
import language from './language'
import { handleHideSubmitError } from './redux/actions'
import { LoginFormErrors } from './redux/reducers'
import { getLoginForm } from './redux/selectors'

import style from './LoginForm.scss'

interface FormFields {
  email: string,
  password: string
}

const formFields: FormFields = {
  email: undefined,
  password: undefined
}
const reduceFunc = (val: any, nextVal: any): any => val && nextVal

interface LoginFormStateProps {
  isLoading: boolean;
  isCheckingSSO: boolean;
  isSSO: boolean;
  isPasswordSupport: boolean;
}

interface LoginFormDispatchProps {
  loginUser: (p: { email: string, password: string, from: string }) => void;
  checkSSOUser: (email: string) => void;
  cancelSSOCheck: () => void;
  ssoRedirect: (email: string, urlAfterLogin: string) => void;
}

interface LoginFormOwnProps extends Partial<RouteComponentProps> {
  handleHideSubmitError: () => void;
  loginForm: LoginFormErrors;
}

export type LoginFormProps = LoginFormOwnProps &
                             LoginFormStateProps &
                             LoginFormDispatchProps &
                             WithLocalizeProps

interface LoginFormState {
  errors: FormFields;
  values: FormFields;
}

export class LoginForm extends Component <LoginFormProps, LoginFormState> {
  state = {
    errors: { ...formFields },
    values: { ...formFields }
  }

  isFormValid = (): boolean => {
    const { t } = this.props
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newErrorObj = { ...this.state.errors }
    const errorKeys = Object.keys(formFields) as Array<keyof FormFields>
    let isValid = true
    for (const key of errorKeys) {
      isValid = isValid && !this.state.errors[key]
      if (!this.state.values[key]) {
        newErrorObj[key] = errors[key].required(t)
      }
    }
    this.setState({ errors: newErrorObj })
    return isValid && Object.values(this.state.values).reduce(reduceFunc, true)
  }

  handleSubmit = (event: React.ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const { history, ssoRedirect } = this.props
    let navigateUrlAfterLogin
    const fromUrl: { pathname: string, search: string } = get(history.location.state, 'from', { pathname: '', search: '' })
    if (fromUrl.pathname) {
      navigateUrlAfterLogin = fromUrl.pathname + fromUrl.search
    }

    const { email } = this.state.values
    if (
      this.props.isSSO &&
      validator.isEmail(email) &&
      (!this.props.isPasswordSupport || !this.state.values.password)
    ) {
      ssoRedirect(email, navigateUrlAfterLogin)
    } else if (this.isFormValid()) {
      this.props.loginUser({
        email,
        password: this.props.isPasswordSupport ? this.state.values.password : undefined,
        from: navigateUrlAfterLogin
      })
    }
  }

  validateEmail = (value: string): void => {
    this.setState((prevState) => {
      if (value && !validator.isEmail(value)) {
        return {
          errors: {
            ...prevState.errors,
            email: errors.email.invalid(this.props.t)
          }
        }
      }
      return null
    })
  }

  checkSsoEmail = (email: string): void => {
    if (email && validator.isEmail(email)) {
      this.props.checkSSOUser(email)
    } else if (this.props.isCheckingSSO) {
      this.props.cancelSSOCheck()
    }
  }

  handleEmailChange = (value: string): void => {
    this.setState(({ values }) => ({
      values: {
        ...values,
        email: value
      }
    }), (): void => this.checkSsoEmail(value))
  }

  onPasswordChange = (value: string): void => {
    this.setState(({ values }) => ({
      values: {
        ...values,
        password: value
      }
    }))
  }

  handleOnFocus = (field: string): void => {
    this.setState(({ errors }): Pick<LoginFormState, 'errors'> => {
      if (errors[field as keyof FormFields]) {
        return {
          errors: {
            ...errors,
            [field]: undefined
          }
        }
      }
      // bail from rendering
      return null
    })
  }

  componentDidMount = (): void => {
    this.props.handleHideSubmitError()
  }

  render (): JSX.Element {
    const { t } = this.props
    const showSsoMessage = this.props.isSSO &&
                           this.state.values.email &&
                           (!this.props.isPasswordSupport || !this.state.values.password)
    const loginButtonText = showSsoMessage ? language.login.sso(t) : language.login.regular(t)
    return (
      <form onSubmit={this.handleSubmit}>
        <InputField
          label={language.email(t)}
          id='login-email'
          type='email'
          autoComplete='email'
          autoFocus
          onChange={(event): void => { this.handleEmailChange(event.target.value) }}
          onBlur={(event): void => { this.validateEmail(event.target.value) }}
          onFocus={(): void => { this.handleOnFocus('email') }}
          error={this.state.errors.email ? this.state.errors.email : undefined}
        />
        <div className={classnames(style.password, {
          [style['password--hidden']]: !this.props.isPasswordSupport
        })}
        >
          <InputField
            label={language.password(t)}
            id='login-password'
            type='password'
            autoComplete='current-password'
            onChange={(event): void => {
              this.onPasswordChange(event.target.value)
            }}
            onFocus={(): void => { this.handleOnFocus('password') }}
            error={this.state.errors.password ? this.state.errors.password : undefined}
          />
        </div>
        <div
          className={style['sso-message-container']}
          data-visible={showSsoMessage}
          data-visible-password={this.props.isPasswordSupport}
        >
          {
              showSsoMessage && (
              <div className={style['sso-message']}>
                {language.ssoDescription(t)}
                &nbsp;
                <InfoTooltip
                  content={language.login.ssoTooltip(t)}
                  placement={InfoTooltip.Placements.LeftCenter}
                />
              </div>
              )
            }
        </div>
        <FormButtonWrapper>
          <FormError isVisible={this.props.loginForm.formErrorVisibility === 'visible'}>
            {this.props.loginForm.formErrorValue}
          </FormError>
          <Button loading={this.props.isLoading}>
            {this.props.isCheckingSSO ? '' : loginButtonText}
          </Button>
        </FormButtonWrapper>
      </form>
    )
  }
}

const mapDispatchToProps = {
  loginUser: auth.loginUser,
  checkSSOUser: auth.checkSSOUser,
  cancelSSOCheck: auth.cancelSSOCheck,
  ssoRedirect: auth.ssoRedirect,
  handleHideSubmitError
}

const mapStateToProps = (state: PortalState) => ({
  loginForm: getLoginForm(state),
  isLoading: isLoggingIn(state),
  isCheckingSSO: getIsCheckingSSO(state),
  isSSO: getIsSSO(state),
  isPasswordSupport: getIsPasswordSupport(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(LoginForm))
