import { isEqual } from 'lodash'
import { DecimalFormats, GroupDelimiter } from '@payu/portal-numbers'

import { NumberFormatProps } from '../../Localize/types'
import { NumberFormats } from '../types'

const NumberFormatsPropertiesMapping = {
  [NumberFormats['group=coma;decimal=period']]: {
    group: GroupDelimiter.Comma,
    decimal: DecimalFormats.Dot
  },
  [NumberFormats['group=period;decimal=comma']]: {
    group: GroupDelimiter.Dot,
    decimal: DecimalFormats.Comma
  },
  [NumberFormats['group=space;decimal=comma']]: {
    group: GroupDelimiter.Space,
    decimal: DecimalFormats.Comma
  },
  [NumberFormats['group=space;decimal=period']]: {
    group: GroupDelimiter.Space,
    decimal: DecimalFormats.Dot
  }
}

export const getNumberFormatPropertiesByFormat = (numberFormat: NumberFormats): NumberFormatProps => (
  NumberFormatsPropertiesMapping[numberFormat]
)

export const getNumberFormatByProperties = (formatProperties: NumberFormatProps): NumberFormats => (
  Object.keys(NumberFormatsPropertiesMapping) as NumberFormats[])
  .find((key: NumberFormats) => isEqual(NumberFormatsPropertiesMapping[key], formatProperties))
