export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_USER = 'LOGIN_USER'
export const SSO_LOGIN_USER = 'SSO_LOGIN_USER'
export const SSO_USER_LOGGED_IN = 'SSO_USER_LOGGED_IN'
export const LOGIN_PAGE_ERROR = 'LOGIN_PAGE_ERROR'
export const AUTHORIZED = 'AUTHORIZED'
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const CLEAR_SESSION = 'CLEAR_SESSION'
export const REGISTER_PAGE_ERROR = 'REGISTER_PAGE_ERROR'
export const JOIN_USER = 'JOIN_USER'
export const JOIN_EXISTING_USER = 'JOIN_EXISTING_USER'
export const JOIN_USER_SUCCESS = 'JOIN_USER_SUCCESS'
export const JOIN_PAGE_ERROR = 'JOIN_PAGE_ERROR'
export const IS_LOGGING_IN = 'IS_LOGGING_IN'
export const IS_JOINING_USER = 'IS_JOINING_USER'
export const CHECK_SSO = 'CHECK_SSO'
export const CHECK_SSO_END = 'CHECK_SSO_END'
export const CANCEL_CHECK_SSO = 'CANCEL_CHECK_SSO'
export const SSO_REDIRECT = 'SSO_REDIRECT'
export const IS_UNATTACHED_USER = 'IS_UNATTACHED_USER'

export type AuthAction = typeof LOGIN_SUCCESS |
  typeof LOGIN_ERROR |
  typeof LOGIN_USER |
  typeof SSO_LOGIN_USER |
  typeof SSO_USER_LOGGED_IN |
  typeof LOGIN_PAGE_ERROR |
  typeof AUTHORIZED |
  typeof UNAUTHORIZED |
  typeof CLEAR_SESSION |
  typeof REGISTER_PAGE_ERROR |
  typeof JOIN_USER |
  typeof JOIN_EXISTING_USER |
  typeof JOIN_USER_SUCCESS |
  typeof JOIN_PAGE_ERROR |
  typeof IS_LOGGING_IN |
  typeof IS_JOINING_USER |
  typeof CHECK_SSO |
  typeof CHECK_SSO_END |
  typeof CANCEL_CHECK_SSO |
  typeof SSO_REDIRECT |
  typeof IS_UNATTACHED_USER
