import envConfig from '../../envConfig'
import { getAccountId } from '../../redux/account/selectors'
import { currentUser } from '../../redux/auth/selectors'

const DemoUtil = {
  isDemo (): boolean {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('../../redux/store')
    const user = currentUser(store.getState()).userId
    const accountId = getAccountId(store.getState())
    return user === envConfig.demo.user && accountId === envConfig.demo.account
  }
}

export default DemoUtil
