import { TranslationFunction } from '@zooz/react-localize'
import { global as globalTranslations, sign_in as signInTranslations } from '@zooz/translation-values'

export default {
  title: (t: TranslationFunction) => t(signInTranslations.main_title_welcomeBack.key, {
    fallback: signInTranslations.main_title_welcomeBack.fallback
  }),
  subtitle: (t: TranslationFunction) => t(signInTranslations.main_title_logIn.key, {
    fallback: signInTranslations.main_title_logIn.fallback
  }),
  ssoDescription: (t: TranslationFunction) => t(signInTranslations.main_lbl_ssoDescription.key, {
    fallback: signInTranslations.main_lbl_ssoDescription.fallback
  }),
  email: (t: TranslationFunction) => t(globalTranslations.sharedText_customerProperties_email.key, {
    fallback: globalTranslations.sharedText_customerProperties_email.fallback
  }),
  password: (t: TranslationFunction) => t(globalTranslations.sharedText_lbl_password.key, {
    fallback: globalTranslations.sharedText_lbl_password.fallback
  }),
  login: {
    regular: (t: TranslationFunction) => t(signInTranslations.main_btn_regularLogin.key, {
      fallback: signInTranslations.main_btn_regularLogin.fallback
    }),
    sso: (t: TranslationFunction) => t(signInTranslations.main_btn_sso.key, {
      fallback: signInTranslations.main_btn_sso.fallback
    }),
    ssoTooltip: (t: TranslationFunction) => t(signInTranslations.main_toolTip_sso.key, {
      fallback: signInTranslations.main_toolTip_sso.fallback
    })
  },
  link: {
    forgot: (t: TranslationFunction) => t(signInTranslations.main_lbl_forgotPassword.key, {
      fallback: signInTranslations.main_lbl_forgotPassword.fallback
    }),
    register: (t: TranslationFunction) => t(signInTranslations.main_lbl_register.key, {
      fallback: signInTranslations.main_lbl_register.fallback
    })
  }
}
