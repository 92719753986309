import { PayloadAction, createSlice, Draft } from '@reduxjs/toolkit'
import { ShortDateFormats } from '@zooz/react-localize'
import { AllScreenNamesType } from 'cc-localization'
import { Language } from '@zooz/localize'

import localStorageAccessor from '../../../helpers/localStorageAccessor/localStorageAccessor'
import { DEFAULT_SHORT_DATE_FORMAT, DEFAULT_DECIMAL_NUMBER_FORMAT, DEFAULT_GROUP_NUMBER_DELIMITER } from '../constants'
import { NumberFormatProps } from '../types'

export type ScreensLoadingStatus = {
  [key in AllScreenNamesType]?: boolean
}

export interface LocalizationState {
  isLoading: boolean;
  isInit: boolean;
  isPreviewMode: boolean;
  language: string;
  dateFormat: ShortDateFormats;
  numberFormat: NumberFormatProps
  languages: Language[];
  screensLoading: ScreensLoadingStatus;
  mountedScreens: AllScreenNamesType[]
}

export const initialState: LocalizationState = {
  isLoading: false,
  isInit: false,
  isPreviewMode: false,
  language: '',
  dateFormat: DEFAULT_SHORT_DATE_FORMAT,
  numberFormat: {
    decimal: DEFAULT_DECIMAL_NUMBER_FORMAT,
    group: DEFAULT_GROUP_NUMBER_DELIMITER
  },
  languages: [],
  screensLoading: {},
  mountedScreens: []
}

const { reducer, actions } = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    initLocalize: setLoading,
    stopInitLocalize: setStopLoading,
    initLocalizeSuccess (
      state,
      action: PayloadAction<{
        language: string,
        isPreviewMode: boolean,
        languages: Language[],
        screens: AllScreenNamesType[],
        dateFormat: ShortDateFormats
      }>
    ): void {
      state.isLoading = false
      state.isInit = true
      updateLocalizeInfo(state, action.payload)
    },
    initLocalizeFail (state, action: PayloadAction<{
      language: string,
      isPreviewMode: boolean,
      languages: Language[],
      screens: AllScreenNamesType[],
      dateFormat: ShortDateFormats
    }>): void {
      state.isLoading = false
      state.isInit = false
      updateLocalizeInfo(state, action.payload)
    },
    setLocalizationPreferences: {
      prepare (
        actionPayload: { language: string, dateFormat?: ShortDateFormats,
          numberFormat?: NumberFormatProps }
      ) {
        return { payload: actionPayload }
      },
      reducer: setLoading
    },
    setLocalizationPreferencesSuccess: {
      prepare (
        language: string,
        dateFormat?: ShortDateFormats,
        numberFormat?: NumberFormatProps
      ) {
        return {
          payload: {
            language,
            dateFormat,
            numberFormat
          }
        }
      },
      reducer (
        state,
        action: PayloadAction<{
          language: string,
          dateFormat?: ShortDateFormats,
          numberFormat?: NumberFormatProps
        }>
      ): void {
        state.isLoading = false
        state.language = action.payload.language
        if (action.payload.dateFormat) {
          state.dateFormat = action.payload.dateFormat
        }
        if (action.payload.numberFormat.group && action.payload.numberFormat.decimal) {
          state.numberFormat = {
            group: action.payload.numberFormat.group,
            decimal: action.payload.numberFormat.decimal
          }
        }
        localStorageAccessor.selectedLanguage = action.payload.language
      }
    },
    setLanguageDone: setStopLoading,
    togglePreviewMode: setLoading,
    togglePreviewModeSuccess: {
      prepare (previewMode: boolean) {
        return { payload: { previewMode } }
      },
      reducer (state, action: PayloadAction<{ previewMode: boolean; }>): void {
        state.isLoading = false
        state.isPreviewMode = action.payload.previewMode
      }
    },
    togglePreviewModeFail: setStopLoading,
    togglePreviewModeDone: setStopLoading,
    loadScreenTranslations: {
      prepare (newScreens: AllScreenNamesType | AllScreenNamesType[]) {
        return ({
          payload: {
            newScreens: typeof newScreens === 'string' ? [newScreens] : newScreens
          }
        })
      },
      reducer (state, action: PayloadAction<{ newScreens: AllScreenNamesType[] }>): void {
        for (const screen of action.payload.newScreens) {
          state.mountedScreens.push(screen)
          if (!Object.prototype.hasOwnProperty.call(state.screensLoading, screen)) {
            state.screensLoading[screen] = true
          }
        }
      }
    },
    loadScreenTranslationDone: {
      prepare (newScreens: AllScreenNamesType[]) {
        return { payload: { newScreens } }
      },
      reducer (state, action: PayloadAction<{ newScreens: AllScreenNamesType[] }>) {
        for (const screen of action.payload.newScreens) {
          if (Object.prototype.hasOwnProperty.call(state.screensLoading, screen)) {
            state.screensLoading[screen] = false
          }
        }
      }
    },
    screenUnMounted: {
      prepare (screens: AllScreenNamesType | AllScreenNamesType[]) {
        return ({
          payload: {
            unMountedScreens: typeof screens === 'string' ? [screens] : screens
          }
        })
      },
      reducer (state, action: PayloadAction<{ unMountedScreens: AllScreenNamesType[] }>): void {
        for (const screen of action.payload.unMountedScreens) {
          const idx = state.mountedScreens.indexOf(screen)
          if (idx !== -1) {
            state.screensLoading[screen] = false
            state.mountedScreens.splice(idx, 1)
          }
        }
      }
    },
    setLanguages (state: Draft<LocalizationState>, action: PayloadAction<Language[]>): void {
      state.languages = action.payload
    }
  }
})

function setLoading (state: Draft<LocalizationState>): void {
  state.isLoading = true
}

function setStopLoading (state: Draft<LocalizationState>): void {
  state.isLoading = false
}

function updateLocalizeInfo (state: Draft<LocalizationState>, payload: {
  language: string,
  isPreviewMode: boolean,
  languages: Language[],
  screens: AllScreenNamesType[],
  dateFormat: ShortDateFormats
}): void {
  state.language = payload.language
  state.isPreviewMode = payload.isPreviewMode
  state.languages = payload.languages
  state.dateFormat = payload.dateFormat
  for (const screen of payload.screens) {
    state.screensLoading[screen] = false
  }
  localStorageAccessor.selectedLanguage = payload.language
}

export { reducer, actions }
