// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorBoundary__error___aXV8M{text-align:center;height:50%;display:flex;justify-content:center;align-items:center;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/features/App/components/ErrorBoundary/ErrorBoundary.scss"],"names":[],"mappings":"AAAA,8BACE,iBAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA","sourcesContent":[".error {\n  text-align: center;\n  height: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `ErrorBoundary__error___aXV8M`
};
export default ___CSS_LOADER_EXPORT___;
