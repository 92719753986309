// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LanguageSelection__wrapper___iUp8u{width:220px}.LanguageSelection__selected___opfau{display:flex;align-items:center}.LanguageSelection__selected__icon___gF19H{margin-right:6px}.LanguageSelection__languageText___X94Rh{font-size:12px}`, "",{"version":3,"sources":["webpack://./src/features/Localize/components/LanguageSelection/LanguageSelection.scss"],"names":[],"mappings":"AAAA,oCACE,WAAA,CAGF,qCACE,YAAA,CACA,kBAAA,CAEA,2CACE,gBAAA,CAIJ,yCACE,cAAA","sourcesContent":[".wrapper {\n  width: 220px;\n}\n\n.selected {\n  display: flex;\n  align-items: center;\n\n  &__icon {\n    margin-right: 6px;\n  }\n}\n\n.languageText {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LanguageSelection__wrapper___iUp8u`,
	"selected": `LanguageSelection__selected___opfau`,
	"selected__icon": `LanguageSelection__selected__icon___gF19H`,
	"languageText": `LanguageSelection__languageText___X94Rh`
};
export default ___CSS_LOADER_EXPORT___;
