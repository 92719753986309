import { Error, LabeledCheckbox, Paragraph } from '@zooz/generic-ui-components'
import * as React from 'react'

import { FormItem } from '../../../shared'

import css from './style.scss'

interface Props {
  checkboxText: string,
  onChange?: (isChecked: boolean) => void,
  checked?: boolean,
  error?: boolean,
  errorMessage?: string
}

const CheckboxWithDescription: React.FC<Props> =
  ({ onChange, checked, checkboxText, error, errorMessage }) => (
    <FormItem>
      <LabeledCheckbox
        className={css.CheckboxWithDescription__labeledCheckbox}
        checkboxClassName={css.checkbox}
        checked={checked}
        onChange={onChange}
      >
        <Paragraph className={css.CheckboxWithDescription__text}>{checkboxText}</Paragraph>
      </LabeledCheckbox>
      {error && <Error data-test='checkbox__error'>{errorMessage}</Error>}
    </FormItem>
  )

export default CheckboxWithDescription
