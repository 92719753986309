import { Action } from 'redux'

import { LeadSourceType } from '../SignupForm'

export const ActionTypes = {
  SIGN_UP_REQUEST: 'Portal/signUp/request',
  SIGN_UP_REQUEST_SUCCESS: 'Portal/signUp/requestSuccess',
  SIGN_UP_REQUEST_FAIL: 'Portal/signUp/requestFail',
  SIGN_UP_HIDE_SUCCESS_MESSAGE: 'Portal/signUp/hideSuccessMessage',
  SIGN_UP_CLEAR_FAIL_MESSAGE: 'Portal/signUp/clearFailMessage',
  SIGN_UP_INIT: 'Portal/signUp/init'
}

export interface SignUpRequestInfo {
  firstName: string;
  lastName: string;
  merchantName: string;
  merchantDomain: string;
  userEmail: string;
  leadSource: LeadSourceType;
}

export interface SignUpRequestAction extends Action<typeof ActionTypes.SIGN_UP_REQUEST> {
  payload: SignUpRequestInfo;
}

export const signUpRequest = (signUpInfo: SignUpRequestAction['payload']): SignUpRequestAction => ({
  type: ActionTypes.SIGN_UP_REQUEST,
  payload: signUpInfo
})

export interface SignUpRequestSuccessAction extends Action<typeof ActionTypes.SIGN_UP_REQUEST_SUCCESS> {}

export const signUpRequestSuccess = (): SignUpRequestSuccessAction => ({
  type: ActionTypes.SIGN_UP_REQUEST_SUCCESS
})

export interface SignUpRequestFailAction extends Action<typeof ActionTypes.SIGN_UP_REQUEST_FAIL>{
  payload: {
    errorMessage: string;
  };
}

export const signUpRequestFail = (errorMessage: string): SignUpRequestFailAction => ({
  type: ActionTypes.SIGN_UP_REQUEST_FAIL,
  payload: { errorMessage }
})

export interface HideSignUpSuccessMessageAction extends Action<typeof ActionTypes.SIGN_UP_HIDE_SUCCESS_MESSAGE> {}

export const hideSignUpSuccessMessage = (): HideSignUpSuccessMessageAction => ({
  type: ActionTypes.SIGN_UP_HIDE_SUCCESS_MESSAGE
})

export interface ClearSignUpErrorMessageAction extends Action<typeof ActionTypes.SIGN_UP_CLEAR_FAIL_MESSAGE> {}

export const clearSignUpErrorMessage = (): ClearSignUpErrorMessageAction => ({
  type: ActionTypes.SIGN_UP_CLEAR_FAIL_MESSAGE
})

export interface SignUpInitAction extends Action<typeof ActionTypes.SIGN_UP_INIT> {}

export const signupInit = (): SignUpInitAction => ({
  type: ActionTypes.SIGN_UP_INIT
})
