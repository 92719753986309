import { ShortDateFormats } from '@zooz/portal-dates'

export type UserProfileLocalizationPreferences = {
  language: string,
  dateFormat: ShortDateFormats,
  numberFormat: NumberFormats
}

export enum NumberFormats {
  'group=coma;decimal=period' = 'group=coma;decimal=period',
  'group=period;decimal=comma' = 'group=period;decimal=comma',
  'group=space;decimal=comma' = 'group=space;decimal=comma',
  'group=space;decimal=period' = 'group=space;decimal=period'
}
