import {
  takeLatest, call, put,
  ForkEffect
} from 'redux-saga/effects'

import UsersApi from '../../../../api/UsersApi'
import errors from '../../../../constants/Errors'
import localize from '../../../Localize/localize'
import {
  ActionTypes,
  SignUpRequestAction,
  signUpRequestSuccess,
  signUpRequestFail
} from './actions'
import { APIError } from '../../../../api/ApiBase'

function * requestSignUp ({ payload }: SignUpRequestAction) {
  try {
    const requestInfo = {
      user_first_name: payload.firstName,
      user_last_name: payload.lastName,
      merchant_name: payload.merchantName,
      merchant_domain: payload.merchantDomain,
      user_email: payload.userEmail,
      lead_source: payload.leadSource
    }
    yield call(UsersApi.signUp, requestInfo)
    yield put(signUpRequestSuccess())
  } catch (error) {
    const errorMessage = (error as APIError)?.response?.status === 409
      ? errors.email.exists(localize.translate)
      : errors.defaultError(localize.translate)
    yield put(signUpRequestFail(errorMessage))
  }
}

export default function * signUpSaga (): IterableIterator<ForkEffect> {
  yield takeLatest(ActionTypes.SIGN_UP_REQUEST, requestSignUp)
}
