import produce from 'immer'
import { set, cloneDeep } from 'lodash'

import { FETCH_ACTIVATIONS_SUCCESS } from '../../../../redux/activations/types'
import {
  SET_FIELD_TEXT,
  SET_ACTIVATE_LOADING_SPINNER,
  UPDATE_ACCOUNT_ACTIVATION_SUCCESS
} from '../types'
import PlanTypes from '../../constants/planTypes'

export interface State {
  selectedPlan: string,
  showValidationErrors: boolean,
  showSuccessPage: boolean,
  isLoading: boolean,
  businessInformation: {
    companyLegalName: string,
    businessRegistrationNumber: string
  },
  businessAddress: {
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zipCode: string,
    country: string
  },
  contactInformation: {
    financial: string,
    development: string,
    business: string
  },
  userHasNecessaryRightConfirmation: {
    isChecked: boolean
  },
  isUpdateSucceeded: boolean
}

export const initialState: State = {
  selectedPlan: PlanTypes.GROWING,
  showValidationErrors: false,
  showSuccessPage: false,
  isLoading: false,
  businessInformation: {
    companyLegalName: '',
    businessRegistrationNumber: ''
  },
  businessAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  contactInformation: {
    financial: '',
    development: '',
    business: ''
  },
  userHasNecessaryRightConfirmation: {
    isChecked: false
  },
  isUpdateSucceeded: false
}

const activationRegistrationUIReducer = produce((state: State, action): State | void => {
  switch (action.type) {
    case SET_FIELD_TEXT:
      return set(state, action.fieldName.split('.'), action.text)
    case SET_ACTIVATE_LOADING_SPINNER:
      state.isLoading = action.isLoading
      break
    case FETCH_ACTIVATIONS_SUCCESS:
      return mapActivationDataToActivationState(cloneDeep(initialState), action.activation)
    case UPDATE_ACCOUNT_ACTIVATION_SUCCESS:
      state.isUpdateSucceeded = true
      break
  }
}, initialState)

interface ActivationState {
  // eslint-disable-next-line camelcase
  billing_package: string,
  // eslint-disable-next-line camelcase
  business_information: {
    // eslint-disable-next-line camelcase
    company_legal_name: string,
    // eslint-disable-next-line camelcase
    business_registration_number: string,
    // eslint-disable-next-line camelcase
    business_address: {
      city: string,
      state: string,
      country: string,
      // eslint-disable-next-line camelcase
      address_line: string,
      // eslint-disable-next-line camelcase
      zip_code: string
    }
  },
  // eslint-disable-next-line camelcase
  contact_information: {
    financial: string,
    development: string,
    business: string
  },
  // eslint-disable-next-line camelcase
  payment_method?: {
    // eslint-disable-next-line camelcase
    last_4_digits?: string
  }
}

function mapActivationDataToActivationState (stateToFill: State, activationData: ActivationState): State {
  stateToFill.selectedPlan = activationData.billing_package
  stateToFill.businessInformation = {
    ...stateToFill.businessInformation,
    companyLegalName: activationData.business_information.company_legal_name,
    businessRegistrationNumber: activationData.business_information.business_registration_number
  }

  stateToFill.businessAddress = {
    ...stateToFill.businessAddress,
    city: activationData.business_information.business_address.city,
    state: activationData.business_information.business_address.state,
    country: activationData.business_information.business_address.country,
    addressLine1: activationData.business_information.business_address.address_line.split(', ')[0],
    addressLine2: activationData.business_information.business_address.address_line.split(', ')[1] || '',
    zipCode: activationData.business_information.business_address.zip_code
  }

  stateToFill.contactInformation = {
    ...stateToFill.contactInformation,
    financial: activationData.contact_information.financial,
    development: activationData.contact_information.development,
    business: activationData.contact_information.business
  }

  return stateToFill
}

export default activationRegistrationUIReducer
