import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { isFeatureAllowed, combinePathsWithEnv } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'

const { permissions } = PermissionsUtil
const microAppUrl = envConfig.apps.decisionEngine

const App: MicroApp = {
  name: 'decisionEngine',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: () => {
    const requiredPermissions = [
      permissions.decisionEngine.flows.get
    ]
    return isFeatureAllowed(PermissionsUtil.all, requiredPermissions)
  },
  paths: combinePathsWithEnv([
    Locations.decisionEngineList,
    Locations.decisionEngineItemById,
    Locations.businessUnitsBlock,
    Locations.businessUnitsBlockCreate,
    Locations.businessUnitsTdsAndRisk,
    Locations.businessUnitsTdsAndRiskCreate,
    Locations.businessUnitsRoute,
    Locations.businessUnitsFallback,
    Locations.businessUnitsFallbackRule,
    Locations.businessUnitsFallbackCreate
  ])
}

export default App
