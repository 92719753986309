// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProviderLogo__logo-wrapper___EKCb5{display:flex}`, "",{"version":3,"sources":["webpack://./src/helpers/tools/ProviderLogo.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA","sourcesContent":[".logo-wrapper {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-wrapper": `ProviderLogo__logo-wrapper___EKCb5`
};
export default ___CSS_LOADER_EXPORT___;
