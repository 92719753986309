export default [
  '#79a582',
  '#b3cc97',
  '#8fb1cf',
  '#a484b0',
  '#7e89c9',
  '#e394a7',
  '#b86c6c',
  '#74b7c2',
  '#82c27a',
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5'
]
