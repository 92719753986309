import React from 'react'
import classNames from 'classnames'
import { SideNote } from '@zooz/generic-ui-components'

import style from './style.scss'

interface MenuItem {
  handleClick?: React.MouseEventHandler;
  className?: string;
  buttonClassName?: string;
}

const MenuItem: React.FC<MenuItem> = ({
  className,
  buttonClassName,
  handleClick,
  children
}) => (
  <SideNote bold className={classNames(style['menu-item-wrapper'], className)}>
    <button
      type='button'
      className={classNames(style['menu-item'], buttonClassName)}
      onClick={handleClick}
    >
      {children}
    </button>
  </SideNote>
)

export default MenuItem
