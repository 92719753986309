import { push } from 'connected-react-router'
import {
  all, takeEvery, select, put
} from 'redux-saga/effects'

import UsersApi from '../../../../api/UsersApi'
import errors from '../../../../constants/Errors'
import Locations from '../../../../helpers/tools/locations'
import localize from '../../../Localize/localize'
import language from '../language'
import {
  RESET_PASSWORD_EMAIL,
  CHANGE_PASSWORD,
  requestError, resetError, resetLoading, requestLoading
} from './actions'
import { requestEmail, resetFields } from './selectors'
import messageDispatcher from '../../../Messages/redux/messageDispatcher'

// Sagas
function * resetPasswordSaga () {
  yield takeEvery(RESET_PASSWORD_EMAIL, function * () {
    try {
      yield put(requestLoading(true))
      const email = yield select(requestEmail)
      yield UsersApi.resetPassword(email)
      messageDispatcher.addSuccess(language.requestResetForm.checkEmail(localize.translate))
      yield put(requestLoading(false))
    } catch (err) {
      yield put(requestError(errors.defaultError(localize.translate)))
      yield put(requestLoading(false))
      console.log(err)
    }
  })
}

function * changePasswordSaga () {
  yield takeEvery(CHANGE_PASSWORD, function * (action) {
    try {
      yield put(resetLoading(true))
      const { password } = yield select(resetFields)
      yield UsersApi.changePassword(password, action.token)
      messageDispatcher.addSuccess(language.resetForm.success(localize.translate))
      yield put(resetLoading(false))
      yield put(push(Locations.login()))
    } catch (err) {
      yield put(resetLoading(false))
      yield put(resetError(errors.defaultError(localize.translate)))
      console.log(err)
    }
  })
}

export function * registerSagas () {
  yield all([
    resetPasswordSaga(),
    changePasswordSaga()
  ])
}
