import * as React from 'react'
import { RouteProps } from 'react-router'
import { Route, Redirect, Switch } from 'react-router-dom'

import Join from './features/UnProtected/JoinPage/components/Join'
import JoinExistingUser from './features/UnProtected/JoinPage/components/JoinExistingUser'
import JoinNewUser from './features/UnProtected/JoinPage/components/JoinNewUser'
import ResetPassword from './features/UnProtected/ResetPassword'
import SignIn from './features/UnProtected/SignInPage/signin'
import SignUp from './features/UnProtected/SignUpPage/SignUp'
import SignUpVerified from './features/UnProtected/SignupVerifiedPage/SignupVerifiedPage'
import LocalStorageAccessor from './helpers/localStorageAccessor/localStorageAccessor'
import Locations from './helpers/tools/locations'

const PublicRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { sessionToken } = LocalStorageAccessor
  return (
    <Route
      {...rest}
      render={props => (sessionToken
        ? <Redirect to={Locations.root()} />
        : <Component {...props} />
      )}
    />
  )
}

const RouterSignedOut: React.VFC = () => (
  <Switch>
    <PublicRoute
      exact
      path={Locations.signUp()}
      component={SignUp}
    />
    <PublicRoute
      exact
      path={Locations.signupVerified()}
      component={SignUpVerified}
    />
    <PublicRoute
      exact
      path={Locations.forgotPassword()}
      component={ResetPassword}
    />
    <PublicRoute
      exact
      path={Locations.login()}
      component={SignIn}
    />
    <PublicRoute
      exact
      path={Locations.join()}
      component={Join}
    />
    <PublicRoute
      exact
      path={Locations.joinExistingUser()}
      component={JoinExistingUser}
    />
    <PublicRoute
      exact
      path={Locations.joinNewUser()}
      component={JoinNewUser}
    />
    <Route
      render={props => (
        <Redirect to={{
          state: {
            from: {
              pathname: props.location.pathname,
              search: props.location.search
            }
          },
          pathname: Locations.login()
        }}
        />
      )}
    />
  </Switch>
)

export default RouterSignedOut
