import { Location } from 'history'
import { cloneDeep } from 'lodash'
import { createSelector } from 'reselect'

import { PortalState } from '../store'

export const getLocation = (state: PortalState): Location => cloneDeep(state.router.location)

export type CurrentPage = string | void
export const getCurrentPage = createSelector(getLocation, (location): CurrentPage => {
  if (location) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { root } = require('../../helpers/tools/locations/index') // done due to circular dependencies
    let { pathname } = location
    pathname = pathname.replace(root(), '')
    return pathname.split('/')[0]
  }
})

export const getIsUnattachedUser = createSelector(getLocation, (location): boolean => {
  if (location) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const unattachedUserPath = require('../../helpers/tools/locations/index').unattachedUser // done due to circular dependencies
    return location.pathname === unattachedUserPath()
  }

  return false
})
