import React, { Component } from 'react'
import { ContextHydratingActions } from '@zooz/context-hydrating'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as ENVIRONMENTS from '../../constants/environments'
import { isAccountLive, getAccountId, getCompanyName } from '../../redux/account/selectors'
import { isFetched as isActivationFetched } from '../../redux/activations/selectors'
import appActions from '../../redux/app/actions'
import { currentUser, getIsUnattachedUser } from '../../redux/auth/selectors'
import { setTestLive } from '../../redux/environment/actions'
import { getEnvironment, defaultEnv } from '../../redux/environment/selectors'
import { isAccountLevelFeatureTogglesFetched } from '../../redux/featureToggles/selectors'
import { isPermissionsLoaded } from '../../redux/roles/selector'
import Messages from '../Messages'
import Sidebar from '../Sidebar/Sidebar'
import Topbar from '../Topbar/Topbar'
import AppsRouter from './AppsRouter'
import Walkthrough from './components/Walkthrough'
import { addContextHooks } from './HOC/contextHooks'

import css from './style.scss'
import 'react-perfect-scrollbar/dist/css/styles.css'

const { toggleAll, fetchGlobalData, setContentPosition } = appActions

export class App extends Component {
  constructor (props) {
    super(props)
    this.appContentRef = React.createRef()
  }

  componentDidMount () {
    this.props.fetchGlobalData()
    if (this.appContentRef.current && this.appContentRef.current.getBoundingClientRect) {
      this.props.setContentPosition(this.appContentRef.current.getBoundingClientRect())
    }
    window.addEventListener('resize', this.onResize)
  }

  onResize = debounce(() => {
    this.props.toggleAll(
      window.innerWidth,
      window.innerHeight
    )
    if (this.appContentRef.current && this.appContentRef.current.getBoundingClientRect) {
      this.props.setContentPosition(this.appContentRef.current.getBoundingClientRect())
    }
  }, 100)

  componentDidUpdate () {
    const { env, setTestLive, defaultEnv, isActivationFetched, isAccountLive } = this.props

    if (isActivationFetched && env !== ENVIRONMENTS.TEST && !isAccountLive) {
      setTestLive(false)
    } else if (isActivationFetched && env == null) {
      setTestLive(defaultEnv === ENVIRONMENTS.LIVE)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  onContextSwitched () {
    this.props.fetchContextData()
  }

  render () {
    const { url } = this.props.match
    const { env, isActivationFetched, isPermissionsLoaded, isUnattachedUser, isFeatureToggleLoaded } = this.props
    const loadApp = isActivationFetched && isPermissionsLoaded && env && isFeatureToggleLoaded

    return (
      <>
        {/* app-sanity-indicator is for pingdom - do not remove */}
        <span id='app-sanity-indicator' />
        <div className={css.app}>
          <Sidebar path={this.props.location.pathname} />
          <div className={css.app__right}>
            <div className={css.app__topbar}>
              <Topbar url={url} />
            </div>
            <div ref={this.appContentRef} className={css.app__content}>
              <div id='apps-content'>
                {(loadApp || isUnattachedUser) && <AppsRouter url={url} env={env} />}
              </div>
              <div id='micro-apps-content' />
            </div>
          </div>
        </div>
        {loadApp && <Walkthrough />}
        <Messages />
      </>
    )
  }
}

App.propTypes = {
  fetchGlobalData: PropTypes.func,
  setContentPosition: PropTypes.func,
  setTestLive: PropTypes.func,
  match: PropTypes.object
}

const mapStateToProps = state => ({
  isPermissionsLoaded: isPermissionsLoaded(state),
  isFeatureToggleLoaded: isAccountLevelFeatureTogglesFetched(state),
  env: getEnvironment(state),
  isActivationFetched: isActivationFetched(state),
  isAccountLive: isAccountLive(state),
  defaultEnv: defaultEnv(state),
  accountId: getAccountId(state),
  accountName: getCompanyName(state),
  currentUser: currentUser(state),
  isUnattachedUser: getIsUnattachedUser(state)
})

const mapDispatchToProps = {
  toggleAll,
  fetchGlobalData,
  setTestLive,
  fetchContextData: ContextHydratingActions.fetchContextData,
  setContentPosition
}

export default connect(mapStateToProps, mapDispatchToProps)(addContextHooks(App))
