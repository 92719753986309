import React from 'react'
import classnames from 'classnames'
import { SideNote } from '@zooz/generic-ui-components'
import { Link } from 'react-router-dom'

import style from './breadcrumbs.scss'

export interface CrumbProps {
  text?: string,
  path?: string,
  isFinal?: boolean,
}

const SideNoteLinkWrapper: React.FC<CrumbProps> = ({ children, path, isFinal }) => {
  const dataTest = 'breadcrumbs__crumb'
  if (isFinal || !path) {
    return <div data-test={dataTest}>{children}</div>
  }
  return (
    <Link
      data-test='breadcrumbs__crumb'
      to={isFinal || !path ? null : `/${path}`}
    >
      {children}
    </Link>
  )
}

const Crumb: React.FC<CrumbProps> = ({ text, path, isFinal = false }) => (
  <SideNoteLinkWrapper path={path} text={text} isFinal={isFinal}>
    <SideNote
      obscure={!isFinal}
      bold={isFinal}
      className={classnames(style.crumb, {
        [style.final]: isFinal,
        [style.clickable]: !!path
      })}
    >
      {text}
    </SideNote>
  </SideNoteLinkWrapper>
)

export default Crumb
