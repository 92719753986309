import { createSelector } from 'reselect'

import localStorageAccessor from '../../../../../../helpers/localStorageAccessor/localStorageAccessor'
import { isAccountLive } from '../../../../../../redux/account/selectors'
import { isLive } from '../../../../../../redux/environment/selectors'

const dismissedSelector = (): boolean => localStorageAccessor.activatedAccountDismissed

export const isMessageDismissed = createSelector(
  isAccountLive,
  isLive,
  dismissedSelector,
  (isAccountActive, isLive, dismissed): boolean => (
    (!isAccountActive || !isLive) ? true : dismissed
  )
)
