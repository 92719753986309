import produce from 'immer'
import { UserConfiguration } from '@payu/paymentsos-types'

import { ActionTypes, Actions } from './actions'

export interface State {
  isFetched: boolean
  configuration: UserConfiguration.UserConfiguration,
}

export const initialState: State = {
  isFetched: false,
  configuration: {}
}

const userConfigurationReducer = produce((state: State, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FETCH_USER_CONFIGURATION_SUCCESSFULLY:
      state.isFetched = true
      state.configuration = action.userConfiguration
      break
    case ActionTypes.UPDATE_USER_CONFIGURATION_SUCCESSFULLY:
      state.configuration = action.userConfiguration
      break
  }
}, initialState)

export default userConfigurationReducer
