// Sub-Selectors
import { createSelector } from 'reselect'
import { Accounts } from '@payu/paymentsos-types'

import { PortalState } from '../store'

export const getReverseDomain = (state: PortalState): string => state.accountReducer.account.reverse_domain
export const getCompanyName = (state: PortalState): string => state.accountReducer.account.name
export const getAccountId = (state: PortalState): string => state.accountReducer.account.id
export const isAccountLive = (state: PortalState): boolean => state.accountReducer.account.is_live
export const getRootAccountId = (state: PortalState): string => state.accountReducer.account.root_account
export const isSubAccount = createSelector(
  [getRootAccountId, getAccountId],
  (rootAccountId, accountId) => rootAccountId && rootAccountId !== accountId
)
export const getConnectedAccounts =
    (state: PortalState): Accounts.BaseAccount[] => state.accountReducer.rootAccount.connectedAccounts

window.zooz_account = Object.freeze({
  reverseDomain: () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('../store')
    return getReverseDomain(store.getState())
  },
  companyName: () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('../store')
    return getCompanyName(store.getState())
  },
  accountId: () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('../store')
    return getAccountId(store.getState())
  },
  isLive: () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('../store')
    return isAccountLive(store.getState())
  },
  rootAccountId: () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('../store')
    return getRootAccountId(store.getState())
  },
  isSubAccount: () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('../store')
    return isSubAccount(store.getState())
  }
})
