/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { Component } from 'react'
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import classnames from 'classnames'
import { Popover, SideNote, copyToClipboard } from '@zooz/generic-ui-components'
import { Managment } from '@payu/paymentsos-types'
import ClickOutHandler from 'react-onclickout'
import { connect } from 'react-redux'
import validator from 'validator'

import AccountsApi from '../../../../api/AccountsApi'
import env from '../../../../envConfig'
import PermissionsWrapper from '../../../../helpers/permissions/PermissionsWrapper'
import { currentUser, CurrentUser } from '../../../../redux/auth/selectors'
import { PortalState } from '../../../../redux/store'
import language from '../../language'
import MenuItem from '../MenuItem'
import AccountId from './components/AccountId'
import AccountMenu, { AccountMenuItem } from './components/AccountMenu'
import Separator from './components/Separator'
import SwitchAccountBox from './components/SwitchAccountBox'
import messageDispatcher from '../../../Messages/redux/messageDispatcher'

import css from './style.scss'

const STATUS_PAGE_LINK = 'https://status.paymentsos.com'
const SUPPORT_TOOLS = env.supportTools
const POPOVER_OFFSET = 5

export interface UserPopoverProps extends WithLocalizeProps {
  currentAccountId?: string,
  token?: string,
  userAccountId?: string,
  company?: string,
  isMasquerader?: boolean,
  isCurrentlyMasquerading?: boolean,
  fetchPrivileges: () => void,
  logout?: () => void,
  navigateToUserProfile?: () => void,
  setAccount: (account: Managment.AccountResource) => void,
  children: React.ReactNode,
  currentUser: CurrentUser
}

interface UserPopoverState {
  showMenu?: boolean,
  showSwitchAccount?: boolean,
  searchAccountId?: string,
  originalDefaultAccount?: string,
  isValidAccountId?: boolean
}

class UserPopover extends Component<UserPopoverProps, UserPopoverState> {
  constructor (props: UserPopoverProps) {
    super(props)
    this.state = {
      showMenu: false,
      showSwitchAccount: false,
      searchAccountId: '',
      isValidAccountId: true
    }
  }

  handleClickOutside = (e: Event): void => {
    if (!this.state.showMenu) { return }
    const path = e.composedPath && e.composedPath()
    // @ts-ignore: An issue with typescript (incorrect return type for composed path)
    const targets = path ? path.map(target => target.classList) : []
    if (targets.find(target => target && target.contains(css['user-menu-item']))) { return }
    this.setState({ showMenu: false })
  }

  onMenuItemClick = (): void => {
    this.setState(prevState => ({ showMenu: !prevState.showMenu }))
  }

  handleSwitchAccountClick = (): void => {
    this.setState(prevState => ({ showSwitchAccount: !prevState.showSwitchAccount }))
  }

  handleAccountSwitch = async (): Promise<void> => {
    try {
      const accountToMasq = this.state.searchAccountId
      if (validator.isUUID(accountToMasq)) {
        const account = await AccountsApi.fetchAccount(accountToMasq)
        if (account) {
          const newState: UserPopoverState = {}

          if (!this.state.originalDefaultAccount) {
            newState.originalDefaultAccount = this.props.company
          }
          this.props.setAccount(account)
          this.props.fetchPrivileges()
          Object.assign(newState, { isValidAccountId: true, showMenu: false, searchAccountId: '' })
          this.setState(newState)
        } else {
          this.setState({ isValidAccountId: false })
        }
      } else {
        this.setState({ isValidAccountId: false })
      }
    } catch (error) {
      this.setState({ isValidAccountId: false })
    }
  }

  handleOriginalAccountClick = async (): Promise<void> => {
    try {
      const account = await AccountsApi.fetchAccount(this.props.userAccountId)
      this.props.setAccount(account)
      this.setState({ showMenu: false })
      this.props.fetchPrivileges()
    } catch (error) {
      console.error(error)
    }
  }

  handleUserProfileClick = (): void => {
    this.props.navigateToUserProfile()
    this.setState({ showMenu: false })
  }

  handleStatusPageClick = (): void => {
    window.open(STATUS_PAGE_LINK, '_blank')
  }

  handleSupportToolsClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()
    window.open(`${SUPPORT_TOOLS}?token=${this.props.token}&account=${this.props.currentAccountId}`, '_blank')
  }

  handleCopySessionClick = (): void => {
    try {
      copyToClipboard(this.props.currentUser.sessionToken)
      messageDispatcher.addSuccess(language.copySession.success)
    } catch {
      messageDispatcher.addError(language.copySession.error)
    }
  }

  render (): JSX.Element {
    const { t, logout, currentAccountId, children, company, isMasquerader, isCurrentlyMasquerading } = this.props
    const { showMenu, showSwitchAccount, searchAccountId, isValidAccountId } = this.state
    const userMenu = (
      <ClickOutHandler onClickOut={this.handleClickOutside}>
        <AccountMenu>

          {isMasquerader && (
            <>
              <AccountMenuItem
                arrow
                title={language.switchAccount.title(t)}
                onClick={this.handleSwitchAccountClick}
              />
              <PermissionsWrapper permission='.'>
                <AccountMenuItem
                  title={language.supportTools(t)}
                  onClick={this.handleSupportToolsClick}
                />
              </PermissionsWrapper>
              <AccountMenuItem
                title={language.copySession.text(t)}
                onClick={this.handleCopySessionClick}
              />
              <Separator />
            </>
          )}
          <AccountMenuItem
            title={language.statusPage(t)}
            onClick={this.handleStatusPageClick}
          />
          <Separator />
          <AccountMenuItem
            title={language.userProfile(t)}
            onClick={this.handleUserProfileClick}
          />
          <AccountMenuItem
            title={language.logout(t)}
            onClick={logout}
            dataTest='logout-link'
          />

          <Separator />

          <AccountId name={company}>{currentAccountId}</AccountId>
          {showSwitchAccount && (
            <SwitchAccountBox
              onConfirm={this.handleAccountSwitch}
              accountId={searchAccountId}
              isValid={isValidAccountId}
              defaultAccount={this.state.originalDefaultAccount || company}
              onOriginalAccountClick={this.handleOriginalAccountClick}
              onAccountIdChange={(value): void => { this.setState({ searchAccountId: value }) }}
            />
          )}
        </AccountMenu>
      </ClickOutHandler>
    )

    return (
      <div data-test='topBar__userId' className={css['user-wrapper']}>
        <Popover
          placement={Popover.Placements.BottomRight}
          isOpened={showMenu}
          content={userMenu}
          offset={POPOVER_OFFSET}
        >
          <MenuItem
            buttonClassName={css['user-menu-item__button']}
            className={css['user-menu-item']}
            handleClick={this.onMenuItemClick}
          >
            <span className={css['user-menu-item__name']}>{children}</span>
            <SideNote
              obscure
              data-test='app-account-name'
              className={classnames(css['user-menu-item__company'], {
                [css['user-menu-item__company--masq']]: isCurrentlyMasquerading
              })}
            >
              {company}
            </SideNote>
          </MenuItem>
        </Popover>
      </div>
    )
  }
}

const mapStateToProps = (state: PortalState): { currentUser: CurrentUser } => ({
  currentUser: currentUser(state)
})

export default connect(mapStateToProps)(withLocalize(UserPopover))
