import { createSelector } from 'reselect'
import { Currencies } from '@payu/paymentsos-types'

import { State } from './reducer'

export interface Store {
  Currencies: State
}

export const getCurrencies = (state: Store): Currencies.Currency[] => state.Currencies.currencies

export const getCurrencyCodeToCountryNameMap = createSelector(
  getCurrencies,
  currencies => currencies.reduce(
    (acc, currency): Record<string, string> => {
      acc[currency.code] = currency.name
      return acc
    }, {} as Record<string, string>
  )
)

export const getCurrencyNameToCountryCodeMap = createSelector(
  getCurrencies,
  currencies => currencies.reduce(
    (acc, currency): Record<string, string> => {
      acc[currency.name] = currency.code
      return acc
    }, {} as Record<string, string>
  )
)
