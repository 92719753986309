import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import differenceInDays from 'date-fns/differenceInDays'
import {
  all,
  call,
  put,
  takeEvery,
  select,
  takeLatest
} from 'redux-saga/effects'

import apiConfig from '../../../api/AnalyticsApiHelpers/apiConfig'
import BusinessUnitsApi from '../../../api/BusinessUnitsApi'
import Errors from '../../../constants/Errors'
import localize from '../../Localize/localize'
import AnalyticsApi from '../api/dashboardAnalyticsApi'
import { getGranularityConfigurationByDays } from '../granularityConfig'
import {
  FETCH_DASHBOARD,
  ON_PAYMENT_STAGE_CHANGE,
  ON_DATE_CHANGE,
  ON_COUNTRY_CHANGE,
  ON_APP_CHANGE,
  ON_GRANULARITY_CHANGE,
  ON_METRICS_CHANGE,
  selectGranularity,
  fetchApps,
  appsFetchSuccess,
  fetchDimensionBars,
  dimensionBarsFetchSuccess,
  fetchCountries,
  countriesFetchSuccess,
  fetchTrendGraph,
  trendGraphFetchSuccess,
  fetchPaymentStageBreakdown,
  paymentStageBreakdownFetchSuccess,
  selectPaymentStage,
  selectDate,
  selectCountries,
  selectApp,
  selectMetrics
} from './actions'
import {
  getDashboardCurrency,
  getDatesData,
  getPaymentStage,
  selectedApps,
  getSelectedCountries,
  getMetrics
} from './selectors'
import messageDispatcher from '../../Messages/redux/messageDispatcher'

// Sagas
export function * fetchAppsSaga () {
  try {
    yield put(fetchApps())
    const apps = yield call(BusinessUnitsApi.fetchBusinessUnits)
    yield put(appsFetchSuccess(apps || []))
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

function * fetchCountriesFilter () {
  try {
    yield put(fetchCountries())
    const { from, to } = yield select(getDatesData)
    const dimension = apiConfig.dimensions.country
    const countries = yield call(AnalyticsApi.getDashboardFiltersData, { from, to, dimension })
    yield put(countriesFetchSuccess(countries))
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

function * fetchAllDashboardsData () {
  // Should be called first to get the dashboard currency
  yield fetchPaymentStagesDashboardData()

  yield all([
    call(fetchProgressBarDashboardData),
    call(fetchTrendGraphDashboardData)
  ])
}

function * fetchPaymentStagesDashboardData () {
  try {
    yield put(fetchPaymentStageBreakdown())
    const { from, to } = yield select(getDatesData)
    const apps = yield select(selectedApps)
    const countries = yield select(getSelectedCountries)
    const metrics = yield select(getMetrics)
    const paymentStageTabsData = yield call(
      AnalyticsApi.getPaymentStageBreakdownData, { from, to, apps, countries, metrics }
    )
    yield put(paymentStageBreakdownFetchSuccess(paymentStageTabsData))
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

function * fetchProgressBarDashboardData () {
  try {
    yield put(fetchDimensionBars())
    const { from, to } = yield select(getDatesData)
    const paymentStage = yield select(getPaymentStage)
    const apps = yield select(selectedApps)
    const countries = yield select(getSelectedCountries)
    const metric = yield select(getMetrics)
    const dashboardCurrency = yield select(getDashboardCurrency)
    const bars = yield call(AnalyticsApi.getDimensionBarsData, {
      from, to, paymentStage, apps, countries, metric, dashboardCurrency
    })
    yield put(dimensionBarsFetchSuccess(bars))
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

function * fetchTrendGraphDashboardData () {
  try {
    yield put(fetchTrendGraph())
    const { from, to, granularity } = yield select(getDatesData)
    const paymentStage = yield select(getPaymentStage)
    const apps = yield select(selectedApps)
    const countries = yield select(getSelectedCountries)
    const metrics = yield select(getMetrics)
    const dashboardCurrency = yield select(getDashboardCurrency)
    const graph = yield call(
      AnalyticsApi.getTrendGraphData,
      {
        from,
        to,
        granularity,
        paymentStage,
        apps,
        countries,
        metrics,
        dashboardCurrency
      }
    )
    yield put(trendGraphFetchSuccess(graph))
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * hydrateDashboardPage () {
  try {
    yield all([
      call(fetchAppsSaga),
      call(fetchCountriesFilter),
      call(fetchAllDashboardsData)
    ])
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * updateDashboardBySelectedDate (action) {
  try {
    const { from, to } = action
    const differenceDays = differenceInCalendarDays(to, from)

    if (from && to) {
      yield put(selectDate(action))
      let { granularity } = yield select(getDatesData)
      if (
        differenceInDays(to, from) > 30 &&
        ![apiConfig.granularity.week, apiConfig.granularity.day].includes(granularity)
      ) {
        granularity = apiConfig.granularity.month
      } else {
        const granularityConfiguration = getGranularityConfigurationByDays(differenceDays)
        if (!granularityConfiguration.granularities.includes(granularity)) {
          granularity = granularityConfiguration.defaultGranularity
        }
      }
      yield put(selectGranularity(granularity))
      yield fetchCountriesFilter()
      yield fetchAllDashboardsData()
    }
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * updateDashboardBySelectedCountries ({ countries }) {
  try {
    yield put(selectCountries(countries))
    yield fetchAllDashboardsData()
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * updateDashboardBySelectedApps ({ apps }) {
  try {
    yield put(selectApp(apps))
    yield fetchAllDashboardsData()
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * updateDashboardBySelectedMetricType ({ metrics }) {
  try {
    yield put(selectMetrics(metrics))
    yield fetchAllDashboardsData()
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * updateDashboardBySelectedPaymentStage (action) {
  try {
    yield put(selectPaymentStage(action.paymentStage))
    yield fetchProgressBarDashboardData()
    yield fetchTrendGraphDashboardData()
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * updateDashboardBySelectedGranularity ({ granularity }) {
  try {
    yield put(selectGranularity(granularity))
    yield fetchTrendGraphDashboardData()
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * registerSagas () {
  // Hydrate dashboard on page is loaded
  yield takeLatest(FETCH_DASHBOARD, hydrateDashboardPage)

  // Change dashboard data by filters
  yield takeLatest(ON_DATE_CHANGE, updateDashboardBySelectedDate)
  yield takeEvery(ON_COUNTRY_CHANGE, updateDashboardBySelectedCountries)
  yield takeEvery(ON_APP_CHANGE, updateDashboardBySelectedApps)

  // Dashboard graphs capabilities
  yield takeLatest(ON_PAYMENT_STAGE_CHANGE, updateDashboardBySelectedPaymentStage)
  yield takeLatest(ON_GRANULARITY_CHANGE, updateDashboardBySelectedGranularity)
  yield takeLatest(ON_METRICS_CHANGE, updateDashboardBySelectedMetricType)
}
