import { get } from 'lodash'
import { call, put, select, takeLatest, SelectEffect, PutEffect, CallEffect } from 'redux-saga/effects'

import AccountsApi from '../../../../api/AccountsApi'
import Errors from '../../../../constants/Errors'
import localize from '../../../Localize/localize'
import { buildAccountActivationsRequest } from '../../helpers/requestBuilders'
import {
  setLoading,
  updateAccountActivationSuccess,
  updateAccountActivationFailed
} from '../actions'
import { accountDetailsSelector } from '../selectors'
import { UPDATE_ACCOUNT_ACTIVATION } from '../types'
import messageDispatcher from '../../../Messages/redux/messageDispatcher'

export function * updateAccountActivationSaga (): IterableIterator<
  SelectEffect |
  CallEffect |
  PutEffect
  > {
  try {
    const accountDetails = yield select(accountDetailsSelector)
    const activateAccountRequest = buildAccountActivationsRequest(accountDetails)
    yield call(AccountsApi.updateActivation, activateAccountRequest)
    yield put(updateAccountActivationSuccess())
    yield put(setLoading(false))
  } catch (err) {
    messageDispatcher.addError({
      text: get(err, 'response.data.description') || Errors.defaultError(localize.translate),
      err
    })
    yield put(setLoading(false))
    yield put(updateAccountActivationFailed())
  }
}

export default function * accountActivationSaga () {
  yield takeLatest(UPDATE_ACCOUNT_ACTIVATION, updateAccountActivationSaga)
}
