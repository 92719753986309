import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getAccountId } from '../../../redux/account/selectors'
import { isLoggedIn } from '../../../redux/auth/selectors'
import { getEnvironment, isLive } from '../../../redux/environment/selectors'

export const addContextHooks = (WrappedComponent) => {
  const mapStateToProps = state => ({
    environment: getEnvironment(state),
    isLive: isLive(state),
    accountId: getAccountId(state),
    isLoggedIn: isLoggedIn(state)
  })

  class ClassComponentWrapper extends Component {
    componentDidUpdate (prevProps) {
      const curProps = this.props
      if (curProps.isLoggedIn) {
        if (curProps.environment !== prevProps.environment || curProps.accountId !== prevProps.accountId) {
          this.onContextSwitched()
        }
      }
    }

    onContextSwitched () {
      if (!this.child.onContextSwitched) {
        console.warn(new Error('warning: \'onContextSwitched\' function is expected.'))
        return
      }
      this.child.onContextSwitched()
    }

    render () {
      return (
        <WrappedComponent ref={(cd) => { this.child = cd }} {...this.props} />
      )
    }
  }

  const FunctionalComponentWrapper = props => (
    <WrappedComponent {...props} />
  )

  const getComponentWrapper = () => {
    if (WrappedComponent.prototype && WrappedComponent.prototype.render) {
      return ClassComponentWrapper
    }
    return FunctionalComponentWrapper
  }

  return connect(mapStateToProps)(getComponentWrapper())
}
