// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__topbar-accountid___R4VmG{font-size:11px;color:#a8a8a8;white-space:nowrap;padding:0px 20px}.style__topbar-accountid__header___dbxat{font-weight:bold}.style__topbar-accountid__id___zQP_5{font-weight:normal}`, "",{"version":3,"sources":["webpack://./src/features/Topbar/components/UserPopover/components/AccountId/style.scss"],"names":[],"mappings":"AAAA,iCACE,cAAA,CACA,aAAA,CACA,kBAAA,CACA,gBAAA,CAGF,yCACE,gBAAA,CAGF,qCACE,kBAAA","sourcesContent":[".topbar-accountid {\n  font-size: 11px;\n  color: #a8a8a8;\n  white-space: nowrap;\n  padding: 0px 20px;\n}\n\n.topbar-accountid__header {\n  font-weight: bold;\n}\n\n.topbar-accountid__id {\n  font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topbar-accountid": `style__topbar-accountid___R4VmG`,
	"topbar-accountid__header": `style__topbar-accountid__header___dbxat`,
	"topbar-accountid__id": `style__topbar-accountid__id___zQP_5`
};
export default ___CSS_LOADER_EXPORT___;
