import React from 'react'
import { Button } from '@zooz/generic-ui-components'

interface ButtonSubmitProps {
  children?: React.ReactNode;
  loading?: boolean;
}

const ButtonSubmit: React.FC<ButtonSubmitProps> = ({ children, loading }) => (
  <Button type='submit' spinner={loading} width='100%'>
    {children}
  </Button>
)

export default ButtonSubmit
