import envConfig from '../envConfig'
import { getVersionUrl, getAppUrl } from '../helpers/microApps/buildUrls'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { combinePathsWithEnv, isFeatureAllowed } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'

const { permissions } = PermissionsUtil

const microAppUrl = envConfig.apps.accountManagement

const App: MicroApp = {
  name: 'account_management',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: (): boolean => (isFeatureAllowed(PermissionsUtil.any, [
    permissions.users.get,
    permissions.sftp.get,
    permissions.support.accounts.activate.create
  ])),
  paths: combinePathsWithEnv([
    Locations.userManagement,
    Locations.sftpManagement,
    Locations.accountActivationsPage
  ])
}

export default App
