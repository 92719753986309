// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ps .ps__rail-y:hover, .ps .ps--clicking {
    background: transparent !important;
    opacity: 0.6 !important;
}

.ps__thumb-y {
    width: 6px !important;
    background-color: #aaa !important;
}
`, "",{"version":3,"sources":["webpack://./src/shared-components/CustomScrollbar/global_overrides.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;IACrB,iCAAiC;AACrC","sourcesContent":[".ps .ps__rail-y:hover, .ps .ps--clicking {\n    background: transparent !important;\n    opacity: 0.6 !important;\n}\n\n.ps__thumb-y {\n    width: 6px !important;\n    background-color: #aaa !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
