import { all, call } from 'redux-saga/effects'

import accountActivationSaga from '../features/Activation/redux/sagas/activateAccountSaga'
import { registerSagas } from '../features/Dashboard/redux/sagas'
import localizeSaga from '../features/Localize/redux/saga'
import { messagesSagas } from '../features/Messages/redux/sagas'
import breadcrumbsSaga from '../features/Topbar/components/Breadcrumbs/redux/sagas'
import { registerSagas as resetPasswordSagas } from '../features/UnProtected/ResetPassword/redux/sagas'
import signUpSaga from '../features/UnProtected/SignUpPage/redux/saga'
import signUpVerifySaga from '../features/UnProtected/SignupVerifiedPage/redux/saga'
import userProfileSagas from '../features/UserProfile/redux/sagas'
import accountSaga from './account/sagas'
import activationsSaga from './activations/sagas'
import { globalDataSaga } from './app/sagas'
import countriesSaga from './countries/sagas'
import currenciesSaga from './currencies/sagas'
import environmentSaga from './environment/sagas'
import featureTogglesSaga from './featureToggles/sagas'
import { providersSaga } from './providers/sagas'
import rolesSaga from './roles/sagas'
import userConfigurationSaga from './userConfiguration/sagas'
import ssoAuthSaga from './auth/saga'

const defaultSagas = [
  ssoAuthSaga,
  globalDataSaga,
  breadcrumbsSaga,
  accountSaga,
  rolesSaga,
  providersSaga,
  environmentSaga,
  registerSagas,
  messagesSagas,
  resetPasswordSagas,
  countriesSaga,
  currenciesSaga,
  accountActivationSaga,
  activationsSaga,
  featureTogglesSaga,
  userConfigurationSaga,
  signUpSaga,
  signUpVerifySaga,
  localizeSaga,
  userProfileSagas
]

export function * registerDefaultSagas () {
  yield all(defaultSagas.map(saga => call(saga)))
}

export function * registerAsyncSagas (asyncSagas = []) {
  if (asyncSagas.length) {
    yield all(asyncSagas.map(saga => call(saga)))
  }
}
