import React, { Fragment, useCallback } from 'react'
import { useLocalize } from '@zooz/react-localize'
import { useSelector } from 'react-redux'
import { matchPath } from 'react-router'

import { getEnvironment } from '../../../../redux/environment/selectors'
import menus, { DisabledFunction } from '../../../Sidebar/menus'
import Crumb from './Crumb'
import CrumbSeparator from './CrumbSeparator'

import style from './breadcrumbs.scss'

export interface PathSnippet {
  path?: string,
  text: string
}

export interface BreadcrumbsProps {
  pathSnippets?: PathSnippet[] | undefined
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ pathSnippets = [] }) => {
  const { t } = useLocalize()
  const environment = useSelector(getEnvironment)
  const translatedCrumbs: string[] = []

  const isLinkActive = useCallback(
    (path: string, disabled?: DisabledFunction) => {
      const isLinkDisplayed = disabled ? !disabled({ environment }) : true

      if (!isLinkDisplayed) {
        return false
      }
      return matchPath(location.pathname, {
        path,
        exact: true
      })
    },
    [environment]
  )

  menus.forEach((parentMenus) => {
    const { children, disabled, link } = parentMenus

    if (children) {
      children.forEach(({ disabled, link, translationKey, text }) => {
        const isLinkPartOfPath = isLinkActive(link(environment), disabled)
        if (isLinkPartOfPath) {
          translatedCrumbs.push(t(parentMenus.translationKey, {
            fallback: parentMenus.text
          }))

          translatedCrumbs.push(t(translationKey, {
            fallback: text
          }))
        }
      })
    } else {
      const isLinkPartOfPath = link ? isLinkActive(link(environment), disabled) : false
      if (isLinkPartOfPath) {
        translatedCrumbs.push(t(parentMenus.translationKey, {
          fallback: parentMenus.text
        }))
      }
    }
  })

  return (
    <div className={style['bread-crumbs-list']}>
      {pathSnippets?.map((snippet, index) => {
        const isFinal = index === pathSnippets.length - 1

        return (
          <Fragment key={index}>
            <Crumb
              text={translatedCrumbs[index] || snippet.text}
              path={snippet.path}
              isFinal={isFinal}
            />
            {
                !isFinal && <CrumbSeparator />
              }
          </Fragment>
        )
      })}
    </div>
  )
}

export default Breadcrumbs
