import { app_logged_in as appLoggedInTranslations } from '@zooz/translation-values'

import * as ENVIRONMENTS from '../../constants/environments'
import permissions from '../../constants/permissions'
import FeaturesToggleUtil from '../../helpers/permissions/FeaturesToggleUtil'
import PermissionsUtil from '../../helpers/permissions/PermissionsUtil'
import Locations from '../../helpers/tools/locations'
import { isSubAccount } from '../../redux/account/selectors'
import { isMasqueraderUser, isCurrentlyMasquerading } from '../../redux/auth/selectors'
import { store } from '../../redux/store'
import { OPERATORS_PROVIDERS_PREFIX } from '../../constants/common'
import { Environments } from '../../../portal-types/environments'
import { PaymentLinksPermissionsCheck } from '../../microApps/payment_links'

interface EnvLink {
    environment?: Environments
    isAccountActivated?: boolean
}

interface Hint {
    position: number,
    title: string,
    text: string
}

export type DisabledFunction = (parameters: EnvLink) => boolean
export type LinkFunction = (environment: Environments) => string

export interface MenuChild {
    text: string,
    translationKey: string,
    link?: LinkFunction,
    disabled?: DisabledFunction,
    hint?: Hint,
    beta?: boolean
}

export interface MenuEntry {
    text: string,
    translationKey: string,
    icon?: string,
    link?: LinkFunction,
    disabled?: DisabledFunction,
    hint?: Hint,
    children?: MenuChild[]
}

const menus: MenuEntry[] = [
  {
    text: appLoggedInTranslations.sidebar_menu_dashboard.fallback,
    translationKey: appLoggedInTranslations.sidebar_menu_dashboard.key,
    icon: 'pie-chart',
    link: (environment: Environments): string => Locations.dashboard({ env: environment }),
    disabled: ({ environment }: EnvLink): boolean => (
      !PermissionsUtil.isAllowed(
        permissions.analytics.payments(environment)
      )
    )
  },
  {
    text: appLoggedInTranslations.sidebar_menu_decisionEngine.fallback,
    translationKey: appLoggedInTranslations.sidebar_menu_decisionEngine.key,
    icon: 'flash',
    link: (environment: Environments): string => Locations.decisionEngineList({ env: environment }),
    disabled: ({ environment }: EnvLink): boolean => (
      !PermissionsUtil.isAllowed(
        permissions.decisionEngine.flows.get(environment)
      )
    )
  },
  {
    text: appLoggedInTranslations.sidebar_menu_analytics.fallback,
    translationKey: appLoggedInTranslations.sidebar_menu_analytics.key,
    icon: 'line-chart',
    children: [
      {
        text: appLoggedInTranslations.sidebar_menu_transactionApproval.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_transactionApproval.key,
        link: (environment: Environments): string => Locations.transactionsApproval({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => (
          !PermissionsUtil.isAllowed(
            permissions.analytics.payments(environment)
          )
        )
      },
      {
        text: appLoggedInTranslations.sidebar_menu_instantRetry.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_instantRetry.key,
        link: (environment: Environments): string => Locations.instantRetryPage({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => (
          !PermissionsUtil.isAllowed(
            permissions.analytics.payments(environment)
          )
        )
      }
    ]
  },
  {
    text: appLoggedInTranslations.sidebar_menu_reports.fallback,
    translationKey: appLoggedInTranslations.sidebar_menu_reports.key,
    icon: 'file-text',
    link: (environment: Environments): string => Locations.reports({ env: environment }),
    disabled: ({ environment }: EnvLink): boolean => (
      !PermissionsUtil.isAllowed(
        permissions.reports.generate(environment)
      )
    )
  },
  {
    text: appLoggedInTranslations.sidebar_menu_treasury.fallback,
    translationKey: appLoggedInTranslations.sidebar_menu_treasury.key,
    icon: 'bank',
    children: [
      {
        text: appLoggedInTranslations.sidebar_menu_treasuryAnalytics.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_treasuryAnalytics.key,
        link: (environment: Environments): string => Locations.treasuryAnalyticsPage({ env: environment }),
        disabled: (): boolean => {
          const requiredPrefixes = [
            OPERATORS_PROVIDERS_PREFIX,
            permissions.operations.payouts.search()
          ]

          return !requiredPrefixes.every(prefix => PermissionsUtil.findPermissionsByPrefix(prefix,
            PermissionsUtil.masqueraderRoles).length)
        }
      },
      {
        text: appLoggedInTranslations.sidebar_menu_balances.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_balances.key,
        link: (environment: Environments): string => Locations.treasuryBalancesManagementPage({ env: environment }),
        disabled: (): boolean => {
          const requiredPrefixes = [
            OPERATORS_PROVIDERS_PREFIX,
            permissions.operations.balances.get(),
            permissions.operations.balanceOperationsSearch.get(),
            permissions.operations.balancesConfigurationsSearch.get(),
            permissions.operations.payouts.search()
          ]

          return !requiredPrefixes.every(prefix => PermissionsUtil.findPermissionsByPrefix(prefix,
            PermissionsUtil.masqueraderRoles).length)
        }
      },
      {
        text: appLoggedInTranslations.sidebar_menu_payouts.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_payouts.key,
        link: (environment: Environments): string => Locations.treasuryPayoutsManagementPage({ env: environment }),
        disabled: (): boolean => {
          const requiredPrefixes = [
            OPERATORS_PROVIDERS_PREFIX,
            permissions.operations.balances.get(),
            permissions.operations.payouts.search()
          ]

          return !requiredPrefixes.every(prefix => PermissionsUtil.findPermissionsByPrefix(prefix,
            PermissionsUtil.masqueraderRoles).length)
        }
      },
      {
        text: appLoggedInTranslations.sidebar_menu_locks.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_locks.key,
        link: (environment: Environments): string => `${Locations.treasuryBalanceLocksPage({ env: environment })}?page_mode=active`,
        disabled: (): boolean => {
          const requiredPrefixes = [
            OPERATORS_PROVIDERS_PREFIX,
            permissions.operations.balances.get(),
            permissions.operations.balanceLocks.activate()
          ]

          return !requiredPrefixes.every(prefix => PermissionsUtil.findPermissionsByPrefix(prefix,
            PermissionsUtil.masqueraderRoles).length)
        }
      },
      // this submenu is for operators to use the reports settlement feature
      {
        text: 'Settlements Reports',
        translationKey: appLoggedInTranslations.sidebar_menu_settlements.key,
        link: (environment: Environments): string => Locations.operatorSettlementsReports({ env: environment }),
        disabled: (): boolean => {
          const requiredPrefixes = [
            OPERATORS_PROVIDERS_PREFIX,
            permissions.operations.settlements.templates(),
            permissions.operations.settlements.reports()
          ]

          return !requiredPrefixes.every(prefix => PermissionsUtil.findPermissionsByPrefix(prefix,
            PermissionsUtil.masqueraderRoles).length)
        }
      }
    ]
  },
  {
    text: appLoggedInTranslations.sidebar_menu_settlements.fallback,
    translationKey: appLoggedInTranslations.sidebar_menu_settlements.key,
    icon: 'money',
    children: [
      {
        text: 'Reports',
        translationKey: appLoggedInTranslations.sidebar_menu_transactionReports.key,
        link: (environment: Environments): string => Locations.settlementsReports({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => {
          const hasReportPermission = PermissionsUtil.isAllowed(
            permissions.reports.generate(environment)
          )

          if (!hasReportPermission) {
            return true
          }

          const isMasquerader = isMasqueraderUser(store.getState())
          if (isMasquerader) {
            return false
          }

          const hasToggle = FeaturesToggleUtil.isAccountOn('settlements')

          return !hasToggle
        }
      }
    ]
  },
  {
    text: appLoggedInTranslations.sidebar_menu_payments.fallback,
    translationKey: appLoggedInTranslations.sidebar_menu_payments.key,
    icon: 'credit-card',
    children: [
      {
        text: appLoggedInTranslations.sidebar_menu_paymentsSearch.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_paymentsSearch.key,
        link: (environment: Environments): string => Locations.paymentsSearch({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => (
          !PermissionsUtil.isAllowed(
            permissions.payments.get(environment)
          )
        )
      },
      {
        text: appLoggedInTranslations.sidebar_menu_virtualTerminal.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_virtualTerminal.key,
        link: (environment: Environments): string => Locations.virtualTerminal({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => (
          !PermissionsUtil.isAllowed(PermissionsUtil.all([
            permissions.payments.create(environment),
            permissions.authorization.create(environment),
            permissions.charge.create(environment),
            permissions.paymentMethod.create(environment),
            permissions.customer.create(environment),
            permissions.customer.get(environment),
            permissions.token.create(environment)
          ]))
        )
      },
      {
        text: appLoggedInTranslations.sidebar_menu_paymentLinks.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_paymentLinks.key,
        link: (environment: Environments): string => Locations.paymentLinksPage({ env: environment }),
        disabled: (): boolean => {
          const userHasPermissions = PaymentLinksPermissionsCheck()
          return !userHasPermissions
        }
      }
    ]
  },
  {
    text: 'Fraud Manager',
    translationKey: '',
    icon: 'shield',
    link: (environment: Environments): string => Locations.fraudManagerPage({ env: environment }),
    disabled: (): boolean => {
      const getConfigurationPermission = permissions.fraudManager.configurations.get()
      const permissionsByPrefix = PermissionsUtil.findPermissionsByPrefix(
        getConfigurationPermission, PermissionsUtil.masqueraderRoles
      )
      return !permissionsByPrefix.length
    }
  },
  {
    text: appLoggedInTranslations.sidebar_menuLbl_configurations.fallback,
    translationKey: appLoggedInTranslations.sidebar_menuLbl_configurations.key,
    icon: 'gear',
    children: [
      {
        text: appLoggedInTranslations.sidebar_menu_providers.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_providers.key,
        link: (environment: Environments): string => Locations.providerConfigurationsList({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => (
          !PermissionsUtil.isAllowed(
            permissions.providerConfigurations.get(environment)
          )
        )
      },
      {
        text: appLoggedInTranslations.sidebar_menu_businessUnits.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_businessUnits.key,
        link: (environment: Environments): string => Locations.businessUnits({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => (
          !PermissionsUtil.isAllowed(
            permissions.businessUnits.get(environment)
          )
        )
      },
      {
        text: appLoggedInTranslations.sidebar_menu_webhooks.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_webhooks.key,
        link: (environment: Environments): string => Locations.webhooksConfigurationsList({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => (
          !PermissionsUtil.isAllowed(
            permissions.webhooks.get(environment)
          )
        )
      },
      {
        text: appLoggedInTranslations.sidebar_menu_sftp.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_sftp.key,
        link: (environment: Environments): string => Locations.sftpManagement({ env: environment }),
        disabled: (): boolean => !PermissionsUtil.isAllowed(permissions.sftp.get())
      }
    ]
  },
  {
    text: appLoggedInTranslations.sidebar_menu_account.fallback,
    translationKey: appLoggedInTranslations.sidebar_menu_account.key,
    icon: 'address-card-o',
    children: [
      {
        text: appLoggedInTranslations.sidebar_menu_balances.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_balances.key,
        link: (environment: Environments): string => Locations.accountBalancePage({ env: environment }),
        disabled: ({ environment }: EnvLink): boolean => {
          const isMasquerader = isMasqueraderUser(store.getState())
          if (isMasquerader) {
            return false
          }

          const hasAccountBalancesPermission = PermissionsUtil.isAllowed(PermissionsUtil.all([
            permissions.balanceConfiguration.get(environment),
            permissions.balances.get(environment)
          ]))

          if (!hasAccountBalancesPermission) {
            return true
          }

          const hasToggle = FeaturesToggleUtil.isAccountOn('balances')

          return !hasToggle
        }
      },
      {
        text: appLoggedInTranslations.sidebar_menu_userManagement.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_userManagement.key,
        link: (): string => Locations.userManagement({ env: ENVIRONMENTS.LIVE }),
        disabled: (): boolean => (
          !PermissionsUtil.isAllowed(
            permissions.users.get()
          )
        )
      },
      {
        text: appLoggedInTranslations.sidebar_menu_settings.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_settings.key,
        link: (): string => Locations.activationSettingsPage({ env: ENVIRONMENTS.LIVE }),
        disabled: ({ isAccountActivated }: EnvLink): boolean => (
          !PermissionsUtil.isAllowed(permissions.activation.get()) ||
                    !isAccountActivated ||
                    isSubAccount(store.getState())
        )
      },
      {
        text: appLoggedInTranslations.sidebar_menu_activations.fallback,
        translationKey: appLoggedInTranslations.sidebar_menu_activations.key,
        link: (environment: Environments): string => Locations.accountActivationsPage({ env: environment }),
        disabled: (): boolean => !isCurrentlyMasquerading(store.getState()) ||
                    !PermissionsUtil.isAllowed(permissions.support.accounts.activate.create())
      }
    ]
  }
]

export default menus
