import React, { Component } from 'react'
import { useLocalize } from '@zooz/react-localize'
import classnames from 'classnames'
import { Popover } from '@zooz/generic-ui-components'
import ClickOutHandler from 'react-onclickout'

import * as ENVIRONMENTS from '../../../../constants/environments'
import Locations from '../../../../helpers/tools/locations'
import { CurrentPage } from '../../../../redux/location/selectors'
import { history } from '../../../../redux/store'
import ActivatedAccountMessage from './ActivatedAccountMessage'
import language from './language'

import style from './style.scss'

interface ItemProps {
  testData?: string,
  selected?: boolean,
  children?: React.ReactElement | string,
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}
const Item: React.FC<ItemProps> = ({ testData, selected, children, onClick }) => {
  const classes = classnames(style['switch-item'], {
    [style['switch-item--selected']]: selected
  })
  return (
    <div className={classes} data-test={testData} onClick={onClick}>
      <div className={style['switch-item__text']}>
        {children}
      </div>
    </div>
  )
}

interface SwitcherProps {
  isTestSelected: boolean,
  isLiveSelected: boolean,
  onClick: () => void,
  environment?: string
}

const Switcher: React.FC<SwitcherProps> = ({ isTestSelected, isLiveSelected, onClick, environment }) => {
  const { t } = useLocalize()
  const classes = classnames(style['switch-container'], {
    [style['switch-container--live']]: isLiveSelected,
    [style['switch-container--test']]: isTestSelected
  })

  return (
    <div className={classes} data-test={`app_env_${environment}`} onClick={onClick}>
      <Item testData='env-test' selected={isTestSelected}>
        { language.test(t) }
      </Item>
      <Item testData='env-live' selected={isLiveSelected}>
        { language.live(t) }
      </Item>
    </div>

  )
}

interface PopUpContentProps {
  isActivationPermitted: boolean
}

const PopUpContent: React.FC<PopUpContentProps> = ({ isActivationPermitted }) => {
  const { t } = useLocalize()
  if (isActivationPermitted) {
    return (
      <div className={style['popup-text']}>
        {language.activationPermitted(t)}
      </div>
    )
  }
  return (
    <div className={style['popup-text']}>
      {language.activationNotPermitted(t)}
    </div>
  )
}

const getNewUrl = (isLive: boolean): string => {
  const liveRoot = Locations.root(ENVIRONMENTS.LIVE)
  const testRoot = Locations.root(ENVIRONMENTS.TEST)
  const { pathname } = history.location
  return isLive
    ? pathname.replace(liveRoot, testRoot)
    : pathname.replace(testRoot, liveRoot)
}

interface TestLiveSwitchProps {
  isLive?: boolean,
  currentPage?: CurrentPage,
  environment?: string,
  isAccountLive?: boolean,
  isActivationPermitted?: boolean
}

interface TestLiveSwitchState {
  showSelected?: boolean,
  showLiveTooltip?: boolean
}

export class TestLiveSwitch extends Component<TestLiveSwitchProps, TestLiveSwitchState> {
  state = {
    showSelected: false,
    showLiveTooltip: false
  }

  static getDerivedStateFromProps (nextProps: TestLiveSwitchProps): Partial<TestLiveSwitchState> {
    if (nextProps.currentPage && nextProps.currentPage !== '/') {
      return {
        showSelected: true
      }
    }
    return null
  }

  handleOutsideClick = (): void => {
    this.setState({
      showLiveTooltip: false
    })
  }

  handleTooltipContainerClick = (): void => {
    this.setState({
      showLiveTooltip: true
    })
  }

  handleClick = (): void => {
    const { isLive, isAccountLive } = this.props
    if (!isAccountLive) {
      return
    }

    const from = history.location
    history.push(getNewUrl(isLive), { from })
  }

  render (): JSX.Element {
    const { isLive, isAccountLive, isActivationPermitted, environment } = this.props
    const { showSelected } = this.state
    const liveSelected = showSelected && isLive
    const testSelected = showSelected && !isLive
    return (
      <ClickOutHandler onClickOut={this.handleOutsideClick}>
        <Popover
          content={<PopUpContent isActivationPermitted={isActivationPermitted} />}
          placement={Popover.Placements.BottomCenter}
          offset={10}
          isOpened={!isAccountLive && this.state.showLiveTooltip}
        >
          <div onClick={this.handleTooltipContainerClick} className={style.tooltips}>
            <ActivatedAccountMessage />
            <Switcher
              isLiveSelected={liveSelected}
              isTestSelected={testSelected}
              onClick={this.handleClick}
              environment={environment}
            />
          </div>
        </Popover>
      </ClickOutHandler>
    )
  }
}

export default TestLiveSwitch
