import { ToasterTypes } from '@zooz/generic-ui-components'
import { createSelector } from 'reselect'

import { Message, State } from './reducers'

interface Store {
  messagesReducer: State
}

export const getMessages = (state: Store): Message[] => state.messagesReducer.messages

export const getErrors = createSelector(
  getMessages, messages => messages.filter(message => message.type === ToasterTypes.ERROR)
)
