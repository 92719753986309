import {
  call,
  put,
  select,
  takeLatest,
  SelectEffect,
  CallEffect,
  PutEffect, ForkEffect
} from 'redux-saga/effects'

import CountriesApi from '../../api/CountriesApi'
import errors from '../../constants/Errors'
import localize from '../../features/Localize/localize'
import { fetchCountriesSuccess, ActionTypes } from './actions'
import { getCountries } from './selectors'
import messageDispatcher from '../../features/Messages/redux/messageDispatcher'

export function * fetchCountriesSaga (): IterableIterator<
  SelectEffect |
  CallEffect |
  PutEffect
  > {
  try {
    const countries = getCountries(yield select())
    const countriesFetched = countries.length > 0
      ? countries
      : yield call(CountriesApi.fetchCountries)
    yield put(fetchCountriesSuccess(countriesFetched))
  } catch (err) {
    messageDispatcher.addError({
      text: errors.defaultError(localize.translate),
      err
    })
  }
}

export default function * countriesSaga (): IterableIterator<ForkEffect> {
  yield takeLatest(ActionTypes.FETCH_COUNTRIES, fetchCountriesSaga)
}
