import { IBusinessUnitsApi, IUsersApi } from 'cc-apis'

import BusinessUnitsApi from '../api/BusinessUnitsApi'
import UsersApi from '../api/UsersApi'

interface CcApis {
  usersApi: IUsersApi,
  businessUnitsApi: IBusinessUnitsApi
}

const ccApis: CcApis = {
  usersApi: UsersApi,
  businessUnitsApi: BusinessUnitsApi
}

export default ccApis
