import { UserConfiguration } from '@payu/paymentsos-types'

import ApiBase from './ApiBase'

export type UpdateUserConfigurationsOptions = {
  userId: string,
  sessionToken: string
}

class UsersConfigurationApi extends ApiBase {
  private resourcePath = (userId?: string): string => `/users/${this.currentUser?.userId || userId}/user-configuration`

  fetchUserConfigurations = async (): Promise<UserConfiguration.UserConfigurationResource> => {
    const response = await this.httpClient.get<UserConfiguration.UserConfigurationResource>(`${this.resourcePath()}`, {}, this.defaultConfig)
    return response.data
  }

  updateUserConfigurations = async (
    userConfig: UserConfiguration.UserConfiguration,
    options?: UpdateUserConfigurationsOptions
  ): Promise<UserConfiguration.UserConfigurationResource> => {
    let config: unknown = this.defaultConfig

    if (options) {
      config = {
        headers: {
          Authorization: `Bearer ${options.sessionToken}`
        }
      }
    }

    const response = await this.httpClient.patch<UserConfiguration.UserConfigurationResource>(
      `${this.resourcePath(options?.userId)}`,
      userConfig,
      {},
      config
    )
    return response.data
  }
}

export default new UsersConfigurationApi()
