import { UserTypeContext } from 'cc-locations'

import defaultMixinsStyles from '../../styles/defaultMixins.scss'

const { floatingSideBarResolutionBreakpoint } = defaultMixinsStyles

export function getView (width: number): string {
  let newView = 'MobileView'
  if (width > parseInt(floatingSideBarResolutionBreakpoint, 10)) {
    newView = 'DesktopView'
  } else if (width > 767) {
    newView = 'TabView'
  }
  return newView
}
const types = {
  COLLAPSE_CHANGE: 'COLLAPSE_CHANGE',
  COLLAPSE_OPEN_DRAWER: 'COLLAPSE_OPEN_DRAWER',
  TOGGLE_ALL: 'TOGGLE_ALL',
  FETCH_GLOBAL_DATA: 'FETCH_GLOBAL_DATA',
  SET_CONTENT_RECT_DATA: 'SET_CONTENT_RECT_DATA',
  SET_MICROAPP_LOADING: 'App/setting microapp loading',
  SET_CURRENT_MICROAPP: 'App/setting current microapp',
  SET_MICROAPP_CONTEXT: 'App/setting current context'
}

export type ActionTypes = typeof types.COLLAPSE_CHANGE |
                          typeof types.COLLAPSE_OPEN_DRAWER |
                          typeof types.TOGGLE_ALL |
                          typeof types.FETCH_GLOBAL_DATA

export interface Action {
  type: ActionTypes
  payload?: any
}

export interface ToggleCollapsedAction extends Action {
  type: typeof types.COLLAPSE_CHANGE
}

export interface ToggleOpenDrawerAction extends Action {
  type: typeof types.COLLAPSE_OPEN_DRAWER
}

export interface FetchGlobalDataAction extends Action {
  type: typeof types.FETCH_GLOBAL_DATA
}

export interface ToggleAllAction extends Action {
  type: typeof types.TOGGLE_ALL,
  collapsed: boolean,
  height: number,
  view: string
}

export interface SetContentPosition extends Action {
  type: typeof types.SET_CONTENT_RECT_DATA,
  payload: DOMRect
}

export interface SetMicroAppLoading extends Action {
  type: typeof types.SET_MICROAPP_LOADING,
  payload: {
    isLoaded: boolean,
    name: string
  }
}

export interface SetCurrentMicroApp extends Action {
  type: typeof types.SET_MICROAPP_LOADING,
  payload: {
    name: string
  }
}

export interface SetCurrentMicroAppContext extends Action {
  type: typeof types.SET_MICROAPP_CONTEXT,
  payload: {
    userTypeContext: UserTypeContext
  }
}

export type Actions = ToggleAllAction |
  ToggleOpenDrawerAction |
  FetchGlobalDataAction |
  ToggleCollapsedAction |
  SetContentPosition |
  SetMicroAppLoading

const actions = {
  toggleCollapsed: (): ToggleCollapsedAction => ({
    type: types.COLLAPSE_CHANGE
  }),
  toggleAll: (width: number, height: number): ToggleAllAction => {
    const view = getView(width)
    const collapsed = view !== 'DesktopView'
    return {
      type: types.TOGGLE_ALL,
      collapsed,
      view,
      height
    }
  },
  toggleOpenDrawer: (): ToggleOpenDrawerAction => ({
    type: types.COLLAPSE_OPEN_DRAWER
  }),
  fetchGlobalData: (): FetchGlobalDataAction => ({
    type: types.FETCH_GLOBAL_DATA
  }),
  setContentPosition: (rectData: DOMRect): SetContentPosition => ({
    type: types.SET_CONTENT_RECT_DATA,
    payload: rectData
  }),
  setMicroAppLoading: ({ isLoaded, name }: { isLoaded: boolean, name: string }): SetMicroAppLoading => ({
    type: types.SET_MICROAPP_LOADING,
    payload: {
      isLoaded,
      name
    }
  }),
  setCurrentLoadedMicroApp: ({ name }: { name: string }): SetCurrentMicroApp => ({
    type: types.SET_CURRENT_MICROAPP,
    payload: {
      name
    }
  }),
  setCurrentMicroAppContext: (
    { userTypeContext }: { userTypeContext: UserTypeContext }
  ): SetCurrentMicroAppContext => ({
    type: types.SET_MICROAPP_CONTEXT,
    payload: {
      userTypeContext
    }
  })
}

export default actions
export { types, actions }
