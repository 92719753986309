import permissions from '../constants/permissions'
import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { isFeatureAllowed, combinePathsWithEnv } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'

const microAppUrl = envConfig.apps.transactionsApproval

const App: MicroApp = {
  name: 'transactions_approval',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: () => isFeatureAllowed(PermissionsUtil.all, [permissions.analytics.payments]),
  paths: combinePathsWithEnv([Locations.transactionsApproval])
}

export default App
