import { ccLocalStorage } from 'cc-local-storage'
import { Managment } from '@payu/paymentsos-types'

import { storage } from '../../globalStoragePolyfill'
import configuration from './localStorageAccessorConfiguration'

interface User {
  id?: string,
  email?: string
}

class LocalStorageAccessor implements ccLocalStorage {
  constructor () {
    this.initializeUser()
  }

  get dismissedPayUChangePassword (): boolean {
    return this.localStorage.getItem(configuration.payUHubChangePasswordDismissed) === 'true'
  }

  set dismissedPayUChangePassword (isDismissed: boolean) {
    this.localStorage.setItem(configuration.payUHubChangePasswordDismissed, isDismissed.toString())
  }

  get walkthroughDismissed (): boolean {
    return this.localStorage.getItem(configuration.walkthroughDismissed) === 'true'
  }

  set walkthroughDismissed (isDismissed: boolean) {
    this.localStorage.setItem(configuration.walkthroughDismissed, isDismissed.toString())
  }

  get selectedLanguage (): string {
    return this.localStorage.getItem(configuration.selectedLanguage) || ''
  }

  set selectedLanguage (selectedLanguage: string) {
    this.localStorage.setItem(configuration.selectedLanguage, selectedLanguage)
  }

  get activeMerchant (): string {
    return this.localStorage.getItem(configuration.activeMerchant) || ''
  }

  set activeMerchant (activeMerchant: string) {
    this.localStorage.setItem(configuration.activeMerchant, activeMerchant)
  }

  get sessionToken (): string {
    return this.localStorage.getItem(configuration.sessionToken) || ''
  }

  set sessionToken (sessionToken: string) {
    this.localStorage.setItem(configuration.sessionToken, sessionToken)
  }

  get merchantIds (): string[] {
    return this.localStorage.getItem(configuration.merchantIds)?.split(',') || []
  }

  set merchantIds (merchantIds: string[]) {
    this.localStorage.setItem(configuration.merchantIds, merchantIds.toString())
  }

  get activatedAccountDismissed (): boolean {
    return this.localStorage.getItem(configuration.activated_account_dismissed) === 'true'
  }

  set activatedAccountDismissed (isDismissed: boolean) {
    this.localStorage.setItem(configuration.activated_account_dismissed, isDismissed.toString())
  }

  get onboarding (): boolean {
    return this.localStorage.getItem(configuration.onboarding) === 'true'
  }

  set onboarding (onboarding: boolean) {
    this.localStorage.setItem(configuration.onboarding, onboarding.toString())
  }

  get accountInformation (): Partial<Managment.AccountResource> {
    try {
      return JSON.parse(this.localStorage.getItem(configuration.accountInformation)) as Managment.AccountResource || {}
    } catch (err) {
      console.error('failed to parse accountInformation')
      return {}
    }
  }

  set accountInformation (accountInformation: Partial<Managment.AccountResource>) {
    this.localStorage.setItem(configuration.accountInformation, JSON.stringify(accountInformation))
  }

  get user (): User {
    try {
      return JSON.parse(this.localStorage.getItem(configuration.user)) as User || {}
    } catch (err) {
      console.error('failed to parse user')
      return {}
    }
  }

  set user (user: User) {
    this.localStorage.setItem(configuration.user, JSON.stringify(user))
  }

  // eslint-disable-next-line class-methods-use-this
  get localStorage (): Storage {
    return storage as unknown as Storage
  }

  initializeUser () {
    const { localStorage } = this
    if (localStorage && !localStorage.getItem(configuration.user)) {
      this.user = {}
    }
  }

  // eslint-disable-next-line class-methods-use-this
  clearSession () {
    const keysToClear = [
      configuration.user,
      configuration.userEmail,
      configuration.accountInformation,
      configuration.merchantIds,
      configuration.activeMerchant,
      configuration.sessionToken
    ]
    for (const key of keysToClear) {
      localStorage.removeItem(key)
    }
  }

  saveSession (
    { merchantIds, userEmail, sessionToken, userId, primaryAccountId }: {
      merchantIds: string[];
      userEmail: string;
      sessionToken: string;
      userId: string;
      primaryAccountId: string;
    }
  ) {
    this.merchantIds = merchantIds
    this.activeMerchant = primaryAccountId
    this.sessionToken = sessionToken
    this.user = {
      id: userId,
      email: userEmail
    }
  }
}

export default new LocalStorageAccessor()
