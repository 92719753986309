import * as React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'

import Locations from './helpers/tools/locations'
import auth from './redux/auth/actions'
import * as authSelectors from './redux/auth/selectors'
import RouterSignedIn from './RouterSignedIn'
import RouterSignedOut from './RouterSignedOut'

interface PublicRoutesProps {
  history: History
}

const Router: React.FC<PublicRoutesProps> = ({ history }) => {
  const dispatch = useDispatch()
  const [isAuthorizedDispatched, setIsAuthorizedDispatched] = React.useState(false)
  try {
    if (history.location.search) {
      const qs = queryString.parse(window.location.search)
      const sessionBase64: string = qs.session as string
      if (sessionBase64) {
        const sessionObj = JSON.parse(atob(sessionBase64))
        const sessionToken = sessionObj.session_token
        delete qs.session

        const urlToNavigate = `${history.location.pathname}?${queryString.stringify(qs)}`
        history.replace(urlToNavigate)

        dispatch(auth.loginUserSSO({
          sessionToken,
          from: urlToNavigate
        }))
      }
    }
  } catch (error) {
    history.replace(Locations.login())
    console.error('failed to parse base64', error)
  }

  React.useEffect(() => {
    dispatch(auth.authorized())
    setIsAuthorizedDispatched(true)
  }, [dispatch])

  const isLoggedIn = useSelector(authSelectors.isLoggedIn)

  return (
    isAuthorizedDispatched &&
      (
      <ConnectedRouter history={history}>
        <Switch>
          { !isLoggedIn && <RouterSignedOut /> }
          { isLoggedIn && <RouterSignedIn /> }
        </Switch>
      </ConnectedRouter>
      )
  )
}

export default Router
