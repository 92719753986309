import React from 'react'
import { Props } from 'cc-provider-logo'
import classnames from 'classnames'

import { getNameToLogoMap } from '../../redux/providers/selectors'
import { ProviderLogo as ProviderLogoTypes } from './ProviderLogo.types'

import style from './ProviderLogo.scss'

const getProviderLogoSource = (providerName: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { store } = require('../../redux/store')
  const nameToLogo = getNameToLogoMap(store.getState())
  return nameToLogo[providerName]
}

const getProviderLogo: ProviderLogoTypes.GetProviderLogo = (providerName, height = '70px', width) => {
  const imgUrl = getProviderLogoSource(providerName)
  return imgUrl ? <img alt={providerName} src={imgUrl} height={height} width={width} /> : providerName
}

const ProviderLogo: React.FC<Props> = ({ providerName, height, width, className, ...rest }) => (
  <div className={classnames(style['logo-wrapper'], className)} {...rest}>
    {getProviderLogo(providerName, height, width)}
  </div>
)

export { getProviderLogo, ProviderLogo, getProviderLogoSource }
export default ProviderLogo

window.getProviderLogo = getProviderLogo
