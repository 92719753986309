import ccLocations, { BalanceLocksPage, FraudPlatformLocation } from 'cc-locations'

import * as ENVIRONMENTS from '../../../constants/environments'
import { getEnvironment } from '../../../redux/environment/selectors'
import { store } from '../../../redux/store'
import { EncodeStringToUrl } from '../../helpers'

type EnvLocation = ccLocations.EnvLocation
type EnvIdLocation = ccLocations.EnvIdLocation

const getPrefixByEnv = (env = getEnvironment(store.getState())): string => (env === ENVIRONMENTS.LIVE) ? '' : '/test'

const replaceArgsInUrl = function (url: string, args?: Record<string, string | number>): string {
  if (!args) { return url }
  if (!url) { return '' }

  const argNames = Object.getOwnPropertyNames(args)
  argNames.forEach((argName) => {
    let value = args[argName]
    if (typeof value === 'number') {
      value = value.toString()
    }
    url = value ? url.replace(`:${argName}`, value) : url
  })

  return url
}

const dashboard = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/dashboard`

const transactionsApproval = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/analytics/transactions_approval`

const instantRetryPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/analytics/instant_retry`

const paymentsSearch = ({ env, queryParam }: ccLocations.PaymentsSearch = {}): string => {
  const url = `${getPrefixByEnv(env)}/payments/search`
  if (queryParam) {
    return `${url}?${queryParam}`
  }
  return url
}

const businessUnits = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/configurations/bu`

const reports = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/reports/payments`

const createReport = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/reports/payments/create`

const settlementsReports = ({ env }: EnvLocation): string => `${getPrefixByEnv(env)}/reports/settlements`

const settlementsReportById = ({ id, env }: EnvIdLocation): string => {
  const url = `${getPrefixByEnv(env)}/reports/settlements/:id`
  return replaceArgsInUrl(url, { id })
}

const createSettlementsReport = ({ env, payoutId, providerName }: ccLocations.CreatSettlementReportPage): string => {
  const queryParams = []

  if (payoutId) {
    queryParams.push(`payout_id=${payoutId}`)
  }

  if (providerName) {
    queryParams.push(`provider_name=${providerName}`)
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''

  return `${getPrefixByEnv(env)}/reports/settlements/create${queryString}`
}

const displayReport = ({ env, templateName }: ccLocations.DisplayReport = {}): string => {
  const url = `${getPrefixByEnv(env)}/reports/payments/:templateName`
  return replaceArgsInUrl(url, {
    templateName: templateName ? EncodeStringToUrl(templateName) : undefined
  })
}

const downloadReport = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/reports/:reportId/download`

const operatorSettlementsReports = ({ env }: EnvLocation): string => `${getPrefixByEnv(env)}/treasury/settlements`

const operatorCreateSettlementsReport = ({ env, payoutId }: ccLocations.CreatSettlementReportPage): string => {
  const query = payoutId ? `?payout_id=${payoutId}` : ''
  return `${getPrefixByEnv(env)}/treasury/settlements/create${query}`
}

const operatorDownloadReport = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/settlements/:reportId/download`

const operatorSettlementsReportById = ({ id, env }: EnvIdLocation): string => {
  const url = `${getPrefixByEnv(env)}/treasury/settlements/:id`
  return replaceArgsInUrl(url, { id })
}

const providerConfigurationsList = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/configurations/providers`

const createProviderConfiguration = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/configurations/providers/:id/create`
  return replaceArgsInUrl(url, { id })
}

const providerConfigurationsById = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/configurations/providers/:id`
  return replaceArgsInUrl(url, { id })
}

const noPermission = (options?: EnvLocation): string => `${getPrefixByEnv(options?.env)}/no-permissions`

const decisionEngineList = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/decision_engine`
const decisionEngineItemById = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id`
  return replaceArgsInUrl(url, { id })
}

const businessUnitsBlock = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id/block`
  return replaceArgsInUrl(url, { id })
}
const businessUnitsBlockCreate = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id/block/create`
  return replaceArgsInUrl(url, { id })
}
const businessUnitsTdsAndRisk = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id/tds_and_risk`
  return replaceArgsInUrl(url, { id })
}
const businessUnitsTdsAndRiskCreate = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id/tds_and_risk/create`
  return replaceArgsInUrl(url, { id })
}
const businessUnitsRoute = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id/route`
  return replaceArgsInUrl(url, { id })
}
const businessUnitsFallback = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id/inst_retry`
  return replaceArgsInUrl(url, { id })
}
const businessUnitsFallbackCreate = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id/inst_retry/create`
  return replaceArgsInUrl(url, { id })
}

const businessUnitsFallbackRule = ({ env, ruleName }: ccLocations.BusinessUnitsFallbackRule = {}): string => {
  const url = `${getPrefixByEnv(env)}/decision_engine/:id/inst_retry/:ruleName`
  return replaceArgsInUrl(url, {
    ruleName: ruleName ? EncodeStringToUrl(ruleName) : undefined
  })
}

const webhooksConfigurationsList = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/configurations/webhooks`
const userManagement = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/account/team`

const sftpManagement = ({ env }: EnvLocation): string => `${getPrefixByEnv(env)}/configurations/sftp`

const userProfile = (options?: EnvLocation): string => `${getPrefixByEnv(options?.env)}/user`

const virtualTerminal = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/payments/new_transaction`

const congratulationsPage = (): string => '/activate_account/success'

const activationSettingsPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/account/settings`

const accountBalancePage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/balances`

const accountActivationsPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/account/activations`

const balanceTransactionsPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/balances/transactions`

const balancePayoutsHistoryPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/balances/payouts`

const balanceRollingReservedPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/balances/rolling_reserved`

const balanceInvoicesPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/balances/invoices`

const paymentLinksPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/payments/payment_links`

const createPaymentLinkPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/payments/payment_links/create`

const paymentLinkDetailsPage = ({ env, id }: ccLocations.PaymentLinkDetailsPage = {}): string => {
  const url = `${getPrefixByEnv(env)}/payments/payment_links/:id`
  return replaceArgsInUrl(url, { id })
}

const treasuryBalancesManagementPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/balances_management`

const treasuryBalanceTransactionsPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/balances_management/transactions`

const treasuryPayoutsHistoryPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/balances_management/payouts`

const treasuryBalanceCorrectionsPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/balances_management/corrections`

const treasuryRollingReservedPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/balances_management/rolling_reserved`

const treasuryInvoicesPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/balances_management/invoices`

const treasuryBalanceLocksPage = ({ env, pageMode }: BalanceLocksPage = {}): string => {
  const queryParams = pageMode ? [`page_mode=${pageMode}`] : []
  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  return `${getPrefixByEnv(env)}/treasury/balances_management/locks${queryString}`
}

const treasuryPayoutsManagementPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/payouts_management`

const treasuryAnalyticsPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/analytics`

const treasuryPacksOverviewPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/treasury/payouts_management/batches`

const treasuryPayoutsInPackPage = ({ env, id }: EnvIdLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/treasury/payouts_management/batches/:id`
  return replaceArgsInUrl(url, { id })
}

const fraudManagerPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/fraud_manager/platforms`

const fraudFlowPage = ({ platformName, env }: FraudPlatformLocation = {}): string => {
  const url = `${getPrefixByEnv(env)}/fraud_manager/platforms/:platformName`
  return replaceArgsInUrl(url, { platformName })
}

const editSettingsPage = ({ env }: EnvLocation = {}): string => `${getPrefixByEnv(env)}/account/settings/edit`

const login = (): string => '/login'

const signUp = (): string => '/signup'

const signupVerified = (): string => '/signup-verified'

const join = (): string => '/join'

const joinExistingUser = (): string => '/join/existing-user'

const joinNewUser = (): string => '/join/new-user'

const forgotPassword = (): string => '/reset'

export const unattachedUser = (): string => '/unattached-user'

export const root = (env?: 'live' | 'test'): string => `${getPrefixByEnv(env)}/`

export const isInNonProtectedArea = (path: string): boolean => {
  const nonProtectedAreas = [
    login(),
    signUp(),
    signupVerified(),
    join(),
    joinExistingUser(),
    joinNewUser(),
    root(ENVIRONMENTS.LIVE),
    root(ENVIRONMENTS.TEST),
    forgotPassword()
  ]
  return nonProtectedAreas.includes(path)
}

export const signedOutLocations = {
  signUp,
  signupVerified,
  forgotPassword,
  login,
  join,
  joinExistingUser,
  joinNewUser
}

const Locations: typeof ccLocations = {
  displayReport,
  createReport,
  reports,
  settlementsReports,
  operatorSettlementsReports,
  operatorCreateSettlementsReport,
  operatorDownloadReport,
  operatorSettlementsReportById,
  settlementsReportById,
  createSettlementsReport,
  downloadReport,
  businessUnits,
  dashboard,
  paymentsSearch,
  decisionEngineList,
  decisionEngineItemById,
  businessUnitsBlock,
  businessUnitsBlockCreate,
  businessUnitsTdsAndRisk,
  businessUnitsTdsAndRiskCreate,
  businessUnitsRoute,
  businessUnitsFallback,
  businessUnitsFallbackRule,
  businessUnitsFallbackCreate,
  providerConfigurationsList,
  createProviderConfiguration,
  providerConfigurationsById,
  webhooksConfigurationsList,
  userProfile,
  noPermission,
  userManagement,
  sftpManagement,
  transactionsApproval,
  instantRetryPage,
  root,
  unattachedUser,
  isInNonProtectedArea,
  activationSettingsPage,
  accountBalancePage,
  balanceTransactionsPage,
  balancePayoutsHistoryPage,
  balanceRollingReservedPage,
  balanceInvoicesPage,
  editSettingsPage,
  congratulationsPage,
  virtualTerminal,
  accountActivationsPage,
  paymentLinkDetailsPage,
  paymentLinksPage,
  createPaymentLinkPage,
  treasuryBalancesManagementPage,
  treasuryBalanceTransactionsPage,
  treasuryPayoutsHistoryPage,
  treasuryBalanceCorrectionsPage,
  treasuryRollingReservedPage,
  treasuryInvoicesPage,
  treasuryPayoutsManagementPage,
  treasuryPayoutsInPackPage,
  treasuryPacksOverviewPage,
  treasuryBalanceLocksPage,
  treasuryAnalyticsPage,
  fraudManagerPage,
  fraudFlowPage,
  ...signedOutLocations
}

export default Locations
