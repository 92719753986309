import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects'

import AccountsApi from '../../api/AccountsApi'
import Errors from '../../constants/Errors'
import localize from '../../features/Localize/localize'
import localStorageAccessor from '../../helpers/localStorageAccessor/localStorageAccessor'
import { startMicroApps } from '../../initMicroApps'
import { fetchAccountFeatureToggles } from '../featureToggles/actions'
import actions, { types } from './actions'
import messageDispatcher from '../../features/Messages/redux/messageDispatcher'

export function * fetchAccountSaga (action: any): IterableIterator<CallEffect | PutEffect> {
  try {
    const account = yield call(AccountsApi.fetchAccount, action.accountId)
    yield put(actions.accountFetchSuccess(account))
    localStorageAccessor.accountInformation = account
    yield put(fetchAccountFeatureToggles())
    yield call(startMicroApps)
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export function * fetchConnectedAccountsSaga (action: any): IterableIterator<CallEffect | PutEffect> {
  try {
    const connectedAccounts = yield call(AccountsApi.fetchConnectedAccounts, action.accountId)
    yield put(actions.fetchConnectedAccountsSuccess(connectedAccounts))
  } catch (err) {
    messageDispatcher.addError({
      text: Errors.defaultError(localize.translate),
      err
    })
  }
}

export default function * accountSaga () {
  yield takeEvery(types.FETCH_ACCOUNT, fetchAccountSaga)
  yield takeEvery(types.FETCH_CONNECTED_ACCOUNTS, fetchConnectedAccountsSaga)
}
