import { Action } from 'redux'

import localStorageAccessor from '../../../../../../helpers/localStorageAccessor/localStorageAccessor'

const FAKE_ACTION_KEY = 'DISMISS_ACTIVATED_ACCOUNT_MESSAGE'

interface DismissMessageAction extends Action {
  onboard: boolean
}

export const dismissMessage = (): DismissMessageAction => {
  localStorageAccessor.activatedAccountDismissed = true
  return {
    type: FAKE_ACTION_KEY,
    onboard: true
  }
}
