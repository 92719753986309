import { TranslationFunction } from '@zooz/react-localize'
import { app_logged_in as LoggedInAppTranslations } from '@zooz/translation-values'

export default {
  message: {
    header: (t: TranslationFunction) => t(LoggedInAppTranslations.unsavedChanges_msg_header.key, {
      fallback: LoggedInAppTranslations.unsavedChanges_msg_header.fallback
    }),
    content: (t: TranslationFunction) => t(LoggedInAppTranslations.unsavedChanges_msg_content.key, {
      fallback: LoggedInAppTranslations.unsavedChanges_msg_content.fallback
    })
  },
  buttons: {
    leave: (t: TranslationFunction) => t(LoggedInAppTranslations.unsavedChanges_btn_leave.key, {
      fallback: LoggedInAppTranslations.unsavedChanges_btn_leave.fallback
    }),
    stay: (t: TranslationFunction) => t(LoggedInAppTranslations.unsavedChanges_btn_stay.key, {
      fallback: LoggedInAppTranslations.unsavedChanges_btn_stay.fallback
    })
  },
  appCrash: {
    sorry: (t: TranslationFunction) => t(LoggedInAppTranslations.appCrash_msg_sorry.key, {
      fallback: LoggedInAppTranslations.appCrash_msg_sorry.fallback
    }),
    teamNotified: (t: TranslationFunction) => t(LoggedInAppTranslations.appCrash_msg_teamNotified.key, {
      fallback: LoggedInAppTranslations.appCrash_msg_teamNotified.fallback
    })
  }
}
