import React, { Component } from 'react'
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import classnames from 'classnames'
import { FeaturesToggle } from '@payu/paymentsos-types'
import { connect } from 'react-redux'
import { matchPath } from 'react-router-dom'

import { isAccountActivated } from '../../redux/activations/selectors'
import appActions from '../../redux/app/actions'
import { getCollapsed, getOpenDrawer } from '../../redux/app/selectors'
import { getEnvironment } from '../../redux/environment/selectors'
import { getAccountLevelFeatureToggles } from '../../redux/featureToggles/selectors'
import { isPermissionsLoaded, permissionsList } from '../../redux/roles/selector'
import { PortalState } from '../../redux/store'
import CustomScrollbar from '../../shared-components/CustomScrollbar'
import { isDisplayingHint, getCurrentStepIndex } from '../App/components/Walkthrough/redux/selectors'
import registerStep from '../App/components/Walkthrough/register'
import localize from '../Localize/localize'
import Logo from './components/Logo'
import MenuItem from './components/MenuItem'
import Submenu from './components/Submenu'
import menus, { MenuEntry } from './menus'
import MenusMeasurer from './MenusMeasurer'

import style from './style.scss'

const { toggleOpenDrawer } = appActions

type environment = 'test' | 'live'

const WALKTHROUGH_SPACINGS = {
  vertical: 7,
  horizontal: 10
}

interface SidebarStateProps {
  environment?: environment;
  collapsed?: boolean;
  openDrawer?: boolean;
  permissionsLoaded?: boolean;
  isAccountActivated?: boolean;
  isDisplayingHint?: boolean;
  stepIndex?: number;
  permissionsList?: string[];
  accountLevelToggles?: FeaturesToggle.ToggleByAccountResponse[];
}

interface SidebarDispatchProps {
  toggleOpenDrawer: () => void;
}

interface SidebarOwnProps extends WithLocalizeProps {
  path?: string;
}

export type SidebarProps = SidebarOwnProps & SidebarStateProps & SidebarDispatchProps

interface SidebarState {
  width: number
}

interface MenuHints {
  [x: string]: {
    description: string,
    targetRef: React.RefObject<HTMLLIElement>,
    position: number,
    title: string,
    parent?: React.RefObject<HTMLLIElement>
  }
}

const DEFAULT_SIDEBAR_WIDTH = 240

export class Sidebar extends Component<SidebarProps, SidebarState> {
  menuHints: MenuHints = {}
  scrollTimer: ReturnType<typeof setTimeout>

  constructor (props: SidebarProps) {
    super(props)
    this.state = {
      width: DEFAULT_SIDEBAR_WIDTH
    }
  }

  componentDidMount (): void {
    this.registerHints()
    localize.on('languageChange', () => {
      this.setState({ width: DEFAULT_SIDEBAR_WIDTH })
    })
  }

  componentDidUpdate (): void {
    this.scrollToHint()
    this.registerHints()
  }

  componentWillUnmount (): void {
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer)
    }
  }

  handleScroll = (): void => {
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer)
    }
    this.scrollTimer = setTimeout(this.registerHints, 500)
  }

  registerHints = (): void => {
    Object.values(this.menuHints).forEach((hint) => {
      const currentRef = hint.targetRef.current
      const reactClientRect: Partial<DOMRect> = currentRef ? currentRef.getBoundingClientRect() : {}
      let { top: y, height } = reactClientRect
      const { x, width } = reactClientRect
      let tooltipOffsetY

      if (hint.parent) {
        const parentRef = hint.parent.current
        const parentReactClientRect: Partial<DOMRect> = parentRef ? parentRef.getBoundingClientRect() : {}
        const { top: parentY, height: parentHeight } = parentReactClientRect
        height = height + y - parentY
        y = parentY
        tooltipOffsetY = (parentHeight / 2) - (height / 2) - 20
      }

      registerStep({
        ...hint,
        x: x + WALKTHROUGH_SPACINGS.horizontal,
        y: y - WALKTHROUGH_SPACINGS.vertical,
        width: width - WALKTHROUGH_SPACINGS.horizontal * 2,
        height: height + WALKTHROUGH_SPACINGS.vertical * 2,
        tooltipOffsets: {
          x: 15,
          y: tooltipOffsetY
        }
      })
    })
  }

  getMenuRef = (menuEntry: MenuEntry): React.RefObject<HTMLLIElement> => {
    let menuRef: React.RefObject<HTMLLIElement>
    if (this.menuHints[menuEntry.text]) {
      menuRef = this.menuHints[menuEntry.text].targetRef
    } else {
      menuRef = React.createRef()
    }
    return menuRef
  }

  scrollToHint (): void {
    const { stepIndex, isDisplayingHint } = this.props
    const hint = Object.values(this.menuHints).find(value => value.position === stepIndex)
    const targetRef = hint ? hint.targetRef : null
    if (isDisplayingHint && hint && targetRef && targetRef.current) {
      targetRef.current.scrollIntoView(!!hint.parent)
    }
  }

  addHint (
    menuEntry: MenuEntry,
    disabled: boolean,
    parent?: React.RefObject<HTMLLIElement>
  ): React.RefObject<HTMLLIElement> {
    const menuRef = this.getMenuRef(menuEntry)
    if (menuEntry.hint && !disabled) {
      this.menuHints[menuEntry.text] = {
        description: menuEntry.hint.text,
        targetRef: menuRef,
        position: menuEntry.hint.position,
        title: menuEntry.hint.title,
        parent
      }
    }
    return menuRef
  }

  isActivePath (menuEntry: MenuEntry): boolean {
    const { path, environment } = this.props
    return !!matchPath(path, { path: menuEntry.link(environment) })
  }

  render () {
    const {
      t,
      collapsed,
      openDrawer,
      toggleOpenDrawer,
      environment,
      permissionsLoaded,
      isAccountActivated,
      isDisplayingHint
    } = this.props
    const isCollapsed = !isDisplayingHint && collapsed && !openDrawer

    const onMouseEnter = () => {
      if (openDrawer === false) {
        toggleOpenDrawer()
      }
    }
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer()
      }
    }

    const classes = classnames(style.sidebar, {
      [style['sidebar--collapsed']]: isCollapsed
    })
    return (
      <>
        <div className={style.sidebarStub} />
        <div
          className={classes}
          data-test={isCollapsed ? 'app__sidebar--collapsed' : 'app__sidebar--open'}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{
            ['--sidebar-width' as any]: `${this.state.width}px`
          }}
        >
          <Logo collapsed={isCollapsed} />
          {permissionsLoaded && (
            <CustomScrollbar component='nav' onScroll={this.handleScroll} className={style.sidebar__menus}>
              <MenusMeasurer
                onResize={(width) => {
                  if (width > this.state.width) {
                    this.setState({ width })
                  }
                }}
              >
                {
                menus.map((menuEntry) => {
                  const disabledMenu = !!menuEntry.disabled && menuEntry.disabled({ environment, isAccountActivated })
                  const menuRef = this.addHint(menuEntry, disabledMenu)
                  const menuText = t(menuEntry.translationKey, { fallback: menuEntry.text })

                  if (menuEntry.children) {
                    const isChildActive = !!menuEntry.children.find(subMenuEntry => this.isActivePath(subMenuEntry))
                    const isChildActiveShown = !isCollapsed && isChildActive
                    const isChildWithHint = isDisplayingHint &&
                      !!menuEntry.children.find(subMenuEntry => subMenuEntry.hint && !isCollapsed)
                    return (
                      <Submenu
                        isSelected={isCollapsed && isChildActive}
                        ref={menuRef}
                        isCollapsed={isCollapsed}
                        expanded={isChildWithHint || isChildActiveShown}
                        locked={isCollapsed}
                        key={menuEntry.text}
                        text={menuText}
                        icon={menuEntry.icon}
                      >
                        {
                          menuEntry.children.map((subMenuEntry) => {
                            const disabledSubMenu = !!subMenuEntry.disabled &&
                              subMenuEntry.disabled?.({ environment, isAccountActivated })
                            const subMenuRef = this.addHint(subMenuEntry, disabledSubMenu, menuRef)

                            return (
                              <MenuItem
                                key={subMenuEntry.text.toLowerCase()}
                                ref={subMenuRef}
                                {...subMenuEntry}
                                isCollapsed={isCollapsed}
                                secondNavigation
                                isActivePath={this.isActivePath(subMenuEntry) && !isCollapsed}
                                link={subMenuEntry.link(environment)}
                                disabled={disabledSubMenu}
                              />
                            )
                          })
                        }
                      </Submenu>
                    )
                  }
                  return (
                    <MenuItem
                      key={menuEntry.text.toLowerCase()}
                      isCollapsed={isCollapsed}
                      ref={menuRef}
                      {...menuEntry}
                      link={menuEntry.link(environment)}
                      isActivePath={this.isActivePath(menuEntry)}
                      disabled={disabledMenu}
                    />
                  )
                })
              }
              </MenusMeasurer>
            </CustomScrollbar>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: PortalState): SidebarStateProps => ({
  environment: getEnvironment(state),
  collapsed: getCollapsed(state),
  openDrawer: getOpenDrawer(state),
  permissionsLoaded: isPermissionsLoaded(state),
  isAccountActivated: isAccountActivated(state),
  isDisplayingHint: isDisplayingHint(state),
  stepIndex: getCurrentStepIndex(state),
  permissionsList: permissionsList(state),
  accountLevelToggles: getAccountLevelFeatureToggles(state)
})

const mapDispatchToProps = {
  toggleOpenDrawer
}

export default connect<SidebarStateProps, SidebarDispatchProps, SidebarOwnProps, PortalState>(
  mapStateToProps, mapDispatchToProps
)(withLocalize(Sidebar))
