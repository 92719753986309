// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignupVerifiedPage__verifySignupForm___JYUxx{display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/features/UnProtected/SignupVerifiedPage/SignupVerifiedPage.scss"],"names":[],"mappings":"AAEA,8CACE,YAAA,CACA,qBAAA","sourcesContent":["@import '~@zooz/generic-ui-components/shared-styles/spacings';\n\n.verifySignupForm {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacing_0": `0`,
	"spacing_4": `4px`,
	"spacing_8": `8px`,
	"spacing_12": `12px`,
	"spacing_20": `20px`,
	"spacing_32": `32px`,
	"spacing_52": `52px`,
	"spacing_84": `84px`,
	"spacing_136": `136px`,
	"spacing_220": `220px`,
	"spacing_356": `356px`,
	"spacing_576": `576px`,
	"spacing_932": `932px`,
	"spacing_1508": `1508px`,
	"spacing_2440": `2440px`,
	"verifySignupForm": `SignupVerifiedPage__verifySignupForm___JYUxx`
};
export default ___CSS_LOADER_EXPORT___;
