import { FeaturesToggle } from '@payu/paymentsos-types'
import { Action } from 'redux'

export const ActionTypes = {
  FETCH_ACCOUNT_FEATURE_TOGGLES: 'featureToggles/fetch account level',
  FETCH_ACCOUNT_FEATURE_TOGGLES_SUCCESS: 'featureToggles/fetch account level success'
}

export const fetchAccountFeatureToggles = (): Action<typeof ActionTypes.FETCH_ACCOUNT_FEATURE_TOGGLES> => ({
  type: ActionTypes.FETCH_ACCOUNT_FEATURE_TOGGLES
})

interface FetchAccountFeatureTogglesSuccessAction
  extends Action<typeof ActionTypes.FETCH_ACCOUNT_FEATURE_TOGGLES_SUCCESS> {
  payload: {
    featureToggles: FeaturesToggle.ToggleByAccountResponse[]
  }
}
export const fetchAccountFeatureTogglesSuccess = (
  featureToggles: FeaturesToggle.ToggleByAccountResponse[]
): FetchAccountFeatureTogglesSuccessAction => ({
  type: ActionTypes.FETCH_ACCOUNT_FEATURE_TOGGLES_SUCCESS,
  payload: {
    featureToggles
  }
})
