import { Currencies } from '@payu/paymentsos-types'

import ApiBase from './ApiBase'

class CurrenciesApi extends ApiBase {
  fetchCurrencies = async (): Promise<Currencies.Currency[]> => {
    const response = await this.httpClient.get(
      '/currencies',
      undefined,
      this.defaultConfig
    )
    return response.data
  }
}

export default new CurrenciesApi()
