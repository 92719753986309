import { Dispatch } from 'redux'

import UsersApi from '../../../../api/UsersApi'
import messageDispatcher from '../../../Messages/redux/messageDispatcher'

export const JOIN_PAGE_HIDE_ERROR = 'JOIN_PAGE_HIDE_ERROR'
export const IS_USER_EXISTS = 'IS_USER_EXISTS'
export const IS_USER_EXISTS_ERROR = 'USER_EXISTS_ERROR'

export const handleHideSubmitError = () => ({ type: JOIN_PAGE_HIDE_ERROR })
export const resetEmail = () => ({ type: JOIN_PAGE_HIDE_ERROR })

export const verifyUserResponseAction = (payload: any) => ({ type: IS_USER_EXISTS, payload })
export const verifyUserErrorAction = (payload: any) => ({ type: IS_USER_EXISTS_ERROR, payload })
export const isUserExists = (token: string, accountId: string, invitationId: string) => async (dispatch: Dispatch) => {
  const headers = {
    Authorization: `Bearer ${token}`
  }
  try {
    const userExists = await UsersApi.verifyUser(headers, accountId, invitationId)
    return dispatch(verifyUserResponseAction(userExists))
  } catch (error) {
    messageDispatcher.addError({ text: 'An error has occurred', err: error })
    return dispatch(verifyUserErrorAction(error))
  }
}
