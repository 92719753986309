import ErrorBoundary from './features/App/components/ErrorBoundary'
import { addContextHooks } from './features/App/HOC/contextHooks'
import withCrumbs from './features/App/HOC/withCrumbs'
import DemoUtil from './helpers/demo/DemoUtil'
import LeaveUnsavedPageMessage from './shared-components/LeaveUnsavedPageMessage'
import messageDispatcher from './features/Messages/redux/messageDispatcher'

window.zooz_hoc = {
  context: addContextHooks,
  crumbs: withCrumbs
}

window.zooz_message = messageDispatcher

window.zooz_demo = Object.freeze({
  isDemo: DemoUtil.isDemo
})

window.zooz_components = Object.freeze({
  LeaveUnsavedPageMessage,
  ErrorBoundary
})
