/* eslint-disable camelcase */
import { Managment, Register, Users } from '@payu/paymentsos-types'
import { IUsersApi, PatchUser, SignUpVerifyRequestInfo } from 'cc-apis'

import ApiBase from './ApiBase'

class UsersApi extends ApiBase implements IUsersApi {
  get resourcePath (): string {
    return `/accounts/${this.currentUser.accountId}/users`
  }

  signUp = async (signUpRequestInfo: Register.PostSignupBody): Promise<void> => {
    await this.httpClient.post('/signup', signUpRequestInfo, {}, this.defaultConfig)
  }

  signUpVerify = async ({ token, password }: SignUpVerifyRequestInfo): Promise<Register.SignupVerifiedResource> => {
    const config = this.defaultConfig
    config.headers.Authorization = `Bearer ${token}`
    const signUpVerifyRequestBody: Register.PostSignupVerifiedBody = { user_password: password }
    const { data } = await this.httpClient.post<Register.SignupVerifiedResource>(
      '/signup-verified',
      signUpVerifyRequestBody,
      {},
      config
    )
    return data
  }

  join = async (accountId: string, password: string, invitationToken: string): Promise<Register.RegisterResource> => {
    const headers = {
      headers: {
        Authorization: `Bearer ${invitationToken}`,
        'api-version': '1.1.0'
      }
    }
    const response = await this.httpClient.post(`/accounts/${accountId}/users`, { password }, {}, headers)
    return response.data
  }

  joinExistingUser = async (accountId: string, password: string, invitationToken: string)
    : Promise<unknown> => {
    const headers = {
      headers: {
        Authorization: `Bearer ${invitationToken}`,
        'api-version': '1.1.0'
      }
    }
    const response = await this.httpClient.post(`/accounts/${accountId}/users/join`, { password }, {}, headers)
    return response.data
  }

  resetPassword = async (email: string): Promise<void> => {
    const response = await this.httpClient.post('/users/reset_password', { email }, {}, {})
    return response.data
  }

  changePassword = async (password: string, token: string): Promise<void> => {
    const headers = {
      headers: {
        'api-version': '1.1.0',
        Authorization: `Bearer ${token}`,
        'x-payments-os-env': this.currentEnvironment
      }
    }

    const response = await this.httpClient.post('/users/change_password', { password }, {}, headers)
    return response.data
  }

  fetchUser = async ({
    id = this.currentUser.userId,
    expand
  }: {
    id?: string,
    expand?: string
  },
  isMasquerading: boolean): Promise<Managment.UserResource> => {
    const accountId = isMasquerading ? '*' : this.currentUser.accountId
    const params = { expand }

    const { data } = await this.httpClient.get(
      `/accounts/${accountId}/users/${id}`,
      params,
      this.defaultConfig
    )
    return data
  }

  getProfile = async (): Promise<Managment.UserResource> => {
    const response = await this.httpClient.get(
      `${this.resourcePath}/${this.currentUser.userId}`,
      {},
      this.defaultConfig
    )
    return response.data
  }

  getUserPrivileges = async ({ expand }: { expand?: string }): Promise<Users.UserPrivileges> => {
    const response = await this.httpClient.get(
        `/accounts/${this.accountId}/users/${this.currentUser.userId}/privileges`,
        { expand },
        this.defaultConfig
    )
    return response.data
  }

  // eslint-disable-next-line camelcase
  updateProfile = async ({ first_name, last_name }: { first_name?: string, last_name?: string }): Promise<void> => {
    const profile = {
      // eslint-disable-next-line camelcase
      first_name,
      // eslint-disable-next-line camelcase
      last_name
    }
    const response = await this.httpClient.put(`${this.resourcePath}/${this.currentUser.userId}`, profile, {}, this.defaultConfig)

    return response.data
  }

  updatePassword = async ({
    currentPassword,
    newPassword
  }:
  {
    currentPassword: string,
    newPassword: string
  }): Promise<void> => {
    const passwords = {
      // eslint-disable-next-line camelcase
      current_password: currentPassword,
      // eslint-disable-next-line camelcase
      new_password: newPassword
    }
    const response = await this.httpClient.put(`${this.resourcePath}/${this.currentUser.userId}/password`, passwords, {}, this.defaultConfig)

    return response.data
  }

  fetchUsers = async ({
    expand,
    limit,
    page
  }: {
    expand?: string,
    limit?: number,
    page?: number
  } = {}): Promise<Managment.UserResource[]> => {
    const { accountId } = this
    const params = { expand, limit, page }

    const { data } = await this.httpClient.get(`/accounts/${accountId}/users`, params, this.defaultConfig)
    return data
  }

  // eslint-disable-next-line camelcase
  patchUser = async ({ id, first_name, last_name, account_roles, account_apps }: PatchUser = {}): Promise<void> => {
    const { accountId } = this

    const url = `/accounts/${accountId}/users/${id}`
    // eslint-disable-next-line camelcase
    const body = { first_name, last_name, account_roles, account_apps }

    const { data } = await this.httpClient.put(url, body, {}, this.defaultConfig)
    return data
  }

  removeUser = async ({ id }: { id: string }): Promise<void> => {
    const { accountId } = this

    const url = `/accounts/${accountId}/users/${id}/permissions`

    const { data } = await this.httpClient.delete(url, {}, this.defaultConfig)
    return data
  }

  permittedAccounts = async (userId: string, sessionToken: string, accountId: string): Promise<string[]> => {
    const queryParams = {
      limit: '100'
    }
    const config = {
      headers: {
        'api-version': '1.1.0',
        Authorization: `Bearer ${sessionToken}`
      }
    }
    const response = await this.httpClient.get(`accounts/${accountId}/users/${userId}/permitted_accounts`, queryParams, config)
    return response.data ? response.data.accounts : []
  }

  verifyUser = async (headers: Record<string, string> = {}, accountId: string, invitationId: string): Promise<void> => {
    const config = {
      headers: {
        ...headers,
        'api-version': '1.1.0'
      }
    }

    const response = await this.httpClient.get(`accounts/${accountId}/invitations/${invitationId}/verify`, {}, config)
    return response.data
  }
}

export default new UsersApi()
