import produce from 'immer'

import localStorageAccessor from '../../../helpers/localStorageAccessor/localStorageAccessor'
import {
  SET_ONBOARDING_ACTION_TYPE
} from './actions'

export interface State {
  isOnboarding: boolean
}

const initialState = {
  isOnboarding: localStorageAccessor.onboarding = true
}

const onBoardReducer = produce((state: State, action) => {
  switch (action.type) {
    case SET_ONBOARDING_ACTION_TYPE: {
      localStorageAccessor.onboarding = action.onboard
      state.isOnboarding = action.onboard
      break
    }
  }
}, initialState)

export default onBoardReducer
