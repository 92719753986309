export const REQUEST_EMAIL_CHANGE = 'REQUEST_EMAIL_CHANGE'
export const REQUEST_FOCUS_CHANGE = 'REQUEST_FOCUS_CHANGE'
export const REQUEST_ERROR = 'REQUEST_PASSWORD_ERROR'
export const REQUEST_LOADING = 'REQUEST_PASSWORD_LOADING'

export const RESET_INPUT_CHANGE = 'RESET_PASSWORD_INPUT_CHANGE'
export const RESET_FOCUS_CHANGE = 'RESET_PASSWORD_FOCUS_CHANGE'
export const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL'
export const RESET_ERROR = 'REQUEST_PASSWORD_ERROR'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const RESET_LOADING = 'CHANGE_PASSWORD_LOADING'

export const requestEmailChange = email => ({ type: REQUEST_EMAIL_CHANGE, email })
export const requestFocusChange = (focusId, focus) => ({ type: REQUEST_FOCUS_CHANGE, focusId, focus })
export const requestError = error => ({ type: REQUEST_ERROR, error })
export const requestLoading = loading => ({ type: REQUEST_LOADING, loading })

export const resetInputChange = (key, value) => ({ type: RESET_INPUT_CHANGE, key, value })
export const resetFocusChange = (focusId, focus) => ({ type: RESET_FOCUS_CHANGE, focusId, focus })
export const resetPasswordEmail = () => ({ type: RESET_PASSWORD_EMAIL })
export const resetError = error => ({ type: RESET_ERROR, error })
export const changePassword = token => ({ type: CHANGE_PASSWORD, token })
export const resetLoading = loading => ({ type: RESET_LOADING, loading })
