import * as React from 'react'
import { AllScreenNamesType, WithLoadTranslationsOptions } from 'cc-localization'
import { Localize } from '@zooz/localize'
import { useDispatch, useSelector } from 'react-redux'

import LargeSpinner from '../../../../shared-components/LargeSpinner'
import localize from '../../localize'
import { getLoadingStatusByScreenName } from '../../redux/selectors'
import { actions } from '../../redux/slice'

// eslint-disable-next-line @typescript-eslint/ban-types
export default function withLoadTranslations<P extends React.PropsWithoutRef<{}>> (
  options: WithLoadTranslationsOptions
): (Component: React.ComponentType<P>) => React.ComponentType<P> {
  const screensList: AllScreenNamesType[] = typeof options.screenName === 'string' ? [options.screenName] : options.screenName
  // it either takes a provided localize instance or the control center's localize instance
  const localizeInstance = options.localizeInstance || localize
  return function enhanceWithLoadTranslations (Component: React.ComponentType<P>): React.FC<P> {
    const EnhancedComponent: React.FC<P> = (props: P) => {
      const dispatch = useDispatch()
      let isLoading = false
      for (const screen of screensList) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if (useSelector(getLoadingStatusByScreenName[screen])) {
          isLoading = true
        }
      }
      const isInit = localizeInstance.isInitialized()

      React.useEffect(() => {
        if (isInit) {
          dispatch(actions.loadScreenTranslations(screensList))
        } else if (localizeInstance instanceof Localize) {
          localizeInstance.once('init', () => {
            dispatch(actions.loadScreenTranslations(screensList))
          })
        }

        return () => {
          dispatch(actions.screenUnMounted(screensList))
        }
      }, [dispatch])

      return (
        (isLoading || !isInit) && options.withSpinner
          ? (
            <LargeSpinner />
            )
          : <Component {...props} />
      )
    }

    EnhancedComponent.displayName = `WithTranslationLoad${Component.displayName || Component.name || ''}`

    return EnhancedComponent
  }
}
