import { addError, addMessage, addSuccess, AddError, AddMessage, MessageTypes } from './actions'
import { store } from '../../../redux/store'

export default {
  addError: (params: string | AddError): void => {
    if (typeof params === 'string') {
      store.dispatch(addError({ text: params }))
    } else {
      store.dispatch(addError(params))
    }
  },
  addSuccess: (text: string, options?: Omit<AddMessage, 'text' | 'type'>): void => {
    store.dispatch(addSuccess(text, options))
  },
  addInfo: (text: string): void => {
    store.dispatch(addMessage({ text, type: MessageTypes.INFO }))
  }
}
