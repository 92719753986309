import { matchPath } from 'react-router-dom'
import { createSelector } from 'reselect'
import ccLocations from 'cc-locations'

import * as ENVIRONMENTS from '../../constants/environments'
import Locations from '../../helpers/tools/locations/index'
import { getLocation } from '../location/selectors'

const liveEnv: ccLocations.EnvLocation = { env: ENVIRONMENTS.LIVE }

export const getCrossEnvPageLocations = (): string[] => [
  Locations.userProfile(liveEnv),
  Locations.userManagement(liveEnv),
  Locations.congratulationsPage(),
  Locations.editSettingsPage(liveEnv),
  Locations.activationSettingsPage(liveEnv)
]

export const isCrossEnv = createSelector(getLocation,
  (location) => {
    const path = (location && location.pathname) ? location.pathname.replace('/test', '') : undefined
    return !!getCrossEnvPageLocations().find(location => matchPath(path, { path: location }))
  })
