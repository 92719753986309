/* eslint-disable camelcase */
import * as React from 'react'
import { useLocalize } from '@zooz/react-localize'
import { PasswordInput } from '@zooz/generic-ui-components'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { RouteProps } from 'react-router'

import errorMessages from '../../../constants/Errors'
import { safeDecode } from '../../../helpers/jwtUtils'
import validatePassword, { REQUIRED_PASSWORD_STRENGTH_SCORE } from '../../../helpers/passwordValidation'
import Locations from '../../../helpers/tools/locations'
import { withLoadTranslations } from '../../Localize/HOCs'
import { ScreenNames } from '../../Localize/screens'
import {
  Page,
  FormError,
  FormItem,
  InputField,
  ButtonSubmit as Button,
  FormButtonWrapper
} from '../shared'
import InvalidTokenMessage from './InvalidTokenMessage'
import language from './language'
import { verifySignUp } from './redux/actions'
import { getIsVerifyingSignUp, getVerifySignUpErrorMessage } from './redux/selectors'

import styles from './SignupVerifiedPage.scss'

const { useEffect, useState, useMemo, useRef } = React

interface SignupVerifiedTokenPayload {
  user_email?: string;
}

const SignupVerifiedPage: React.FC<RouteProps> = ({ location }) => {
  const { t } = useLocalize()
  const search = location?.search ?? ''
  const { token } = queryString.parse(search)
  const memoizedTokenPayload = useMemo((): SignupVerifiedTokenPayload => {
    if (token) {
      const { user_email } = safeDecode(token as string).payload ?? {}
      return { user_email }
    }
    return {}
  }, [token])

  const isInvalidToken = !memoizedTokenPayload.user_email

  /** App State **/
  const dispatch = useDispatch()
  const isVerifying = useSelector(getIsVerifyingSignUp)
  const signUpVerifyErrorMessage = useSelector(getVerifySignUpErrorMessage)

  /** Component State **/
  // Password
  const [passwordValue, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [passwordStrength, setPasswordStrength] = useState(0)
  // Confirmed password
  const [confirmedPasswordValue, setConfirmedPassword] = useState('')
  const [confirmedPasswordError, setConfirmedPasswordError] = useState('')

  // handle submit
  function handleSubmit (event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    let isValid = !passwordError && !confirmedPasswordError
    if (passwordValue.length === 0) {
      setPasswordError(errorMessages.password.required(t))
      isValid = false
    }
    if (confirmedPasswordValue.length === 0) {
      setConfirmedPasswordError(errorMessages.confirmPassword.required(t))
      isValid = false
    }
    if (isValid) {
      dispatch(verifySignUp({
        password: passwordValue,
        token: token as string
      }))
    }
  }

  /** handle input change **/

  function handlePasswordChange (password: string): void {
    setPassword(password)
    if (confirmedPasswordValue.length > 0 && confirmedPasswordValue !== password) {
      setConfirmedPasswordError(errorMessages.confirmPassword.invalid(t))
    } else if (confirmedPasswordValue.length > 0 && confirmedPasswordValue === password) {
      setConfirmedPasswordError('')
    }
  }

  function handleConfirmedPasswordChange (confirmedPassword: string): void {
    setConfirmedPassword(confirmedPassword)
    if (confirmedPassword.length > 0 && confirmedPassword !== passwordValue) {
      setConfirmedPasswordError(errorMessages.confirmPassword.invalid(t))
    } else {
      setConfirmedPasswordError('')
    }
  }

  /** Validate on Blur **/

  // password validation throttling
  const passThrottleTimeoutRef = useRef(0)
  useEffect(() => {
    clearTimeout(passThrottleTimeoutRef.current)
    passThrottleTimeoutRef.current = window.setTimeout(() => {
      const { strengthScore, message, valid } = validatePassword(passwordValue)
      setPasswordStrength(strengthScore)
      if (passwordValue.length > 0 && !valid) {
        setPasswordError(message(t))
      } else {
        setPasswordError('')
      }
    }, 250)
  }, [passwordValue])

  return (
    <Page
      title={isInvalidToken ? language.invalidTokenTitle(t) : language.validTitle(t)}
      id='sign-up-verified'
      links={
        isInvalidToken
          ? []
          : [
              {
                to: Locations.login(),
                id: 'back-login',
                text: language.link.login(t)
              }
            ]
      }
    >
      {
        isInvalidToken
          ? <InvalidTokenMessage />
          : (
            <form id='signup-verified-form' className={styles.verifySignupForm} onSubmit={handleSubmit}>
              <InputField
                label={language.verifySignUpForm.email(t)}
                isEdit={false}
                value={memoizedTokenPayload.user_email}
                id='register-email'
              />
              <FormItem>
                <PasswordInput
                  passwordValue={passwordValue}
                  errorText={passwordError}
                  passwordTitle={language.verifySignUpForm.password(t)}
                  enableStrengthBar
                  enableShowHide
                  id='register-password'
                  onChange={handlePasswordChange}
                  strengthScore={passwordStrength}
                  strengthBoundaries={{
                    weakUpperBound: REQUIRED_PASSWORD_STRENGTH_SCORE,
                    mediumUpperBound: REQUIRED_PASSWORD_STRENGTH_SCORE + 1
                  }}
                  autoComplete='new-password'
                />
              </FormItem>
              <PasswordInput
                onChange={handleConfirmedPasswordChange}
                passwordValue={confirmedPasswordValue}
                errorText={confirmedPasswordError}
                passwordTitle={language.verifySignUpForm.confirmPassword(t)}
                enableStrengthBar={false}
                enableShowHide
                id='register-confirmed-password'
                autoComplete='new-password'
              />
              <FormButtonWrapper>
                <FormError isVisible={!!signUpVerifyErrorMessage}>
                  {signUpVerifyErrorMessage}
                </FormError>
                <Button loading={isVerifying}>{language.verifySignUpForm.button(t)}</Button>
              </FormButtonWrapper>
            </form>
            )
      }

    </Page>
  )
}

export default withLoadTranslations({ screenName: ScreenNames.SignUp })(SignupVerifiedPage)
