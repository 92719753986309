// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Walkthrough is not typed correctly but not really in use so for now not putting any effort into it
import produce, { Draft } from 'immer'

import {
  ADD_WALK_STEP, NEXT_STEP, PREVIOUS_STEP, CLEAR_STEPS
} from './actionTypes'

export interface Reducer {
  steps: Partial<{
    x: number,
    y: number,
    height: number,
    width: number,
    description: string,
    title: string,
    position: number,
    tooltipOffsets? :{
      x: number,
      y: number
    }
  }>,
  current: number,
  dismissed: boolean

}

export const initialState: Reducer = {
  steps: {},
  current: 0,
  dismissed: false
}

export default produce((state: Draft<Reducer>, action: any): void => {
  const { x, y, height, width, description, position, title, tooltipOffsets } = action
  switch (action.type) {
    case ADD_WALK_STEP: {
      if (state[position]) {
        console.warn('There\'s already a step in this position')
      }
      state.steps[position] = {
        x,
        y,
        height,
        width,
        description,
        title,
        position,
        tooltipOffsets
      }
      break
    }
    case CLEAR_STEPS: {
      state.current = null
      state.dismissed = true
      break
    }
    case NEXT_STEP: {
      state.current += 1
      break
    }
    case PREVIOUS_STEP: {
      if (state.current > 0) {
        state.current -= 1
      } else {
        console.error('Zero is the first possible step')
      }
      break
    }
  }
}, initialState)
