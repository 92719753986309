import { AllScreenNamesType } from 'cc-localization'
import { ToasterTypes } from '@zooz/generic-ui-components'
import { Language, ShortDateFormats } from '@zooz/localize'
import { UserConfiguration } from '@payu/paymentsos-types'
import { takeLeading, takeEvery, call, put, select } from 'redux-saga/effects'

import sentry from '../../../helpers/sentry'
import { getSessionToken, isLoggedIn } from '../../../redux/auth/selectors'
import { updateUserConfiguration } from '../../../redux/userConfiguration/actions'
import { getUserConfiguration, getIsUserConfigurationFetched } from '../../../redux/userConfiguration/selectors'
import { addError, addMessage } from '../../Messages/redux/actions'
import { PRIMARY_LANGUAGE_CODE } from '../constants'
import {
  getLocalizationPreferences,
  getCurrentDateFormat,
  getCurrentGroupNumberDelimiter,
  getCurrentDecimalNumberFormat
} from '../helpers'
import lang from '../language'
import localize from '../localize'
import { getSelectedLanguage, getMountedScreens, getLocalizationLanguages } from './selectors'
import { actions } from './slice'

export default function * localizeSaga () {
  yield takeLeading(actions.initLocalize.type, initLocalizeSaga)
  yield takeEvery(actions.setLocalizationPreferences.type, switchLocalizationPreferences)
  yield takeEvery(actions.togglePreviewMode, togglePreviewMode)
  yield takeEvery(actions.loadScreenTranslations, loadScreenTranslations)
}

function * initLocalizeSaga () {
  try {
    if (!localize.isInitialized()) {
      yield call({ context: localize, fn: localize.init })
      yield put(actions.initLocalizeSuccess({
        language: (localize.getTranslationLanguage()),
        languages: localize.getLanguages(),
        isPreviewMode: localize.isPreviewMode(),
        screens: (localize.getScreensList() as AllScreenNamesType[]),
        dateFormat: localize.getShortDateFormat() as ShortDateFormats
      }))
    } else {
      yield put(actions.stopInitLocalize())
    }

    let localizationPreferences = getLocalizationPreferences(localize)
    let userConfiguration: UserConfiguration.UserConfiguration = {}
    const isUserConfigurationFetched = (yield select(getIsUserConfigurationFetched)) as boolean

    const isUserLoggedIn: boolean = yield select(isLoggedIn)
    if (isUserLoggedIn && isUserConfigurationFetched) {
      userConfiguration = (yield select(getUserConfiguration)) as UserConfiguration.UserConfiguration
      if (userConfiguration?.localization?.language_code === localize.getTranslationLanguage()) {
        localizationPreferences = getLocalizationPreferences(localize, userConfiguration)
      } else {
        const userConfiguration: UserConfiguration.UserConfiguration = {
          localization: {
            language_code: localizationPreferences.language,
            date_format: localizationPreferences.dateFormat,
            number_decimal_format: localizationPreferences.numberFormat.decimal,
            number_group_delimiter: localizationPreferences.numberFormat.group
          }
        }
        yield put(updateUserConfiguration(userConfiguration))
      }
    }

    localize.changeShortDateFormat(localizationPreferences.dateFormat)
    localize.changeNumberFormat({
      groupNumberDelimiter: localizationPreferences.numberFormat.group,
      decimalNumberFormat: localizationPreferences.numberFormat.decimal
    })
    yield put(actions.setLocalizationPreferencesSuccess(
      localizationPreferences.language,
      localizationPreferences.dateFormat,
      localizationPreferences.numberFormat
    ))

    if (isUserLoggedIn) {
      const sessionToken = getSessionToken(yield select())
      yield call({ context: localize, fn: localize.setSessionToken }, sessionToken)
      yield put(actions.setLanguages(localize.getLanguages()))
    }
  } catch (error) {
    // silently report initialization error
    sentry.captureException(error as Error)
    yield put(actions.initLocalizeFail({
      language: localize.getTranslationLanguage(),
      languages: localize.getLanguages(),
      isPreviewMode: localize.isPreviewMode(),
      screens: (localize.getScreensList() as AllScreenNamesType[]),
      dateFormat: localize.getShortDateFormat() as ShortDateFormats
    }))
  }
}

export function * switchLocalizationPreferences (
  { payload: { language, dateFormat, numberFormat } }: ReturnType<typeof actions.setLocalizationPreferences>
) {
  const hasInitialized = localize.isInitialized()
  if (hasInitialized && localize.isReady()) {
    try {
      // @ts-ignore: ts fails to set types correctly
      yield call({ context: localize, fn: localize.changeLanguage }, language)

      const preferredLanguage = localize.getTranslationLanguage()
      const preferredShortDateFormat = dateFormat || getCurrentDateFormat(localize) as ShortDateFormats
      let preferredNumberFormat = numberFormat

      if (!numberFormat) {
        preferredNumberFormat = {
          decimal: getCurrentDecimalNumberFormat(localize),
          group: getCurrentGroupNumberDelimiter(localize)
        }
      }

      const isUserLoggedIn: boolean = yield select(isLoggedIn)
      if (isUserLoggedIn) {
        const userConfiguration: UserConfiguration.UserConfiguration = {
          localization: {
            language_code: preferredLanguage,
            date_format: preferredShortDateFormat,
            number_decimal_format: preferredNumberFormat.decimal,
            number_group_delimiter: preferredNumberFormat.group
          }
        }
        yield put(updateUserConfiguration(userConfiguration))
      }

      // update localize formats
      yield call({ context: localize, fn: localize.changeShortDateFormat }, preferredShortDateFormat)
      yield call({ context: localize, fn: localize.changeNumberFormat }, {
        groupNumberDelimiter: preferredNumberFormat.group,
        decimalNumberFormat: preferredNumberFormat.decimal
      })

      yield put(actions.setLocalizationPreferencesSuccess(
        preferredLanguage,
        preferredShortDateFormat,
        preferredNumberFormat
      ))
    } catch (error) {
      yield put(addError({
        text: lang.errors.failedLangChange,
        err: error
      }))
      yield put(actions.setLanguageDone())
    }

    const mountedScreens: AllScreenNamesType[] = yield select(getMountedScreens)
    yield call(unTranslatedScreenMessage, mountedScreens)
  } else {
    yield put(actions.setLanguageDone())
  }
}

function * togglePreviewMode () {
  const hasInitialized = localize.isInitialized()
  if (hasInitialized && localize.isReady()) {
    try {
      // @ts-ignore: ts fails to set types correctly
      yield call({ context: localize, fn: localize.togglePreviewMode })
      yield put(actions.togglePreviewModeSuccess(localize.isPreviewMode()))
    } catch (error) {
      yield put(addError({
        text: lang.errors.failedPreviewToggle,
        err: error
      }))
      yield put(actions.togglePreviewModeFail())
    }
  } else {
    yield put(actions.togglePreviewModeDone())
  }
}

function * loadScreenTranslations (action: ReturnType<typeof actions.loadScreenTranslations>) {
  const hasInitialized = localize.isInitialized()
  if (hasInitialized) {
    try {
      // @ts-ignore: ts fails to set types correctly
      yield call({ context: localize, fn: localize.addScreen }, action.payload.newScreens)
      yield put(actions.loadScreenTranslationDone(action.payload.newScreens))
    } catch (error) {
      sentry.captureException(error as Error)
    }
    yield call(unTranslatedScreenMessage, action.payload.newScreens)
  }
}

export function * unTranslatedScreenMessage (testedScreens: AllScreenNamesType[]) {
  const isSupported = testedScreens.reduce((acc, screenName) => (
    acc && localize.isScreenSupported(screenName as string)
  ), true)
  if (!isSupported) {
    const currentLanguageCode: string = yield select(getSelectedLanguage)
    if (currentLanguageCode !== PRIMARY_LANGUAGE_CODE) {
      const languages: Language[] = yield select(getLocalizationLanguages)
      const { displayName } = languages.find(({ code }) => code === currentLanguageCode)
      yield put(addMessage({
        text: lang.messages.screenNotSupported(localize.translate, { unsupportedLang: displayName }, [displayName]),
        type: ToasterTypes.INFO
      }))
    }
  }
}
