import { LongDateFormatFunction, TranslationFunction } from '@zooz/react-localize'
import format from 'date-fns/format'
import { LongDateFormats } from '@zooz/localize'

import apiConfig from '../../api/AnalyticsApiHelpers/apiConfig'
import language from './language'

export const granularities = { hour: 'hour', day: 'day', week: 'week', month: 'month' } as const

interface GraphDateFormatOptions {
  date: Date;
  formatter: LongDateFormatFunction;
  translator: TranslationFunction
}
export const granularityConfig = {
  hour: {
    id: apiConfig.granularity.hour,
    title: 'Hour',
    graphDateFormat: ({ date, translator }: GraphDateFormatOptions): string => {
      const time = format(date, 'HH:mm')
      let abbreviatedDay = ''
      const dateIndex = date?.getDay()
      if (dateIndex < language.abbreviatedWeekdays.length) {
        abbreviatedDay = language.abbreviatedWeekdays[dateIndex](translator)
      }
      return `${time}${abbreviatedDay ? ', ' : ''}${abbreviatedDay}`
    },
    label: language.trends.granularity.hour
  },
  day: {
    id: apiConfig.granularity.day,
    title: 'Day',
    graphDateFormat: ({ date, formatter }: GraphDateFormatOptions): string => (
      formatter(date, LongDateFormats.shortened)
    ),
    label: language.trends.granularity.day
  },
  week: {
    id: apiConfig.granularity.week,
    title: 'Week',
    graphDateFormat: ({ date, formatter }: GraphDateFormatOptions): string => (
      formatter(date, LongDateFormats.shortened)
    ),
    label: language.trends.granularity.week
  },
  month: {
    id: apiConfig.granularity.month,
    title: 'Month',
    graphDateFormat: ({ date, translator }: GraphDateFormatOptions): string => {
      let month = ''
      const monthIndex = date?.getMonth()
      if (monthIndex < language.months.length) {
        month = language.months[monthIndex](translator)
      }
      const year = date?.getFullYear()
      return `${month}${month && year ? ', ' : ''}${year}`
    },
    label: language.trends.granularity.month
  }
}

type GranularityTime = typeof granularities[keyof typeof granularities]

interface GranularityConfiguration {
  granularities: GranularityTime[];
  defaultGranularity: string;
}

export const getGranularityConfigurationByDays = (daysSelected: number): GranularityConfiguration => {
  if (daysSelected <= 1) {
    return {
      granularities: [granularities.hour],
      defaultGranularity: granularityConfig.hour.id
    }
  }
  if (daysSelected <= 6) {
    return {
      granularities: [
        granularities.hour,
        granularities.day
      ],
      defaultGranularity: granularityConfig.hour.id
    }
  }
  if (daysSelected <= 31) {
    return {
      granularities: [
        granularities.day,
        granularities.week
      ],
      defaultGranularity: granularityConfig.day.id
    }
  }
  return {
    granularities: [
      granularities.day,
      granularities.week,
      granularities.month
    ],
    defaultGranularity: granularityConfig.day.id
  }
}
