import produce from 'immer'
import { without } from 'lodash'
import { Managment } from '@payu/paymentsos-types'

import {
  PRIMARY_LANGUAGE_CODE,
  DEFAULT_DECIMAL_NUMBER_FORMAT,
  DEFAULT_GROUP_NUMBER_DELIMITER,
  DEFAULT_SHORT_DATE_FORMAT
} from '../../Localize/constants'
import { getNumberFormatByProperties } from '../helpers/localizationMapping'
import { UserProfileLocalizationPreferences } from '../types'
import {
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_FIELD,
  CLEAN_TRANSIENT_PROFILE,
  CLEAN_TRANSIENT_PASSWORD,
  UPDATE_PASSWORD_FIELD,
  SET_LOCALIZATION_PREFERENCES_SUCCEED,
  UPDATE_LOCALIZATION_FIELD,
  HANDLE_FOCUS_CHANGE,
  ENFORCE_FIELDS_VALIDATION, UserProfileAction
} from './actions'

export interface Passwords {
  currentPassword: string,
  newPassword: string,
  repeatPassword: string
}

export interface UserLocalizationStore extends UserProfileLocalizationPreferences {
  isEdited: boolean
}

export interface IsValid {
  currentPassword: boolean,
  newPassword: boolean,
  repeatPassword: boolean
}

export interface State {
  profile: Managment.UserResource,
  passwords: Passwords,
  localization: UserLocalizationStore,
  isValid: IsValid,
  focused: string[]
}

const initialState: State = {
  profile: {},
  passwords: {
    currentPassword: '',
    newPassword: '',
    repeatPassword: ''
  },
  localization: {
    isEdited: false,
    language: PRIMARY_LANGUAGE_CODE,
    dateFormat: DEFAULT_SHORT_DATE_FORMAT,
    numberFormat: getNumberFormatByProperties({
      group: DEFAULT_GROUP_NUMBER_DELIMITER,
      decimal: DEFAULT_DECIMAL_NUMBER_FORMAT
    })
  },
  isValid: {
    currentPassword: true,
    newPassword: true,
    repeatPassword: true
  },
  focused: []
}

const userProfileReducer = produce((state = initialState, action: UserProfileAction) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS: {
      state.profile = action.profile
      break
    }
    case UPDATE_PROFILE_FIELD: {
      state.profile = action.profile
      break
    }
    case UPDATE_PASSWORD_FIELD: {
      state.passwords = action.passwords
      state.isValid[action.fieldName as keyof IsValid] = false
      break
    }
    case SET_LOCALIZATION_PREFERENCES_SUCCEED: {
      state.localization.language = action.localizationPreferences.language
      state.localization.dateFormat = action.localizationPreferences.dateFormat
      state.localization.numberFormat = action.localizationPreferences.numberFormat
      break
    }
    case UPDATE_LOCALIZATION_FIELD: {
      state.localization = action.localizationFields
      break
    }
    case CLEAN_TRANSIENT_PROFILE: {
      state.profile = {}
      state.passwords = initialState.passwords
      state.localization = initialState.localization
      break
    }
    case CLEAN_TRANSIENT_PASSWORD: {
      state.passwords = initialState.passwords
      state.isValid = initialState.isValid
      break
    }
    case HANDLE_FOCUS_CHANGE: {
      const toAdd = action.focus

      state.focused = toAdd ? state.focused.concat(action.focusId) : without(state.focused, action.focusId)
      break
    }
    case ENFORCE_FIELDS_VALIDATION: {
      const isValid = {
        currentPassword: !action.isValid,
        newPassword: !action.isValid,
        repeatPassword: !action.isValid
      }
      state.isValid = isValid
      break
    }
  }
}, initialState)

export default userProfileReducer
