import React, { Component } from 'react'
import { withLocalize, WithLocalizeProps } from '@zooz/react-localize'
import { Button, FontAwesome, SideNote } from '@zooz/generic-ui-components'

import translations from './translations'

import style from './GetUserConfirmation.scss'

export interface GetUserConfirmationOwnProps {
  callback: (prevent: boolean) => void
}

export interface GetUserConfirmationState {
  isHidden: boolean
}

export type GetUserConfirmationProps = GetUserConfirmationOwnProps & WithLocalizeProps

export class GetUserConfirmation extends Component<GetUserConfirmationProps, GetUserConfirmationState> {
  state = {
    isHidden: false
  }

  componentDidMount (): void {
    this.listenToKeys()
  }

  componentDidUpdate (_prevProps: GetUserConfirmationProps, prevState: GetUserConfirmationState): void {
    if (prevState.isHidden !== this.state.isHidden) {
      if (this.state.isHidden) {
        this.listenToKeysStop()
      } else {
        this.listenToKeys()
      }
    }
  }

  componentWillUnmount (): void {
    this.listenToKeysStop()
  }

  listenToKeys = (): void => {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  listenToKeysStop = (): void => {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleLeave = (): void => {
    this.setState({ isHidden: true })
    this.props.callback(true)
  }

  handleStay = (): void => {
    this.setState({ isHidden: true })
    this.props.callback(false)
  }

  handleKeyDown = (event: KeyboardEvent): void => {
    if (!this.state.isHidden && event.key === 'Enter') {
      this.handleLeave()
    }
  }

  render (): JSX.Element {
    const { isHidden } = this.state
    if (isHidden) {
      return null
    }
    const { t } = this.props
    return (
      <div data-test='user-confirmation' className={style.wrapper}>
        <div className={style.popup}>
          <div className={style['content-wrapper']}>
            <div className={style['icon-wrapper']}>
              <FontAwesome className={style.icon} icon='exclamation-circle' />
            </div>
            <div className={style.message}>
              <SideNote bold className={style['message-header']}>
                {translations.message.header(t)}
              </SideNote>
              <SideNote>{translations.message.content(t)}</SideNote>
            </div>
          </div>
          <div className={style.buttons}>
            <Button
              data-test='user-confirmation-stay'
              ghost
              btnType={Button.TYPES.DANGER}
              type='button'
              onClick={this.handleStay}
            >
              {translations.buttons.stay(t)}
            </Button>
            <Button
              btnType={Button.TYPES.DANGER}
              data-test='user-confirmation-leave'
              type='button'
              onClick={this.handleLeave}
            >
              {translations.buttons.leave(t)}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default withLocalize(GetUserConfirmation)
