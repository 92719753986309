import React from 'react'

import language from '../../../../language'

import css from './style.scss'

interface AccountProps {
  children: React.ReactNode,
  name: string
}

const AccountId: React.FC<AccountProps> = ({ children, name }) => (
  <div className={css['topbar-accountid']}>
    <div className={css['topbar-accountid__header']}>{name} {language.accountId}:</div>
    <div className={css['topbar-accountid__id']} data-test='account-id'>{children}</div>
  </div>
)

export default AccountId
