import * as React from 'react'
import { useLocalize } from '@zooz/react-localize'
import { Paragraph } from '@zooz/generic-ui-components'
import { Link } from 'react-router-dom'

import locations from '../../../helpers/tools/locations'
import { externalLocations } from '../../../helpers/tools/locations/externalLocations'
import language from './language'
import languageElements from './languageElemetns'

import styles from './InvalidTokenMessage.scss'

export const InvalidTokenError: React.FC = ({ children }) => (
  <a
    className={styles.link}
    href={`mailto:${externalLocations.zoozSupportMail}`}
    target='_blank'
    rel='noreferrer'
  >
    {children}
  </a>
)

const InvalidTokenMessage: React.FC = () => {
  const { t, te } = useLocalize()
  return (
    <div className={styles.message}>
      <Paragraph className={styles.text}>
        {language.invalidTokenMessage.invalidSignUpLink(t)}<br />
        <Link to={locations.signUp()} className={styles.link}>
          {language.invalidTokenMessage.signUpAgain(t)}
        </Link>
      </Paragraph>
      <Paragraph className={styles.text}>
        {languageElements.invalidTokenMessage.invalidToken(te)}
      </Paragraph>
    </div>
  )
}

export default InvalidTokenMessage
