import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { combinePathsWithEnvAndContext } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'
import { OPERATORS_PROVIDERS_PREFIX } from '../constants/common'
import { TREASURY_OPERATOR } from '../redux/app/types'

const { permissions } = PermissionsUtil

const microAppUrl = envConfig.apps.payoutsManagement

const App: MicroApp = {
  name: 'payouts_management',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: () => {
    const requiredPrefixes = [
      OPERATORS_PROVIDERS_PREFIX,
      permissions.operations.payouts.search(),
      permissions.operations.balances.get(),
      permissions.operations.payouts.packs.search(),
      permissions.operations.payouts.packs.get()
    ]
    return requiredPrefixes.every(prefix => PermissionsUtil.findPermissionsByPrefix(prefix,
      PermissionsUtil.masqueraderRoles).length)
  },
  paths: combinePathsWithEnvAndContext([
    { path: Locations.treasuryPayoutsManagementPage, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.treasuryPayoutsInPackPage, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.treasuryPacksOverviewPage, userTypeContext: TREASURY_OPERATOR }
  ])
}

export default App
