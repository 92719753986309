import permissions from '../constants/permissions'
import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { combinePathsWithEnvAndContext, isFeatureAllowed } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'
import { OPERATORS_PROVIDERS_PREFIX } from '../constants/common'
import { MERCHANT, TREASURY_OPERATOR } from '../redux/app/types'

const microAppUrl = envConfig.apps.reports

const App: MicroApp = {
  name: 'reports',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: () => {
    const requiredPermissions = [
      permissions.reports.generate
    ]

    const requiredPrefixes = [
      OPERATORS_PROVIDERS_PREFIX,
      permissions.operations.settlements.reports(),
      permissions.operations.settlements.templates()
    ]
    const isSettlementOperator = requiredPrefixes.every(prefix => PermissionsUtil.findPermissionsByPrefix(prefix,
      PermissionsUtil.masqueraderRoles).length)

    return isFeatureAllowed(PermissionsUtil.all, requiredPermissions) || isSettlementOperator
  },
  paths: combinePathsWithEnvAndContext([
    { path: Locations.reports, userTypeContext: MERCHANT },
    { path: Locations.createReport, userTypeContext: MERCHANT },
    { path: Locations.displayReport, userTypeContext: MERCHANT },
    { path: Locations.downloadReport, userTypeContext: MERCHANT },
    { path: Locations.settlementsReports, userTypeContext: MERCHANT },
    { path: Locations.settlementsReportById, userTypeContext: MERCHANT },
    { path: Locations.createSettlementsReport, userTypeContext: MERCHANT },
    { path: Locations.operatorSettlementsReports, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.operatorCreateSettlementsReport, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.operatorDownloadReport, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.operatorSettlementsReportById, userTypeContext: TREASURY_OPERATOR }
  ])
}

export default App
