import { all, put, takeEvery, ForkEffect } from 'redux-saga/effects'

import accountActions from '../account/actions'
import { fetchCountries } from '../countries/actions'
import { fetchCurrencies } from '../currencies/actions'
import providerActions from '../providers/actions'
import rolesActions from '../roles/actions'
import { fetchUserConfiguration } from '../userConfiguration/actions'
import { types } from './actions'

function * fetchGlobalDataItems () {
  yield put(rolesActions.fetchPrivileges())
  yield all([
    put(providerActions.fetchProviders()),
    put(accountActions.fetchAccount()),
    put(fetchCurrencies()),
    put(fetchCountries()),
    put(fetchUserConfiguration())
  ])
}

export function * globalDataSaga (): IterableIterator<ForkEffect> {
  yield takeEvery(types.FETCH_GLOBAL_DATA, fetchGlobalDataItems)
}
