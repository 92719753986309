import 'systemjs/dist/system'
import 'systemjs/dist/extras/amd'
import 'systemjs/dist/extras/named-register'

import * as React from 'react'
import { LocalizeProvider } from '@zooz/react-localize'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { matchPath } from 'react-router'
import { registerApplication } from 'single-spa'

import './setupGlobals'
// eslint-disable-next-line import/order
import { history, store } from './redux/store'
import ApiBase from './api/ApiBase'
import envConfig from './envConfig'
import localize from './features/Localize/localize'
import unfocusedWindow from './helpers/unfocusedWindow'
import { init, startMicroApps, domElementGetter } from './initMicroApps'
import actions from './redux/app/actions'
import Router from './Router'
import './styles/global.css'

init()
unfocusedWindow()

// eslint-disable-next-line no-underscore-dangle
window.__VERSION__ = process.env.__VERSION__
ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider localizeInstance={localize}>
      <Router history={history} />
    </LocalizeProvider>
  </Provider>,
  document.getElementById('root')
)

const appProps = {
  ApiBase,
  store,
  domElementGetter,
  history
}

const appsRequire = require.context('./microApps', true, /.*\.[jt]s$/)

appsRequire.keys().forEach(async (configFilePath) => {
  const { name, importFunction, permissionsCheck, paths, getVersion } = appsRequire(configFilePath).default
  const version = getVersion ? await getVersion() : ''
  const stringPaths = paths.map(path => typeof path === 'string' ? path : path.path)

  registerApplication(
    name,
    () => importFunction(version),
    shouldLoad(
      stringPaths,
      permissionsCheck
    ),
    appProps
  )
})

function shouldLoad (prefixes, hasPermission) {
  return function (location) {
    if (!hasPermission()) {
      return false
    }

    return !!prefixes.find((prefix) => {
      const pathsPrefix = envConfig.baseRouteName ? `/${envConfig.baseRouteName.replace(/^\/|\/$/g, '')}` : ''
      const pathUrl = pathsPrefix + prefix

      return matchPath(location.pathname, { path: pathUrl, exact: true })
    })
  }
}

startMicroApps()

window.addEventListener('single-spa:before-app-change', (evt) => {
  const newApp = evt.detail.appsByNewStatus.MOUNTED[0]
  store.dispatch(actions.setMicroAppLoading({
    isLoaded: false,
    name: newApp
  }))
})

window.addEventListener('single-spa:app-change', (evt) => {
  const newApp = evt.detail.appsByNewStatus.MOUNTED[0]
  store.dispatch(actions.setMicroAppLoading({
    isLoaded: true,
    name: newApp
  }))
  store.dispatch(actions.setCurrentLoadedMicroApp({
    name: newApp
  }))
})
