import { isLoggedIn } from '../redux/auth/selectors'
import { store } from '../redux/store'

const REFRESH_WAIT_MS = 30 * 60 * 1000

let refreshTimeout: ReturnType<typeof setTimeout>
const handleVisibilityChange = (): void => {
  clearTimeout(refreshTimeout)
  if (document.hidden) {
    refreshTimeout = setTimeout(() => {
      if (document.hidden) {
        const isLoggedInUser = isLoggedIn(store.getState())
        if (!isLoggedInUser) {
          location.reload()
        }
      }
    }, REFRESH_WAIT_MS)
  }
}

export default (): void => {
  document.addEventListener('visibilitychange', handleVisibilityChange, false)
  handleVisibilityChange()
}
