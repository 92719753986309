import {
  call,
  put,
  select,
  takeLatest,
  CallEffect,
  SelectEffect,
  PutEffect, ForkEffect
} from 'redux-saga/effects'

import CurrenciesApi from '../../api/CurrenciesApi'
import errors from '../../constants/Errors'
import localize from '../../features/Localize/localize'
import { fetchCurrenciesSuccess, ActionTypes } from './actions'
import { getCurrencies } from './selectors'
import messageDispatcher from '../../features/Messages/redux/messageDispatcher'

const errorHandler = (err: Error) => ({
  text: errors.defaultError(localize.translate),
  err
})

export function * fetchCurrenciesSaga (): IterableIterator<
  SelectEffect |
  CallEffect |
  PutEffect
  > {
  try {
    const currencies = getCurrencies(yield select())
    const currenciesFetched = currencies?.length > 0
      ? currencies
      : yield call(CurrenciesApi.fetchCurrencies)
    yield put(fetchCurrenciesSuccess(currenciesFetched))
  } catch (err) {
    messageDispatcher.addError(errorHandler(err as Error))
  }
}
export default function * currenciesSaga (): IterableIterator<ForkEffect> {
  yield takeLatest(ActionTypes.FETCH_CURRENCIES, fetchCurrenciesSaga)
}
