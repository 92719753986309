import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { UserTypeContext, PathAndContext } from 'cc-locations'

import { TEST, LIVE } from '../../constants/environments'
import permissions from '../../constants/permissions'
import Locations, { signedOutLocations } from '../../helpers/tools/locations'
import { MicroApp } from '../../types/microApp'
import MicroAppLoader from './components/MicroAppLoader'
import PageNotFound from './components/PageNotFound'
import { getFallBackPageUrl } from './helpers/getFallBackPage'
import Route from './Route'
import { getIsUnattachedUser } from '../../redux/auth/selectors'
import { MERCHANT, NONE } from '../../redux/app/types'
// @ts-ignore: require.context not typed
const appsRequire = require.context('../../microApps', true, /.*\.[jt]s$/)

const stubPaths: {
  path: string,
  name: string,
  permissionsCheck: () => boolean,
  userTypeContext: UserTypeContext
}[] = []

appsRequire.keys().forEach((configFilePath: string) => {
  const { paths, permissionsCheck, name }: MicroApp = appsRequire(configFilePath).default
  paths.forEach((path: string | PathAndContext) => (
    stubPaths.push({
      path: typeof path === 'string' ? path : path.path,
      permissionsCheck,
      name,
      // TODO: until all locations will have a context, any other context is MERCHANT
      userTypeContext: typeof path === 'string' ? MERCHANT : path.userTypeContext
    })
  ))
})

interface AppRouterProps {
  url: string,
  env: 'test' | 'live'
}

const Dashboard = React.lazy(() => import('../Dashboard/DashboardPage'))
const CongratulationsPage = React.lazy(() => import('../Activation/components/CongratulationsPage'))
const EditSettingsPage = React.lazy(() => import('../Activation/EditSettingsPage'))
const ViewSettingsPage = React.lazy(() => import('../Activation/ViewSettingsPage'))
const NoPermissionsPage = React.lazy(() => import('../NoPermissionsPage/NoPermissionsPage'))
const UnattachedUserPage = React.lazy(() => import('../UnattachedUserPage/UnattachedUserPage'))
const UserProfile = React.lazy(() => import('../UserProfile/components/UserProfile'))

const AppsRouter: React.FC<AppRouterProps> = ({ env, url }) => {
  const isUnattachedUser = useSelector(getIsUnattachedUser)
  return (
    <React.Suspense fallback={<div />}>

      <Switch>
        <Redirect exact from={url} to={getFallBackPageUrl(env)} />
        <Route
          path={Locations.dashboard({ env })}
          permission={permissions.analytics.payments(env)}
          component={Dashboard}
          userTypeContext={MERCHANT}
        />
        <Route
          crossEnvPage
          exact
          path={Locations.userProfile({ env: LIVE })}
          component={UserProfile}
          userTypeContext={MERCHANT}
        />
        <Redirect
          from={Locations.userProfile({ env: TEST })}
          to={Locations.userProfile({ env: LIVE })}
        />

        <Route
          path={Locations.noPermission()}
          component={NoPermissionsPage}
          userTypeContext={NONE}
        />

        <Route
          exact
          path={Locations.unattachedUser()}
          component={UnattachedUserPage}
          userTypeContext={NONE}
        />
        { isUnattachedUser && <Redirect to={Locations.unattachedUser()} />}

        <Route
          crossEnvPage
          exact
          path={Locations.activationSettingsPage({ env: LIVE })}
          component={ViewSettingsPage}
          userTypeContext={MERCHANT}
        />
        <Redirect
          from={Locations.activationSettingsPage({ env: TEST })}
          to={Locations.activationSettingsPage({ env: LIVE })}
        />

        <Route
          crossEnvPage
          exact
          path={Locations.editSettingsPage({ env: LIVE })}
          component={EditSettingsPage}
          userTypeContext={MERCHANT}
        />
        <Redirect
          from={Locations.editSettingsPage({ env: TEST })}
          to={Locations.editSettingsPage({ env: LIVE })}
        />

        {
            stubPaths.filter(stubPath => stubPath.permissionsCheck()).map(({ path, name, userTypeContext }) => (
              <Route
                key={path}
                exact
                path={path}
                userTypeContext={userTypeContext}
              >
                <MicroAppLoader name={name} />
              </Route>

            ))
          }
        <Route
          crossEnvPage
          exact
          path={Locations.congratulationsPage()}
          component={CongratulationsPage}
          userTypeContext={MERCHANT}
        />

        {
          (Object.keys(signedOutLocations) as Array<keyof typeof signedOutLocations>).map(key => (
            <Redirect
              from={signedOutLocations[key]()}
              to={Locations.root(env)}
              key={key}
            />
          ))
        }
        <Route
          component={PageNotFound}
          userTypeContext={NONE}
        />
      </Switch>
    </React.Suspense>
  )
}

export default AppsRouter
