import React from 'react'
import classNames from 'classnames'
import { FontAwesome } from '@zooz/generic-ui-components'

import style from './AccountMenu.scss'

interface AccountMenuItemProps extends React.HtmlHTMLAttributes<HTMLElement>{
  arrow?: boolean,
  dataTest?: string
}

const AccountMenuItem: React.FC<AccountMenuItemProps> = ({ onClick, className, title, arrow, dataTest, ...rest }) => (
  <a
    className={classNames(className, style['dropdown-link'])}
    onClick={onClick}
    data-test={dataTest}
    {...rest}
  >
    {title} {arrow && <FontAwesome icon='chevron-right' className={style.arrow} />}
  </a>
)

export default AccountMenuItem
