// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LargeSpinner__spinnerContainer___bZbKt{padding:32px 0;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/shared-components/LargeSpinner.scss"],"names":[],"mappings":"AAKA,wCACE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["@import '!~@zooz/generic-ui-components/shared-styles/spacings';\n\n$spinner-size: 50px;\n$spinner-width: 5px;\n\n.spinnerContainer {\n  padding: $spacing_32 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n:export {\n  spinner-size: $spinner-size;\n  spinner-width: $spinner-width;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacing_0": `0`,
	"spacing_4": `4px`,
	"spacing_8": `8px`,
	"spacing_12": `12px`,
	"spacing_20": `20px`,
	"spacing_32": `32px`,
	"spacing_52": `52px`,
	"spacing_84": `84px`,
	"spacing_136": `136px`,
	"spacing_220": `220px`,
	"spacing_356": `356px`,
	"spacing_576": `576px`,
	"spacing_932": `932px`,
	"spacing_1508": `1508px`,
	"spacing_2440": `2440px`,
	"spinner-size": `50px`,
	"spinner-width": `5px`,
	"spinnerContainer": `LargeSpinner__spinnerContainer___bZbKt`
};
export default ___CSS_LOADER_EXPORT___;
