import { TranslationFunction } from '@zooz/react-localize'
import { sign_up as signUpTranslations, global as globalTranslations } from '@zooz/translation-values'

export default {
  validTitle: (t: TranslationFunction): string => t(
    signUpTranslations.main_title_createPassword.key, {
      fallback: signUpTranslations.main_title_createPassword.fallback
    }
  ),
  invalidTokenTitle: (t: TranslationFunction): string => t(
    signUpTranslations.main_title_invalidSignUpLink.key, {
      fallback: signUpTranslations.main_title_invalidSignUpLink.fallback
    }
  ),
  verifySignUpForm: {
    email: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_customerProperties_email.key, {
        fallback: globalTranslations.sharedText_customerProperties_email.fallback
      }
    ),
    password: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_lbl_password.key, {
        fallback: globalTranslations.sharedText_lbl_password.fallback
      }
    ),
    confirmPassword: (t: TranslationFunction): string => t(
      signUpTranslations.verifySignUpForm_lbl_confirmPassword.key, {
        fallback: signUpTranslations.verifySignUpForm_lbl_confirmPassword.fallback
      }
    ),
    button: (t: TranslationFunction): string => t(
      signUpTranslations.verifySignUpForm_btn_signUp.key, {
        fallback: signUpTranslations.verifySignUpForm_btn_signUp.fallback
      }
    )
  },
  invalidTokenMessage: {
    invalidSignUpLink: (t: TranslationFunction): string => t(
      signUpTranslations.invalidTokenMessage_lbl_invalidSignUpLink.key, {
        fallback: signUpTranslations.invalidTokenMessage_lbl_invalidSignUpLink.fallback
      }
    ),
    signUpAgain: (t: TranslationFunction): string => t(
      signUpTranslations.invalidTokenMessage_lbl_signUpAgain.key, {
        fallback: signUpTranslations.invalidTokenMessage_lbl_signUpAgain.fallback
      }
    )
  },
  link: {
    login: (t: TranslationFunction): string => t(
      signUpTranslations.main_lbl_login.key, {
        fallback: signUpTranslations.main_lbl_login.fallback
      }
    )
  }
}
