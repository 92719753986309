import { isEmpty } from 'lodash'
import validator from 'validator'

import * as validatedFieldNames from '../constants/validatedFieldNames'

interface AddressToValidate {
  addressLine1?: string,
  city?: string,
  country?: string
}
export const validateBusinessAddress = (_businessAddress: AddressToValidate): string[] => {
  const businessAddress = []

  if (isEmpty(_businessAddress.addressLine1)) {
    businessAddress.push(validatedFieldNames.BUSINESS_ADDRESS_ADDRESS_LINE1)
  }
  if (isEmpty(_businessAddress.city)) {
    businessAddress.push(validatedFieldNames.BUSINESS_ADDRESS_CITY)
  }
  if (isEmpty(_businessAddress.country)) {
    businessAddress.push(validatedFieldNames.BUSINESS_ADDRESS_COUNTRY)
  }

  return businessAddress
}

interface ContactInfoToValidate {
  business?: string,
  development?: string,
  financial?: string
}

export const validateContactInformation = (_contactInformation: ContactInfoToValidate): string[] => {
  const contactInformation = []
  if (!validator.isEmail(_contactInformation.business)) {
    contactInformation.push(validatedFieldNames.COMPANY_EMAIL_CONTACT_BUSINESS_DEPT)
  }

  if (!validator.isEmail(_contactInformation.development)) {
    contactInformation.push(validatedFieldNames.COMPANY_EMAIL_CONTACT_DEVELOPMENT_DEPT)
  }
  if (!validator.isEmail(_contactInformation.financial)) {
    contactInformation.push(validatedFieldNames.COMPANY_EMAIL_CONTACT_FINANCIAL_DEPT)
  }

  return contactInformation
}

interface UserConfirmationToValidate {
  isChecked?: boolean
}

export const validateUserConfirmation = (_userConfirmation: UserConfirmationToValidate): string[] => {
  const userConfirmation = []
  if (!_userConfirmation.isChecked) {
    userConfirmation.push(validatedFieldNames.USER_HAS_NECESSARY_RIGHT_CONFIRMATION_IS_CHECKED)
  }

  return userConfirmation
}

interface BusinessInfoToValidate {
  companyLegalName?: string,
  businessRegistrationNumber?: string
}

export const validateBusinessInformation = (_businessInformation: BusinessInfoToValidate): string[] => {
  const businessInformation = []

  if (isEmpty(_businessInformation.companyLegalName)) {
    businessInformation.push(validatedFieldNames.BUSINESS_INFORMATION_COMPANY_LEGAL_NAME)
  }

  if (isEmpty(_businessInformation.businessRegistrationNumber)) {
    businessInformation.push(validatedFieldNames.BUSINESS_INFORMATION_BUSINESS_REGISTRATION_NUMBER)
  }

  return businessInformation
}
