// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__dropdown___Xr5eR{margin:0px 0px 0px 30px;width:200px}.style__selected___NaDbc{display:flex;align-items:center}.style__selected__icon___rBNoW{margin-right:6px}.style__selected__text___db6z8{margin-top:7px}`, "",{"version":3,"sources":["webpack://./src/features/Topbar/components/SwitchProvider/style.scss"],"names":[],"mappings":"AAAA,yBACE,uBAAA,CACA,WAAA,CAGF,yBACE,YAAA,CACA,kBAAA,CAEA,+BACE,gBAAA,CAGF,+BACE,cAAA","sourcesContent":[".dropdown {\n  margin: 0px 0px 0px 30px;\n  width: 200px;\n}\n\n.selected {\n  display: flex;\n  align-items: center;\n\n  &__icon {\n    margin-right: 6px;\n  }\n\n  &__text {\n    margin-top: 7px\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `style__dropdown___Xr5eR`,
	"selected": `style__selected___NaDbc`,
	"selected__icon": `style__selected__icon___rBNoW`,
	"selected__text": `style__selected__text___db6z8`
};
export default ___CSS_LOADER_EXPORT___;
