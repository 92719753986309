import * as React from 'react'
import { TranslationElementsFunction } from '@zooz/react-localize'
import { sign_up as signUpTranslations } from '@zooz/translation-values'

import { ReenterLink } from './CheckEmailMessage'

export default {
  checkInbox: {
    checkSpam: (te: TranslationElementsFunction): JSX.Element => te(
      signUpTranslations.checkInbox_lbl_checkSpamTryAgain.key, {
        fallback: signUpTranslations.checkInbox_lbl_checkSpamTryAgain.fallback,
        Components: {
          link: <ReenterLink />
        }
      }
    )
  }
}
