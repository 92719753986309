import { Localize } from '@zooz/localize'

import { createLocalizeInstance } from '../../Localize/helpers'

class LocalizationPreviewSingleton {
  private instance: Localize

  getInstance () {
    if (!this.instance) {
      this.instance = createLocalizeInstance()
    }

    return this.instance
  }

  killInstance () {
    this.instance = undefined
  }
}

export default new LocalizationPreviewSingleton()
