import React, { Component } from 'react'
import { useLocalize } from '@zooz/react-localize'

import translations from '../translations'
import sentry from '../../../../helpers/sentry'

import style from './ErrorBoundary.scss'

export interface ErrorBoundaryProps {
  children?: React.ReactNode
}

interface ErrorBoundaryState {
  error?: Error
}

const DisplayedError: React.FC = () => {
  const { t } = useLocalize()
  return (
    <div
      className={style.error}
    >
      <p>{translations.appCrash.sorry(t)}</p>
      <p>{translations.appCrash.teamNotified(t)}</p>
    </div>
  )
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor (props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  componentDidUpdate (prevProps: ErrorBoundaryProps) {
    if (this.props.children !== prevProps.children && this.state.error) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ error: null })
    }
  }

  componentDidCatch (error: Error) {
    sentry.captureException(error)
    this.setState({ error })
  }

  render () {
    if (this.state.error) {
      return (
        <DisplayedError />
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
