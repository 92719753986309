import * as types from './types'

export const setFieldText = (fieldName: string, text: string | boolean) => ({
  type: types.SET_FIELD_TEXT,
  fieldName,
  text
})
export const updateAccountActivation = () => ({
  type: types.UPDATE_ACCOUNT_ACTIVATION
})
export const updateAccountActivationSuccess = () => ({
  type: types.UPDATE_ACCOUNT_ACTIVATION_SUCCESS
})
export const updateAccountActivationFailed = () => ({
  type: types.UPDATE_ACCOUNT_ACTIVATION_FAILED
})
export const setLoading = (isLoading: boolean) => ({
  type: types.SET_ACTIVATE_LOADING_SPINNER,
  isLoading
})
