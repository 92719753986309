import { Currencies } from '@payu/paymentsos-types'
import { createSelector } from 'reselect'

import { State } from './reducer'

interface Store {
  Countries: State
}

export const getCountries = (state: Store): Currencies.Currency[] => state.Countries.countries

export const getCountryCodeToCountryNameMap = createSelector(
  getCountries,
  (countries): Record<string, string> => countries.reduce(
    (acc, country) => {
      acc[country.code] = country.name
      return acc
    }, {} as Record<string, string>
  )
)

export const getCountryNameToCountryCodeMap = createSelector(
  getCountries,
  (countries): Record<string, string> => countries.reduce(
    (acc, country) => {
      acc[country.name] = country.code
      return acc
    }, {} as Record<string, string>
  )
)
