import produce from 'immer'

import {
  TOGGLE_TEST_LIVE,
  SET_TEST_LIVE, EnvironmentActions
} from './actions'

export interface EnvironmentReducer {
  isLive?: boolean
}

const initialState: EnvironmentReducer = {
  isLive: undefined
}

const liveTestReducer = produce((state: EnvironmentReducer, action: EnvironmentActions) => {
  switch (action.type) {
    case TOGGLE_TEST_LIVE:
      state.isLive = !state.isLive
      break
    case SET_TEST_LIVE:
      state.isLive = action.liveStatus
  }
}, initialState)

export default liveTestReducer
