import { Countries } from '@payu/paymentsos-types'

type Country = Countries.Country

export const ActionTypes = {
  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS'
}

interface Action {
  type: typeof ActionTypes.FETCH_COUNTRIES | typeof ActionTypes.FETCH_COUNTRIES_SUCCESS
}

interface FetchCountriesAction extends Action {
  type: typeof ActionTypes.FETCH_COUNTRIES
}

export const fetchCountries = (): FetchCountriesAction => ({ type: ActionTypes.FETCH_COUNTRIES })

export interface FetchCountriesSuccessAction extends Action {
  type: typeof ActionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: {
    countries: Country[]
  }
}

export const fetchCountriesSuccess = (countries: Country[]): FetchCountriesSuccessAction => ({
  type: ActionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: {
    countries
  }
})

export type Actions = FetchCountriesAction | FetchCountriesSuccessAction
