import { Managment } from '@payu/paymentsos-types'
import { createSelector } from 'reselect'

import { PortalState } from '../store'
import PermissionsUtil from '../../helpers/permissions/PermissionsUtil'
import { getEnvironment } from '../environment/selectors'
import { Environments } from '../../../portal-types/environments'
import { getCurrentMicroApp } from '../app/selectors'
import {
  MicroAppToOperationsRoleIdentifier,
  OPERATORS_PROVIDER_INDEX,
  OPERATORS_PROVIDERS_PREFIX
} from '../../constants/common'

export const isPermissionsLoaded = (state: PortalState): boolean => (
  state.rolesReducer &&
  state.rolesReducer.roles &&
  state.rolesReducer.roles.length !== 0
)

const findRolesByName = (roles: Managment.RoleResource[], roleIdentifier: string):
  Managment.RoleResource[] => roles.filter(
  (role: Managment.RoleResource) => role.name.toLowerCase().includes(roleIdentifier)
)

export const extractProvidersFromPermissions = (
  roles: Managment.RoleResource[],
  environment: Environments,
  microApp: string
): string[] => {
  const filteredRoles = findRolesByName(roles, MicroAppToOperationsRoleIdentifier[microApp])
  const permissions = PermissionsUtil.findPermissionsByPrefix(OPERATORS_PROVIDERS_PREFIX, filteredRoles)
  const permissionsByEnv = permissions.filter(permission => permission.endsWith(environment))
  return permissionsByEnv.map(permission => permission.split('.')[OPERATORS_PROVIDER_INDEX])
}

export const userRoles = (state: PortalState): Managment.RoleResource[] => state.rolesReducer.roles

export const masqueraderRoles = (state: PortalState): Managment.RoleResource[] => state.rolesReducer.masqueraderRoles

export const getPermittedProviders = createSelector(
  [masqueraderRoles, getEnvironment, getCurrentMicroApp],
  (
    masqueradingRoles: Managment.RoleResource[],
    environment: Environments,
    microApp: string
  ): string[] => extractProvidersFromPermissions(masqueradingRoles, environment, microApp)
)

export const getActiveProvider = (state: PortalState): string => state.rolesReducer.activeProvider

export const permissionsList = createSelector(userRoles, (roles = []): string[] => {
  const permissionsSet = new Set<string>() // using set to remove duplicates
  for (const { permissions = [] } of roles) {
    for (const permission of permissions) {
      permissionsSet.add(permission)
    }
  }
  return Array.from(permissionsSet)
})
