import React from 'react'
import { TranslationElementsFunction } from '@zooz/react-localize'
import { sign_up as signUpTranslations } from '@zooz/translation-values'

import { InvalidTokenError } from './InvalidTokenMessage'

export default {
  invalidTokenMessage: {
    invalidToken: (te: TranslationElementsFunction): JSX.Element => te(
      signUpTranslations.invalidTokenMessage_lbl_problemContact.key, {
        fallback: signUpTranslations.invalidTokenMessage_lbl_problemContact.fallback,
        Components: {
          link: <InvalidTokenError />
        }
      }
    )
  }
}
