import { Analytics } from '@payu/paymentsos-types'

import { PaymentStageNamesTypes } from '../../components/paymentStagesConfig'
import { getDimensionsPerPaymentStage } from '../requestBuilder'
import apiConfig from '../../../../api/AnalyticsApiHelpers/apiConfig'
import { DEFAULT_CURRENCY } from '../../config'

type ProgressBarGraphData = Record<string, number> | null;

type ProgressBarsData = {
  provider_name?: ProgressBarGraphData,
  currency?: ProgressBarGraphData,
  vendor?: ProgressBarGraphData
}

export default function convertToDimensionProgressBarsData (
  data: Analytics.AnalyticsResponse,
  paymentStage: PaymentStageNamesTypes,
  metric: Analytics.Metric['name'],
  dashboardCurrency: string
): ProgressBarsData {
  return isEmptyData(data)
    ? createEmptyProgressBarsData(paymentStage)
    : createProgressBarsData(data.buckets, metric, dashboardCurrency)
}
function isEmptyData (data: Analytics.AnalyticsResponse): boolean {
  return !(data?.buckets?.[0]?.data?.length > 0)
}

function createEmptyProgressBarsData (paymentStage: PaymentStageNamesTypes): ProgressBarsData {
  const dimensions = getDimensionsPerPaymentStage(paymentStage)

  const emptyProgressBarsData = dimensions.reduce((acc, dimension: Analytics.Dimension) => {
    // @ts-ignore: Analytics is not well maintained for types generation
    acc[dimension] = null
    return acc
  }, {}) as ProgressBarsData

  return emptyProgressBarsData
}

function createProgressBarsData (buckets: Analytics.Bucket[], metric: Analytics.Metric['name'], dashboardCurrency:string): ProgressBarsData {
  let metricName = metric

  if (metric === apiConfig.metrics.totalAmount && dashboardCurrency !== DEFAULT_CURRENCY) {
    metricName = apiConfig.metrics.originalCurrency
  }

  const progressBarsData: ProgressBarsData = {}
  buckets.forEach((bucket: Analytics.Bucket) => {
    bucket.data.forEach((bucketData: Analytics.ResponseItem) => {
      Object.entries(bucketData.dimensions).forEach(([key, value]) => {
        // @ts-ignore: Analytics is not well maintained for types generation
        if (!progressBarsData[key]) {
          // @ts-ignore: Analytics is not well maintained for types generation
          progressBarsData[key] = {}
        }
        // @ts-ignore: Analytics is not well maintained for types generation
        if (!progressBarsData[key][value]) {
          // @ts-ignore: Analytics is not well maintained for types generation
          progressBarsData[key][value] = bucketData.metrics[metricName] as number
        } else {
          // @ts-ignore: Analytics is not well maintained for types generation
          progressBarsData[key][value] += bucketData.metrics[metricName] as number
        }
      })
    })
  })

  return progressBarsData
}
