import React from 'react'
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar'
import './global_overrides.css'

const CustomScrollbar: React.FC<ScrollBarProps> = ({ children, ...rest }) => (
  <PerfectScrollbar {...rest}>
    {children}
  </PerfectScrollbar>
)

export default CustomScrollbar
