import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { combinePathsWithEnvAndContext, isFeatureAllowed } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'
import { MERCHANT, TREASURY_OPERATOR } from '../redux/app/types'
import { OPERATORS_PROVIDERS_PREFIX } from '../constants/common'

const { permissions } = PermissionsUtil

const microAppUrl = envConfig.apps.balancesManagement

const App: MicroApp = {
  name: 'balances_management',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: () => {
    const requiredPrefixes = [
      OPERATORS_PROVIDERS_PREFIX,
      permissions.operations.balances.get(),
      permissions.operations.balanceOperationsSearch.get(),
      permissions.operations.balancesConfigurationsSearch.get(),
      permissions.operations.payouts.search()
    ]

    const merchantRequiredPrefixes = [
      permissions.balanceConfiguration.get,
      permissions.balances.get,
      permissions.balancesOperations.get,
      permissions.payouts.search
    ]

    const isOperatorAllowed = requiredPrefixes.every(prefix => PermissionsUtil.findPermissionsByPrefix(prefix,
      PermissionsUtil.masqueraderRoles).length)

    return isFeatureAllowed(PermissionsUtil.all, merchantRequiredPrefixes) || isOperatorAllowed
  },

  paths: combinePathsWithEnvAndContext([
    { path: Locations.accountBalancePage, userTypeContext: MERCHANT },
    { path: Locations.balanceTransactionsPage, userTypeContext: MERCHANT },
    { path: Locations.balancePayoutsHistoryPage, userTypeContext: MERCHANT },
    { path: Locations.balanceRollingReservedPage, userTypeContext: MERCHANT },
    { path: Locations.balanceInvoicesPage, userTypeContext: MERCHANT },

    { path: Locations.treasuryBalancesManagementPage, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.treasuryBalanceTransactionsPage, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.treasuryPayoutsHistoryPage, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.treasuryBalanceCorrectionsPage, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.treasuryRollingReservedPage, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.treasuryInvoicesPage, userTypeContext: TREASURY_OPERATOR },
    { path: Locations.treasuryBalanceLocksPage, userTypeContext: TREASURY_OPERATOR }
  ])
}

export default App
