import { TranslationFunction } from '@zooz/react-localize'
import { app_logged_in as appLoggedInTranslations, global as globalTranslation } from '@zooz/translation-values'

export default {
  activationPermitted: (t: TranslationFunction): string => t(
    appLoggedInTranslations.activatePopup_msg_contactAccountManager.key, {
      fallback: appLoggedInTranslations.activatePopup_msg_contactAccountManager.fallback
    }
  ),
  activationNotPermitted: (t: TranslationFunction): string => t(
    appLoggedInTranslations.activatePopup_msg_contactAdmin.key, {
      fallback: appLoggedInTranslations.activatePopup_msg_contactAdmin.fallback
    }
  ),
  goToActivation: (t: TranslationFunction): string => t(
    appLoggedInTranslations.activatePopup_link_goToActivation.key, {
      fallback: appLoggedInTranslations.activatePopup_link_goToActivation.fallback
    }
  ),
  test: (t: TranslationFunction): string => t(
    globalTranslation.sharedText_environment_test.key, {
      fallback: globalTranslation.sharedText_environment_test.fallback
    }
  ).toUpperCase(),
  live: (t: TranslationFunction): string => t(
    globalTranslation.sharedText_environment_live.key, {
      fallback: globalTranslation.sharedText_environment_live.fallback
    }
  ).toUpperCase()
}
