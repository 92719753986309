import axios from 'axios'

import auth from '../redux/auth/actions'
import { store } from '../redux/store'

const SESSION_EXPIRED = 'Session is expired.'
const SESSION_AUTHENTICATION_FAILED_INFO = 'Session authentication failed.'
const JWT_MISSING_HEADER_INFO = 'Authorization header is mandatory, in JWT (JSON Web Token) format using the Bearer schema. For more information see jwt.io.'

const response = {
  unAuthorized: [config => config, (err) => {
    if (axios.isCancel(err)) {
      return
    }
    const status = err.response && err.response.status
    const moreInfo = err.response && err.response.data && err.response.data.more_info

    const nobounce = err.config && err.config.zooz_nobounce
    const isSessionExpired = status === 401 && (
      moreInfo === SESSION_AUTHENTICATION_FAILED_INFO || moreInfo === SESSION_EXPIRED
    )
    const isSessionNotExist = status === 400 && moreInfo === JWT_MISSING_HEADER_INFO
    if ((isSessionExpired || isSessionNotExist) && !nobounce) {
      store.dispatch(auth.unauthorized(401))
    }
    throw err
  }]
}

const request = {
  unAuthorized: [(config) => {
    if (config.params) {
      config.zooz_nobounce = config.params.zooz_nobounce
      delete config.params.zooz_nobounce
    }
    return config
  }]
}

export default { response, request }
