import { Action } from 'redux'

export const SET_ONBOARDING_ACTION_TYPE = 'SET_ONBOARDING_APP'

interface SetOnBoardingAction extends Action<typeof SET_ONBOARDING_ACTION_TYPE> {
  onboard: boolean
}

export const setOnboarding = (): SetOnBoardingAction => ({
  type: SET_ONBOARDING_ACTION_TYPE,
  onboard: true
})

export const dismissOnboarding = (): SetOnBoardingAction => ({
  type: SET_ONBOARDING_ACTION_TYPE,
  onboard: false
})
