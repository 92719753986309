// Actions

export const FETCH_DASHBOARD = 'FETCH_DASHBOARD'
export const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS'
export const ON_PAYMENT_STAGE_CHANGE = 'DASHBOARD_ON_PAYMENT_STAGE_CHANGE'
export const SELECT_PAYMENT_STAGE = 'DASHBOARD_SELECT_PAYMENT_STAGE'
export const ON_DATE_CHANGE = 'DASHBOARD_ON_DATE_CHANGE'
export const SELECT_DATE = 'DASHBOARD_SELECT_DATE'
export const ON_COUNTRY_CHANGE = 'DASHBOARD_ON_COUNTRY_CHANGE'
export const SELECT_COUNTRY = 'DASHBOARD_SELECT_COUNTRY'
export const ON_APP_CHANGE = 'DASHBOARD_ON_ON_APP_CHANGE'
export const SELECT_APP = 'DASHBOARD_SELECT_APP'
export const SELECT_METRICS = 'SELECT_METRICS'
export const ON_METRICS_CHANGE = 'ON_METRICS_CHANGE'
export const SELECT_BAR = 'SELECT_BAR'

export const SELECT_GRANULARITY = 'SELECT_GRANULARITY'
export const ON_GRANULARITY_CHANGE = 'DASHBOARD_ON_GRANULARITY_CHANGE'

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
export const COUNTRIES_FETCH_SUCCESS = 'COUNTRIES_FETCH_SUCCESS'
export const FETCH_APPS = 'FETCH_APPS'
export const APPS_FETCH_SUCCESS = 'APPS_FETCH_SUCCESS'
export const FETCH_TREND_GRAPH = 'FETCH_TREND_GRAPH'
export const TREND_GRAPH_FETCH_SUCCESS = 'TREND_GRAPH_FETCH_SUCCESS'
export const FETCH_DIMENSION_BARS = 'FETCH_DIMENSION_BARS'
export const DIMENSION_BARS_FETCH_SUCCESS = 'DIMENSION_BARS_FETCH_SUCCESS'
export const FETCH_PAYMENT_STAGE_BREAKDOWN = 'FETCH_PAYMENT_STAGE_BREAKDOWN'
export const PAYMENT_STAGE_BREAKDOWN_FETCH_SUCCESS = 'PAYMENT_STAGE_BREAKDOWN_FETCH_SUCCESS'

// Action Creators
export function fetchDashboard () {
  return { type: FETCH_DASHBOARD }
}

export function dashboardFetchSuccess () {
  return { type: DASHBOARD_FETCH_SUCCESS }
}

export function fetchCountries () {
  return { type: FETCH_COUNTRIES }
}
export function countriesFetchSuccess (countries) {
  return { type: COUNTRIES_FETCH_SUCCESS, countries }
}

export function fetchApps () {
  return { type: FETCH_APPS }
}
export function appsFetchSuccess (apps) {
  return { type: APPS_FETCH_SUCCESS, apps }
}

export function fetchTrendGraph () {
  return { type: FETCH_TREND_GRAPH }
}
export function trendGraphFetchSuccess (graph) {
  return { type: TREND_GRAPH_FETCH_SUCCESS, graph }
}

export function fetchDimensionBars () {
  return { type: FETCH_DIMENSION_BARS }
}
export function dimensionBarsFetchSuccess (bars) {
  return { type: DIMENSION_BARS_FETCH_SUCCESS, bars }
}

export function fetchPaymentStageBreakdown () {
  return { type: FETCH_PAYMENT_STAGE_BREAKDOWN }
}
export function paymentStageBreakdownFetchSuccess (data) {
  return { type: PAYMENT_STAGE_BREAKDOWN_FETCH_SUCCESS, data }
}

export function onPaymentStateChange (paymentStage) {
  return { type: ON_PAYMENT_STAGE_CHANGE, paymentStage }
}

export function selectPaymentStage (payment) {
  return { type: SELECT_PAYMENT_STAGE, payment }
}

export function onDateChange ({ from, to, selectedLabel }) {
  return { type: ON_DATE_CHANGE, from, to, selectedLabel }
}

export function selectDate ({ from, to }) {
  return { type: SELECT_DATE, from, to }
}

export function onCountryChange (countries) {
  return { type: ON_COUNTRY_CHANGE, countries }
}

export function selectCountries (countries) {
  return { type: SELECT_COUNTRY, countries }
}

export function onAppChange (apps) {
  return { type: ON_APP_CHANGE, apps }
}

export function selectApp (apps) {
  return { type: SELECT_APP, apps }
}

export function selectGranularity (granularity) {
  return { type: SELECT_GRANULARITY, granularity }
}
export function onGranularityChange (granularity) {
  return { type: ON_GRANULARITY_CHANGE, granularity }
}

export function onMetricsChange (metrics) {
  return { type: ON_METRICS_CHANGE, metrics }
}

export function selectMetrics (metrics) {
  return { type: SELECT_METRICS, metrics }
}

export function selectBar (bar) {
  return { type: SELECT_BAR, bar }
}
