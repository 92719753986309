import { utils } from '@zooz/react-localize'
import { dashboard as dashboardTranslations, global as globalTranslations } from '@zooz/translation-values'

const { memoLang } = utils

export default {
  trends: {
    granularity: {
      month: memoLang(
        globalTranslations.sharedText_timeUnits_month.key,
        globalTranslations.sharedText_timeUnits_month.fallback
      ),
      week: memoLang(
        globalTranslations.sharedText_timeUnits_week.key,
        globalTranslations.sharedText_timeUnits_week.fallback
      ),
      day: memoLang(
        globalTranslations.sharedText_timeUnits_day.key,
        globalTranslations.sharedText_timeUnits_day.fallback
      ),
      hour: memoLang(
        globalTranslations.sharedText_timeUnits_hour.key,
        globalTranslations.sharedText_timeUnits_hour.fallback
      )
    },
    tooltip: {
      total: memoLang(
        globalTranslations.graph_tooltipLabel_total.key,
        globalTranslations.graph_tooltipLabel_total.fallback
      ),
      provider: memoLang(
        globalTranslations.graph_tooltipLabel_provider.key,
        globalTranslations.graph_tooltipLabel_provider.fallback
      ),
      amount: memoLang(
        globalTranslations.graph_tooltipLabel_amount.key,
        globalTranslations.graph_tooltipLabel_amount.fallback
      ),
      count: memoLang(
        globalTranslations.graph_tooltipLabel_count.key,
        globalTranslations.graph_tooltipLabel_count.fallback
      ),
      currency: memoLang(
        globalTranslations.graph_tooltipLabel_currency.key,
        globalTranslations.graph_tooltipLabel_currency.fallback
      ),
      paymentMethod: memoLang(
        globalTranslations.graph_tooltipLabel_paymentMethod.key,
        globalTranslations.graph_tooltipLabel_paymentMethod.fallback
      ),
      status: memoLang(
        globalTranslations.graph_tooltipLabel_status.key,
        globalTranslations.graph_tooltipLabel_status.fallback
      ),
      rate: memoLang(
        globalTranslations.graph_tooltipLabel_rate.key,
        globalTranslations.graph_tooltipLabel_rate.fallback
      )
    }
  },
  tabs: {
    emptyText: memoLang(
      dashboardTranslations.tabs_text_empty.key,
      dashboardTranslations.tabs_text_empty.fallback
    ),
    label: {
      provider: memoLang(
        dashboardTranslations.tabs_lbl_provider.key,
        dashboardTranslations.tabs_lbl_provider.fallback
      ),
      currency: memoLang(
        dashboardTranslations.tabs_lbl_currency.key,
        dashboardTranslations.tabs_lbl_currency.fallback
      ),
      paymentMethod: memoLang(
        dashboardTranslations.tabs_lbl_paymentMethod.key,
        dashboardTranslations.tabs_lbl_paymentMethod.fallback
      )
    }
  },
  flow: {
    label: {
      initiated: memoLang(
        dashboardTranslations.flow_lbl_initiated.key,
        dashboardTranslations.flow_lbl_initiated.fallback
      ),
      blocked: memoLang(
        dashboardTranslations.flow_lbl_blocked.key,
        dashboardTranslations.flow_lbl_blocked.fallback
      ),
      voided: memoLang(
        dashboardTranslations.flow_lbl_voided.key,
        dashboardTranslations.flow_lbl_voided.fallback
      ),
      declined: memoLang(
        dashboardTranslations.flow_lbl_declined.key,
        dashboardTranslations.flow_lbl_declined.fallback
      ),
      successful: memoLang(
        dashboardTranslations.flow_lbl_successful.key,
        dashboardTranslations.flow_lbl_successful.fallback
      ),
      refund: memoLang(
        dashboardTranslations.flow_lbl_refund.key,
        dashboardTranslations.flow_lbl_refund.fallback
      )
    },
    description: {
      initiated: memoLang(
        dashboardTranslations.flow_desc_initiated.key,
        dashboardTranslations.flow_desc_initiated.fallback
      ),
      blocked: memoLang(
        dashboardTranslations.flow_desc_blocked.key,
        dashboardTranslations.flow_desc_blocked.fallback
      ),
      voided: memoLang(
        dashboardTranslations.flow_desc_voided.key,
        dashboardTranslations.flow_desc_voided.fallback
      ),
      declined: memoLang(
        dashboardTranslations.flow_desc_declined.key,
        dashboardTranslations.flow_desc_declined.fallback
      ),
      successful: memoLang(
        dashboardTranslations.flow_desc_successful.key,
        dashboardTranslations.flow_desc_successful.fallback
      ),
      refund: memoLang(
        dashboardTranslations.flow_desc_refund.key,
        dashboardTranslations.flow_desc_refund.fallback
      )
    }
  },
  filtersRow: {
    dropdown: {
      allBusinessUnits: memoLang(
        dashboardTranslations.filtersRow_dropdown_allBusinessUnits.key,
        dashboardTranslations.filtersRow_dropdown_allBusinessUnits.fallback
      ),
      allCountries: memoLang(
        dashboardTranslations.filtersRow_dropdown_allCountries.key,
        dashboardTranslations.filtersRow_dropdown_allCountries.fallback
      )
    },
    toggle: {
      amount: memoLang(
        dashboardTranslations.filtersRow_toggle_amount.key,
        dashboardTranslations.filtersRow_toggle_amount.fallback
      ),
      payments: memoLang(
        dashboardTranslations.filtersRow_toggle_payments.key,
        dashboardTranslations.filtersRow_toggle_payments.fallback
      )
    }
  },
  abbreviatedWeekdays: [
    memoLang(
      globalTranslations.dates_abbreviatedWeekdays_sunday.key,
      globalTranslations.dates_abbreviatedWeekdays_sunday.fallback
    ),
    memoLang(
      globalTranslations.dates_abbreviatedWeekdays_monday.key,
      globalTranslations.dates_abbreviatedWeekdays_monday.fallback
    ),
    memoLang(
      globalTranslations.dates_abbreviatedWeekdays_tuesday.key,
      globalTranslations.dates_abbreviatedWeekdays_tuesday.fallback
    ),
    memoLang(
      globalTranslations.dates_abbreviatedWeekdays_wednesday.key,
      globalTranslations.dates_abbreviatedWeekdays_wednesday.fallback
    ),
    memoLang(
      globalTranslations.dates_abbreviatedWeekdays_thursday.key,
      globalTranslations.dates_abbreviatedWeekdays_thursday.fallback
    ),
    memoLang(
      globalTranslations.dates_abbreviatedWeekdays_friday.key,
      globalTranslations.dates_abbreviatedWeekdays_friday.fallback
    ),
    memoLang(
      globalTranslations.dates_abbreviatedWeekdays_saturday.key,
      globalTranslations.dates_abbreviatedWeekdays_saturday.fallback
    )
  ],
  months: [
    memoLang(
      globalTranslations.dates_months_january.key,
      globalTranslations.dates_months_january.fallback
    ),
    memoLang(
      globalTranslations.dates_months_february.key,
      globalTranslations.dates_months_february.fallback
    ),
    memoLang(
      globalTranslations.dates_months_march.key,
      globalTranslations.dates_months_march.fallback
    ),
    memoLang(
      globalTranslations.dates_months_april.key,
      globalTranslations.dates_months_april.fallback
    ),
    memoLang(
      globalTranslations.dates_months_may.key,
      globalTranslations.dates_months_may.fallback
    ),
    memoLang(
      globalTranslations.dates_months_june.key,
      globalTranslations.dates_months_june.fallback
    ),
    memoLang(
      globalTranslations.dates_months_july.key,
      globalTranslations.dates_months_july.fallback
    ),
    memoLang(
      globalTranslations.dates_months_august.key,
      globalTranslations.dates_months_august.fallback
    ),
    memoLang(
      globalTranslations.dates_months_september.key,
      globalTranslations.dates_months_september.fallback
    ),
    memoLang(
      globalTranslations.dates_months_october.key,
      globalTranslations.dates_months_october.fallback
    ),
    memoLang(
      globalTranslations.dates_months_november.key,
      globalTranslations.dates_months_november.fallback
    ),
    memoLang(
      globalTranslations.dates_months_december.key,
      globalTranslations.dates_months_december.fallback
    )
  ]
}
