import React, { Component } from 'react'
import classnames from 'classnames'
import { Popover } from '@zooz/generic-ui-components'
import { pick, isEqual, get } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Transition } from 'react-transition-group'

import Hint from './Hint'
import * as actions from './redux/actions'
import * as selectors from './redux/selectors'

import css from './Walkthrough.scss'

const HINT_TRANSITION_SEC = 0.2
const DIMENSIONS_FIELDS = ['x', 'y', 'height', 'width']

class Walkthrough extends Component {
  static propTypes = {
    step: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      position: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
      height: PropTypes.number,
      width: PropTypes.number
    }),
    numberOfSteps: PropTypes.number,
    currentStepIndex: PropTypes.number,
    clearAll: PropTypes.func
  }

  state = {
    step: null
  }

  frameRef = React.createRef()
  hintMovedTime = null

  componentDidUpdate () {
    if (this.props.currentStep && !isEqual(this.props.currentStep, this.state.step)) {
      this.setState({ step: this.props.currentStep })
      clearTimeout(this.hintMovedTime)
      this.hintMovedTime = setTimeout(() => {
        this.setState({
          hintMoved: this.props.currentStepIndex + JSON.stringify(pick(this.props.step, DIMENSIONS_FIELDS))
        })
      }, HINT_TRANSITION_SEC * 1000)
    }
  }

  componentWillUnmount () {
    if (this.hintMovedTime) {
      clearTimeout(this.hintMovedTime)
    }
  }

  render () {
    const { currentStep, numberOfSteps, nextHint, previousHint, isPreviousExists, clearAll, dismissed } = this.props
    const { step } = this.state
    const dimensions = pick(step, DIMENSIONS_FIELDS)
    const canNext = step ? step.position < numberOfSteps - 1 : false
    const canPrevious = isPreviousExists
    const validDimensions = Object.values(dimensions).length === DIMENSIONS_FIELDS.length
    const showWalkthroughs = !!currentStep && !dismissed && validDimensions

    const offsetY = `${get(step, ['tooltipOffsets', 'y'], 0)}px`

    return (
      <Transition unmountOnExit in={showWalkthroughs} timeout={500}>
        {status => (
          <div
            style={{
              '--transition-time': `${HINT_TRANSITION_SEC}s`
            }}
            className={classnames(css.walkthrough, css[`walkthrough--${status}`])}
          >
            <div
              ref={this.frameRef}
              className={css.walkthrough__frame}
              style={{
                '--hint-x': `${dimensions.x}px`,
                '--hint-y': `${dimensions.y}px`,
                '--hint-width': `${dimensions.width}px`,
                '--hint-height': `${dimensions.height}px`
              }}
            >
              <div className={css.walkthrough__popoverwrapper} style={{ top: offsetY, left: 0, bottom: 0, right: 0 }}>
                <Popover
                  key={JSON.stringify(dimensions) + this.state.hintMoved}
                  placement={Popover.Placements.RightCenter}
                  offset={get(step, ['tooltipOffsets', 'x'])}
                  eventsEnabled={false}
                  content={(
                    <Hint
                      closeHint={clearAll}
                      canNext={canNext}
                      canPrevious={canPrevious}
                      onNext={nextHint}
                      onPrevious={previousHint}
                      title={step ? step.title : ''}
                      description={step ? step.description : ''}
                    />
                  )}
                  isOpened
                >
                  <div />
                </Popover>
              </div>
            </div>
          </div>
        )}
      </Transition>
    )
  }
}

const mapStateToProps = state => ({
  currentStep: selectors.getCurrentStep(state),
  currentStepIndex: selectors.getCurrentStepIndex(state),
  numberOfSteps: selectors.getNumberOfSteps(state),
  isPreviousExists: selectors.isAvailablePreviousStep(state),
  dismissed: selectors.getDismissed(state)
})

const mapDispatchToProps = {
  nextHint: actions.nextStep,
  previousHint: actions.previousStep,
  clearAll: actions.clearSteps
}

export default connect(mapStateToProps, mapDispatchToProps)(Walkthrough)
