import { IBusinessUnitsApi, UIBusinessUnit } from 'cc-apis'
import { Apps } from '@payu/paymentsos-types'

import { store } from '../redux/store'
import * as ENVIRONMENTS from '../constants/environments'
import { isAccountLive } from '../redux/account/selectors'
import ApiBase from './ApiBase'

class BusinessUnitsApi extends ApiBase implements IBusinessUnitsApi {
  get resourcePath (): string {
    return `/accounts/${this.accountId}/apps`
  }

  fetchBusinessUnits = async (env: typeof ENVIRONMENTS.TEST | typeof ENVIRONMENTS.LIVE): Promise<UIBusinessUnit[]> => {
    const queryParams = { limit: 0 }
    const headersContainer = this.defaultConfig

    // need to change all screens to user current env bu
    if (!isAccountLive(store.getState())) {
      env = ENVIRONMENTS.TEST
    }
    if (env) {
      headersContainer.headers['x-payments-os-env'] = env
    }
    const response = await this.httpClient.get<Apps.AppResource[]>(this.resourcePath, queryParams, headersContainer)
    return response.data.map(app => ({
      description: app.description,
      id: app.id,
      defaultProcessor: app.default_processor,
      status: app.status
    }))
  }
}

export default new BusinessUnitsApi()
