/* eslint-disable camelcase */
import { SignUpVerifyRequestInfo } from 'cc-apis'
import { push } from 'connected-react-router'
import { UserConfiguration } from '@payu/paymentsos-types'
import {
  takeLatest,
  call,
  put,
  select,
  CallEffect,
  PutEffect,
  SelectEffect,
  SagaReturnType,
  ForkEffect
} from 'redux-saga/effects'

import UsersApi from '../../../../api/UsersApi'
import * as Environments from '../../../../constants/environments'
import errors from '../../../../constants/Errors'
import localStorageAccessor from '../../../../helpers/localStorageAccessor/localStorageAccessor'
import sentry from '../../../../helpers/sentry'
import Locations from '../../../../helpers/tools/locations'
import authActions from '../../../../redux/auth/actions'
import { updateUserConfiguration } from '../../../../redux/userConfiguration/actions'
import localize from '../../../Localize/localize'
import { getSelectedLanguage } from '../../../Localize/redux/selectors'
import { setOnboarding } from '../../../OnBoarding/redux/actions'
import { ActionTypes, VerifySignUpAction, verifySignUpSuccess, verifySignUpFail } from './actions'
import { APIError } from '../../../../api/ApiBase'

const INVALID_TOKEN = 'Invalid token'
const PASSWORD_VALIDATION_ERROR = 'password field is mandatory and should be a strong password.'

function * signUpVerifySaga (action: VerifySignUpAction): IterableIterator<
  CallEffect |
  PutEffect |
  SelectEffect
  > {
  try {
    const { token, password } = action.payload
    const signupVerifyInfo: SignUpVerifyRequestInfo = { token, password }
    const signupVerifyResponse = yield call(UsersApi.signUpVerify, signupVerifyInfo)
    const {
      email,
      merchant_id,
      user_id,
      session_token
    } = signupVerifyResponse as SagaReturnType<typeof UsersApi.signUpVerify>
    const merchantIds = yield call(UsersApi.permittedAccounts, user_id, session_token, merchant_id)
    localStorageAccessor.initializeUser()
    localStorageAccessor.saveSession({
      merchantIds,
      userEmail: email,
      sessionToken: session_token,
      userId: user_id,
      primaryAccountId: merchant_id
    })

    // Localization
    const userSelectedLanguage: string = yield select(getSelectedLanguage)
    const userConfiguration: UserConfiguration.UserConfiguration = {
      localization: {
        language_code: userSelectedLanguage
      }
    }
    yield put(updateUserConfiguration(userConfiguration, { userId: user_id, sessionToken: session_token }))

    yield put(setOnboarding())
    yield put(authActions.loginSuccess({
      email,
      merchantIds,
      accountId: merchant_id,
      sessionToken: session_token,
      userId: user_id
    }))
    yield put(verifySignUpSuccess())
    yield put(push(Locations.root(Environments.TEST), { noPrompt: true }))
  } catch (error) {
    let errorMessage = errors.defaultError(localize.translate)
    const moreInfo = (error as APIError)?.response?.data?.more_info ?? ''
    const status = (error as APIError)?.response?.status
    if (status === 401 && moreInfo.startsWith(INVALID_TOKEN)) {
      errorMessage = errors.invalidToken(localize.translate)
    } else if (status === 409) {
      errorMessage = errors.email.exists(localize.translate)
    } else if (status === 400 && moreInfo.startsWith(PASSWORD_VALIDATION_ERROR)) {
      sentry.captureException(error as Error)
      errorMessage = moreInfo.split(PASSWORD_VALIDATION_ERROR)[1] || errors.defaultError(localize.translate)
    }
    yield put(verifySignUpFail(errorMessage))
  }
}

export default function * (): IterableIterator<ForkEffect> {
  yield takeLatest(ActionTypes.SIGN_UP_VERIFY_REQUEST, signUpVerifySaga)
}
