/* eslint-disable camelcase */
import produce, { Draft } from 'immer'
import merge from 'lodash/merge'
import { Activations } from '@payu/paymentsos-types'

import * as types from './types'

export interface Reducer {
  fetched?: boolean,
  isAccountActivated?: boolean,
  contact_information: Activations.ContactInformation,
  billing_package: string,
  business_information: Activations.BusinessInformation,
  customer_id: string,
  payment_method: {
    type: string,
    token: string,
    token_type: string,
    fingerprint: string,
    holder_name: string,
    expiration_date: string,
    last_4_digits: '',
    pass_luhn_validation: string,
    created: string,
    bin_number: string,
    vendor: string,
    issuer: string,
    card_type: string,
    level: string,
    country_code: string
  }
}

export const initActivations: Reducer = {
  fetched: null,
  isAccountActivated: null,
  contact_information: {
    business: '',
    development: '',
    financial: ''
  },
  billing_package: '',
  business_information: {
    company_legal_name: '',
    business_registration_number: '',
    business_address: {
      address_line: '',
      city: '',
      state: '',
      country: '',
      zip_code: ''
    }
  },
  customer_id: '',
  payment_method: {
    type: null,
    token: null,
    token_type: null,
    fingerprint: null,
    holder_name: null,
    expiration_date: null,
    last_4_digits: '',
    pass_luhn_validation: null,
    created: null,
    bin_number: null,
    vendor: null,
    issuer: null,
    card_type: null,
    level: null,
    country_code: null
  }
}

export default produce((state: Draft<Reducer>, action: any): void => {
  switch (action.type) {
    case types.FETCH_ACTIVATIONS_SUCCESS: {
      merge(state, action.activation)
      state.fetched = true
      state.isAccountActivated = true
      break
    }
    case types.FETCH_ACTIVATIONS_FAILED: {
      state.fetched = true
      state.isAccountActivated = false
      break
    }
  }
}, initActivations)
