import produce from 'immer'
import { Countries } from '@payu/paymentsos-types'

import { Actions, ActionTypes, FetchCountriesSuccessAction } from './actions'

type Country = Countries.Country;

export interface State {
  countries: Country[]
}

const INITIAL_STATE: State = {
  countries: []
}

const reducer = produce((state, action: Actions) => {
  switch (action.type) {
    case ActionTypes.FETCH_COUNTRIES_SUCCESS:
      state.countries = (action as FetchCountriesSuccessAction).payload.countries
  }
}, INITIAL_STATE)

export default reducer
