import * as React from 'react'
import { useLocalize } from '@zooz/react-localize'
import { app_logged_in as appLoggedInTranslations } from '@zooz/translation-values'
import { UiSwitcher } from '@zooz/generic-ui-components'
import { Localize } from '@zooz/localize'
import { useSelector, useDispatch } from 'react-redux'

import { getIsPreviewMode } from '../../redux/selectors'
import { actions } from '../../redux/slice'

const PreviewToggle: React.FC = () => {
  const dispatch = useDispatch()
  const isPreview = useSelector(getIsPreviewMode)
  const { t, isReady, localize } = useLocalize()

  const dispatchToggle = React.useCallback(() => {
    dispatch(actions.togglePreviewMode())
  }, [dispatch])

  React.useEffect(() => {
    if (!isPreview) {
      if (isReady) {
        dispatchToggle()
      } else if (localize instanceof Localize) {
        localize.once('init', () => { dispatchToggle() })
      }
    }

    return function () {
      if (isPreview) {
        dispatchToggle()
      }
    }
  }, [])

  return (
    <UiSwitcher onChange={dispatchToggle} activeState={isPreview}>
      {t(appLoggedInTranslations.topBar_lbl_previewTranslation.key, {
        fallback: appLoggedInTranslations.topBar_lbl_previewTranslation.fallback
      })}
    </UiSwitcher>
  )
}

export default PreviewToggle
