import React from 'react'
import { useSelector } from 'react-redux'

import { getLoadedMicroApps } from '../../../redux/app/selectors'
import LargeSpinner from '../../../shared-components/LargeSpinner'

interface MicroAppLoaderProps {
  name: string
}

const MicroAppLoader: React.FC<MicroAppLoaderProps> = ({ name }) => {
  const loadedMicroApps = useSelector(getLoadedMicroApps)
  if (loadedMicroApps[name]) {
    return null
  }
  return (
    <LargeSpinner />
  )
}

export default MicroAppLoader
