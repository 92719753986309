import React, { Component } from 'react'
import { connect } from 'react-redux'

import { actions as crumbActions } from '../../Topbar/components/Breadcrumbs/redux/actions'
import MODES from '../../Topbar/components/Breadcrumbs/redux/MODES'

const mapStateToProps = state => ({
  location: state.router.location
})

const mapDispatchToProps = {
  addCrumbs: crumbActions.addCrumbs,
  clearCrumbs: crumbActions.clearCrumbs
}

const withCrumbs = (crumbList, options = {}) => (WrappedComponent) => {
  class CrumbWrapper extends Component {
    static generateCrumbs (anyProps) {
      let fullCrumbsList
      if (options.mode === MODES.MANUAL) {
        fullCrumbsList = typeof crumbList === 'function' ? crumbList(anyProps) : crumbList
      } else {
        fullCrumbsList = crumbList.map(crumb => (typeof crumb === 'function') ? crumb(anyProps) : crumb)
      }
      return fullCrumbsList.filter(crumbName => crumbName !== undefined)
    }

    static crumbsHaveChanged (prevProps, newProps) {
      // listen for crumb and location changes
      const prevCrumbs = CrumbWrapper.generateCrumbs(prevProps)
      const currentCrumbs = CrumbWrapper.generateCrumbs(newProps)
      const prevCrumbsWithLocation = JSON.stringify(prevCrumbs).concat(JSON.stringify(prevProps.location))
      const currentCrumbsWithLocation = JSON.stringify(currentCrumbs).concat(JSON.stringify(newProps.location))
      return prevCrumbsWithLocation !== currentCrumbsWithLocation
    }

    componentDidMount () {
      const crumbs = CrumbWrapper.generateCrumbs(this.props)
      return this.props.addCrumbs(crumbs, options.mode)
    }

    componentDidUpdate (prevProps) {
      if (CrumbWrapper.crumbsHaveChanged(prevProps, this.props)) {
        this.props.addCrumbs(CrumbWrapper.generateCrumbs(this.props), options.mode)
      }
    }

    componentWillUnmount () {
      this.props.clearCrumbs()
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(CrumbWrapper)
}

withCrumbs.Modes = MODES
export default withCrumbs
