import { Managment } from '@payu/paymentsos-types'

export const types = {
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_PRIVILEGES: 'FETCH_PRIVILEGES',
  FETCH_MASQ_ROLES_SUCCESS: 'FETCH_MASQ_ROLES_SUCCESS',
  SET_ACTIVE_PROVIDER: 'SET_ACTIVE_PROVIDER'
}

const actions = {
  fetchRolesSuccess: (roles: Managment.RoleResource[]) => ({
    type: types.FETCH_ROLES_SUCCESS,
    payload: roles
  }),
  fetchMasqueraderRolesSuccess: (masqueraderRoles: Managment.RoleResource[]) => ({
    type: types.FETCH_MASQ_ROLES_SUCCESS,
    payload: masqueraderRoles
  }),
  fetchPrivileges: () => ({
    type: types.FETCH_PRIVILEGES
  }),
  setActiveProvider: (activeProvider: string) => ({
    type: types.SET_ACTIVE_PROVIDER,
    payload: activeProvider
  })
}
export default actions
