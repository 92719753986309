// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__paymentsos-menu-text___zvMxN{height:100%;color:#fff;padding-left:24px;padding-right:10px;display:flex;align-items:center;justify-content:flex-start}.style__paymentsos-menu-text--collapsed___fl3nk .style__nav-text___C3qPR{opacity:0}.style__nav-text___C3qPR{color:inherit;opacity:1;transition:all .7s}`, "",{"version":3,"sources":["webpack://./src/features/Sidebar/components/MenuEntry/style.scss","webpack://./src/features/Sidebar/constants.scss"],"names":[],"mappings":"AAEA,qCACE,WAAA,CACA,UAAA,CACA,iBCHkB,CDIlB,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,0BAAA,CAGE,yEACE,SAAA,CAKN,yBACE,aAAA,CACA,SAAA,CACA,kBAAA","sourcesContent":["@import \"../../constants\";\n\n.paymentsos-menu-text {\n  height: 100%;\n  color: #fff;\n  padding-left: $menu-left-padding;\n  padding-right: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n\n  &--collapsed {\n    .nav-text {\n      opacity: 0;\n    }\n  }\n}\n\n.nav-text {\n  color: inherit;\n  opacity: 1;\n  transition: all $animation-time;\n}\n","$animation-time: 0.7s;\n$collapsed-width: 64px;\n$menu-left-padding: 24px;\n$subMenu-left-padding: 50px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentsos-menu-text": `style__paymentsos-menu-text___zvMxN`,
	"paymentsos-menu-text--collapsed": `style__paymentsos-menu-text--collapsed___fl3nk`,
	"nav-text": `style__nav-text___C3qPR`
};
export default ___CSS_LOADER_EXPORT___;
