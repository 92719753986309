import { TranslationFunction } from '@zooz/react-localize'
import { global as globalTranslations, join as joinTranslations, sign_in as signInTranslations } from '@zooz/translation-values'

export default {
  title: (t: TranslationFunction) => t(joinTranslations.main_form_title.key, {
    fallback: joinTranslations.main_form_title.fallback
  }),
  createUser: (t:TranslationFunction) => t(joinTranslations.main_form_createUser.key, {
    fallback: joinTranslations.main_form_createUser.fallback
  }),
  joinTeam: (t:TranslationFunction) => t(joinTranslations.main_form_joinTeam.key, {
    fallback: joinTranslations.main_form_joinTeam.fallback
  }),
  password: (t:TranslationFunction) => t(globalTranslations.sharedText_lbl_password.key, {
    fallback: globalTranslations.sharedText_lbl_password.fallback
  }),
  passwordAgain: (t:TranslationFunction) => t(globalTranslations.sharedText_lbl_typePasswordAgain.key, {
    fallback: globalTranslations.sharedText_lbl_typePasswordAgain.fallback
  }),
  forgot: (t: TranslationFunction) => t(signInTranslations.main_lbl_forgotPassword.key, {
    fallback: signInTranslations.main_lbl_forgotPassword.fallback
  }),
  existingUserTitle: (t: TranslationFunction) => t(joinTranslations.main_title_welcomeBack.key, {
    fallback: joinTranslations.main_title_welcomeBack.fallback
  }),
  existingUserSubtitle: (t: TranslationFunction) => t(joinTranslations.main_subtitle_youveBeenInvited.key, {
    fallback: joinTranslations.main_subtitle_youveBeenInvited.fallback
  }),
  checkboxDescription: (t: TranslationFunction) => t(joinTranslations.main_checkbox_description.key, {
    fallback: joinTranslations.main_checkbox_description.fallback
  }),
  checkboxApproval: (t: TranslationFunction) => t(joinTranslations.main_checkbox_approval.key, {
    fallback: joinTranslations.main_checkbox_approval.fallback
  }),
  buttonJoinTeam: (t: TranslationFunction) => t(joinTranslations.main_button_joinTeam.key, {
    fallback: joinTranslations.main_button_joinTeam.fallback
  }),
  buttonLoginToPreviousAccount: (t: TranslationFunction) => t(joinTranslations.main_button_loginToPreviousTeam.key, {
    fallback: joinTranslations.main_button_loginToPreviousTeam.fallback
  }),
  orBetweenButtons: (t: TranslationFunction) => t(globalTranslations.sharedText_connectors_or.key, {
    fallback: globalTranslations.sharedText_connectors_or.fallback
  })
}
