import http, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  HeadersDefaults
} from 'axios'

import config from '../envConfig'
import interceptors from './httpInterceptors'

http.defaults.timeout = config.requestTimeout as number
http.defaults.baseURL = config.apiUrl;
[
  'post',
  'put',
  'patch'
].forEach((method) => {
  http.defaults.headers[method as keyof HeadersDefaults]['Content-Type'] = 'application/json'
})

interface HttpClientOptions extends AxiosRequestConfig {
  disableInterceptors?: boolean
}

class HttpClient {
  http: AxiosInstance
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  }

  constructor (options: HttpClientOptions = {}) {
    this.http = http.create(options)
    this.http.interceptors.response.use(...interceptors.response.unAuthorized)
    this.http.interceptors.request.use(...interceptors.request.unAuthorized)

    if (!options.disableInterceptors) {
      // @ts-ignore: Axios has wrongly typed response interceptor
      this.http.interceptors.response.handlers.push(...http.interceptors.response.handlers)
      // @ts-ignore: Axios has wrongly typed request interceptor
      this.http.interceptors.request.handlers.push(...http.interceptors.request.handlers)
    }

    this.interceptors = this.http.interceptors
  }

  async get<T = any> (url: string, params: any = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.http.get(url, { params, ...config })
  }

  async post<T = any> (url: string, data?: any, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.post(url, data, { params, ...config })
  }

  async patch<T = any> (url: string, data?: any, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.patch(url, data, { params, ...config })
  }

  async put<T = any> (url: string, data?: any, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.put(url, data, { params, ...config })
  }

  async delete<T = any> (url: string, params: any = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.http.delete(url, { params, ...config })
  }

  async head<T = any> (url: string, params: any = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.http.head(url, { params, ...config })
  }
}

type HttpError<T> = AxiosError<T>

export { HttpClient, http, HttpError }
