import React, { Component, ReactNode } from 'react'
import { Location } from 'history'
import { isEmpty } from 'lodash'
import { Prompt } from 'react-router-dom'

export interface LeaveUnsavedPageMessageProps {
  shouldAlert: boolean
}

interface LocationState {
  noPrompt?: boolean
}

export type LeaveUnsavedPageMessageLocation = Location<LocationState>

class LeaveUnsavedPageMessage extends Component<LeaveUnsavedPageMessageProps> {
  messageFunc = (location: LeaveUnsavedPageMessageLocation): boolean | string => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { history, store } = require('../../redux/store')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const authSelectors = require('../../redux/auth/selectors')
    const isLoggedIn = authSelectors.isLoggedIn(store.getState())

    if (!isLoggedIn) {
      return true
    }

    if (location.pathname === history.location.pathname) {
      return true
    }

    const shouldNotPrompt = (!isEmpty(location.state) && location.state.noPrompt) ||
      (!isEmpty(history.location.state) && history.location.state.noPrompt)
    return shouldNotPrompt ? true : 'message' // true will not show message. any string will do.
  }

  render (): ReactNode {
    const { shouldAlert } = this.props
    return <Prompt when={shouldAlert} message={this.messageFunc} />
  }
}

export default LeaveUnsavedPageMessage
