import { ToasterTypes } from '@zooz/generic-ui-components'
import {
  all, call, put, takeEvery, delay
} from 'redux-saga/effects'

import Errors from '../../../constants/Errors'
import { errorCodeIn } from '../../../helpers/errorsHelper'
import sentry from '../../../helpers/sentry'
import localize from '../../Localize/localize'
import {
  ADD_ERROR,
  ADD_MESSAGE,
  showMessage,
  hideMessage,
  AddMessageAction,
  AddErrorAction,
  ShowMessageAction,
  HideMessageAction
} from './actions'
import generateId from './generateId'

const MESSAGE_MS_TIME = 10000

// Sagas
function * showMessageHandle ({ code, text, messageType, id }: Omit<ShowMessageAction, 'type'>) {
  try {
    yield put(showMessage({ code, text, messageType, id }))
  } catch (err) {
    console.log(err)
  }
}

function * hideMessageHandle ({ id }: Omit<HideMessageAction, 'type'>) {
  try {
    yield put(hideMessage({ id }))
  } catch (err) {
    console.log(err)
  }
}

function * messageVisibilityHandle ({ code, text, messageType }: Omit<AddMessageAction, 'type'>) {
  const id = generateId()
  yield call(showMessageHandle, { code, text, messageType, id })
  yield delay(MESSAGE_MS_TIME)
  yield call(hideMessageHandle, { id })
}

export function * errorManager ({ code, text, err, shouldLogError = true }: AddErrorAction) {
  try {
    if (err && errorCodeIn(err, [401])) {
      return
    }

    if (shouldLogError && err) {
      sentry.captureException(err as Error)
    }

    if (err && errorCodeIn(err, [403])) {
      return
    }

    yield call(
      messageVisibilityHandle,
      {
        code,
        text: text || Errors.defaultError(localize.translate),
        messageType: ToasterTypes.ERROR
      }
    )
  } catch (err) {
    sentry.captureException(err as Error)
    console.log(err)
  }
}

export function * messagesManager ({ code, text, messageType }: AddMessageAction) {
  try {
    yield call(messageVisibilityHandle, { code, text, messageType })
  } catch (err) {
    sentry.captureException(err as Error)
    console.log(err)
  }
}

function * addErrorSaga () {
  yield takeEvery(ADD_ERROR, errorManager)
}

function * addMessageSaga () {
  yield takeEvery(ADD_MESSAGE, messagesManager)
}

export function * messagesSagas () {
  yield all([
    addErrorSaga(),
    addMessageSaga()
  ])
}
