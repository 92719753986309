import { UserConfiguration } from '@payu/paymentsos-types'
import {
  call, put, takeEvery,
  CallEffect, PutEffect, ForkEffect
} from 'redux-saga/effects'

import UsersConfigurationApi from '../../api/UsersConfigurationApi'
import { actions as localizationActions } from '../../features/Localize/redux/slice'
import {
  ActionTypes,
  fetchUserConfigurationSuccessfully,
  updateUserConfigurationSuccessfully,
  UpdateUserConfigurationType,
  FetchUserConfigurationSuccessfullyType
} from './actions'

export function * fetchUserConfiguration (): IterableIterator<
  CallEffect<UserConfiguration.UserConfigurationResource> |
  PutEffect<FetchUserConfigurationSuccessfullyType> |
  PutEffect<{ type: typeof localizationActions.initLocalize.type }>
  > {
  try {
    const userConfig: UserConfiguration.UserConfigurationResource = yield call(
      UsersConfigurationApi.fetchUserConfigurations
    )
    yield put(fetchUserConfigurationSuccessfully(userConfig.configuration))
  } catch (err) {
    console.error('Failed fetching user configuration with the following error: ', err)
  } finally {
    yield put(localizationActions.initLocalize())
  }
}

export function * updateUserConfiguration (payload: UpdateUserConfigurationType): IterableIterator<
  CallEffect |
  PutEffect
  > {
  try {
    const configurationResource: UserConfiguration.UserConfigurationResource =
      yield call(UsersConfigurationApi.updateUserConfigurations, payload.userConfiguration, payload.options)
    yield put(updateUserConfigurationSuccessfully(configurationResource.configuration))
  } catch (err) {
    console.error('Failed updating user configuration with the following error: ', err)
  }
}

export default function * userConfigurationSaga (): IterableIterator<ForkEffect> {
  yield takeEvery(ActionTypes.FETCH_USER_CONFIGURATION, fetchUserConfiguration)
  yield takeEvery(ActionTypes.UPDATE_USER_CONFIGURATION, updateUserConfiguration)
}
