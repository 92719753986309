import { PortalState } from '../../../../redux/store'
import { SignUpRequestInfo } from './actions'

export const getIsSigningUp = (state: PortalState): boolean => state.signUp.isSigningUp

export const getFormErrorMessage = (state: PortalState): string => state.signUp.formErrorMessage

export const getShowSuccessMessage = (state: PortalState): boolean => state.signUp.showSuccessMessage

export const getSignUpInfo = (state: PortalState): Omit<SignUpRequestInfo, 'leadSource'> => state.signUp.signUpInfo
