import produce from 'immer'
import { FeaturesToggle } from '@payu/paymentsos-types'

import { ActionTypes } from './actions'

export interface State {
  account: FeaturesToggle.ToggleByAccountResponse[],
  isFetched: {
    account: boolean
  }
}

const INITIAL_STATE: State = {
  account: [],
  isFetched: {
    account: false
  }
}

const reducer = produce((state, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ACCOUNT_FEATURE_TOGGLES_SUCCESS: {
      state.account = action.payload.featureToggles
      state.isFetched = {
        account: true
      }
      break
    }
  }
}, INITIAL_STATE)

export default reducer
