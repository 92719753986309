/* eslint-disable camelcase */
import { cloneDeep } from 'lodash'
import { combineReducers } from 'redux'

import * as auth from '../../../../redux/auth/types'
import { JOIN_PAGE_HIDE_ERROR, IS_USER_EXISTS, IS_USER_EXISTS_ERROR } from './actions'

interface UserExistsReducer {
  user_id?: string
}

interface JoinReducer {
  joinForm: {
    formErrorValue: string,
    formErrorVisibility: 'hidden' | 'visible'
  },
  userExists: UserExistsReducer
}

const initialState: JoinReducer = {
  joinForm: {
    formErrorValue: 'default',
    formErrorVisibility: 'hidden'
  },
  userExists: {}
}

const userExists = (state: UserExistsReducer = initialState.userExists, action: {
  type: string,
  payload: any
}): UserExistsReducer => {
  const { payload, type } = action

  switch (type) {
    case IS_USER_EXISTS:
      return { ...state, user_id: payload.user_id }
    case IS_USER_EXISTS_ERROR:
      return { ...state, user_id: payload }
    default:
      return state
  }
}

const handleFormRequestsErrors = (state: JoinReducer = initialState, action: {
  type: string,
  payload: any
}): JoinReducer => {
  let newState
  switch (action.type) {
    case auth.JOIN_PAGE_ERROR:
      newState = cloneDeep(state)
      newState.joinForm.formErrorVisibility = 'visible'
      newState.joinForm.formErrorValue = action.payload
      return newState
    case JOIN_PAGE_HIDE_ERROR:
      newState = cloneDeep(state)
      newState.joinForm.formErrorVisibility = 'hidden'
      newState.joinForm.formErrorValue = action.payload
      return newState
    default:
      return state
  }
}

export interface JoinFormReducerState {
  userExists: UserExistsReducer,
  handleFormRequestsErrors: JoinReducer
}

const joinReducers = combineReducers({ userExists, handleFormRequestsErrors })
export default joinReducers
