import { CcSelectors } from 'cc-selectors'

import { getLocalizationDateFormat, getIsLocalizedInit } from '../features/Localize/redux/selectors'
import { getAccountId, isAccountLive } from '../redux/account/selectors'
import { getStartOfDataAnalyticsCollection } from '../redux/analytics/selectors'
import { getContentRectData, getCurrentMicroAppContext } from '../redux/app/selectors'
import { currentUser, isCurrentlyMasquerading, isMasqueraderUser } from '../redux/auth/selectors'
import * as countriesSelectors from '../redux/countries/selectors'
import { getEnvironment, isLive } from '../redux/environment/selectors'
import * as providerSelectors from '../redux/providers/selectors'
import { userRoles, masqueraderRoles, getPermittedProviders, getActiveProvider } from '../redux/roles/selector'
import { getUserPredefinedLanguage } from '../features/UserProfile/redux/selectors'

const ccSelectors: CcSelectors = {
  getLocalizationDateFormat,
  getLocalizationIsInit: getIsLocalizedInit,
  getEnvironment,
  getCurrentUser: currentUser,
  isCurrentlyMasquerading,
  isMasqueraderUser,
  getUserRoles: userRoles,
  getMasqueraderRoles: masqueraderRoles,
  getPermittedProviders,
  getActiveProvider,
  getAccountId,
  isLive,
  getContentRectData,
  getCurrentMicroAppContext,
  analyticsSelectors: {
    getStartOfDataAnalyticsCollection
  },
  isAccountLive,
  globalDataSelectors: {
    providers: providerSelectors,
    countries: countriesSelectors
  },
  getUserPredefinedLanguage
}

export default ccSelectors
