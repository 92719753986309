import { TranslationFunction } from '@zooz/react-localize'
import { page_not_found as pageNotFoundTranslations } from '@zooz/translation-values'

export default {
  likeToGo: (t: TranslationFunction) => t(pageNotFoundTranslations.title_msg_likeToGo.key, {
    fallback: pageNotFoundTranslations.title_msg_likeToGo.fallback
  }),
  pageNotFound: (t: TranslationFunction) => t(pageNotFoundTranslations.main_msg_pageNotFound.key, {
    fallback: pageNotFoundTranslations.main_msg_pageNotFound.fallback
  })
}
