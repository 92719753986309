import { FeaturesToggleUtil as ccFeaturesToggleUtil } from 'cc-feature-toggle-utils'
import { FeaturesToggle } from '@payu/paymentsos-types'

import { getAccountLevelFeatureToggles } from '../../redux/featureToggles/selectors'
import { store } from '../../redux/store'

class FeaturesToggleUtil implements ccFeaturesToggleUtil {
  // eslint-disable-next-line class-methods-use-this
  get accountFeatures (): FeaturesToggle.ToggleByAccountResponse[] {
    return getAccountLevelFeatureToggles(store.getState())
  }

  isAccountOn (featureName: string): boolean {
    if (!this.accountFeatures.length) {
      return false
    }

    const feature = this.accountFeatures.find(feature => feature.feature_name === featureName)
    return feature?.is_on || false
  }
}

export default new FeaturesToggleUtil()
