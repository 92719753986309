import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import intervalToDuration from 'date-fns/intervalToDuration'
import produce from 'immer'
import { combineReducers } from 'redux'

import apiConfig from '../../../api/AnalyticsApiHelpers/apiConfig'
import colorPalette from '../../../styles/colorPalette'
import { getDimensionsPerPaymentStage } from '../api/requestBuilder'
import {
  FETCH_COUNTRIES,
  COUNTRIES_FETCH_SUCCESS,
  FETCH_APPS,
  APPS_FETCH_SUCCESS,
  FETCH_TREND_GRAPH,
  TREND_GRAPH_FETCH_SUCCESS,
  FETCH_DIMENSION_BARS,
  DIMENSION_BARS_FETCH_SUCCESS,
  FETCH_PAYMENT_STAGE_BREAKDOWN,
  PAYMENT_STAGE_BREAKDOWN_FETCH_SUCCESS,
  SELECT_PAYMENT_STAGE,
  SELECT_DATE,
  SELECT_COUNTRY,
  SELECT_APP,
  SELECT_GRANULARITY,
  SELECT_METRICS,
  SELECT_BAR
} from './actions'
import { DEFAULT_CURRENCY } from '../config'
import { stagesNames } from '../components/paymentStagesConfig'

const today = new Date()

const initialState = {
  paymentStagesData: {
    data: {},
    format: apiConfig.metrics.totalAmount
  },
  dimensionBarsData: {
    data: {},
    format: apiConfig.metrics.totalAmount
  },
  trendGraphData: {
    keys: {},
    graphData: [],
    format: apiConfig.metrics.totalAmount
  },
  dashboardCurrency: DEFAULT_CURRENCY,
  countriesData: undefined,
  appsData: undefined,
  paymentStage: stagesNames.INITIATED,
  from: startOfDay(today),
  to: endOfDay(today),
  countries: [],
  apps: [],
  granularity: apiConfig.granularity.hour,
  metrics: apiConfig.metrics.totalAmount,
  bar: apiConfig.dimensions.currency,
  colors: {}
}

const setDashboardGraphsColors = (state) => {
  const graphKeys = state.trendGraphData.keys
  const selectedBar = state.bar

  const colorMapping = {}

  Object.keys(graphKeys[selectedBar] || {}).forEach((item) => {
    if (!colorMapping[item]) {
      const { length } = Object.values(colorMapping)
      colorMapping[item] = colorPalette[length]
    }
  })

  state.colors = colorMapping
}

// Reducers
export const dashboard = produce((state, action) => {
  switch (action.type) {
    case COUNTRIES_FETCH_SUCCESS: {
      state.countriesData = action.countries
      break
    }
    case APPS_FETCH_SUCCESS: {
      state.appsData = action.apps
      break
    }
    case TREND_GRAPH_FETCH_SUCCESS: {
      const { keys, graphData } = action.graph
      const format = state.metrics

      state.trendGraphData = { keys, graphData, format }
      setDashboardGraphsColors(state)
      break
    }
    case PAYMENT_STAGE_BREAKDOWN_FETCH_SUCCESS: {
      const format = state.metrics

      state.paymentStagesData = {
        data: action.data?.paymentStages,
        format
      }
      state.dashboardCurrency = action.data?.dashboardCurrency || DEFAULT_CURRENCY

      break
    }
    case DIMENSION_BARS_FETCH_SUCCESS: {
      const data = action.bars
      const format = state.metrics

      state.dimensionBarsData = { data, format }
      break
    }
    case SELECT_PAYMENT_STAGE: {
      const { bar } = state
      let newSelectedBar = bar
      const barsKeys = getDimensionsPerPaymentStage(action.payment)
      if (!barsKeys.includes(bar)) {
        newSelectedBar = barsKeys.slice(0, 1).toString()
      }

      state.paymentStage = action.payment
      state.bar = newSelectedBar
      break
    }
    case SELECT_COUNTRY: {
      state.countries = action.countries
      break
    }
    case SELECT_APP: {
      state.apps = action.apps
      break
    }
    case SELECT_DATE: {
      const { from, to } = action
      const fromDate = new Date(from)
      const toDate = new Date(to)
      const { years } = intervalToDuration({ start: fromDate, end: toDate })

      state.from = years === 0 ? startOfDay(from) : fromDate
      state.to = years === 0 ? endOfDay(to) : toDate
      break
    }
    case SELECT_GRANULARITY: {
      const { granularity } = action
      state.granularity = granularity
      break
    }
    case SELECT_METRICS: {
      state.metrics = action.metrics
      state.metricsFormat = action.metrics
      break
    }
    case SELECT_BAR: {
      state.bar = action.bar
      setDashboardGraphsColors(state)
      break
    }
  }
}, initialState)

const apiReducerInitialState = {
  isFetchingCountries: false,
  isFetchingBusinessApps: false,
  isFetchingGraph: false,
  isFetchingBars: false,
  isFetchingPayment: false
}

export const dashboardApi = produce((state, action) => {
  switch (action.type) {
    case FETCH_APPS:
      state.isFetchingBusinessApps = true
      break
    case APPS_FETCH_SUCCESS:
      state.isFetchingBusinessApps = false
      break
    case FETCH_COUNTRIES:
      state.isFetchingCountries = true
      break
    case COUNTRIES_FETCH_SUCCESS:
      state.isFetchingCountries = false
      break
    case FETCH_TREND_GRAPH:
      state.isFetchingGraph = true
      break
    case TREND_GRAPH_FETCH_SUCCESS:
      state.isFetchingGraph = false
      break
    case FETCH_DIMENSION_BARS:
      state.isFetchingBars = true
      break
    case DIMENSION_BARS_FETCH_SUCCESS:
      state.isFetchingBars = false
      break
    case FETCH_PAYMENT_STAGE_BREAKDOWN:
      state.isFetchingPayment = true
      break
    case PAYMENT_STAGE_BREAKDOWN_FETCH_SUCCESS:
      state.isFetchingPayment = false
      break
  }
}, apiReducerInitialState)

const dashboardReducer = combineReducers({ dashboard, dashboardApi })
export default dashboardReducer
