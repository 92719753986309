import * as React from 'react'
import { useSelector } from 'react-redux'
import { RouteProps } from 'react-router'
import { Route, Redirect, Switch } from 'react-router-dom'

import * as ENVIRONMENTS from './constants/environments'
import App from './features/App/App'
import Locations from './helpers/tools/locations'
import * as authSelectors from './redux/auth/selectors'

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn)

  return (
    <Route
      {...rest}
      render={props => (isLoggedIn
        ? <Component {...props} />
        : (
          <Redirect to={{ pathname: Locations.login(), state: { from: props.location } }} />
          )
      )}
    />
  )
}

const RouterSignedIn: React.VFC = () => (
  <Switch>
    <PrivateRoute
      path={Locations.root(ENVIRONMENTS.TEST)}
      component={App}
    />
    <PrivateRoute
      path={Locations.root(ENVIRONMENTS.LIVE)}
      component={App}
    />
  </Switch>
)

export default RouterSignedIn
