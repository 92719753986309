import React from 'react'
import { PermissionsRouteProps } from 'cc-components'
import { RouteProps } from 'react-router'
import { Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { UserTypeContext } from 'cc-locations'

import PermissionsRoute from '../../helpers/permissions/PermissionsRoute'
import ErrorBoundary from './components/ErrorBoundary'
import actions from '../../redux/app/actions'

export interface CustomRouteProps extends RouteProps {
  permission?: string | (() => boolean),
  crossEnvPage?: boolean,
  userTypeContext: UserTypeContext
}

const CustomRoute: React.FC<CustomRouteProps> = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(actions.setCurrentMicroAppContext({
      userTypeContext: rest.userTypeContext
    }))
  }, [rest.userTypeContext])

  if (rest.permission) {
    return (
      <PermissionsRoute component={Component} {...(rest as PermissionsRouteProps)} />
    )
  }
  return (
    <Route
      {...rest}
      render={props => (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      )}
    />
  )
}

export default CustomRoute
