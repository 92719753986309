import envConfig from '../envConfig'
import FeaturesToggleUtil from '../helpers/permissions/FeaturesToggleUtil'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { isFeatureAllowed, combinePathsWithEnv } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'
import { isMasqueraderUser } from '../redux/auth/selectors'
import { store } from '../redux/store'

const { permissions } = PermissionsUtil

const PAYMENT_LINKS_FEATURE_NAME = 'pay_by_link'

export const PaymentLinksPermissionsCheck = (): boolean => {
  const isMasquerader = isMasqueraderUser(store.getState())
  if (isMasquerader) {
    return true
  }

  const requiredPermissions = [
    permissions.paymentLinks.get
  ]
  const hasPermissions = isFeatureAllowed(PermissionsUtil.all, requiredPermissions)
  const isFeatureOn = FeaturesToggleUtil.isAccountOn(PAYMENT_LINKS_FEATURE_NAME)

  return hasPermissions && isFeatureOn
}

const App: MicroApp = {
  name: 'payment_links',
  importFunction: async (version: string) => System.import(`${envConfig.apps.paymentLinks}app.${version}.js`),
  getVersion: async (): Promise<string> => {
    const version = await System.import(`${envConfig.apps.paymentLinks}version.js?c=${Date.now()}`)
    return version.default
  },
  permissionsCheck: (): boolean => PaymentLinksPermissionsCheck(),
  paths: combinePathsWithEnv([Locations.paymentLinkDetailsPage,
    Locations.createPaymentLinkPage,
    Locations.paymentLinksPage])
}

export default App
