import React from 'react'
import { useLocalize } from '@zooz/react-localize'
import { PageForm, H1, Card, SideNote, Action, Hr } from '@zooz/generic-ui-components'
import { useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { Link, RouteComponentProps } from 'react-router-dom'

import { getEnvironment } from '../../../../redux/environment/selectors'
import { withLoadTranslations } from '../../../Localize/HOCs'
import { ScreenNames } from '../../../Localize/screens'
import menus, { MenuEntry } from '../../../Sidebar/menus'
import language from './language'

import style from './style.scss'

export const PageNotFound: React.FC<RouteComponentProps> = ({ location }) => {
  const { t } = useLocalize()
  const environment = useSelector(getEnvironment)
  let relativeMenuParent: MenuEntry = null
  menus.forEach((parentMenus) => {
    const { children } = parentMenus
    if (children) {
      children.forEach(({ disabled, link }) => {
        const isLinkDisplayed = !disabled({ environment })
        const isLinkPartOfPath = matchPath(link(environment), {
          path: location.pathname,
          exact: false
        })
        if (isLinkDisplayed && isLinkPartOfPath) {
          relativeMenuParent = parentMenus
        }
      })
    }
  })

  if (relativeMenuParent) {
    return (
      <PageForm>
        <Card data-test='PageNotFound__index' className={style.MenusAvailable} type={Card.CARD_TYPES.MESSAGE}>
          <div className={style.MenusAvailable__top}>
            <H1 className={style.MenusAvailable__top__title}>{t(relativeMenuParent.translationKey, {
              fallback: relativeMenuParent.text
            })}
            </H1>
            <SideNote>{language.likeToGo(t)}</SideNote>
          </div>
          <Hr className={style.hr} />
          <ul>
            {relativeMenuParent.children.map(({ link, translationKey, text, disabled }) => {
              if (disabled({ environment })) {
                return null
              }
              return (
                <li key={translationKey}>
                  <Link to={link(environment)}>
                    <Action data-test='PageNotFound__link' type={Action.TYPES.GHOST}>
                      {t(translationKey, {
                        fallback: text
                      })}
                    </Action>
                  </Link>
                </li>
              )
            })}
          </ul>
        </Card>
      </PageForm>
    )
  }
  return (
    <div className={style.PageNotFound} data-test='page-not-found'>
      { language.pageNotFound(t) }
    </div>
  )
}

export default withLoadTranslations<RouteComponentProps>({
  screenName: ScreenNames.PageNotFound
})(PageNotFound)
