import React from 'react'
import { useLocalize } from '@zooz/react-localize'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import MenuEntry from '../MenuEntry'
import Beta from './Beta'

import style from './style.scss'

interface MenuItemProps extends React.HTMLAttributes<HTMLLIElement>{
  text: string,
  translationKey: string,
  icon?: string,
  disabled: boolean,
  isActivePath?: boolean,
  isCollapsed?: boolean,
  stub?: boolean,
  link: string,
  secondNavigation?: boolean,
  beta?: boolean,
  className?: string,
  ref?: React.Ref<HTMLLIElement>
}

const MenuItem: React.ForwardRefExoticComponent<MenuItemProps> = React.forwardRef((
  {
    stub,
    text,
    disabled,
    link,
    icon,
    secondNavigation,
    isActivePath,
    className,
    beta,
    isCollapsed,
    translationKey,
    ...rest
  },
  ref: React.Ref<HTMLLIElement>
) => {
  const { t } = useLocalize()
  const itemClasses = classnames(className,
    style['paymentsos-menu-item'],
    {
      [style['paymentsos-menu-item--second-navigation']]: secondNavigation,
      [style['paymentsos-menu-item--selected']]: isActivePath
    })

  if (disabled) {
    return null
  }

  return (
    <li
      data-test='menu-list-item'
      {...rest}
      ref={ref}
      className={itemClasses}
    >
      <Link to={link}>
        <MenuEntry
          isCollapsed={isCollapsed}
          data-test={!stub && `menu-${text.toLowerCase().split(' ').join('-')}`}
          icon={icon}
        >
          {t(translationKey, {
            fallback: text
          })}
          {beta && <Beta hidden={isCollapsed} />}
        </MenuEntry>
      </Link>
    </li>
  )
})

export default MenuItem
