import * as SentryReact from '@sentry/react'

import { getAccountId } from '../redux/account/selectors'
import { history, store } from '../redux/store'
import envConfig from '../envConfig'

class Sentry {
  private sentryDSN: string
  constructor () {
    // create init sentry options
    const sentryInit: SentryReact.BrowserOptions = {
      release: process.env.REACT_APP_TAG_NAME
    }

    this.sentryDSN = envConfig.sentryDSN
    if (!this.sentryDSN) {
      console.warn('Sentry DSN was not set')
    }
    sentryInit.dsn = this.sentryDSN

    // set sampling rate
    const ENV_SAMPLING_RATE_NUMBER = Number(envConfig.sentrySamplingRate)
    sentryInit.tracesSampleRate = (
      !isNaN(ENV_SAMPLING_RATE_NUMBER) &&
      ENV_SAMPLING_RATE_NUMBER > 0 &&
      ENV_SAMPLING_RATE_NUMBER <= 1
    )
      ? ENV_SAMPLING_RATE_NUMBER
      : 0.05

    // create tracing options
    const browserTracingOptions = {
      routingInstrumentation: SentryReact.reactRouterV5Instrumentation(history)
    }
    // TODO: uncomment after adjusting the headers required by Sentry [https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/#tracingorigins]
    // try {
    //   const apiHost = new URL(envConfig.apiUrl).host
    //   browserTracingOptions.tracingOrigins = [apiHost]
    // } catch (err) {
    //   console.warn('API_URL is invalid, not setting tracing origin to sentry')
    // }

    // add tracing integration
    sentryInit.integrations = [new SentryReact.BrowserTracing(browserTracingOptions)]

    // init sentry
    SentryReact.init(sentryInit)
  }

  captureException = (err: Error) => {
    if (!process.env.REACT_APP_TAG_NAME) {
      console.error(`Error occurred. wasn't sent to Sentry, as the release is not a tag:`, err)
      return
    }
    if (!this.sentryDSN) {
      return
    }
    if (store) {
      SentryReact.setUser({
        accountId: getAccountId(store.getState())
      })
    }
    SentryReact.captureException(err)
  }
}

export default new Sentry()
