import React from 'react'
import { useLocalize } from '@zooz/react-localize'
import { LabeledCheckbox, Error } from '@zooz/generic-ui-components'

import { externalLocations } from '../../../../helpers/tools/locations/externalLocations'
import FormItem from '../FormItem'
import language from './language'

import css from './TermsAndConditions.scss'

interface TermsAndConditionsProps {
  onChange?: (isChecked: boolean) => void,
  checked?: boolean,
  error?: boolean
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ onChange, checked, error }) => {
  const { t } = useLocalize()
  return (
    <FormItem>
      <LabeledCheckbox
        className={css.labeledCheckboxWrapper}
        checkboxClassName={css.checkbox}
        checked={checked}
        onChange={onChange}
      >
        <span className={css.checkBoxText}>{ language.termsAndConditions.first(t) }
          <a href={externalLocations.termsOfUse} rel='noopener noreferrer' target='_blank'>
            { language.termsAndConditions.termsOfUse(t) }
          </a>
          { language.termsAndConditions.connector(t) }
          <a href={externalLocations.privacyPolicy} rel='noopener noreferrer' target='_blank'>
            { language.termsAndConditions.privacy(t) }
          </a>
        </span>
      </LabeledCheckbox>
      { error && <Error>{ language.termsAndConditions.error(t) }</Error> }
    </FormItem>
  )
}

export default TermsAndConditions
