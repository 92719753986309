import { ToasterTypes } from '@zooz/generic-ui-components'
import produce from 'immer'

import {
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  ShowMessageAction,
  HideMessageAction
} from './actions'

export interface Message {
  code: string,
  id: string,
  text: string,
  type?: ToasterTypes
}

export interface State {
  messages: Message[]
}

const initialState = {
  messages: new Array<Message>()
}

const messagesReducer = produce((state: State, action: ShowMessageAction | HideMessageAction) => {
  switch (action.type) {
    case SHOW_MESSAGE: {
      const { code, text, messageType, id } = action
      state.messages.push({ code, text, id, type: messageType })
      break
    }
    case HIDE_MESSAGE: {
      const { id } = action
      state.messages = state.messages.filter(msg => msg.id !== id)
      break
    }
  }
}, initialState)

export default messagesReducer
