import { Environments } from '../../../../portal-types/environments'
import * as ENVIRONMENTS from '../../../constants/environments'
import Locations from '../../../helpers/tools/locations'
import menus, { MenuEntry } from '../../Sidebar/menus'

const getLocation = (menu: MenuEntry, defaultEnv: Environments) => {
  if (menu.disabled && !menu.disabled({ environment: defaultEnv })) {
    return menu.link(defaultEnv)
  }
  return undefined
}

export const getFallBackPageUrl = (defaultEnv: Environments = ENVIRONMENTS.TEST): string => {
  let location: string = null

  menus.forEach((menu) => {
    if (!location) {
      if (!menu.children) {
        location = getLocation(menu, defaultEnv)
      } else if (menu.children) {
        menu.children.forEach((child) => {
          if (!location) {
            location = getLocation(child, defaultEnv)
          }
        })
      }
    }
  })

  return location || Locations.noPermission({ env: defaultEnv })
}
