import produce from 'immer'
import { Managment } from '@payu/paymentsos-types'

import { types } from './actions'

export interface ProvidersByEnv {
  live: Managment.ProviderResource[],
  test: Managment.ProviderResource[]
}

export interface State {
  providers: ProvidersByEnv
}

export const initialState: State = {
  providers: { live: [], test: [] }
}

const providersReducer = produce((state, action) => {
  switch (action.type) {
    case types.FETCH_PROVIDERS_SUCCESS:
      state.providers = {
        live: action.providersLive,
        test: action.providersTest
      }
  }
}, initialState)

export default providersReducer
