import { Permissions, PermissionsUtil as ccPermissionsUtil } from 'cc-permissions-utils'
import { flatten } from 'lodash'
import { Managment } from '@payu/paymentsos-types'

import permissions from '../../constants/permissions'
import { userRoles, masqueraderRoles } from '../../redux/roles/selector'
import { store } from '../../redux/store'

class PermissionsUtil implements ccPermissionsUtil {
  // eslint-disable-next-line class-methods-use-this
  get permissions (): Permissions {
    return permissions
  }

  // eslint-disable-next-line class-methods-use-this
  get roles (): Managment.RoleResource[] {
    return userRoles(store.getState())
  }

  // eslint-disable-next-line class-methods-use-this
  get masqueraderRoles (): Managment.RoleResource[] {
    return masqueraderRoles(store.getState())
  }

  isAllowed (permission: string | (() => boolean), roles = this.roles): boolean {
    if (!roles) {
      return false
    }

    if (this.isAdmin(roles)) {
      return true
    }
    if (typeof permission === 'function') {
      return permission()
    }
    return this.findPermission(permission, roles)
  }

  private isAdmin (roles = this.roles): boolean {
    if (!roles) {
      return false
    }

    return !!roles.find(role => role.permissions[0] === '.')
  }

  any (permissions: string[], roles = this.roles): () => boolean {
    return (): boolean => permissions.reduce<boolean>((accumulated, permission) => {
      if (accumulated) {
        return true
      }
      return this.findPermission(permission, roles)
    }
    , false)
  }

  all (permissions: string[], roles = this.roles): () => boolean {
    return (): boolean => permissions.reduce<boolean>((accumulated, permission) => {
      if (!accumulated) {
        return false
      }
      return this.findPermission(permission, roles)
    }, true)
  }

  private findPermission (_permission: string, roles = this.roles): boolean {
    if (!roles) {
      return false
    }
    const allRolesPermissions = PermissionsUtil
      .findAllRolesPermissions(roles)
      .map(permission => permission.toLowerCase())
    const permissions = _permission.toLowerCase().split('.')

    while (permissions.length) {
      if (allRolesPermissions.includes(permissions.join('.'))) {
        return true
      }
      permissions.pop()
    }

    return false
  }

  findPermissionsByPrefix (_prefix: string, roles = this.roles): string[] {
    if (!roles?.length || !_prefix?.trim().length) {
      return []
    }
    const allRolesPermissions = PermissionsUtil.findAllRolesPermissions(roles)
    const matchingPermissions = allRolesPermissions
      .filter(permission => permission.startsWith(_prefix))
    return Array.from(new Set(matchingPermissions))
  }

  // eslint-disable-next-line class-methods-use-this
  private static findAllRolesPermissions (roles: Managment.RoleResource[]): string[] {
    return flatten(roles.map(role => role.permissions))
  }
}

export default new PermissionsUtil()
