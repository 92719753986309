import _ from 'lodash'
import { createSelector } from 'reselect'

import BreadcrumbMapper from '../../../../../constants/BreadcrumbMapper'
import { TEST } from '../../../../../constants/environments'
import { getLocation } from '../../../../../redux/location/selectors'
import { PathSnippet } from '../Breadcrumbs'
import MODES from './MODES'
import { State } from './reducer'

const prettifyCrumb = (crumb: string): string => {
  if (!crumb) {
    return crumb
  }

  return crumb.split('_').map(_.capitalize).join(' ')
}

interface CrumbsState {
  crumbOverrides: State
}

export const crumbOverrides = (state: CrumbsState): string[] | PathSnippet[] => state.crumbOverrides.crumbs
export const crumbMode = (state: CrumbsState): MODES => state.crumbOverrides.mode

export const BreadCrumbsService = {

  pathToSnippets: (path: string): string[] => path.split('/').filter(i => i),

  removePathFirstDash: (path: string): string => path.split('/').filter(i => i).join('/'),

  // ToDo: remove once state is typed
  // @ts-ignore: state not typed yet
  crumbs: createSelector(
    crumbOverrides,
    getLocation,
    crumbMode,
    (
      crumbOverrides: string[] | PathSnippet[],
      location: Location,
      mode: MODES = MODES.AUTO
    ): PathSnippet[] | undefined => {
      if (location) {
        if (mode === MODES.AUTO) {
          const snippets = BreadCrumbsService.pathToSnippets(location.pathname).slice(0).reverse()
          const newSnippets = snippets.map((snippet, index) => (snippet !== TEST) && {
            path: snippets.slice(0).reverse().slice(0, snippets.length - index).join('/'),
            text: crumbOverrides[index] || BreadcrumbMapper[snippet] || prettifyCrumb(snippet)
          }).filter(i => i).reverse()
          // @ts-ignore: ignoring for now. need to understand of it can really have PathSnippet nested in text as might happen
          return newSnippets
        }
        return (crumbOverrides as PathSnippet[]).map(snippet => ({
          ...snippet,
          path: snippet.path ? BreadCrumbsService.removePathFirstDash(snippet.path) : undefined
        }))
      }
      return undefined
    }
  )
}
