import React from 'react'

import style from './FormItem.scss'

const FormItem: React.FC = ({ children }) => (
  <div className={style.item}>
    {children}
  </div>
)

export default FormItem
