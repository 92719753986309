import React from 'react'
import classnames from 'classnames'
import Svg from 'react-inlinesvg'

import logoLarge from '../../assets/payu_enterprise_logo.svg'
import logoSmall from '../../assets/payu-u-logo.svg'

import style from './style.scss'

const Logo: React.FC<{ collapsed?: boolean }> = ({ collapsed }) => (
  <div
    data-test='menu-paymentsos-logo'
    className={classnames(style.wrapper, {
      [style.collapsed]: collapsed
    })}
  >
    <div className={style.large}>
      <Svg src={logoLarge} />
    </div>
    <Svg className={style.small} src={logoSmall} />
  </div>
)

export default Logo
