import ccLocations from 'cc-locations'

import { Environments } from '../../portal-types/environments'
import * as ENVIRONMENTS from '../constants/environments'
import PermissionsUtil from './permissions/PermissionsUtil'

const hasPermissions = (
  restrictionType: (params: any) => () => boolean,
  requiredPermissions: Array<(env?: Environments) => string>,
  env: Environments
): boolean => {
  const envPermissions = requiredPermissions.map(permissionFunc => permissionFunc(env))
  return PermissionsUtil.isAllowed(restrictionType.call(PermissionsUtil, envPermissions))
}

const isFeatureAllowed = (
  restrictionType: (params: any) => () => boolean,
  requiredPermissions: Array<(env?: Environments) => string>
): boolean => {
  const testPermissions = hasPermissions(restrictionType, requiredPermissions, ENVIRONMENTS.TEST)
  const livePermissions = hasPermissions(restrictionType, requiredPermissions, ENVIRONMENTS.LIVE)

  return testPermissions || livePermissions
}

const combinePathsWithEnv = (paths: Array<(params?: ccLocations.EnvLocation) => string>): string[] => {
  const retPaths: string[] = []

  paths.forEach((path) => {
    retPaths.push(path({ env: ENVIRONMENTS.TEST }))
    retPaths.push(path({ env: ENVIRONMENTS.LIVE }))
  })

  return retPaths
}

const combinePathsWithEnvAndContext = (
  paths: Array<{
    path: (params?: ccLocations.EnvLocation) => string,
    userTypeContext: ccLocations.UserTypeContext
  }>
): ccLocations.PathAndContext[] => {
  const retPaths: ccLocations.PathAndContext[] = []

  paths.forEach(({ path, userTypeContext }) => {
    retPaths.push({ path: path({ env: ENVIRONMENTS.TEST }), userTypeContext })
    retPaths.push({ path: path({ env: ENVIRONMENTS.LIVE }), userTypeContext })
  })

  return retPaths
}

export {
  isFeatureAllowed,
  combinePathsWithEnv,
  combinePathsWithEnvAndContext
}
