// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JoinExistingUser__buttonsBox___urVfX{display:flex;flex-direction:column;align-items:center;gap:10px}.JoinExistingUser__MessageBox___ktnJI{display:flex;background-color:#f6f7fa;border-radius:6px;padding:10px;margin-bottom:10px}.JoinExistingUser__MessageBox__icon___XTjzK{margin-top:5px}.JoinExistingUser__MessageBox__text___zyldN{font-size:12px;margin-left:10px}`, "",{"version":3,"sources":["webpack://./src/features/UnProtected/JoinPage/components/JoinExistingUser.scss"],"names":[],"mappings":"AAEA,sCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CAGF,sCACE,YAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CAEA,4CACE,cAAA,CAGF,4CACE,cAAA,CACA,gBAAA","sourcesContent":["@import '~@zooz/generic-ui-components/shared-styles/colors';\n\n.buttonsBox {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n}\n\n.MessageBox {\n  display: flex;\n  background-color: #F6F7FA;\n  border-radius: 6px;\n  padding: 10px;\n  margin-bottom: 10px;\n\n  &__icon {\n    margin-top: 5px;\n  }\n\n  &__text {\n    font-size: 12px;\n    margin-left: 10px;\n\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default-turquoise": `#0d91bd`,
	"error-red": `#f9453e`,
	"danger-red": `#eb7a7c`,
	"border-grey": `#e9e9e9`,
	"white": `#fff`,
	"default-blue": `#557eff`,
	"active-blue": `#3750cd`,
	"glowing-blue": `#0d47ff`,
	"text-black": `#555`,
	"text-grey": `#778195`,
	"disabled-grey-background": `#f9fafb`,
	"disabled-grey-text": `#a8a8a8`,
	"disabled-light-grey-text": `#c2c2c2`,
	"disabled-grey-button": `#969696`,
	"disabled-light-grey-button": `#c2c2c2`,
	"disabled-grey-button-hover": `#545454`,
	"success-green": `#03b571`,
	"list-item-grey": `#f3f3f3`,
	"outline-color": `#79c2db`,
	"greyout": `#fafafa`,
	"button-hover": `#7395ff`,
	"button-active": `#3750cd`,
	"light-orange": `#eba205`,
	"input-focus-shadow": `rgba(13,145,189,.2509803922)`,
	"buttonsBox": `JoinExistingUser__buttonsBox___urVfX`,
	"MessageBox": `JoinExistingUser__MessageBox___ktnJI`,
	"MessageBox__icon": `JoinExistingUser__MessageBox__icon___XTjzK`,
	"MessageBox__text": `JoinExistingUser__MessageBox__text___zyldN`
};
export default ___CSS_LOADER_EXPORT___;
