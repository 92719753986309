import { CcLocalization } from 'cc-localization'

import withLoadTranslations from '../features/Localize/HOCs/withLoadTranslations'
import localize from '../features/Localize/localize'
import { MicroAppScreenNames, ScreenNames } from '../features/Localize/screens'

const ccLocalization: CcLocalization = {
  ScreenNames,
  MicroAppScreenNames,
  localizeInstance: localize,
  withLoadTranslations
}

export default ccLocalization
