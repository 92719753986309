import { createSelector } from '@reduxjs/toolkit'
import { ShortDateFormats } from '@zooz/react-localize'
import { AllScreenNamesType } from 'cc-localization'
import { Language } from '@zooz/localize'

import permissions from '../../../constants/permissions'
import PermissionsUtil from '../../../helpers/permissions/PermissionsUtil'
import { userRoles } from '../../../redux/roles/selector'
import { PortalState } from '../../../redux/store'
import { MicroAppScreenNames, ScreenNames } from '../screens'
import { ScreensLoadingStatus } from './slice'

export const getIsLocalizedInit = (state: PortalState): boolean => state.localization.isInit

export const getIsLocalizationLoading = (state: PortalState): boolean => state.localization.isLoading

export const getSelectedLanguage = (state: PortalState): string => state.localization.language

export const getLocalizationLanguages = (state: PortalState): Language[] => state.localization.languages

export const getLanguagesByStatus = createSelector(
  [userRoles, getLocalizationLanguages],
  (roles, languages = []): Language[] => {
    const isTranslator = PermissionsUtil.isAllowed(permissions.localization.screens.get(), roles)
    if (isTranslator) {
      return languages
    }
    return languages.filter(language => language.status !== 'in_progress')
  }
)

export const getIsPreviewMode = (state: PortalState): boolean => state.localization.isPreviewMode

const screenNames = [...Object.values(ScreenNames), ...Object.values(MicroAppScreenNames)]

const getScreensLoading = (state: PortalState): ScreensLoadingStatus => state.localization.screensLoading

type ScreenLoadingStatusByName = {
  [key in AllScreenNamesType]?: (state: PortalState) => boolean
}

export const getLoadingStatusByScreenName: ScreenLoadingStatusByName = screenNames.reduce((acc, screenName) => {
  acc[screenName] = createSelector(
    getScreensLoading,
    (screensLoadingStatus): boolean => screensLoadingStatus[screenName] ?? false
  )
  return acc
}, {} as ScreenLoadingStatusByName)

export const getMountedScreens = (state: PortalState): AllScreenNamesType[] => state.localization.mountedScreens

export const getLocalizationDateFormat = (state: PortalState): ShortDateFormats => state.localization.dateFormat
