import React, { ReactNode } from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import MenuEntry from '../MenuEntry'

import style from './style.scss'

interface SubmenuProps {
  children: ReactNode[];
  text: string;
  icon: string;
  isCollapsed?: boolean;
  isSelected?: boolean;
  stub?: boolean;
  locked?: boolean;
  expanded?: boolean;
  ref?: React.Ref<HTMLLIElement>;
}

const Submenu = React.forwardRef<HTMLLIElement, SubmenuProps>(({
  children,
  icon,
  text,
  locked,
  isCollapsed,
  isSelected,
  stub,
  expanded: expandedProp
}, ref: React.Ref<HTMLLIElement>) => {
  const [expanded, setExpanded] = React.useState(expandedProp || false)

  let oneChildEnabled = false
  React.Children.forEach(children, (element) => {
    if (!React.isValidElement(element)) { return }

    const { disabled } = element.props

    if (oneChildEnabled || !disabled) {
      oneChildEnabled = true
    }
  })
  if (!oneChildEnabled) {
    return null
  }

  const isChildSelected = React.Children.map(children, child => _.get(child, 'props.isActivePath', false))
    .find(isActive => isActive)

  return (
    <li
      className={style.submenu}
      data-test='sub-menu'
      ref={ref}
      data-expanded={String(expanded && !locked)}
    >
      <div
        className={classnames(style.submenu__text, {
          [style['submenu__text--selected']]: isSelected || (!expanded && isChildSelected),
          [style['submenu__text--collapsed']]: isCollapsed
        })}
        onClick={() => setExpanded(!expanded)}
        data-test='sub-menu-clicker'
      >
        <MenuEntry
          isCollapsed={isCollapsed}
          data-test={!stub && `menu-${text.toLowerCase().replace(' ', '-')}`}
          icon={icon}
        >
          {text}
        </MenuEntry>
      </div>
      <ul data-children={React.Children.count(children)} className={style.submenu__menus}>
        {children}
      </ul>
    </li>
  )
})
Submenu.displayName = 'Submenu'

export default Submenu
