import React from 'react'
import { Spinner } from '@zooz/generic-ui-components'

import styles from './LargeSpinner.scss'

const LargeSpinner: React.FC = () => (
  <div className={styles.spinnerContainer}>
    <Spinner size={styles['spinner-size']} thickness={styles['spinner-width']} inverted />
  </div>
)

export default LargeSpinner
