import { combineReducers } from 'redux'

import activationRegistrationUIReducer, { State } from './reducers/activationRegistrationUIReducer'

export interface ActivationUiReducerState {
  activationRegistrationUIReducer: State
}

const activationUIReducer = combineReducers({ activationRegistrationUIReducer })
export default activationUIReducer
