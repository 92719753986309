import { MemoizedTranslationFunction } from '@zooz/react-localize/src/external-utils'

import language from '../language'

const stagesNames = {
  INITIATED: 'initiated',
  BLOCKED: 'blocked',
  VOIDED: 'voided',
  DECLINED: 'declined',
  SUCCESSFUL: 'successful',
  REFUND: 'refund'
} as const

const GREEN = 'green'
const RED = 'red'

export type PaymentStageNamesTypes = typeof stagesNames[keyof typeof stagesNames]

export interface DefaultPaymentStageConfig {
  description: string;
  percentOf: string;
  hasParenthesis: boolean;
  percentColor: typeof RED | typeof GREEN;
  showPercentage: boolean;
  showCurrencyTab: boolean;
  showMethodTab: boolean;
  showProviderTab: boolean;
}

export interface PaymentStageConfig extends DefaultPaymentStageConfig {
  translations: {
    label: MemoizedTranslationFunction;
    description: MemoizedTranslationFunction;
  };
}

const DEFAULT_COLOR = RED
const DEFAULT_PERCENT_OF = 'Initiated'

const StagesConfig: Record<PaymentStageNamesTypes, PaymentStageConfig> = {
  initiated: addDefaults({
    percentColor: GREEN,
    showPercentage: false,
    showMethodTab: false,
    showProviderTab: false,
    translations: {
      label: language.flow.label.initiated,
      description: language.flow.description.initiated
    }
  }),
  blocked: addDefaults({
    showPercentage: false,
    hasParenthesis: true,
    showProviderTab: false,
    translations: {
      label: language.flow.label.blocked,
      description: language.flow.description.blocked
    }
  }),
  voided: addDefaults({
    showPercentage: false,
    hasParenthesis: true,
    translations: {
      label: language.flow.label.voided,
      description: language.flow.description.voided
    }
  }),
  declined: addDefaults({
    showPercentage: false,
    hasParenthesis: true,
    translations: {
      label: language.flow.label.declined,
      description: language.flow.description.declined
    }
  }),
  successful: addDefaults({
    showPercentage: false,
    percentColor: GREEN,
    translations: {
      label: language.flow.label.successful,
      description: language.flow.description.successful
    }
  }),
  refund: addDefaults({
    showPercentage: false,
    percentOf: ' Successful',
    hasParenthesis: true,
    translations: {
      label: language.flow.label.refund,
      description: language.flow.description.refund
    }
  })
}

const stages = Object.keys(StagesConfig) as Array<PaymentStageNamesTypes>

function addDefaults <T extends Partial<DefaultPaymentStageConfig>> (stageConfig: T): T & DefaultPaymentStageConfig {
  return {
    description: '',
    percentOf: DEFAULT_PERCENT_OF,
    hasParenthesis: false,
    percentColor: RED,
    showPercentage: true,
    showCurrencyTab: true,
    showMethodTab: true,
    showProviderTab: true,
    ...stageConfig
  }
}

export {
  stages,
  StagesConfig,
  DEFAULT_COLOR,
  DEFAULT_PERCENT_OF,
  stagesNames
}
