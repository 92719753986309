import { Managment, Accounts } from '@payu/paymentsos-types'

const types = {
  FETCH_ACCOUNT: 'FETCH_ACCOUNT',
  ACCOUNT_FETCH_SUCCESS: 'ACCOUNT_FETCH_SUCCESS',
  FETCH_CONNECTED_ACCOUNTS: 'FETCH_CONNECTED_ACCOUNTS',
  CONNECTED_ACCOUNTS_FETCH_SUCCESS: 'CONNECTED_ACCOUNTS_FETCH_SUCCESS'
}

const actions = {
  accountFetchSuccess: (account: Managment.AccountResource) => ({ type: types.ACCOUNT_FETCH_SUCCESS, account }),
  fetchAccount: (accountId?: string) => ({ type: types.FETCH_ACCOUNT, accountId }),
  fetchConnectedAccounts: (accountId: string) => ({ type: types.FETCH_CONNECTED_ACCOUNTS, accountId }),
  fetchConnectedAccountsSuccess: (connectedAccounts: Accounts.ConnectedAccountsResponse) => ({
    type: types.CONNECTED_ACCOUNTS_FETCH_SUCCESS, connectedAccounts
  })
}

export default actions
export { types, actions }
