import { Action } from 'redux'

export const ActionTypes = {
  SIGN_UP_VERIFY_REQUEST: 'Portal/signUpVerify/request',
  SIGN_UP_VERIFY_SUCCESS: 'Portal/signUpVerify/success',
  SIGN_UP_VERIFY_FAIL: 'Portal/signupVerify/fail'
}

interface SignUpVerifyInfo {
  token: string;
  password: string;
}

export interface VerifySignUpAction extends Action<typeof ActionTypes.SIGN_UP_VERIFY_REQUEST> {
  payload: SignUpVerifyInfo;
}

export const verifySignUp = (signUpInfo: SignUpVerifyInfo): VerifySignUpAction => ({
  type: ActionTypes.SIGN_UP_VERIFY_REQUEST,
  payload: signUpInfo
})

export interface VerifySignUpSuccessAction extends Action<typeof ActionTypes.SIGN_UP_VERIFY_SUCCESS> {}

export const verifySignUpSuccess = (): VerifySignUpSuccessAction => ({
  type: ActionTypes.SIGN_UP_VERIFY_SUCCESS
})

export interface VerifySignUpFailAction extends Action<typeof ActionTypes.SIGN_UP_VERIFY_FAIL> {
  payload: {
    errorMessage: string;
  };
}

export const verifySignUpFail = (errorMessage: string): VerifySignUpFailAction => ({
  type: ActionTypes.SIGN_UP_VERIFY_FAIL,
  payload: { errorMessage }
})
