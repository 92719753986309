import { TranslationFunction } from '@zooz/react-localize'
import { global as globalTranslations, sign_up as signUpTranslations } from '@zooz/translation-values'

export default {
  welcomeTitle: (t: TranslationFunction): string => t(signUpTranslations.main_title_getStarted.key, {
    fallback: signUpTranslations.main_title_getStarted.fallback
  }),
  checkEmailTitle: (t: TranslationFunction): string => t(signUpTranslations.main_title_checkInbox.key, {
    fallback: signUpTranslations.main_title_checkInbox.fallback
  }),
  checkInbox: {
    emailSent: (t: TranslationFunction): string => t(signUpTranslations.checkInbox_lbl_sentEmail.key, {
      fallback: signUpTranslations.checkInbox_lbl_sentEmail.fallback
    }),
    verifyEmail: (t: TranslationFunction): string => t(signUpTranslations.checkInbox_lbl_verifyEmail.key, {
      fallback: signUpTranslations.checkInbox_lbl_verifyEmail.fallback
    })
  },
  link: {
    login: (t: TranslationFunction): string => t(signUpTranslations.main_lbl_login.key, {
      fallback: signUpTranslations.main_lbl_login.fallback
    })
  },
  signUpForm: {
    firstName: (t: TranslationFunction): string => t(globalTranslations.sharedText_customerProperties_firstName.key, {
      fallback: globalTranslations.sharedText_customerProperties_firstName.fallback
    }),
    lastName: (t: TranslationFunction): string => t(globalTranslations.sharedText_customerProperties_lastName.key, {
      fallback: globalTranslations.sharedText_customerProperties_lastName.fallback
    }),
    companyName: (t: TranslationFunction): string => t(signUpTranslations.signUpForm_lbl_companyName.key, {
      fallback: signUpTranslations.signUpForm_lbl_companyName.fallback
    }),
    domain: (t: TranslationFunction): string => t(signUpTranslations.signUpForm_lbl_domain.key, {
      fallback: signUpTranslations.signUpForm_lbl_domain.fallback
    }),
    email: (t: TranslationFunction): string => t(globalTranslations.sharedText_customerProperties_email.key, {
      fallback: globalTranslations.sharedText_customerProperties_email.fallback
    }),
    button: (t: TranslationFunction): string => t(signUpTranslations.signUpForm_btn_getStarted.key, {
      fallback: signUpTranslations.signUpForm_btn_getStarted.fallback
    })
  }
}
