import { Activations } from '@payu/paymentsos-types'

import { State } from '../redux/reducers/activationRegistrationUIReducer'
import { CUSTOM_BILLING_PLAN } from '../constants/planTypes'

export const buildAccountActivationsRequest = (accountDetails: State): Activations.PutActivation => ({
  contact_information: {
    business: accountDetails.contactInformation?.business,
    development: accountDetails.contactInformation?.development,
    financial: accountDetails.contactInformation?.financial
  },
  billing_package: accountDetails.selectedPlan || CUSTOM_BILLING_PLAN,
  business_information: {
    company_legal_name: accountDetails.businessInformation?.companyLegalName,
    business_registration_number: accountDetails.businessInformation?.businessRegistrationNumber,
    business_address: {
      address_line: `${accountDetails.businessAddress?.addressLine1}, ${accountDetails.businessAddress?.addressLine2}`,
      city: accountDetails.businessAddress?.city,
      state: accountDetails.businessAddress?.state,
      country: accountDetails.businessAddress?.country,
      zip_code: accountDetails.businessAddress?.zipCode
    }
  }
})
