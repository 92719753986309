import { utils } from '@zooz/react-localize'
import { global } from '@zooz/translation-values'

const { memoLang } = utils

export default {
  errors: {
    failedLangChange: 'Failed switching language',
    failedPreviewToggle: 'Failed toggling preview mode'
  },
  messages: {
    screenNotSupported: memoLang(global.sharedText_warnMsg_unsupportedScreen.key,
      global.sharedText_warnMsg_unsupportedScreen.fallback)
  }
}
