import { PortalScreenNamesMapType, MicroAppScreenNamesMapType } from 'cc-localization'

export const ScreenNames: PortalScreenNamesMapType = {
  // common
  Global: 'global',
  LoggedInApp: 'app_logged_in',
  // routes
  UserProfile: 'user_profile',
  Dashboard: 'dashboard',
  UserManagement: 'user_management',
  NoPermission: 'no_permissions',
  SignIn: 'sign_in',
  SignUp: 'sign_up',
  ResetPassword: 'reset_password',
  UnattachedUser: 'unattached_user',
  Activation: 'activation',
  Congratulations: 'congratulations',
  PageNotFound: 'page_not_found',
  OnBoarding: 'on_boarding',
  Join: 'join'
} as const

export const MicroAppScreenNames: MicroAppScreenNamesMapType = {
  AccountManagement: 'account_management',
  SFTP: 'SFTP', // part of accountManagement
  UsersManagement: 'user_management', // part of accountManagement
  BusinessUnits: 'business_units',
  DecisionEngine: 'decision_engine',
  InstantRetry: 'instant_retry',
  PaymentsSearch: 'payments_search',
  Reports: 'reports',
  Services: 'services',
  TransactionsApproval: 'transactions_approval',
  VirtualTerminal: 'virtual_terminal',
  Webhooks: 'webhooks',
  PaymentLinks: 'payment_links',
  BalancesManagement: 'balances_management',
  PayoutsManagement: 'payouts_management',
  TreasuryAnalytics: 'treasury_analytics'
} as const
