import produce, { Draft } from 'immer'
import { Currencies } from '@payu/paymentsos-types'

import { ActionTypes, FetchCurrenciesSuccessAction, FetchCurrenciesAction } from './actions'

export interface State {
  currencies: Currencies.Currency[]
}

const INITIAL_STATE: State = {
  currencies: []
}

const reducer = produce((state: Draft<State>, action: FetchCurrenciesSuccessAction | FetchCurrenciesAction) => {
  switch (action.type) {
    case ActionTypes.FETCH_CURRENCIES_SUCCESS:
      state.currencies = (action as FetchCurrenciesSuccessAction).payload.currencies
  }
}, INITIAL_STATE)

export default reducer
