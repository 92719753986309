import { TranslationFunction } from '@zooz/react-localize'
import { global as globalTranslations, user_profile as userProfileTranslations } from '@zooz/translation-values'

import { NumberFormats } from './types/index'

export default {
  update: (t: TranslationFunction): string => t(
    userProfileTranslations.main_btn_update.key,
    { fallback: userProfileTranslations.main_btn_update.fallback }
  ),
  detailsUpdatedSuccessfully: (t: TranslationFunction): string => t(
    userProfileTranslations.main_msgSuccess_detailsUpdated.key,
    { fallback: userProfileTranslations.main_msgSuccess_detailsUpdated.fallback }
  ),
  wrongPassword: (t: TranslationFunction): string => t(
    userProfileTranslations.main_msgError_wrongPassword.key,
    { fallback: userProfileTranslations.main_msgError_wrongPassword.fallback }
  ),
  passwords: {
    changePassword: (t: TranslationFunction): string => t(
      userProfileTranslations.main_title_changePassword.key,
      { fallback: userProfileTranslations.main_title_changePassword.fallback }
    ),
    currentPassword: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_currentPassword.key,
      { fallback: userProfileTranslations.main_lbl_currentPassword.fallback }
    ),
    newPassword: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_newPassword.key,
      { fallback: userProfileTranslations.main_lbl_newPassword.fallback }
    ),
    repeatPassword: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_repeatPassword.key,
      { fallback: userProfileTranslations.main_lbl_repeatPassword.fallback }
    )
  },
  profile: {
    userDetails: (t: TranslationFunction): string => t(
      userProfileTranslations.main_title_userDetails.key,
      { fallback: userProfileTranslations.main_title_userDetails.fallback }
    ),
    email: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_email.key,
      { fallback: userProfileTranslations.main_lbl_email.fallback }
    ),
    firstName: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_firstName.key,
      { fallback: userProfileTranslations.main_lbl_firstName.fallback }
    ),
    lastName: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_lastName.key,
      { fallback: userProfileTranslations.main_lbl_lastName.fallback }
    )
  },
  localization: {
    headerTitle: (t: TranslationFunction): string => t(
      userProfileTranslations.main_title_localizationSettings.key,
      { fallback: userProfileTranslations.main_title_localizationSettings.fallback }
    ),
    language: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_language.key,
      { fallback: userProfileTranslations.main_lbl_language.fallback }
    ),
    dateFormat: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_dateFormat.key,
      { fallback: userProfileTranslations.main_lbl_dateFormat.fallback }
    ),
    numberFormat: (t: TranslationFunction): string => t(
      userProfileTranslations.main_lbl_numberFormat.key,
      { fallback: userProfileTranslations.main_lbl_numberFormat.fallback }
    ),
    numberFormatOptions: {
      [NumberFormats['group=coma;decimal=period']]: (t: TranslationFunction): string => t(
        userProfileTranslations.main_localizationDateFormatOption_groupCommaDecimalDot.key,
        { fallback: userProfileTranslations.main_localizationDateFormatOption_groupCommaDecimalDot.fallback }
      ),
      [NumberFormats['group=period;decimal=comma']]: (t: TranslationFunction): string => t(
        userProfileTranslations.main_localizationDateFormatOption_groupDotDecimalComma.key,
        { fallback: userProfileTranslations.main_localizationDateFormatOption_groupDotDecimalComma.fallback }
      ),
      [NumberFormats['group=space;decimal=comma']]: (t: TranslationFunction): string => t(
        userProfileTranslations.main_localizationDateFormatOption_groupSpaceDecimalComma.key,
        { fallback: userProfileTranslations.main_localizationDateFormatOption_groupSpaceDecimalComma.fallback }
      ),
      [NumberFormats['group=space;decimal=period']]: (t: TranslationFunction): string => t(
        userProfileTranslations.main_localizationDateFormatOption_groupSpaceDecimalDot.key,
        { fallback: userProfileTranslations.main_localizationDateFormatOption_groupSpaceDecimalDot.fallback }
      )
    },
    preview: {
      headerTitle: (t: TranslationFunction): string => t(
        userProfileTranslations.main_lbl_formatPreview.key,
        { fallback: userProfileTranslations.main_lbl_formatPreview.fallback }
      ),
      date: (t: TranslationFunction): string => t(
        globalTranslations.sharedText_timeUnits_date.key,
        { fallback: globalTranslations.sharedText_timeUnits_date.fallback }
      ),
      shortDate: (t: TranslationFunction): string => t(
        userProfileTranslations.main_localizationPreview_shortDate.key,
        { fallback: userProfileTranslations.main_localizationPreview_shortDate.fallback }
      ),
      numbers: (t: TranslationFunction): string => t(
        userProfileTranslations.main_localizationPreview_numbers.key,
        { fallback: userProfileTranslations.main_localizationPreview_numbers.fallback }
      )
    }
  }
}
