import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import Locations from '../helpers/tools/locations'
import { combinePathsWithEnv } from '../helpers/routeUtil'
import { MicroApp } from '../types/microApp'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'

const { permissions } = PermissionsUtil

const microAppUrl = envConfig.apps.fraudManager

const App: MicroApp = {
  name: 'fraud_manager',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: () => {
    const getConfigurationPermission = permissions.fraudManager.configurations.get()
    const permissionsByPrefix = PermissionsUtil.findPermissionsByPrefix(
      getConfigurationPermission, PermissionsUtil.masqueraderRoles
    )
    return permissionsByPrefix.length > 0
  },
  paths: combinePathsWithEnv([
    Locations.fraudManagerPage,
    Locations.fraudFlowPage
  ])
}

export default App
