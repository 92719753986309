import { useEffect } from 'react'
import { UseCrumbs } from 'cc-hooks'
import { useDispatch } from 'react-redux'

import { actions as crumbActions } from '../../Topbar/components/Breadcrumbs/redux/actions'

export const useCrumbs: UseCrumbs = (reverseCrumbsList: string[]): null => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(crumbActions.addCrumbs(reverseCrumbsList))
    return () => {
      dispatch(crumbActions.clearCrumbs)
    }
  }, [reverseCrumbsList.toString()])

  return null
}
