import startOfDay from 'date-fns/startOfDay'
import subDays from 'date-fns/subDays'
import subYears from 'date-fns/subYears'

export const getStartOfDataAnalyticsCollection = (): Date => {
  const startOfTheDay = startOfDay(new Date())
  const startOfDataCollection = subYears(subDays(startOfTheDay, 1), 1)

  return startOfDataCollection
}
