import { Managment } from '@payu/paymentsos-types'
import { createSelector } from 'reselect'

import { getEnvironment } from '../environment/selectors'
import { ProvidersByEnv, State } from './reducer'

export interface Store {
  providersReducer: State
}

export const getProviders = (state: Store): ProvidersByEnv => state.providersReducer.providers

export const getCurrentEnvProviders =
  createSelector([getProviders, getEnvironment],
    (providers, environment) => providers[environment])

export const getCurrentEnvProvidersAsIdToNameMap = createSelector(
  getCurrentEnvProviders,
  (providers: Managment.ProviderResource[] = []) => {
    const map: Record<string, string> = {}
    providers.forEach((provider) => {
      map[provider.id] = provider.name
    })
    return map
  }
)

export interface ProviderNameLogoMap {
  [x: string]: string
}

export const getNameToLogoMap = createSelector(getProviders, (providers): ProviderNameLogoMap => {
  const reducer = (acc: ProviderNameLogoMap, provider: Managment.ProviderResource) => {
    acc[provider.name] = provider.icon ? provider.icon.replace('http:', 'https:') : null
    return acc
  }
  const nameToLogo = providers.live.reduce(reducer, {})
  return providers.test.reduce(reducer, nameToLogo)
})
