import { without } from 'lodash'
import { combineReducers } from 'redux'

import {
  REQUEST_EMAIL_CHANGE, REQUEST_FOCUS_CHANGE, RESET_FOCUS_CHANGE, RESET_INPUT_CHANGE, REQUEST_ERROR,
  RESET_ERROR, REQUEST_LOADING, RESET_LOADING
} from './actions'

export interface RequestResetReducer {
  loading: boolean,
  email: string,
  focused: string[],
  traveresed: string[],
  error: string
}

const requestResetInitialState: RequestResetReducer = {
  loading: false,
  email: '',
  focused: [],
  traveresed: [],
  error: null
}

export const requestReset = (state = requestResetInitialState, action: any) => {
  switch (action.type) {
    case REQUEST_EMAIL_CHANGE: {
      return {
        ...state,
        email: action.email
      }
    }
    case REQUEST_FOCUS_CHANGE: {
      const toAdd = action.focus
      let { traveresed } = state
      if (action.focus) {
        traveresed = traveresed.concat('email')
      }
      return {
        ...state,
        traveresed,
        focused: toAdd ? state.focused.concat(action.focusId) : without(state.focused, action.focusId)
      }
    }
    case REQUEST_ERROR: {
      return {
        ...state,
        error: action.error
      }
    }
    case REQUEST_LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }
    default:
      return state
  }
}

export interface ResetReducer {
  loading: boolean,
  error: string,
  email: string,
  password: string,
  passwordAgain: string,
  traveresed: string[],
  focused: string[]
}

const resetInitialState: ResetReducer = {
  loading: false,
  error: '',
  email: '',
  password: '',
  passwordAgain: '',
  traveresed: [],
  focused: []
}

export const reset = (state = resetInitialState, action: any) => {
  switch (action.type) {
    case RESET_ERROR: {
      return {
        ...state,
        error: action.error
      }
    }
    case RESET_INPUT_CHANGE: {
      return {
        ...state,
        [action.key]: action.value
      }
    }
    case RESET_FOCUS_CHANGE: {
      const toAdd = action.focus
      let { traveresed } = state
      if (action.focus) {
        traveresed = traveresed.concat(action.focusId)
      }
      return {
        ...state,
        traveresed,
        focused: toAdd ? state.focused.concat(action.focusId) : without(state.focused, action.focusId)
      }
    }
    case RESET_LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }
    default:
      return state
  }
}

export interface ResetPasswordReducerState {
  requestReset: RequestResetReducer,
  reset: ResetReducer
}
const combinedReducers = combineReducers({ requestReset, reset })
export default combinedReducers
