import { TranslationFunction } from '@zooz/react-localize'
import { Managment } from '@payu/paymentsos-types'
import { createSelector } from 'reselect'

import errors from '../../../constants/Errors'
import validatePassword from '../../../helpers/passwordValidation'
import { PortalState } from '../../../redux/store'
import { Passwords, UserLocalizationStore, IsValid } from './reducers'

export const requiredFields = (state: PortalState): Passwords => ({
  currentPassword: state.userProfileReducer.passwords.currentPassword,
  newPassword: state.userProfileReducer.passwords.newPassword,
  repeatPassword: state.userProfileReducer.passwords.repeatPassword
})

export const isPasswordFilled = createSelector(
  requiredFields,
  (passwords): boolean => (
    Object.values(passwords)
      .reduce((acc, passwordValue): boolean => !!passwordValue || acc, false)
  )
)

export type InvalidFieldMessage = Partial<Record<keyof Passwords, (t: TranslationFunction) => string>>

export const requiredFieldsValidation = createSelector(
  requiredFields,
  isPasswordFilled,
  ({ currentPassword, newPassword, repeatPassword }, isValidate): InvalidFieldMessage => {
    const fields: InvalidFieldMessage = {}
    if (!isValidate) {
      return fields
    }
    if (!currentPassword) {
      fields.currentPassword = errors.password.required
    }
    if (!newPassword) {
      fields.newPassword = errors.password.required
    }
    if (!repeatPassword) {
      fields.repeatPassword = errors.confirmPassword.required
    }
    return fields
  }
)

export const invalidFieldsValidation = createSelector(
  requiredFields,
  isPasswordFilled,
  ({ newPassword, repeatPassword }, isValidate): InvalidFieldMessage => {
    const fields: InvalidFieldMessage = {}
    if (!isValidate) {
      return fields
    }
    if (newPassword) {
      const newPasswordErrorMessage = validatePassword(newPassword).message
      if (newPasswordErrorMessage) {
        fields.newPassword = newPasswordErrorMessage
      }
    }
    if (repeatPassword && newPassword !== repeatPassword) {
      fields.repeatPassword = errors.confirmPassword.invalid
    }

    return fields
  }
)

export const focusedField = (state: PortalState): string[] => state.userProfileReducer.focused

export const getProfile = (state: PortalState): Managment.UserResource => state.userProfileReducer.profile

export const getPasswords = (state: PortalState): Passwords => state.userProfileReducer.passwords

export const getLocalization = (state: PortalState): UserLocalizationStore => state.userProfileReducer.localization

export const getUserPredefinedLanguage = (state: PortalState): string => state.userProfileReducer.localization.language

export const getIsValid = (state: PortalState): IsValid => state.userProfileReducer.isValid
