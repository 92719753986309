import { isString } from 'lodash'

class StorageMock {
  data = {}

  setItem (id, val) {
    // eslint-disable-next-line no-return-assign
    return this.data[id] = isString(val) ? val : JSON.stringify(val)
  }

  getItem (id) {
    // eslint-disable-next-line no-prototype-builtins
    return this.data.hasOwnProperty(id) ? this.data[id] : null
  }

  removeItem (id) {
    return delete this.data[id]
  }

  clear () {
    const self = this.data
    Object.keys(this.data).forEach((key) => { self.removeItem(key) })
  }
}

const isLocalStorageSupported = () => {
  try {
    return 'localStorage' in window && window.localStorage != null
  } catch (error) {
    return false
  }
}

export const storage = isLocalStorageSupported() ? localStorage : new StorageMock()
