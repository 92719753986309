// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__wrapper___TfKo0{height:70px;flex-basis:70px;flex-grow:0;flex-shrink:0;text-align:left;display:flex;align-items:center;overflow:hidden;margin:0 17px 29px;position:relative}.style__wrapper___TfKo0 svg{height:100%;flex-shrink:0;flex-grow:0}.style__wrapper___TfKo0 svg path{fill:#fff}.style__large___hzGIv,.style__small___oWwsH{position:absolute;left:0;transition:opacity .7s}.style__large___hzGIv{opacity:1;width:100%;display:flex;margin-right:4px;justify-content:center}.style__small___oWwsH{opacity:0}.style__collapsed___F7HPB .style__large___hzGIv{opacity:0}.style__collapsed___F7HPB .style__small___oWwsH{opacity:1}`, "",{"version":3,"sources":["webpack://./src/features/Sidebar/components/Logo/style.scss"],"names":[],"mappings":"AAEA,wBACE,WAAA,CACA,eAAA,CACA,WAAA,CACA,aAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CAEA,4BACE,WAAA,CACA,aAAA,CACA,WAAA,CAEA,iCACE,SAAA,CAQN,4CAEE,iBAAA,CACA,MAAA,CACA,sBAAA,CAGF,sBACE,SAAA,CACA,UAAA,CACA,YAAA,CACA,gBAAA,CACA,sBAAA,CAGF,sBACE,SAAA,CAIA,gDACE,SAAA,CAGF,gDACE,SAAA","sourcesContent":["@import \"../../constants\";\n\n.wrapper {\n  height: 70px;\n  flex-basis: 70px;\n  flex-grow: 0;\n  flex-shrink: 0;\n  text-align: left;\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  margin: 0 17px 29px;\n  position: relative;\n\n  svg {\n    height: 100%;\n    flex-shrink: 0;\n    flex-grow: 0;\n\n    path {\n      fill: white;\n    }\n  }\n}\n\n$largeLogoSelector: \".large\";\n$smallLogoSelector: \".small\";\n\n#{$largeLogoSelector},\n#{$smallLogoSelector} {\n  position: absolute;\n  left: 0;\n  transition: opacity $animation-time;\n}\n\n#{$largeLogoSelector} {\n  opacity: 1;\n  width: 100%;\n  display: flex;\n  margin-right: 4px;\n  justify-content: center;\n}\n\n#{$smallLogoSelector} {\n  opacity: 0;\n}\n\n.collapsed {\n  #{$largeLogoSelector} {\n    opacity: 0;\n  }\n\n  #{$smallLogoSelector} {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style__wrapper___TfKo0`,
	"large": `style__large___hzGIv`,
	"small": `style__small___oWwsH`,
	"collapsed": `style__collapsed___F7HPB`
};
export default ___CSS_LOADER_EXPORT___;
