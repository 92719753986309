import { createSelector } from 'reselect'

import { TEST, LIVE } from '../../constants/environments'
import { isAccountLive } from '../account/selectors'
import { EnvironmentReducer } from './reducer'

interface Store {
  liveTestReducer: EnvironmentReducer
}

export const isLive = (state: Partial<Store>): boolean => state.liveTestReducer.isLive
export const getEnvironment = createSelector(isLive, (isEnvLive): 'test' | 'live' => {
  if (isEnvLive == null) {
    return null
  }
  return isEnvLive ? LIVE : TEST
})
export const defaultEnv = createSelector(isAccountLive, activatedAccount => activatedAccount ? LIVE : TEST)
