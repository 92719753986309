import produce, { Draft } from 'immer'

import {
  ActionTypes,
  SignUpRequestAction,
  HideSignUpSuccessMessageAction,
  SignUpRequestFailAction,
  SignUpRequestSuccessAction,
  ClearSignUpErrorMessageAction,
  SignUpInitAction,
  SignUpRequestInfo
} from './actions'

export interface SignUpState {
  isSigningUp: boolean;
  showSuccessMessage: boolean;
  formErrorMessage: string;
  signUpInfo: Omit<SignUpRequestInfo, 'leadSource'>;
}

type SignUpActions = SignUpRequestAction
                    | HideSignUpSuccessMessageAction
                    | SignUpRequestFailAction
                    | SignUpRequestSuccessAction
                    | ClearSignUpErrorMessageAction
                    | SignUpInitAction

const initialState: SignUpState = {
  isSigningUp: false,
  showSuccessMessage: false,
  formErrorMessage: '',
  signUpInfo: {
    firstName: '',
    lastName: '',
    merchantDomain: '',
    merchantName: '',
    userEmail: ''
  }
}

export default produce((draft: Draft<SignUpState>, action: SignUpActions): void | SignUpState => {
  switch (action.type) {
    case (ActionTypes.SIGN_UP_REQUEST): {
      draft.isSigningUp = true
      const { leadSource, ...info } = (action as SignUpRequestAction).payload
      draft.signUpInfo = info
      break
    }
    case (ActionTypes.SIGN_UP_REQUEST_SUCCESS):
      draft.isSigningUp = false
      draft.showSuccessMessage = true
      break
    case (ActionTypes.SIGN_UP_REQUEST_FAIL):
      draft.isSigningUp = false
      draft.formErrorMessage = (action as SignUpRequestFailAction).payload.errorMessage
      break
    case (ActionTypes.SIGN_UP_HIDE_SUCCESS_MESSAGE):
      draft.showSuccessMessage = false
      break
    case (ActionTypes.SIGN_UP_CLEAR_FAIL_MESSAGE):
      draft.formErrorMessage = ''
      break
    case (ActionTypes.SIGN_UP_INIT):
      return initialState
  }
}, initialState)
