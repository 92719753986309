import React from 'react'
import { SideNote } from '@zooz/generic-ui-components'

import css from './breadcrumbs.scss'

const CrumbSeparator: React.FC = () => (
  <SideNote obscure className={css['crumb-separator']}>/</SideNote>
)

export default CrumbSeparator
