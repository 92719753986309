import produce, { Draft } from 'immer'

import {
  ActionTypes,
  VerifySignUpAction,
  VerifySignUpFailAction,
  VerifySignUpSuccessAction
} from './actions'

export interface SignUpVerifyState {
  isVerifyingSignUp: boolean;
  verifySignUpErrorMessage: string;
}

const initialState = {
  isVerifyingSignUp: false,
  verifySignUpErrorMessage: ''
}

type SignUpVerifyActions = VerifySignUpAction
                          | VerifySignUpFailAction
                          | VerifySignUpSuccessAction

export default produce((draft: Draft<SignUpVerifyState>, action: SignUpVerifyActions) => {
  switch (action.type) {
    case ActionTypes.SIGN_UP_VERIFY_REQUEST:
      draft.isVerifyingSignUp = true
      draft.verifySignUpErrorMessage = ''
      break
    case ActionTypes.SIGN_UP_VERIFY_SUCCESS:
      draft.isVerifyingSignUp = false
      break
    case ActionTypes.SIGN_UP_VERIFY_FAIL:
      draft.isVerifyingSignUp = false
      draft.verifySignUpErrorMessage = (action as VerifySignUpFailAction).payload.errorMessage
  }
}, initialState)
