import { get } from 'lodash'
import { call, put, takeEvery, select } from 'redux-saga/effects'

import AccountsApi from '../../api/AccountsApi'
import Errors from '../../constants/Errors'
import localize from '../../features/Localize/localize'
import { types as accountActions } from '../account/actions'
import { isAccountLive, isSubAccount } from '../account/selectors'
import { activationsFetchSent, activationsFetchSuccess, activationsFetchFailed, fetchActivations } from './actions'
import { FETCH_ACTIVATIONS } from './types'
import messageDispatcher from '../../features/Messages/redux/messageDispatcher'

export function * fetchActivationsSaga () {
  try {
    yield put(activationsFetchSent())
    const activations = yield call(AccountsApi.fetchAccountActivations)
    yield put(activationsFetchSuccess(activations))
  } catch (err) {
    yield put(activationsFetchFailed())
    if (err.response && err.response.status !== 404) {
      messageDispatcher.addError({
        text: get(err, 'response.data.description') ||
          Errors.defaultError(localize.translate),
        err,
        // not logging error to sentry on 403 (causes too much noise)
        shouldLogError: err.response.status !== 403
      })
    }
  }
}

export function * fetchActivationsOnStartUpSaga () {
  const isLive = yield select(isAccountLive)
  const isSubaccount = yield select(isSubAccount)

  if (isLive && !isSubaccount) {
    yield put(fetchActivations())
  } else {
    yield put(activationsFetchFailed())
  }
}

export default function * activationsSaga () {
  yield takeEvery(FETCH_ACTIVATIONS, fetchActivationsSaga)
  yield takeEvery(accountActions.ACCOUNT_FETCH_SUCCESS, fetchActivationsOnStartUpSaga)
}
